import React, { useEffect } from "react";
import "../../App.css";
import CheckUser from "../common/check-user";
import { useUserLoginState } from "../../shared/context-api/hooks";
import moment from "moment";
import Loader from '../common/loader/loader';
import ModerationDashboard from './moderation-dashboard';
import { SubmitAllowed } from "../../models/submit-allocation";
import ModerationRequest, { ModerationRequestsSummary } from "../../models/moderation-request";
import * as dbrdHelper from '../../helpers/dashboard-helper'


export interface StateProps {
  moderationRequests?: ModerationRequest[];
  moderationRequestStatusCode?: number;
  moderationRequestsSummary: ModerationRequestsSummary;
  submitAllowed: SubmitAllowed
}

export interface DispatchProps {
  getModerationRequests: (
    learningProviderCode: string,
    status: number[],
    searchText: string
  ) => Promise<void>;
  getExamRequestsSummary: (learningProviderCode: string) => Promise<void>;
  submitAllocation: (allocationId: number, assessmentMethodType:number) => Promise<void>;
  checkSubmitAllowed: (allocationId: number, assessmentMethodType:number) => Promise<void>;
  getModerationRequestsSummary: (learningProviderCode: string) => Promise<void>;

}

type Props = DispatchProps & StateProps;

const DashboardComponent = (props: Props) => {
  const modUserLogin = useUserLoginState();
  const isValidUser =
    modUserLogin &&
    modUserLogin.userData &&
    modUserLogin.userData.isUserPermissionValid;
  const UserLockData =
    modUserLogin &&
    modUserLogin.userData &&
    modUserLogin.userData.lockedUntil;
  const isLockedUser = 
    (UserLockData && moment(UserLockData).isAfter()) || false;

  const [loading, setLoading] = React.useState(true);
  const moderationRequestStatusCode = props && props.moderationRequestStatusCode;
  const learningProviderId =
    modUserLogin &&
    modUserLogin.userData &&
    modUserLogin.userData.associatedLearningProviders &&
    modUserLogin.userData.associatedLearningProviders[0] &&
    modUserLogin.userData.associatedLearningProviders[0].learningProviderCode;
  
  const learningProviderLength =
    modUserLogin &&
    modUserLogin.userData && 
    modUserLogin.userData.associatedLearningProviders && 
    modUserLogin.userData.associatedLearningProviders.length;


  useEffect(() => {
    if(learningProviderLength === 1){
    if (learningProviderId) { 
      props && props.getModerationRequestsSummary(learningProviderId);
    }
  }else{ 
    const getCenterNameStorage =  localStorage.getItem("centerName");
  const getCenterName = getCenterNameStorage && getCenterNameStorage.substring(0, getCenterNameStorage.indexOf(' '));
    if (getCenterName) {
      props && props.getModerationRequestsSummary(getCenterName);
    }
    setLoading(false)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learningProviderId]);



  useEffect(() => {
    if (moderationRequestStatusCode && moderationRequestStatusCode === 200) {
      setLoading(false);
    }
    window.addEventListener('beforeunload', dbrdHelper.alertUser)
    window.addEventListener('unload', dbrdHelper.handleTabClosing)
        return () => {
            window.removeEventListener('beforeunload', dbrdHelper.alertUser)
            window.removeEventListener('unload', dbrdHelper.handleTabClosing)
        }
  }, [moderationRequestStatusCode]);

  document.title = "Learner Work Transfer - Dashboard";
  const { moderationRequests, moderationRequestsSummary } = props;

  return (
    <div>
      {isValidUser && !isLockedUser ? (
        <div className="contentWrapper">
          {loading ? (
            <Loader />
          ) : (
            <div>
              <ModerationDashboard
                {...moderationRequests}
                {...moderationRequestsSummary}
                {...props}
              />
            </div>
          )}
        </div>
      ) : (
        <CheckUser islockeduser={isLockedUser} />
      )}
    </div>
  );
};

export default DashboardComponent;
