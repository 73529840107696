import { connect } from "react-redux";
import { StoreState } from "../../store/store-state";
import { ThunkDispatch } from "redux-thunk";
import SubmitAllocation, { SubmitAllowed } from "../../models/submit-allocation";
import ModerationDashboardComponent from "./moderation-component";
import { getModerationRequestsSummary, getModerationRequests, ModerationRequestsAction } from "./ducks/moderation-dashboard.actions";

import {getExamRequestsSummary,submitAllocation, checkSubmitAllowed} from '../dashboard/ducks/dashboard.actions'
import ModerationRequest, { ModerationRequestsSummary } from "../../models/moderation-request";

export interface StateProps {
  moderationRequests?: ModerationRequest[];
  moderationRequestStatusCode?: number;
  moderationRequestsSummary: ModerationRequestsSummary;
  submitAllocation: SubmitAllocation;
  submitAllowed: SubmitAllowed;
}

export interface DispatchProps {
  submitAllocation: (allocationId: number, assessmentMethodType:number) => Promise<void>;
  checkSubmitAllowed: (allocationId: number, assessmentMethodType:number) => Promise<void>;
  getModerationRequests: (learningProviderCode: string, status : number[], searchText : string) => Promise<void>;
  getExamRequestsSummary: (learningProviderCode: string) => Promise<void>;
  getModerationRequestsSummary: (learningProviderCode: string) => Promise<void>;


}

const mapStateToProps = (state: StoreState): StateProps => {
  return {
    moderationRequests: state.moderationRequests,
    moderationRequestStatusCode: state.moderationRequestStatusCode,
    moderationRequestsSummary: state.moderationRequestsSummary,
    submitAllocation: state.submitAllocation,
    submitAllowed: state.submitAllowed,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<StoreState, void, ModerationRequestsAction>
): DispatchProps => ({
  getModerationRequests: (learningProviderCode: string, status : number[], searchText : string) =>
  dispatch(getModerationRequests(learningProviderCode, status, searchText)),
  getExamRequestsSummary: (learningProviderCode: string) =>
    dispatch(getExamRequestsSummary(learningProviderCode)),
  getModerationRequestsSummary: (learningProviderCode: string) =>
    dispatch(getModerationRequestsSummary(learningProviderCode)),
  submitAllocation: (allocationId: number, assessmentMethodType:number) =>
    dispatch(submitAllocation(allocationId, assessmentMethodType)),
  checkSubmitAllowed: (allocationId: number, assessmentMethodType:number) =>
    dispatch(checkSubmitAllowed(allocationId, assessmentMethodType))
});

export default connect(mapStateToProps, mapDispatchToProps)(ModerationDashboardComponent);
