import React, { useEffect, FormEvent } from "react";
import { useHistory } from "react-router";
import ExamRequest from "../../models/exam-request";
import {
  getViewPapersSavedSearch,
  dateFormatter,
  updateViewPapersSavedSearch,
  updateSavedSearch,
  deleteViewPapersSavedSearch,
  clearIntervals
} from "../../helpers/dashboard-request-helper";
// import AppContext from "../../shared/context-api/context";

// import * as _ from "lodash";
import Icon from "../../components/pearson/icon";
import Loader from "../../components/common/loader/loader";
import ViewRequestCard from "../../components/pearson/viewRequestCard";
import SearchBar from "../../components/pearson/search-bar";
import Pagination from "../../components/pearson/pagination";
import { ViewPapersSavedSearch } from "../../models/saved-search";
import config from '../../app-config'

import {
  Option,
  ToDoSearchOptions,
  InProgressSearchOptions,
  CompletedSearchOptions,
} from "../../models/option";
import { SubmitAllowed } from "../../models/submit-allocation";
import { useUserLoginState } from "../../shared/context-api/hooks";
import * as dbrdHelper from '../../helpers/dashboard-helper'


export interface StateProps {
  examRequests?: ExamRequest[];
  viewPapers: any;
  submitAllocationResponse: any;
}
export interface DispatchProps {
  getViewPapersDetails: (
    learningProviderCode: string,
    qualificationTypeCode: string,
    seriesCode: string,
    searchText: string,
    status: number[],
    pageNumber: number,
    pageSize: number
  ) => Promise<void>;
  submitAllocation: (allocationId: number, assessmentMethodType:number) => Promise<void>;
  checkSubmitAllowed?: (allocationId: number, assessmentMethodType:number) => Promise<void>;
  submitAllowed: SubmitAllowed;
}

interface Props extends DispatchProps, StateProps {}
const ViewPapersDetails = (props: Props) => {
  const history = useHistory();
  document.title = "Learner Work Transfer - View Papers";

  const { viewPapers } = props;
  const viewPapersData = viewPapers && viewPapers.reply;
  const [loading, setLoading] = React.useState(false);
  const [loadingContent, setLoadingContent] = React.useState(false);
  const [savedSearchText, setSaveSearchText] = React.useState("");
  const [savedSearchOption, setSaveSearchOption] = React.useState("");
  const [pageCount, setPageCount] = React.useState(viewPapersData && viewPapersData.totalPages);
  const isSubmitAllowed = props && props.submitAllowed && props.submitAllowed.isSubmitAllowed;
  const pageMaxSize = 10;
  const cPage = localStorage.getItem("currentPaperPage");
  
  const initPageNumber = cPage?parseInt(cPage):1; 
  const [pageNumber, setPageNumber] = React.useState(initPageNumber);
  let activePage = cPage?cPage:"1"
  const [acticePageNumber, setActivePageNumber] = React.useState(activePage);
  const [paginationData, setPaginationData] = React.useState(false)


  

//  console.log("props",props)
  const savedSearch = getViewPapersSavedSearch() as ViewPapersSavedSearch;
  // const context = useContext(AppContext);

  let learningProviderId = savedSearch.learningProviderId as string;
  let qualificationTypeCode = savedSearch.qualificationTypeCode as string;
  let seriesCode = savedSearch.seriesCode as string;
  let searchText = savedSearch.searchText as string;
  let selectedTabIndex = savedSearch.selectedTabIndex as string;
  let status = savedSearch.searchFilter as string;
  let searchFilter: any = [];

  let searchFilterOption: Option[] = [];
  if (selectedTabIndex === "0") { 
    searchFilterOption = ToDoSearchOptions;
    searchFilter = [1];
  } else if (selectedTabIndex === "1") {
    searchFilterOption = InProgressSearchOptions;
    if (status === "" || status === "All") {
      searchFilter = [2, 3, 4, 5, 6];
    } else if (status === "InProgress") {
      searchFilter = [2];
    } else if (status === "InProgress(LE)") {
      searchFilter = [3];
    } else if (status === "Submitting") {
      searchFilter = [4];
    } else if (status === "NotSubmitted") {
      searchFilter = [5];
    } else if (status === "Returned") {
      searchFilter = [6];
    }
  }
  if (selectedTabIndex === "2") {
    searchFilterOption = CompletedSearchOptions;
    searchFilter = [7];
  }

  const totalRecords = viewPapersData && viewPapersData.totalRecords;
  const totalPages = viewPapersData && viewPapersData.totalPages;
  useEffect(() => {
    const submitted = localStorage.getItem("submitted");
    setPaginationData(false)
    if(submitted === "true"){
      let tPages = true;
    if(totalPages){
      tPages = false
    }
    window.scrollTo(0, 0);
    setLoading(tPages);
    setSaveSearchOption(status);
    setSaveSearchText(searchText); 
    // activePage ="1";
    setActivePageNumber("1")
      props.getViewPapersDetails(
        learningProviderId,
        qualificationTypeCode,
        seriesCode,
        searchText,
        searchFilter,
        1,
        pageMaxSize
      );

      localStorage.setItem("submitted","false");
      const vPPaginationData = localStorage.getItem("vPPaginationData");
   
      setPaginationData(vPPaginationData === "true");
      // setPaginationData(true)

    }
  }, [props.submitAllocationResponse]);

  useEffect(() => {
    // console.log("props didmount", props, learningProviderId); 
    const submitted = localStorage.getItem("submitted")
    if(submitted === "true"){
      
      let tPages = true;
    if(totalPages){
      tPages = false
    }
    window.scrollTo(0, 0);
    setLoading(tPages);
    setSaveSearchOption(status);
    setSaveSearchText(searchText); 
    // activePage ="1";
    setActivePageNumber("1")
      props.getViewPapersDetails(
        learningProviderId,
        qualificationTypeCode,
        seriesCode,
        searchText,
        searchFilter,
        1,
        pageMaxSize
      );

      localStorage.setItem("submitted","false");
      // setPaginationData(true)

    }else{
      
    let tPages = true; 
    if(totalPages){
      tPages = false
    }
    window.scrollTo(0, 0);
    setLoading(tPages);
    setSaveSearchOption(status);
    setSaveSearchText(searchText); 
    setPageNumber(1)
    // setAct
    // activePage = "1";
    setPaginationData(true)
    props.getViewPapersDetails(
      learningProviderId,
      qualificationTypeCode,
      seriesCode,
      searchText,
      searchFilter,
      pageNumber,
      pageMaxSize
    );
    //setPaginationData(true)
    }
    const vPPaginationData = localStorage.getItem("vPPaginationData");
   
    setPaginationData(vPPaginationData === "true");
  // eslint-disable-next-line react-hooks/exhaustive-deps     

  }, []);

  React.useMemo(() => {
    //alert(1)
    setLoading(false);
    setLoadingContent(false);
    setPageCount(totalPages);
    const currentPaperPage = localStorage.getItem("currentPaperPage")
    currentPaperPage? setActivePageNumber(currentPaperPage):setActivePageNumber("1")
    const vPPaginationData = localStorage.getItem("vPPaginationData");
   
    setPaginationData(vPPaginationData === "true");
  // eslint-disable-next-line react-hooks/exhaustive-deps  
  window.addEventListener('beforeunload', dbrdHelper.alertUser)
  window.addEventListener('unload', dbrdHelper.handleTabClosing)
        return () => {
            window.removeEventListener('beforeunload', dbrdHelper.alertUser)
            window.removeEventListener('unload', dbrdHelper.handleTabClosing)
        }
  }, [props && props.viewPapers, pageCount]);
  

  useEffect(() => {
    
    const interval = setInterval(() => {
      let savedSearchFilter: any = [];
      const savedSearchData = getViewPapersSavedSearch() as ViewPapersSavedSearch;

      let savedSearchTxt:any = savedSearchData ?  savedSearchData.searchText:"";
      if (selectedTabIndex === "0") { 
        searchFilterOption = ToDoSearchOptions;
        savedSearchFilter = [1];
      } else if (selectedTabIndex === "1") {
        if (savedSearchData.searchFilter === "" || savedSearchData.searchFilter === "All") {
          savedSearchFilter = [2, 3, 4, 5, 6];
        } else if (savedSearchData.searchFilter === "InProgress") {
          savedSearchFilter = [2];
        } else if (savedSearchData.searchFilter === "InProgress(LE)") {
          savedSearchFilter = [3];
        } else if (savedSearchData.searchFilter === "Submitting") {
          savedSearchFilter = [4];
        } else if (savedSearchData.searchFilter === "NotSubmitted") {
          savedSearchFilter = [5];
        } else if (savedSearchData.searchFilter === "Returned") {
          savedSearchFilter = [6];
        }
      }
      if (selectedTabIndex === "2") {
        savedSearchFilter = [7];
      }
     // window.scrollTo(0, 0);
      // setLoading(true);
      const currPage = localStorage.getItem("currentPaperPage");
      let cPageData:any
      if(currPage){
        cPageData = currPage
      }else{
        cPageData = pageNumber
      } 
      
       setSaveSearchOption(status);
       setSaveSearchText(savedSearchTxt); 
       
      props.getViewPapersDetails(
        learningProviderId,
        qualificationTypeCode,
        seriesCode,
        savedSearchTxt,
        savedSearchFilter,
        cPageData,
        pageMaxSize
      );
    }, config.refreshTime);
    return () => {
      clearIntervals([interval]);
    }
  }, [])

  const navigateToViewLearners = (selectedAllocationId: string) => {
    let obj = {
      // searchText: savedSearchText,
      // searchFilter: savedSearchOption,
      // selectedTabIndex: savedSearchSelectedTabIndex,
      selectedAllocationId,
    };
    // utterly stupid, code smell circus!!!!
    if(savedSearchOption==="NOTA"){
      obj.selectedAllocationId = savedSearchOption;
    }

    updateViewPapersSavedSearch(obj);
    updateSavedSearch(obj);
    localStorage.setItem("viewPaperScreen","true")
    history.push("/view-learner");
  };

  const doSearch = (reqStatus:number[], searchOption: string, searchTxt: string, pageNum:number = 1):void => {
    props &&
    props.getViewPapersDetails(
      learningProviderId,
      qualificationTypeCode,
      seriesCode,
      searchTxt,
      reqStatus,
      pageNum,
      pageMaxSize
    );
    onSearchUpdate(searchOption, searchTxt);
  }

  const onSearch = (e: any, searchOption: string, searchTxt: string) => {
    setPageCount(0);
    setLoadingContent(true);
    if (selectedTabIndex === "0") {
      doSearch([1], searchOption, searchTxt);
    } else if (selectedTabIndex === "1") {
      switch (searchOption) {
        case "All":
          doSearch([2,3,4,5,6], searchOption, searchTxt);
          break;
        case "InProgress":
          doSearch([2], searchOption, searchTxt);
          break;
        case "InProgress(LE)":
          doSearch([3], searchOption, searchTxt);
          break;
        case "Submitting":
          doSearch([4], searchOption, searchTxt);
          break;
        case "NotSubmitted":
          doSearch([5], searchOption, searchTxt);
          break;
        case "Returned":
          doSearch([6], searchOption, searchTxt);
          break;
      }
    } else if (selectedTabIndex === "2") {
      doSearch([7], searchOption, searchTxt);
    }
  };

  const onTextChange = (event: FormEvent<HTMLInputElement>) => {
    const stxt =
      event && event.target && (event.target as HTMLInputElement).value;
    setSaveSearchText(stxt);
  };

  const onOptionChange = (opt: string = "") => {
    setSaveSearchOption(opt);
  };

  const submitRequest = (allocationId: number) => {
    setPaginationData(false)

    const assessmentMethodType = 1;
    props.submitAllocation(allocationId, assessmentMethodType)
  };
  const checkSubmitAllowed = (allocationId: number) => {
    const assessmentMethodType = 1;
    props.checkSubmitAllowed && props.checkSubmitAllowed(allocationId, assessmentMethodType)
  };
  const onSearchUpdate = (searchOption: string, searchTxt: string) => {
     setSaveSearchOption(searchOption);
     setSaveSearchText(searchTxt);
    updateViewPapersSavedSearch({
      searchFilter: searchOption,
      searchText: searchTxt,
    });
  };
  // const papersCount = 10;
  // const totalRecords = 100

  const onPaginationFetchPaper = (newPageNumber: number) => {
    // localStorage.setItem("currentPage", newPageNumber.toString());
    // console.log("seardchFilter",searchFilter)
     localStorage.setItem("currentPaperPage", newPageNumber.toString());
    setPageNumber(newPageNumber)
    // const searchOption = savedSearchOption && savedSearchOption.length ? savedSearchOption : "All";
    
    const savedSearchData = getViewPapersSavedSearch() as ViewPapersSavedSearch;
    //    const searchText = savedSearchText;
    const searchTxt = savedSearchData.searchText as string;

    props &&
    props.getViewPapersDetails(
      learningProviderId,
      qualificationTypeCode,
      seriesCode,
      searchTxt,
      searchFilter,
      newPageNumber,
      pageMaxSize
    );

    // doSearch(searchFilter, searchOption, searchText, newPageNumber);
    
    // if (selectedTabIndex === "0") {
    //   doSearch(searchFilter, searchOption, searchText, newPageNumber);
    // } else if (selectedTabIndex === "1") {
    //   switch (searchOption) {
    //     case "All":
    //       doSearch(searchFilter, searchOption, searchText, newPageNumber);
    //       break;
    //     case "InProgress":
    //        doSearch(searchFilter, searchOption, searchText, newPageNumber);
    //        break;
    //     case "InProgress(LE)":
    //        doSearch(searchFilter, searchOption, searchText, newPageNumber);
    //        break;
    //     case "Submitting":
    //        doSearch(searchFilter, searchOption, searchText, newPageNumber);
    //        break;
    //     case "NotSubmitted":
    //        doSearch(searchFilter, searchOption, searchText, newPageNumber);
    //        break;
    //     case "Returned":
    //        doSearch(searchFilter, searchOption, searchText, newPageNumber);
    //        break;
    //   }
    // } else if (selectedTabIndex === "2") {
    //   doSearch(searchFilter, searchOption, searchText, newPageNumber);
    // }
  };
  const centerName = localStorage.getItem("centerName")
  const userLoginstate = useUserLoginState();

  const learningCenter = 
    (userLoginstate && 
    userLoginstate.userData && 
    userLoginstate.userData.associatedLearningProviders) || [];

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        
        <div style={{ marginTop: "50px" }}>
         {(learningCenter && learningCenter.length > 1 && centerName) && ( 
        <div className="gr-grid-container ie-flex margin0" style={{ backgroundColor: "#d9d9d9", padding: "10px" }}>
          <div className="gr-col-one gr-col-span-two " style={{ paddingLeft: "18px" }}>Learning Provider</div>
          <div className="gr-col-one gr-col-span-five " style={{ paddingLeft: "18px",fontWeight:"bold" }}>  {centerName}
          </div>
        </div>)}
          <div>
            <SearchBar
              options={searchFilterOption}
              searchOption={
                savedSearch && savedSearch.searchFilter ? savedSearch.searchFilter : "All"
              }
              searchText={savedSearchText}
              placeholderText="Search"
              onSearch={onSearch}
              onTextChange={onTextChange}
              onOptionChange={onOptionChange}
              onSearchUpdate={onSearchUpdate}
              onViewAll={true}
            />
            <br />
            <br />
          </div>
          <BackToDashboardLink />
          {viewPapersData && (
            <div className="singleCenterHeader">
              <div className="singlecardLeftContent">
                {viewPapersData && viewPapersData.qualificationTypeName} (Total{" "}
                {viewPapersData.subjectCount} subjects,{" "}
                {viewPapersData.totalRecords} Papers)
              </div>
              {viewPapersData.seriesName && (
              <div className="singlecardRighttContent">
                Series {viewPapersData.seriesName}
              </div>)}
            </div>
          )}
          <div className="singleCenterCardWrapper">
            {viewPapersData &&
            viewPapersData.totalRecords > 0 &&
            !loadingContent ? (
              viewPapersData.papers.map((paper: any, key2: any) => {
                return (
                  <ViewRequestCard
                    key={key2}
                    name={paper.qualificationName}
                    unitInfo={
                      paper.unitCode +
                      " " +
                      paper.unitName
                    }
                    status={paper.status}
                    statusId={paper.statusId}
                    totalLearnerCount={paper.expectedLearnerCount}
                    learnerCount={paper.actualLearnerEvidenceCount}
                    // recLabel={`Allocated AA:`}
                    // recInfo={`${paper.aaNumber}${" "}${paper.aaName}`}
                    compLabel={`Submitted on`}
                    compInfo={`${
                      dateFormatter(paper.evidenceSubmittedOn).date
                    } at ${
                      dateFormatter(paper.evidenceSubmittedOn).reqTime
                    } by ${paper.evidenceSubmittedBy}`}
                    completionDate={
                      dateFormatter(paper.submissionDeadlineDate).date
                    }
                    submissionDate={
                      dateFormatter(paper.evidenceSubmittedOn).date
                    }
                    submissionDeadLineDate={
                      dateFormatter(paper.submissionDeadlineDate).date
                    }
                    AADeadlineDate={
                      dateFormatter(paper.submissionDeadlineDate).date
                    }
                    allocationId={paper.allocationId}
                    onViewLearners={navigateToViewLearners}
                    aaName={paper.aaName}
                    aaNumber={paper.aaNumber}
                    isThreeDotRequired={true}
                    evidenceScanningCount={paper.evidenceScanningCount}
                    evidenceAcceptedCount={paper.evidenceAcceptedCount}
                    evidenceRejectedCount={paper.evidenceRejectedCount}
                    onSubmitRequest={submitRequest}
                    checkSubmitAllowed={checkSubmitAllowed}
                    isSubmitAllowed={isSubmitAllowed}
                    returnedBy={paper.returnedBy}
                    returnedOn={paper.returnedOn}
                    userClassType={paper.userClassType}
                    returnUserClassType={paper.returnUserClassType}
                  />
                );
              })
            ) : (
              <Loader
                height={"50vh"}
                timeoutSecs={
                  viewPapersData && viewPapersData.totalRecords === 0
                    ? 2000
                    : 5000
                }
                message={"There are no requests available"}
              />
            )}
            <div className="paginationWrapper">
              {paginationData && totalRecords > 0 &&
                pageCount > 0 &&
                totalRecords > pageMaxSize && (
                  <Pagination
                    id="learner_pagination"
                    key={""}
                    totalPageCount={totalPages}
                    onPageChange={onPaginationFetchPaper}
                    totalRecords={totalRecords}
                    activePage = {acticePageNumber}
                  />
                )}
            </div>
          </div>

          <div style={{ minHeight: "15vh" }}>
            {/* Place the learners list here*/}
          </div>
          <BackToDashboardBottomLink />
        </div>
      )}
      <br /> <br /> <br />
    </div>
  );
  
};

const backToDashBoardPage = (history: any) => {
  deleteViewPapersSavedSearch();
  localStorage.removeItem("currentPaperPage");
  history.push("/");
};

const BackToDashboardLink = () => {
  const history = useHistory();

  const backToDashBoard = () => {
    backToDashBoardPage(history);
  };

  return (
    <span
      style={{
        cursor: "pointer",
        marginTop: "15px",
        float: "left",
        width: "13%",
      }}
      onClick={backToDashBoard.bind(null)}
    >
      <Icon size="18" icon="back" />
      <span className="backToDashBoardLink gr-semi-bold">
        &nbsp;&nbsp;&nbsp;Back to Dashboard
      </span>
    </span>
  );
};
const BackToDashboardBottomLink = () => {
  const history = useHistory();
  localStorage.removeItem("vPPaginationData");
  localStorage.removeItem("viewPaperScreen");

  const backToDashBoard = () => {
    backToDashBoardPage(history);
  };

  return (
    <div style={{ width: "100%", float: "left" }}>
      <span
        style={{ cursor: "pointer", marginTop: "15px" }}
        onClick={backToDashBoard.bind(null)}
      >
        <Icon size="18" icon="back" />
        <span className="backToDashBoardLink gr-semi-bold">
          &nbsp;&nbsp;&nbsp;Back to Dashboard
        </span>
      </span>
    </div>
  );
};

export default ViewPapersDetails;
