import React, { useEffect } from "react";
import Input from "../../components/pearson/input";
import Icon from "../../components/pearson/icon";
import { learnerFilterType } from "../../models/learner";
import { FILTER_LEARNER } from "../../shared/app-enums";
interface Props {
  currentFilter: learnerFilterType;
  updateCurrentFilter: (currentFilter: learnerFilterType) => void;
  allCount: number;
  selCount: number;
  unselCount: number;
  linkedCount: number;
}
const MSFFilters = (props: Props) => {
  const handleFilterBtnClick = (key: learnerFilterType) => () => {
    props.updateCurrentFilter(key);
  };
  const filters = [
    {
      key: FILTER_LEARNER.ALL,
      label: "All",
      count: props.allCount,
    },
    {
      key: FILTER_LEARNER.SEL,
      label: "Selected",
      count: props.selCount,
    },
    {
      key: FILTER_LEARNER.UNSEL,
      label: "Unselected",
      count: props.unselCount,
    },
    {
      key: FILTER_LEARNER.LNKD,
      label: "Linked",
      count: props.linkedCount,
    },
  ];

  return (
    <div>
      <div style={{ width: "100%" }}>
        <div className="">
          {filters.map((item,i) => (
            <div
              key={i} 
              className={`allLearnersFilter ${
                props.currentFilter === item.key ? "active" : ""
              }`}
              onClick={handleFilterBtnClick(item.key)}
            >
              {item.label} ({item.count})
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default MSFFilters;
