import * as React from "react";
import { useHistory } from "react-router";
import Icon from "../../components/pearson/icon";
import "./Impersonating.css";

export interface ImpersonatingProps {
    behalfOf: string;   
    backText: string;
    backLink: string;
    page:string;
}

export const Impersonating: React.FC<ImpersonatingProps> = ({
    behalfOf,
    backText,
    backLink,
    page
}: ImpersonatingProps) => {

    const BackToDashboardLink = (props: any) => {
        const history = useHistory();
        const backToDashBoard = () => {
          history.push(backLink);          
          if(page === "VL"){ 
            localStorage.removeItem("viewLearnerGetData")
            localStorage.removeItem("currentLearnerPage");
            localStorage.removeItem("vlpaginationData");
          }
        };
      
        return (backText.length)?(
          <span style={{ marginTop: "3px", width: "100%", float: "left" }} onClick={backToDashBoard.bind(null)}>
            {/* <a
              href=""
              onClick={backToDashBoard.bind(null)}
              style={{ textDecoration: "none" }}
            > */}
              <Icon size="18" icon="back" />
              <span className="backToDashBoardLink gr-semi-bold">
                &nbsp;&nbsp;&nbsp;{backText}
              </span>
            {/* </a> */}
          </span>
        ):(<><span style={{marginLeft:"150px"}}>&nbsp;</span></>);
      };

    return (
        <div className="impersonating-banner">
            <div className="impersonating-link">
                <BackToDashboardLink isViewAll={backLink} />
            </div>
            <div className="impersonating-header">
                { behalfOf.length > 0 && <span>You are acting on behalf of {behalfOf}</span> }
            </div>
        </div>
    );
};

export default Impersonating;
