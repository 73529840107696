import React, { FormEvent, useEffect }  from "react";
import { useHistory } from "react-router";
import { Button, Card, Link, Option, Pagination, Select, TextInput } from "ukas.uux.react-components";
import Loader from "../../components/common/loader/loader";
import { Series } from "../../models/series";
import { EvidenceSearchCriteria, EvidenceSearchResult, EvidenceAllocation } from "../../models/evidence";
import { updateSavedSearch } from "../../helpers/dashboard-request-helper";
import * as dbrdHelper from '../../helpers/dashboard-helper'
import './search-evidence.css';
import { isEmpty } from "lodash";
import * as feature from '../../helpers/get-features-helper';
import { MODERATION } from "../../components/pearson/shared/feature-constants";

export interface StateProps {
    selectedSeries: string;
    productText: string;
    assessmentAssociateText: string;
    learningProviderText: string;
    series: Series[];
    evidenceSearchResult: EvidenceSearchResult | null;
}

export interface DispatchProps {
    getSeries: () => Promise<void>;
    searchEvidence: (criteria: EvidenceSearchCriteria) => Promise<void>;
}
  
interface Props extends DispatchProps, StateProps {}

const SearchEvidence = (props: Props) => {

    const history = useHistory();
    const currentPageStr = localStorage.getItem("current_Interal_page");
    const currentPage = currentPageStr && parseInt(currentPageStr)
    const [searched, setSearched] = React.useState(false);
    const [searching, setSearching] = React.useState(false);
    const [pageNumber, setPageNumber] = React.useState(currentPage?currentPage:1);
    const [selectedSeries, setSelectedSeries] = React.useState(props.selectedSeries);
    const [resultShowSeries, setResultShowSeries] = React.useState(props.selectedSeries);
    const [selectedAsssessmentMethod, setSelectedAsssessmentMethod] = React.useState(0);
    const [productText, setProductText] = React.useState(props.productText);
    const [assessmentAssociateText, setAssessmentAssociateText] = React.useState(props.assessmentAssociateText);
    const [learningProviderText, setLearningProviderText] = React.useState(props.learningProviderText);
    const [initialSearch, setInitialSearch] = React.useState(false);
    localStorage.removeItem("viewLearnerGetData")

    useEffect(() => {
        props.getSeries().then(()=> { getSavedEvidenceSearch(); });
        
    // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, []);

    const isSeriesSelected = (): boolean => {
        if (selectedSeries !== "0") {
            return true;
        }
        return false;
    };

    const isSearchAvailable = (): boolean => {
        if (isSeriesSelected() && productText.length > 0 && learningProviderText.length > 0
            && !isEmpty(productText.trim()) && !isEmpty(learningProviderText.trim())) {
            return true;
        }
        return false;
    };

    const selectedSeriesName = (): string => {
        const seriesName = props.series.find(s => s.seriesCode === selectedSeries)?.seriesName
        return seriesName ? seriesName : '';
    };

    const allocationAssessmentMethodName = (allocation: EvidenceAllocation): string => {
        switch(allocation.assessmentMethodType) {
            case 1:
                return 'Examination ';
            case 2:
                return 'Moderation';
            case 3:
                return 'Verification';
            default:
                return 'Unknown';
          } 
    };

    const getSearchCriteria = (): EvidenceSearchCriteria => {
        return{
            seriesCode: selectedSeries,
            seriesName: selectedSeriesName(),
            searchPaper: productText.trim(),
            searchLearningProvider: learningProviderText.trim(),
            searchAa: assessmentAssociateText.trim(),
            assessmentMethodType: selectedAsssessmentMethod,
            pageNumber: pageNumber,
            pageSize: 6
        };
    };

    const getSearchCriteriaFromLocStorage = (pageNo:number): EvidenceSearchCriteria => {
        const savedSearchCriteria: EvidenceSearchCriteria = JSON.parse(localStorage.getItem("savedEvidenceSearch") + "");
        return{
            seriesCode: savedSearchCriteria.seriesCode,
            seriesName: savedSearchCriteria.seriesName,
            searchPaper: savedSearchCriteria.searchPaper,
            searchLearningProvider: savedSearchCriteria.searchLearningProvider,
            searchAa: savedSearchCriteria.searchAa,
            assessmentMethodType: selectedAsssessmentMethod,
            pageNumber: pageNo,
            pageSize: 6
        };
    };

    const intitialSearch = () => {
        setSearched(false);
        setSearching(true);
        setResultShowSeries(selectedSeriesName())
        setInitialSearch(true);
        setPageNumber(1);
        localStorage.removeItem('current_Interal_page')
        // search().finally (() => {
        //     setSearched(true);
        //     setSearching(false);
        // });
    };

    const search = (): Promise<void> => {
        updateSavedEvidenceSearch();
        let searchCriteria = getSearchCriteria();
        return props.searchEvidence(searchCriteria);
    };

    const clearSearch = () => {
        setSearched(false);
        setSelectedSeries("0");
        setProductText("");
        setAssessmentAssociateText("");
        setLearningProviderText("");
        setSelectedAsssessmentMethod(0);
        setPageNumber(1);
        setResultShowSeries("")
        // updateSavedEvidenceSearch();
        localStorage.removeItem("savedEvidenceSearch");
    };

    const changePage = (pageNo: number) => {
        setPageNumber(pageNo);
        localStorage.setItem("current_Interal_page",pageNo.toString());
        let searchCriteria = getSearchCriteriaFromLocStorage(pageNo);
        props &&  props.searchEvidence(searchCriteria);


    };

    // Added use effect so page number change accuratly affects page
    useEffect(() => {
        if (searched) {
            let searchCriteria = getSearchCriteriaFromLocStorage(1);
            props &&  props.searchEvidence(searchCriteria);
        }
        if (initialSearch) {
          search().finally (() => { 
            setSearched(true);
            setSearching(false); 
            setInitialSearch(false); 
        }
    );
  }
  
    
  window.addEventListener('beforeunload', dbrdHelper.alertUser)
  window.addEventListener('unload', dbrdHelper.handleTabClosing)
        return () => {
            window.removeEventListener('beforeunload', dbrdHelper.alertUser)
            window.removeEventListener('unload', dbrdHelper.handleTabClosing)
        }
// eslint-disable-next-line react-hooks/exhaustive-deps  
}, [ selectedAsssessmentMethod, initialSearch]);

    const currentNoPages = (): number => {
        const totalPages = props.evidenceSearchResult?.totalPages;
        return totalPages ? totalPages : 0;
    };

    const getSavedEvidenceSearch = () => {
        const savedSearchCriteria: EvidenceSearchCriteria = JSON.parse(localStorage.getItem("savedEvidenceSearch") + "");
        const current_Interal_page = localStorage.getItem("current_Interal_page")
        
        if (savedSearchCriteria && savedSearchCriteria.seriesCode !== "0") {
            savedSearchCriteria.pageNumber = current_Interal_page?parseInt(current_Interal_page):1
            setSearched(false);
            setSearching(true);
            setSelectedSeries(savedSearchCriteria.seriesCode);
            setProductText(savedSearchCriteria.searchPaper);
            setAssessmentAssociateText(savedSearchCriteria.searchAa);
            setLearningProviderText(savedSearchCriteria.searchLearningProvider);
            setSelectedAsssessmentMethod(savedSearchCriteria.assessmentMethodType);
            setResultShowSeries(savedSearchCriteria.seriesName)
           // setPageNumber(1);
            props.searchEvidence(savedSearchCriteria).finally (() => {
                setSearched(true);
                setSearching(false);
            });
        }
    };
      
    const updateSavedEvidenceSearch = () => {
        const searchCriteria = getSearchCriteria();
        localStorage.setItem("savedEvidenceSearch", JSON.stringify(searchCriteria));
    };

    const goToAllocation = (allocation: EvidenceAllocation) => {

        let obj = {
            selectedAllocationId:  allocation.allocationId.toString()
        }
        updateSavedSearch(obj);
        allocation.assessmentMethodType === 1 ? dbrdHelper.setCurrentAssessMethod("examination") : dbrdHelper.setCurrentAssessMethod("moderation");
        history.push('/view-learner');
    }

    const clickFilterButton = (allocationAssessmentMethod:number) =>{
        setPageNumber(1)
        setSelectedAsssessmentMethod(allocationAssessmentMethod)
    }
    
    return (
        <div>
            <br/>
            <div className="gr-grid-container">
                <div className="gr-row-one gr-col-one gr-col-span-four">
                    <h3 className="gr-h3 semi-bold-650" style={{lineHeight:"0px"}}>Search</h3>
                </div>
                <div className="gr-row-two gr-col-one gr-col-span-four">
                    <Select id="SeriesSelect" label="Select a series*" value={selectedSeries} onChange={ (e: FormEvent<HTMLSelectElement>) => setSelectedSeries(e.currentTarget.value) }>
                        <Option value="0">
                            Select
                        </Option>
                        {props.series
                            .sort((a : Series, b: Series) => { return a.seriesCode.localeCompare(b.seriesCode); })
                            .reverse()
                            .map(function(seriesItem: Series) {
                            return (
                                <Option value={seriesItem.seriesCode} key={seriesItem.seriesCode}>
                                    {seriesItem.seriesName}
                                </Option>
                            )})
                        }
                    </Select>
                </div>
                <div className="gr-row-three gr-col-one gr-col-span-four">
                    <TextInput id="ProductText" 
                        label="Paper ID / Name*"
                        value={productText} 
                        disabled={!isSeriesSelected()}                    
                        onChange={ (e: FormEvent<HTMLInputElement>) => setProductText(e.currentTarget.value) } />
                </div>
                <div className="gr-row-three gr-col-one gr-col-span-four">
                    <TextInput id="LPText" 
                        label="Learning Provider ID / Name*" 
                        value={learningProviderText} 
                        disabled={!isSeriesSelected()}
                        onChange={ (e: FormEvent<HTMLInputElement>) => setLearningProviderText(e.currentTarget.value) } />   
                </div>
                <div className="gr-row-three gr-col-one gr-col-span-four">
                    <TextInput id="AAText" 
                        label="AA ID / Name" 
                        value={assessmentAssociateText} 
                        disabled={!isSeriesSelected()}
                        onChange={ (e: FormEvent<HTMLInputElement>) => setAssessmentAssociateText(e.currentTarget.value) } />
                </div>
                <div className="gr-row-four gr-col-one gr-col-span-twelve">
                    <Button color="secondary" type="button"
                        disabled={!isSearchAvailable()} 
                        onClick={intitialSearch}>Search</Button>
                    <Button size="large" type="button" onClick={clearSearch}>Clear</Button>
                </div>
            </div>
            <br/>
            {searched && props.evidenceSearchResult &&
                <div className="gr-grid-container ie-flex">
                    <div className="gr-row-one gr-col-one gr-col-span-six"> 
             <div className="results-message">Showing {props.evidenceSearchResult.totalRecords} results for series: {resultShowSeries}</div>
                    </div>
                    <div className="gr-row-one gr-col-seven gr-col-span-six">
                        <div className="gr-form-element input">
                            <div className={selectedAsssessmentMethod === 0? "allLearnersFilter active":"allLearnersFilter"} id="allLearners" onClick={ (e: FormEvent<HTMLDivElement>) => clickFilterButton(0) }>All Requests ({props.evidenceSearchResult.allocationsCount.allRequestsCount})</div>
                            <div className={selectedAsssessmentMethod === 1? "allLearnersFilter active":"allLearnersFilter"} id="examination" onClick={ (e: FormEvent<HTMLDivElement>) => clickFilterButton(1)}>Examination ({props.evidenceSearchResult.allocationsCount.examinationCount})</div>
                            { feature.getFeatureFromStorage(MODERATION) &&  
                            <div className={selectedAsssessmentMethod === 2? "allLearnersFilter active":"allLearnersFilter"} id="moderation" onClick={ (e: FormEvent<HTMLDivElement>) => clickFilterButton(2) }>Moderation ({props.evidenceSearchResult.allocationsCount.moderationCount})</div> }
                        </div>
                    </div>
                </div>
            }
            <br/>
            { searched && props.evidenceSearchResult && props.evidenceSearchResult.allocations.length > 0 &&
            <div>
                <div className="gr-grid-container gr-grid-container-uk ie-flex">
                    {searched && props.evidenceSearchResult?.allocations.map(function(allocation: EvidenceAllocation) {
                        return (
                            <Card key={allocation.allocationId}>
                                <div className="internalUserHeadingText"><span className="gr-neutral-med-one gr-font-normal">{allocation.qualificationTypeName} {allocation.qualificationName} </span>
                                <span className="gr-neutral-med-none semi-bold-650"> {allocation.unitCode} {allocation.unitName}</span></div>
                                <div style={{fontSize:"12px"}}>
                                    <div className="gr-neutral-high-one" style={{ color: "rgb(106, 106, 106)"}}>{allocation.learningProviderCode} {allocation.learningProviderName}</div>
                                    <div className="gr-neutral-high-one" style={{ color: "rgb(106, 106, 106)" }}>
                                            {
                                                allocation.aaNumber === "0" 
                                                ? <strong>UNALLOCATED</strong>
                                                : `${allocation.aaNumber} ${allocation.aaName}`
                                            }
                                        </div>
                                </div>
                                <span>{allocationAssessmentMethodName(allocation)}</span>
                                <hr className="horizontal-rule"/>
                                <span className="gr-neutral-med-one">Status: </span><span className={allocation.statusId === 5 ? "notSubmitWrapper" : "statusWrapper"} style={{padding:"3px"}}>{allocation.status}</span>
                                <span className="float-right"><Link href="javascript:void(0)" onClick={ (e: React.MouseEvent<HTMLElement>) => goToAllocation(allocation)}>Act on behalf</Link></span>
                            </Card>
                        )})
                    }
                </div>
                <br />
                </div>
            }
            { searching &&
                <Loader
                  height={"50vh"}
                  timeoutSecs={
                    props.evidenceSearchResult && props.evidenceSearchResult.allocations.length === 0
                      ? 2000
                      : 5000
                  }
                  message={"There are no learners available"}
                />
            }
            { searched && currentNoPages() > 1 &&
                
                <div className="gr-grid-container ie-flex">
                    {currentPage ? (
                   <div className=" gr-col-twelve margin0">
                    <Pagination noPages={currentNoPages()} maxSize={10} newPage={changePage} nextPage={changePage} prevPage={changePage} currentPage={currentPage}   />
                    <div className="showing-messsage">Showing {currentPage} of {currentNoPages()}</div>
                    </div>
                    ):(
                        <div className=" gr-col-twelve margin0">
                        <Pagination noPages={currentNoPages()} maxSize={10} newPage={changePage} nextPage={changePage} prevPage={changePage}    /> 
                        <div className="showing-messsage">Showing {pageNumber} of {currentNoPages()}</div>
                        </div>
                    )}
                     {/* {currentPage ?(
                    <div className="showing-messsage">Showing {currentPage} of {currentNoPages()}</div>):(<div className="showing-messsage">Showing {pageNumber} of {currentNoPages()}</div>)} */}
                </div>
            }
            { searched && props.evidenceSearchResult && props.evidenceSearchResult.allocations.length === 0 &&
                <div className="gr-grid-container ie-flex">
                    <div className="gr-row-one gr-col-one gr-col-span-twelve no-message">There are no evidence requests</div>
                </div>
            }
        </div>
    );
}

export default SearchEvidence;