import { connect } from "react-redux";
import { StoreState } from "../../store/store-state";
import AssessmentDeadlineConfig, { StateProps, DispatchProps } from "./assessment-deadline-config.component";
import {
  AssessmentDeadlineConfigAction,
  getQualificationDetails,
  getAssessmentDeadlines,
  getSeriesDetails
} from "./ducks/assessment-deadline-config.actions";
import { ThunkDispatch } from "redux-thunk";


const mapStateToProps = (state: StoreState): StateProps => {
  return {
    selectedSeries: "0",
    selectedAvailabilityWindow: "0",
    selectedQualificationType: "0",
    selectedSubject: "",
    seriesResult: state.assesssmentDeadlineConfigseries,
    configSearchResult: state.assessmentDeadlines,
    qualificationDetails: state.qualificationDetails,
    searchedComponent: ""
  };
};


const mapDispatchToProps = (
  dispatch: ThunkDispatch<StoreState, undefined, AssessmentDeadlineConfigAction>
): DispatchProps => {
  return {
    getQualificationDetails: (selectedSeries: string, selectedAvailabilityWindow: string) =>
      dispatch(getQualificationDetails(selectedSeries, selectedAvailabilityWindow)),
    getAssessmentDeadlines: (availabilityWindowCode: string, availabilityValue: string, qualificationTypeCode: string, qualificationCode: string, unitSearchText: string,  pageNumber: number, pageSize: number) =>
      dispatch(getAssessmentDeadlines(availabilityWindowCode, availabilityValue, qualificationTypeCode, qualificationCode, unitSearchText, pageNumber, pageSize)),
    getSeriesDetails: () =>
      dispatch(getSeriesDetails())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentDeadlineConfig);
