import React from "react";
import LearnerCard from "../../components/pearson/learnerCard"
import _ from 'lodash';
import { LearnerML } from "../../models/learner";



export const viewLearners = (
  manageLearnerDetails:any, 
  learnersPerPageNumber: number, 
  currentPageNumber: number, 
  onCheckboxChange: ((e: any) => void),
  currentSelectedLearners: number[] = []
) => { 
    // alert(currentPageNumber)
    const sortedRequests = sortLearners(manageLearnerDetails); 

    let viewTeamRender = sortedRequests.map((learnerData: any, index: any) => {
        //if(!requests.isIseries && !requests.isUploaded){
      const startFromIndex = learnersPerPageNumber*(currentPageNumber-1);
      const endIndex = (startFromIndex+learnersPerPageNumber)-1;
      if(startFromIndex <= index && endIndex >= index){
        return <div key={index+"s"}><LearnerCard 
          key={index} 
          isChecked={isCheckedManageLearner(learnerData, currentSelectedLearners)} 
          learner={learnerData} 
          onCheckboxChange={onCheckboxChange} />
        </div>
      }
    //}
    })
    return viewTeamRender;
  }

  const sortLearners = (learnersData: LearnerML[]) => {
    const sortedRequests = _.orderBy(learnersData, [
      learner => learner && learner.learnerCode 
    ]);
    return sortedRequests;
  }

  export const isCheckedManageLearner = (learnerData: LearnerML, currentSelectedLearners: number[]) => {
    // console.log("isCheckedManageLearner---",learnerData.code, _.includes(currentSelectedLearners, learnerData && learnerData.code))
    return (_.includes(currentSelectedLearners, learnerData && learnerData.learnerId))
  }

  export const searchLearnerGrouping = (learnerData: LearnerML[], searchOption: string, searchText: string) => {    
    let returnLearners: any
    
    
    // learnerData && learnerData.length 
    returnLearners = getGroupedRequests(learnerData, searchText);
    
    return returnLearners;
    
  }

  export const getGroupedRequests = (learnerData: LearnerML[], searchText: string) => {
    let searchedLearners = searchOnProperties(learnerData, searchText);
    // const sortedRequests = _.orderBy(searchedLearners, [
    //   request => request && request.qualificationTypeName && request.qualificationTypeName.toLowerCase(),
    //   request => request && request.qualificationName && request.qualificationName.toLowerCase(),
    //   request => request && request.qualificationCode && request.qualificationCode.toLowerCase(),
    //   request => request && request.unitCode && request.unitCode.toLowerCase(),
    //   request => request && request.unitName && request.unitName.toLowerCase(),
    //   "seriesCode"
    // ], ["asc", "asc", "asc", "asc", "asc", "desc"]);
  
    // const SEPERATOR = "~~";
  
    // const groupedReqeusts = _.groupBy(sortedRequests, item => `${item.unitCode}${SEPERATOR}${item.seriesCode}`)
    const sortedRequests = sortLearners(searchedLearners);    
    return sortedRequests;
    // return searchedLearners;
  }

  const searchOnProperties = (learnerData: LearnerML[], searchTerm: string) => {
    const fields = ["learnerCode","learnerFirstName", "learnerLastName", "learnerDob"];
  
    return _.filter(learnerData, request => {
      
      return _(request).pick(fields).some((searchstring: any) => {
  
        let result = false;
        result = _(searchstring).toLower().includes(searchTerm) || _(searchstring).toUpper().includes(searchTerm) || _(searchstring).includes(searchTerm);
        
        if (!result) {
          let heading = (searchstring.toString().toLowerCase()).split(" ");
          let search = (searchTerm.toString().toLowerCase()).split(" ");
          let intern = search.filter(word => heading.filter((term: string) => term.includes(word)))
          result = Boolean(intern.find(word => { return _(searchstring).toLower().includes(word) }))
        }
        return result;
      });
    })
  }

  