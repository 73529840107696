import * as React from "react";
import './threeDotsMenu.css'

interface Props {
    render : JSX.Element;
    checkSubmitAllowed?: (allocId: number) => void;
    allocId: string;
    statusId?: string;
    cssClass? : string;
    cssStyle? : React.CSSProperties;
}

const ThreeDotsMenu = (props: Props) => {
  const [showMenu, showMenuClick] = React.useState(false);

  const openMenu = () => {
    // console.log("statusId", props.statusId)
    // if(props.statusId !== "4" && props.statusId !== "5" ) {
      props && props.checkSubmitAllowed && props.checkSubmitAllowed(parseInt(props.allocId));
    // }
    showMenuClick(true);
    document.addEventListener("click", closeMenu);
  };

  const closeMenu = () => {
    props && props.checkSubmitAllowed && props.checkSubmitAllowed(0);
    showMenuClick(false);
    document.removeEventListener("click", closeMenu);
  };

  const getClassName = () => {
    return props &&
    props.cssClass &&
    props.cssClass.length?props.cssClass:"table-menu";
  }
  
  const getWidthCSS = ():React.CSSProperties => {
    const defaultWidthStyle = {minWidth:"140px", maxWidth:"155px"}
    return props &&
      props.cssStyle &&
      (props.cssStyle.minWidth && props.cssStyle.maxWidth) ?
        props.cssStyle :
        defaultWidthStyle;
  }  
  
  return (
    <div className={getClassName()}>
      <div className="vdots" onClick={showMenu ? closeMenu : openMenu}></div>
      {showMenu ? (
        <div className="bubble" style={getWidthCSS()}>	 
          <div className="link-holder">{props.render}</div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default React.memo(ThreeDotsMenu);
