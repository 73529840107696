import { connect } from "react-redux";
import { StoreState } from "../../store/store-state";
import ManageLearners from "./manage-learners.component";
import { LearnerML, SaveLearners } from "../../models/learner";
import { ThunkDispatch } from "redux-thunk";

import {
    ManageLearnersAction ,
    getManageLearnersDetails,
    updateManageLearner,
    saveManageLearnersDetails
  } from "./ducks/manage-learners.actions";


export interface StateProps {
  learners?: LearnerML[];
  currentlySelectedManageLearners?: number[];
  saveLearnersResponse: SaveLearners;
}

export interface DispatchProps {
  getLearners: (allocationId: string) => Promise<void>;
  updateManageLearner: (learnersData: number[]) => ManageLearnersAction
  saveManageLearners: (
    allocationId: string, 
    selectedLearners: number[], 
    unSelectedLearners: number[]
  ) => Promise<void>
}

const mapStateToProps = (state: StoreState): StateProps => {
  
  return {
    learners: state.manageLearnersDetails,
    currentlySelectedManageLearners: state.currentlySelectedManageLearners,
    saveLearnersResponse: state.saveLearners
  };
};

const mapDispatchToProps = (
    dispatch: ThunkDispatch<StoreState, undefined, ManageLearnersAction>
  ): DispatchProps => {
    return {
        getLearners: (allocationId: string) =>
          dispatch(
            getManageLearnersDetails(allocationId)
        ),
        updateManageLearner: (learnersData: number[]) =>
          dispatch(updateManageLearner(learnersData)),   
        saveManageLearners: (allocationId: string, selectedLearners: number[], unSelectedLearners: number[]) =>
          dispatch(
            saveManageLearnersDetails(
              allocationId, 
              selectedLearners, 
              unSelectedLearners
            )
          ),
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(ManageLearners);

