import * as React from "react";
import moment from "moment";
// import AppContext from '../../shared/context-api/context';
import { useUserLoginState } from "../../shared/context-api/hooks";
import CheckUser from "./check-user";
import {
  Route,
  RouteComponentProps,
  RouteProps,
  Redirect,
  Switch,
} from "react-router";
import ViewPapers from "../../features/view-papers/view-papers.container";
import DashboardComponent from "../dashboard/dashboard.container";
import FileUploadViewer from "../home/file-upload-viewer";
import AccessDenied from "../errorPages/access-denied";
import AddEvidence from "../../features/view-learner/view-learner.container";
import CallBack from "../callback/callback";
import SearchEvidence from '../../features/search-evidence/search-evidence.container';
import { HasReadAccess, HasWriteAccess, IsUserInternal } from './user-validation';
import { initialStateType } from "../../shared/context-api/provider";
import ModerationDashboard from "../moderation/moderation-dashboard.container";
import ManageLearners from "../../features/manage-learners/manage-learners.container";
import LinkLearners from "../../features/link-learners/link-learners.container";
import Maintenance from "../maintenance/maintenance";
import AssessmentDeadlineConfig from "../../features/assessment-deadline-config/assessment-deadline-config.container";
import ManageAssessmentDeadlineConfig from "../../features/manage-assessment-deadline-config/manage-assessment-deadline-config.container";
import { HasDlwtSetupReadAccess } from "../../components/common/user-validation";

export const Routes = () => {
  const userLoginstate = useUserLoginState();
  const UserLockData =
    userLoginstate &&
    userLoginstate.userData &&
    userLoginstate.userData.lockedUntil;
  const isLockedUser =
    (UserLockData && moment(UserLockData).isAfter()) || false;
  return (
    <div className="main-content">
      <CheckUser islockeduser={isLockedUser} />
      <Switch>
        <PrivateRoute
          exact={true}
          path="/access-denied/:utype/:islocked"
          component={AccessDenied}
        />
        <Route exact={true} path="/callback" component={CallBack} />
        <Route path="/view-papers" component={ViewPapers} />
        <Route path="/view-learner" component={AddEvidence} />
        <Route exact={true} path="/upload" component={FileUploadViewer} />
        <Route exact={true} path="/moderation" component={ModerationDashboard} />
        <Route path="/manage-learners" component={ManageLearners} />
        <Route path="/link-learners" component={LinkLearners} />
        <Route path="/maintenance" component={Maintenance} />
        <Route path="/assessment-deadline-config" component={AssessmentDeadlineConfig} />
        <Route path="/manage-assessment-deadline-config" component={ManageAssessmentDeadlineConfig} />
        <HomePageRoute path="/" />
      </Switch>
    </div>
  );
};

const HomePageRoute = ({ ...rest }: RouteProps) => {
  
  const userLoginstate = useUserLoginState();
  const render = (props: RouteComponentProps<{}>): React.ReactNode => {
   
    if (IsUserInternal(userLoginstate as initialStateType) && (HasWriteAccess(userLoginstate) || HasReadAccess(userLoginstate))) {
      return <SearchEvidence {...props} />;
    }
    if (IsUserInternal(userLoginstate as initialStateType) && HasDlwtSetupReadAccess(userLoginstate) ) {
      return <AssessmentDeadlineConfig {...props} />;
    }
 
      return <DashboardComponent {...props} />;
  

  };

  return <Route {...rest} render={render} />;
};


const PrivateRoute = ({ component, ...rest }: RouteProps) => {
  if (!component) {
    throw Error("component is undefined");
  }

  const Component = component; // JSX Elements have to be uppercase.
  const userLoginstate = useUserLoginState();
  const isValidUser =
    userLoginstate &&
    userLoginstate.userData &&
    userLoginstate.userData.isUserPermissionValid;
  const UserLockData =
    userLoginstate &&
    userLoginstate.userData &&
    userLoginstate.userData.lockedUntil;
  const isLockedUser =
    (UserLockData && moment(UserLockData).isAfter()) || false;
  //  console.log('isValidUser',isLockedUser)

  const render = (props: RouteComponentProps<{}>): React.ReactNode => {
    if (!(isValidUser && !isLockedUser)) {
      return <Component {...props} />;
    }
    return <Redirect to={{ pathname: "/" }} />;
  };

  return <Route {...rest} render={render} />;
};

export default Routes;
