import { connect } from "react-redux";
import { StoreState } from "../../store/store-state";
import ViewLearner from "./view-learner.component";
import ExamRequest from "../../models/exam-request";
import {
  ViewLearnersAction,
  getViewLearnersDetails,
  addLearnerMaterial,
  removeLearnerMaterial,
  updateLearnerStatus,
  downloadAdminMaterial,
  addAdminMaterial,
  removeAdminMaterial,
  getAdminMaterialSummary,
} from "./ducks/view-learner.actions";
import { getSharedFileLearners } from "../link-learners/ducks/link-learners.actions";
import SharedFileLearners from "../../models/shared-file";
import { LearnersList } from "../../models/learner";
import LearnerMaterial from "../../models/learner-material";
import { ThunkDispatch } from "redux-thunk";
import { submitAllocation, checkSubmitAllowed } from "../../components/dashboard/ducks/dashboard.actions";
import { SubmitAllowed } from "../../models/submit-allocation";
import AdminMaterial, { AdminLearnerSummary, AdminSharedMaterialSummary } from "../../models/admin-material";
export interface StateProps {
  examRequests?: ExamRequest[];
  learnerDetails: LearnersList;
  learnerMaterial: LearnerMaterial;
  submitAllowed: SubmitAllowed;
  adminMaterial: AdminMaterial;
  adminSharedMaterial: AdminSharedMaterialSummary;
  sharedFileLearnersResponse: SharedFileLearners;
  downloadAdminMaterialURL: string;
}

export interface DispatchProps {
  getViewLearnersDetails: (
    allocationId: string,
    searchText: string,
    learnerStatus: number,
    pageNumber: number,
    pageSize: number
  ) => Promise<void>;
  addLearnerMaterial: (
    allocationId: number,
    fileId: string,
    fileKey: string,
    fileName: string,
    fileSize: string,
    learnerId: string,
  ) => Promise<void>;
  removeLearnerMaterial: (allocationId: number, fileKey: string, learnerId: string, isRemoveAll: boolean) => Promise<void>;
  updateLearnerStatus: (allocationId: number, learnerId: number, learnerStatusCode: number, assessmentMethodType: number) => Promise<void>;
  submitAllocation: (allocationId: number, assessmentMethodType: number) => Promise<void>;
  checkSubmitAllowed: (allocationId: number, assessmentMethodType: number) => Promise<void>;
  addAdminMaterial: (
    allocationId: number,
    fileId: string,
    fileKey: string,
    fileName: string,
    fileSize: string,
    fileType: number
  ) => Promise<void>;
  downloadAdminMaterial: (allocationId: number, fileKey: string, fileUserType: number, assessmentMethodType: number, duration: number, fileName: string) => Promise<void>;
  removeAdminMaterial: (
    allocationId: number,
    fileKey: string,
    isRemoveAll: boolean,
    fileType: number
  ) => Promise<void>;
  getAdminMaterialSummary: (allocationId: number) => Promise<void>;
  getSharedFileLearners: (allocationId: number, fileKey: string, assessmentMethodType: number, linkedLearnerFilter: number) => Promise<void>;
}

const mapStateToProps = (state: StoreState): StateProps => {
  return {
    examRequests: state.examRequests,
    learnerDetails: state.viewLearnersDetails,
    learnerMaterial: state.learnerMaterial,
    submitAllowed: state.submitAllowed,
    downloadAdminMaterialURL: state.downloadAdminMaterial,
    adminMaterial: state.adminMaterial,
    adminSharedMaterial: state.adminSharedMaterial,
    sharedFileLearnersResponse: state.sharedFileLearnersResponse
  };
};


const mapDispatchToProps = (
  dispatch: ThunkDispatch<StoreState, undefined, ViewLearnersAction>
): DispatchProps => {
  return {
    getViewLearnersDetails: (
      allocationId: string,
      searchText = "",
      fileStatus = 0,
      pageNumber = 1,
      pageSize = 10
    ) =>
      dispatch(
        getViewLearnersDetails(
          allocationId,
          searchText,
          fileStatus,
          pageNumber,
          pageSize
        )
      ),
    addLearnerMaterial: (allocationId, fileId, fileKey, fileName, fileSize, learnerId) =>
      dispatch(
        addLearnerMaterial(allocationId, fileId, fileKey, fileName, fileSize, learnerId)
      ),
    removeLearnerMaterial: (allocationId, fileKey, learnerId, isRemoveAll) =>
      dispatch(removeLearnerMaterial(allocationId, fileKey, learnerId, isRemoveAll)),
    updateLearnerStatus: (allocationId, learnerId, learnerStatusCode, assessmentMethodType) =>
      dispatch(updateLearnerStatus(allocationId, learnerId, learnerStatusCode, assessmentMethodType)),
    submitAllocation: (allocationId: number, assessmentMethodType: number) =>
      dispatch(submitAllocation(allocationId, assessmentMethodType)),
    checkSubmitAllowed: (allocationId: number, assessmentMethodType: number) =>
      dispatch(checkSubmitAllowed(allocationId, assessmentMethodType)),
    addAdminMaterial: (allocationId: number, fileId: string, fileKey: string, fileName: string, fileSize: string, fileType: number) =>
      dispatch(addAdminMaterial(allocationId, fileId, fileKey, fileName, fileSize, fileType)),
    downloadAdminMaterial: (allocationId: number, fileKey: string, fileUserType: number, assessmentMethodType: number, duration: number, fileName: string) =>
      dispatch(downloadAdminMaterial(allocationId, fileKey, fileUserType, assessmentMethodType, duration, fileName)),
    removeAdminMaterial: (allocationId: number, fileKey: string, isRemoveAll: boolean, fileType: number) =>
      dispatch(removeAdminMaterial(allocationId, fileKey, isRemoveAll, fileType)),
    getAdminMaterialSummary: (allocationId: number) =>
      dispatch(getAdminMaterialSummary(allocationId)),
    getSharedFileLearners: (allocationId: number, fileKey: string, assessmentMethodType: number, linkedLearnerFilter: number) =>
      dispatch(getSharedFileLearners(allocationId, fileKey, assessmentMethodType, linkedLearnerFilter))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewLearner);
