import React from 'react';
import { getSavedSearch, updateSavedSearch, viewRequests } from '../helpers/dashboard-request-helper';
import ExamRequest from '../models/exam-request';
import Card from '../components/pearson/card';
import SearchBar from '../components/pearson/search-bar';
import  SavedSearch  from '../models/saved-search';
import { getLearningProviderName } from './user-helper';
import { associatedLearningProviders } from '../models/user';
import Select from '../components/pearson/select';
import Button from '../components/pearson/button';
import Loader from '../components/common/loader/loader';
import { SubmitAllowed } from '../models/submit-allocation';
import * as auth from '../actions/auth/auth'
// var history = useHistory();



export const getTabStatusCount = (
  tabCountData:any,
  assessmentMethod: string = 'Examination',
) => {
  let tabIndex = 0;
  if(assessmentMethod === 'Examination'){
    if (
      tabCountData &&
      tabCountData.examination &&
      tabCountData.examination.inProgressCount > 0
    ) {
      tabIndex = 1;
    } else if (
      tabCountData &&
      tabCountData.examination &&
      tabCountData.examination.completedCount > 0
    ) {
      tabIndex = 2;
    } 
  }else{
    if (
      tabCountData &&
      tabCountData.moderation &&
      tabCountData.moderation.inProgressCount > 0
    ) {
      tabIndex = 1;
    } else if (
      tabCountData &&
      tabCountData.moderation &&
      tabCountData.moderation.completedCount > 0
    ) {
      tabIndex = 2;
    } 

  }
    return tabIndex;
}

export const makeSearchOption = (
    searchOption: string = "All",
    selectedTabIndex: string    
  ): any => {
    if (selectedTabIndex === "0") {
      return [1];
    } else if (selectedTabIndex === "1") {
      switch (searchOption) {
        
        case "InProgress":
          return [2];
        case "InProgress(LE)":
          return [3];
        case "Submitting":
          return [4];
        case "NotSubmitted":
          return [5];
        case "Returned":
          return [6];
        case "All":
          default:
          return [2, 3, 4, 5, 6];
      }
    } else if (selectedTabIndex === '2') {
      return [7];
    }
};

interface ExamReqProps {
  getExamRequests: (
    learningProviderCode: string,
    status: number[],
    searchText: string
  ) => Promise<void>;
  examRequests?: ExamRequest[];
  examRequestsSummary: any;
  submitAllocation: (
    allocationId: number,
    assessmentMethodType: number
  ) => Promise<void>;
  checkSubmitAllowed: (
    allocationId: number,
    assessmentMethodType: number
  ) => Promise<void>;
  submitAllowed: SubmitAllowed;
  getExamRequestsSummary: (learningProviderCode: string) => Promise<void>;
}
interface ModReqProps {
  getModerationRequestsSummary: (learningProviderCode: string) => Promise<void>;
  getModerationRequests: (
    learningProviderCode: string,
    status: number[],
    searchText: string
  ) => Promise<void>;
  moderationRequests?: ExamRequest[];
  moderationRequestsSummary: any;
}

export const doDataRefresh = (
    updateupdateSaveSearchText:Function, 
    updateSaveSearchOption: Function,
    lpId: string,
    props: (ExamReqProps|ModReqProps),
    lsCenterName: string
) => { 
  const savedSearch = getSavedSearch() as SavedSearch;
  const currMethod = getCurrentAssessMethod();
  updateupdateSaveSearchText(savedSearch.searchText);
  updateSaveSearchOption(savedSearch.searchFilter);
  if (lpId) { 
    localStorage.setItem("scrollPosition", window.scrollY.toString());
    
    if (currMethod === "examination") {
      if (props && "getExamRequestsSummary" in props) {
        props.getExamRequestsSummary(
          savedSearch.learningProviderId
            ? savedSearch.learningProviderId
            : lsCenterName
            ? lsCenterName
            : lpId
        );
      }
      if (props && "getExamRequests" in props) {
        props.getExamRequests(
          savedSearch.learningProviderId
            ? savedSearch.learningProviderId
            : lsCenterName
            ? lsCenterName
            : lpId,
          makeSearchOption(
            savedSearch.searchFilter + "",
            savedSearch.selectedTabIndex + ""
          ),
          savedSearch.searchText + ""
        );
      }
    } else if (currMethod === "moderation") { 
      if (props && "getModerationRequestsSummary" in props) {
          props.getModerationRequestsSummary(
            savedSearch.learningProviderId
              ? savedSearch.learningProviderId
              : lsCenterName
              ? lsCenterName
              : lpId
          );
      }
      if (props && "getModerationRequests" in props) {
          props.getModerationRequests(
            savedSearch.learningProviderId
              ? savedSearch.learningProviderId
              : lsCenterName
              ? lsCenterName
              : lpId,
            makeSearchOption(
              savedSearch.searchFilter + "",
              savedSearch.selectedTabIndex + ""
            ),
            savedSearch.searchText + ""
          );
      }
    }
  }
};

export const handleCenterChange = (
  event: any,
  props: (ExamReqProps|ModReqProps),
  updateSaveSearchText:Function, 
  updateSaveSearchOption: Function,
  updateCenterCode: Function,
  updateLoading: Function,
  learningCenter: associatedLearningProviders[]
) => {
  const learningProviderDetails =
      event.target.value && event.target.value.split(",");
    const learningProviderId = learningProviderDetails[0];
    if(learningProviderId){
    updateSavedSearch({
      learningProviderId,
      selectedTabIndex: "",
      searchFilter: "All",
      searchText: ""
    });
    updateSaveSearchText("");
    updateSaveSearchOption("All");
    updateCenterCode(learningProviderId);
    const lpDetails = getLearningProviderName(
      learningCenter,
      learningProviderId + ""
    );
    
    const learningProviderName =
      lpDetails &&
      lpDetails.length &&
      lpDetails[0].learningProviderName;

    localStorage.setItem(
      "centerName",
      learningProviderId + " " + learningProviderName + ""
    );
    updateLoading(true);

    if(props && "getExamRequests" in props) {
      props.getExamRequestsSummary(learningProviderId);
    } else if(props && "getModerationRequestsSummary" in props) {
      props.getModerationRequestsSummary(learningProviderId);
    }
    setCurrentAssessMethod("examination");
    //const history =   useHistory();
    //  history.push("/")
  }
    else{ 
      updateCenterCode("");
      localStorage.removeItem("centerName");
      updateSavedSearch({
        learningProviderId:"",
        selectedTabIndex: "",
        searchFilter: "All",
        searchText: ""
      });
      removeCurrentAssessMethod();
    }
}

const getSearchOptionArray = (searchOption: string = "All") => {
  switch(searchOption.toLowerCase()) {    
    case "todo":
      return [1];
    case "inprogress":
      return [2];
    case "inprogress(le)":
      return [3];
    case "submitting":
      return [4];
    case "notsubmitted":
      return [5];
    case "returned":
      return [6];
    case "completed":
      return [7];
    case "all":
    default:
      return [2,3,4,5,6];          
  }
}

export const handleSearch = (
  updateLoading: Function,
  onSearchUpdate: Function, 
  savedSearch:SavedSearch, 
  props: (ExamReqProps|ModReqProps),
  searchOption: string, 
  searchText: string) => {
  const learningProviderId = savedSearch && savedSearch.learningProviderId;
  if (learningProviderId) {
    updateLoading(true);
    const searchOptionArray = getSearchOptionArray(searchOption)
    if(props && "getExamRequests" in props) { 
      props.getExamRequests(learningProviderId, searchOptionArray, searchText);
    } else if(props && "getModerationRequests" in props) {
      props.getModerationRequests(learningProviderId, searchOptionArray, searchText);
    }
    onSearchUpdate(searchOption, searchText);
  }
}

const getCurrTabSearchOptArray = (
  activeTabIndex: string, 
  tabIndex: string, 
  learningProviderId: string
) => {
  // if (
  //   (activeTabIndex && activeTabIndex.toString() !== tabIndex) &&
  //   learningProviderId.length
  // ) {
    if(activeTabIndex.toString() === "0"){
      return [1];
    } else if(activeTabIndex.toString() === "1"){
      return [2,3,4,5,6];
    } else if(activeTabIndex.toString() === "2"){
      return [7]
    }
  // }
}

export const handleTabClick = (
  updateLoading: Function,
  updateSaveSearchText:Function, 
  updateSaveSearchOption: Function,
  updatesavedSearchSelectedAllocId: Function,
  props: (ExamReqProps|ModReqProps),
  activeTabIndex: string,
  savedSearch: SavedSearch,
  learningProviderId: string,
  savedSearchSelectedAllocId:string, 
) => {
  if (learningProviderId) {
    const tabIndex = savedSearch.selectedTabIndex;
    updateLoading(true);
    const currTabSearchOptArray = getCurrTabSearchOptArray(activeTabIndex, tabIndex+'', learningProviderId) || [];
    
    if(props && "getExamRequests" in props) {
      props.getExamRequests(learningProviderId, currTabSearchOptArray, "");
    } else if(props && "getModerationRequests" in props) {
      props.getModerationRequests(learningProviderId, currTabSearchOptArray, "");
    }
    if (!savedSearchSelectedAllocId.length) {
      updateSaveSearchOption("");
      updateSaveSearchText("");
      updateSavedSearch({ searchFilter: "", searchText: "" });
    }
    updatesavedSearchSelectedAllocId("");
    updateSavedSearch({
      selectedTabIndex: activeTabIndex + "",
      selectedAllocationId: ""
    });
  }
}

export const ShowTopTabs = () => (
  <ul className="tabs mytabs" slot="tabs">
    <li>To Do</li>
    <li>In Progress</li>
    <li>Completed</li>
  </ul>
)

export const showCenterDropdown = (
  learningCenter: associatedLearningProviders[],
  centerOptions: any,
  centerCode: string,
  onCenterChange: (event:any) => void
) => {
  if( learningCenter.length > 1 && centerOptions) {
   return (
    <div
      className="gr-grid-container ie-flex margin0"
      style={{
        backgroundColor: "#d9d9d9",
        marginTop: "30px",
        marginBottom: "30px"
      }}
    >
      <div
        className="gr-col-one gr-col-span-two "
        style={{ paddingLeft: "18px", paddingTop: "12px" }}
      >
        Learning Provider
      </div>
      <div className="gr-col-one gr-col-span-five ">
        {" "}
        <Select
          disabled={false}
          id="searchOption"
          placeholder=""
          value={centerCode}
          options={centerOptions}
          onChange={onCenterChange}
          learner={true}
        />
      </div>
    </div>
  )}
}

export const showTabReqFilterByMethod = (
  tabName:string, 
  tabStatusCount: any,
  assessmentMethod: string = 'Examination',
  isActive: boolean = false,
  filterClickHandler?: (e: any)=>void
) => { 
  const tabStatusPropName = tabName.toUpperCase() === 'TODO'?'toDoCount':
              tabName.toUpperCase() === 'INPROGRESS'?'inProgressCount':
              tabName.toUpperCase() === 'COMPLETED'?'completedCount':'';
  return (
    <span 
      className="filter-card-click-span" 
      onClick={filterClickHandler?filterClickHandler:(e)=>{e.stopPropagation()}}
    >
      <Card key={assessmentMethod+tabName}
        cardTitle={assessmentMethod}
        cardValue={(tabStatusCount && tabStatusCount[tabStatusPropName]) || 0}
      />
      {isActive && <hr className="filter-card-active" />}
    </span>
  );
}
interface SearchBarProps {
  searchOption: string,
  searchText: string,
  onTextChange: (e: any) => void,
  onOptionChange: (opt: string) => void,
  onSearchUpdate: (searchOption: string, searchText: string) => void
}
interface ViewRequestsProps {
  requests: ExamRequest[] | undefined,
  navigateToViewLearners: (selectedAllocationId: string) => void,
  navigateToViewAll: (e: any, qualificationTypeCode: string, seriesCode: string, qualificationTypeName: string) => void,
  submitRequest: (allocationId: number) => void,
  checkSubmitAllowed: (allocationId: number) => void,
  isSubmitAllowed: boolean
}
export const showTabSearchBar = (
  options: any,   
  onSearch: (e: any, searchOption: string, searchText: string)=>void,
  getSearchBarProps: () => SearchBarProps
) => {
  const searchBarProps = getSearchBarProps();
  return (
      <div>
        <SearchBar
          options={options}
          searchOption={searchBarProps.searchOption}
          searchText={searchBarProps.searchText}
          placeholderText="Search"
          onSearch={onSearch}
          onTextChange={searchBarProps.onTextChange}
          onOptionChange={searchBarProps.onOptionChange}
          onSearchUpdate={searchBarProps.onSearchUpdate}
        />
      </div>
    )  
}

export const showTabRequests = (
  tabName: string,
  getViewRequestsProps: () => ViewRequestsProps
) => {
  const viewRequestsProps = getViewRequestsProps();
 if(viewRequestsProps.requests && viewRequestsProps.requests.length >0){
  return viewRequests(
    tabName,
    viewRequestsProps.requests,
    viewRequestsProps.navigateToViewLearners,
    viewRequestsProps.navigateToViewAll,
    viewRequestsProps.submitRequest,
    viewRequestsProps.checkSubmitAllowed,
    viewRequestsProps.isSubmitAllowed
  )
 }else{
   return "There are no requests available";
 }
}

export const showNoRequests = (
  tabCountData:any,
  tabCountPropName: string,
  requests: ExamRequest[] | undefined
) => {
  return (tabCountData &&
    tabCountData.examination &&
    tabCountData.examination[tabCountPropName] === 0) ||
    (requests && requests.length === 0) ? (
    // <div className="no-request">There are no requests available</div>
    <Loader message="There are no requests available" height="100vh" timeoutSecs={4000} />
  ) : (
    ""
  );
}

export const showNoModRequests = (
  tabCountData:any,
  tabCountPropName: string,
  requests: ExamRequest[] | undefined
) => {
  return (tabCountData &&
    tabCountData.moderation &&
    tabCountData.moderation[tabCountPropName] === 0) ||
    (requests && requests.length === 0) ? (
    // <div className="no-request">There are no requests available</div>
    <Loader message="There are no requests available" height="100vh" timeoutSecs={4000} />
  ) : (
    ""
  );
}

export const setCurrentAssessMethod = (method: string) => {
  const currMethod = getCurrentAssessMethod();
  if (!currMethod){
    localStorage.setItem("currentAssessMethod", "examination");
  }
  if(method.length){
    localStorage.setItem("currentAssessMethod", method);
  }
}

export const getCurrentAssessMethod = () =>
  localStorage.getItem("currentAssessMethod");

  export const getCurrentAssessMethodNumber = () =>{
    const localAssessmentMethodType = localStorage.getItem("currentAssessMethod");
    if(localAssessmentMethodType === 'examination'){
      return 1;
    }else if(localAssessmentMethodType === 'moderation'){
      return 2;
    }
    
    
  }

export const ManageLearnersButton = (props: {onClick: (e:any)=>void}) => {
  const assessMethod = getCurrentAssessMethod();
  if(assessMethod === 'moderation') {
    return (
      <div className="manage-learners-btn-holder">
          <Button label="Manage Learners" className="primary" onClick={props.onClick} />
      </div>
    )
  }
  return <></>
}

export const removeCurrentAssessMethod = () => {
  localStorage.removeItem("currentAssessMethod")
}

export const alertUser = (event:any) => { 
  let localStorageFileUploaded = localStorage.getItem("fileUploaded")
      ? JSON.parse(localStorage.getItem("fileUploaded")!)
      : [];
  let localStorageAdminFileUploaded = localStorage.getItem("adminfileUploaded")
    ? JSON.parse(localStorage.getItem("adminfileUploaded")!)
    : [];  
    console.log("localStorageAdminFileUploaded",localStorageAdminFileUploaded.length)
  if( localStorageAdminFileUploaded.length !== 0 || localStorageFileUploaded.length !== 0){ 
    event.preventDefault()
    event.returnValue = ''
  }
}

export const handleTabClosing = () => {
  // removePlayerFromGame();
  localStorage.removeItem("adminfileUploaded");
  localStorage.removeItem("fileUploaded")
}
