import axios, { AxiosRequestConfig } from "axios";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { actionTypes } from "../../../actions/action-types";
import { StoreState } from "../../../store/store-state";
import { AssessmentDeadlines, SubmitSetupResponse,SubmitSetupPrsResponse } from "../../../models/config-models";
import config from "../../../app-config";

interface GetManageAssessmentDeadlinesSuccessAction extends Action {
  type: actionTypes.CONFIG_MANAGE_GET_ASSESSMENT_DEADLINES;
  assessmentDeadlineData: AssessmentDeadlines;
}

interface SubmitAssessmentDeadlineSuccessAction extends Action {
  type: actionTypes.CONFIG_SUBMIT_ASSESSMENT_DEADLINE;
  submitAssessmentDeadlineStatusData: any;
}

interface SubmitPrsAssessmentDeadlineSuccessAction extends Action {
  type: actionTypes.CONFIG_PRS_SUBMIT_ASSESSMENT_DEADLINE;
  submitPrsAssessmentDeadlineStatusData: any;
}

export type ManageAssessmentDeadlineConfigAction =
  | GetManageAssessmentDeadlinesSuccessAction
  | SubmitAssessmentDeadlineSuccessAction
  | SubmitPrsAssessmentDeadlineSuccessAction

type ThunkResult<R> = ThunkAction<R, StoreState, undefined, ManageAssessmentDeadlineConfigAction>;

export const GetManageAssessmentDeadlinesSuccessAction = (
  assessmentDeadlineData: AssessmentDeadlines
): GetManageAssessmentDeadlinesSuccessAction => ({
  assessmentDeadlineData,
  type: actionTypes.CONFIG_MANAGE_GET_ASSESSMENT_DEADLINES,
});

export const SubmitAssessmentDeadlineSuccessAction = (
  submitAssessmentDeadlineStatusData: any
): SubmitAssessmentDeadlineSuccessAction => ({
  submitAssessmentDeadlineStatusData,
  type: actionTypes.CONFIG_SUBMIT_ASSESSMENT_DEADLINE,
});

export const SubmitPrsAssessmentDeadlineSuccessAction = (
  submitPrsAssessmentDeadlineStatusData: any
): SubmitPrsAssessmentDeadlineSuccessAction => ({
  submitPrsAssessmentDeadlineStatusData,
  type: actionTypes.CONFIG_PRS_SUBMIT_ASSESSMENT_DEADLINE,
});


export const submitAssessmentDeadline = (
  assessmentDeadlineId: number, isDlwtEnabled: boolean, accessBeginDate?: Date,accessEndDate?: Date, submissionDeadline?: Date, aaDeadline?: Date, createRequestEndDate?: Date, updateRequestEndDate?: Date, isSetupSubmitted?: boolean
  ): ThunkResult<Promise<void>> => {
    return (dispatch) => {
      const axiosConfig: AxiosRequestConfig = {
        headers: { "content-type": "application/json" },
      };
      return axios
        .post(
          `${config.API_GATEWAY.URL}/assessment-deadline-config/submit-assessment-deadline`,
          { assessmentDeadlineId, isDlwtEnabled, accessBeginDate,accessEndDate, submissionDeadline, aaDeadline, createRequestEndDate, updateRequestEndDate,isSetupSubmitted },
          axiosConfig
        )
        .then((postResponse) => {
          const assessmentDeadlineConfig = postResponse.data && postResponse.data as SubmitSetupResponse;
          dispatch(SubmitAssessmentDeadlineSuccessAction(assessmentDeadlineConfig));
        })
        .catch((error) => {
        });
    };
  };

  export const submitPrsAssessmentDeadline = (
    assessmentDeadlineId: number, prsInternalUserAccessBeginDate?: Date, prsInternalUserAccessEndDate?: Date, prsExternalUserAccessBeginDate?: Date, prsExternalUserAccessEndDate?: Date
    ): ThunkResult<Promise<void>> => {
      return (dispatch) => {
        const axiosConfig: AxiosRequestConfig = {
          headers: { "content-type": "application/json" },
        };
        return axios
          .post(
            `${config.API_GATEWAY.URL}/assessment-deadline-config/submit-prs-assessment-deadline`,
            { assessmentDeadlineId, prsInternalUserAccessBeginDate, prsInternalUserAccessEndDate,prsExternalUserAccessBeginDate, prsExternalUserAccessEndDate },
            axiosConfig
          )
          .then((postResponse) => {
            const assessmentDeadlineConfig = postResponse.data && postResponse.data as SubmitSetupPrsResponse;
            dispatch(SubmitPrsAssessmentDeadlineSuccessAction(assessmentDeadlineConfig));
          })
          .catch((error) => {
          });
      };
    };

export const getAssessmentDeadline = (
  assessmentDeadlineId: number

): ThunkResult<Promise<void>> => {
  return async (dispatch) => {
    const axiosConfig: AxiosRequestConfig = {
      headers: { "content-type": "application/json" },
    };
    return await axios
      .post(
        `${config.API_GATEWAY.URL}/assessment-deadline-config/get-assessment-deadline`,
        JSON.stringify({ assessmentDeadlineId }),
        axiosConfig
      )
      .then((postResponse) => {
        const assessmentDeadline = postResponse.data as AssessmentDeadlines;
        dispatch(GetManageAssessmentDeadlinesSuccessAction(assessmentDeadline));
      })
      .catch((error) => {
      });
  };
};