import React from "react";
interface CardDetails {
  cardTitle: string;
  cardValue: string | number;
}

const Card = (props: CardDetails) => {
  const { cardTitle, cardValue } = props;
  return (
    <div className="card-container">
      <div className="card">
        <div className="card-title">{cardTitle}</div>
        <div className="card-value">{cardValue}</div>
      </div>
    </div>
  );
};

export default Card;
