import React from "react";
import { useHistory } from "react-router";
import { useUserLoginState } from "../../shared/context-api/hooks";

function CheckUser(props: { islockeduser: boolean }) {
  const history = useHistory();
  const userLoginstate = useUserLoginState();

  const userdata = JSON.parse(localStorage.getItem("payload")!);
  let userType = userdata && userdata["family_name"];
  
  if (
    userLoginstate &&
    userLoginstate.userData &&   
    !undefined === userLoginstate.userData.isUserPermissionValid &&
    (!userLoginstate.userData.isUserPermissionValid ||
      (userLoginstate.userData.isUserPermissionValid && props.islockeduser))
  ) {
    history.push({
      pathname: `/access-denied/${userType}/${
        props.islockeduser ? "locked" : "nl"
      }`,
    });
  } else if (userLoginstate &&
    userLoginstate.userData && 
    userLoginstate.userData.email === 'ERRORa2e1dd272bd2fed9361a449337d6c29dd8b18c6811acc5e836c7eb0e3af633a9'
  ) {
		const payloadLocalStorage = JSON.parse(localStorage.getItem('payload') || '');
		payloadLocalStorage && payloadLocalStorage.family_name.toLowerCase() === "internal"
		  ? history.push({ pathname: "/access-denied/Internal/nl" })
		  : history.push({ pathname: "/access-denied/External/nl" });
  }
  return <></>;
}

export default CheckUser;
