import React, { FormEvent } from "react";
import { SEL_ALL_ACTIONS } from "../../shared/app-enums";
import * as LLHlpr from "./link-learners.helper";
import { useUserLoginState } from "../../shared/context-api/hooks";
import { HasWriteAccess  } from "../../components/common/user-validation";

type SelAllActionType = 
    | SEL_ALL_ACTIONS.ADDALL
    | SEL_ALL_ACTIONS.REMOVEALL

interface Props {
  isCheckedSelectedAll?:boolean;
  onSelectAll: (action:SelAllActionType)=>void;
}

const MSFSelectAll = (props:Props) => {
    const isSelAllChecked = props && props.isCheckedSelectedAll || false;
    const userLoginstate = useUserLoginState();
    const handleSelectAll = (event:FormEvent<HTMLInputElement>) => {    
        const selectAllAction = event.currentTarget.checked ?
            SEL_ALL_ACTIONS.ADDALL : 
            SEL_ALL_ACTIONS.REMOVEALL;
        props.onSelectAll(selectAllAction)
    }  

    return  (
      <div           
        style={{
          float: "left",
          width: "100%",
          marginBottom: "10px",
          marginTop: "10px",
        }}
      >&nbsp;&nbsp;&nbsp;
        <input type="checkbox" 
            id="selall" 
            key="llselall" 
            onChange={handleSelectAll} 
            checked={isSelAllChecked} 
            disabled={!HasWriteAccess(userLoginstate)}
        />
        <span 
            style={{marginLeft:"5px", position:"relative", top: "-2px"}}
        >Select All</span>
      </div>)
};

export default MSFSelectAll;
