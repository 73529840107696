import React from "react";
import loading from "./loader.svg";
import "./loader.scss";

export interface StateProps {
  height?: string;
  timeoutSecs?: number;
  message?: string;
}

const Loader = (props: StateProps) => {
  const [showLoader, setShowLoader] = React.useState(true);

  setTimeout(
    () => {
      setShowLoader(false);
    },
    props.timeoutSecs ? props.timeoutSecs : 10000
  );
  return (
    <div>
      {showLoader ? (
        <div
          className="loader-container"
          style={{ height: props.height }}
        >
          <img className="loadingIcon" src={loading} alt="loading" />
        </div>
      ) : (
          <div
            className="loader-container"
            style={{ justifyContent: props.height && "left"   }}
          >
            {props.message ? props.message : "No Data Available"}
          </div>
        )}
    </div>
  );
};

export default Loader;