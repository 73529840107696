import React, { CSSProperties } from "react";
export interface AlertProps {
    type?: "success" | "error" | "confirmation" | "important";
    level?: "global" | "inline";  
    returnNode?: string;  
    animated?: boolean;
    parentStateShowAlertVar?: boolean;
    parentStateHideAlertMethod?: () => void;
    alertHeader?:{styles:CSSProperties; text:string;};
    alertBody?:{styles:CSSProperties; text:string;};
    alertStyles?:CSSProperties;
    alertClassName?:string;
    containerId?:string; 
    closeAfterSc?:string; 
  }
  const ShowAlert = (props:AlertProps) => {
    const {
      type="success", 
      level="global", 
      returnNode="main", 
      animated=false, 
      parentStateShowAlertVar=false, 
      alertStyles={},
      alertClassName=""
    } = props;
    
    // unstable_flushDiscreteUpdates warning handled
    const [closeClicked, setcloseClicked] = React.useState(false); 
  
    const dispNone = {display: "none"};
    const dispBlock = {display: "block"};   
    
    const render = () => (
      <div
        id={props.containerId || "alert-container"}
        style={Object.assign(
          alertStyles,
          {},
          parentStateShowAlertVar ? dispBlock : dispNone
        )}
      >
        <pearson-alert
          level={level}
          returnNode={returnNode}
          type={type}
          animated={animated}
        >          
          {props.alertHeader && (
            <p style={props.alertHeader.styles}>{props.alertHeader.text}</p>
          )}
          {props.alertBody && (
            <div className={props.alertClassName} style={props.alertBody.styles} id={props.containerId + "-alert-body"}>              
              {/* {props.alertBody.text}                       */}
            </div>
          )}          
        </pearson-alert>
      </div>
    );
    
    // The following method removes the close btn in the pearson-alert
    // which, after closing the alert, removes the component from DOM
    // subsequent calls needs to add the same alert using JS
    // one it is removed and replaced, alert remains in DOM
    const removerAlertCloseBtn = (elemId:string) => {
        // unstable_flushDiscreteUpdates warning handled
        if(closeClicked){
            return;
        }
        let d = document.getElementById(elemId)?.querySelector("pearson-alert"); 
        let b = d && d.shadowRoot && d.shadowRoot.querySelectorAll("button");
        if(b && b.length) {
            b.forEach(elem=>{
                elem.parentNode?.removeChild(elem);
            });
        }
    }

    //The following method add our own close btn which looks same as 
    // original. The diff is that the alert component is not removed
    // from the DOM, but will just be hidden using parent component
    // local state variable/method
    const addOwnAlertCloseBtn = (elemId: string) => {
      let d = document.getElementById(elemId)?.querySelector("pearson-alert");
      let s = d && d.shadowRoot;    
      if(s) {
        let c = s.querySelector('.content-container');
        let b = document.createElement('button');
        b.className='pe-icon--btn';
        b.innerHTML=`<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="close-24" viewBox="0 0 24 24" class="pe-icon-close-24" aria-hidden="true" focusable="false" style="width:24px;height:24px;">
        <path d="M12,10.5857864 L17.2928932,5.29289322 C17.6834175,4.90236893 18.3165825,4.90236893 18.7071068,5.29289322 C19.0976311,5.68341751 19.0976311,6.31658249 18.7071068,6.70710678 L13.4142136,12 L18.7071068,17.2928932 C19.0976311,17.6834175 19.0976311,18.3165825 18.7071068,18.7071068 C18.3165825,19.0976311 17.6834175,19.0976311 17.2928932,18.7071068 L12,13.4142136 L6.70710678,18.7071068 C6.31658249,19.0976311 5.68341751,19.0976311 5.29289322,18.7071068 C4.90236893,18.3165825 4.90236893,17.6834175 5.29289322,17.2928932 L10.5857864,12 L5.29289322,6.70710678 C4.90236893,6.31658249 4.90236893,5.68341751 5.29289322,5.29289322 C5.68341751,4.90236893 6.31658249,4.90236893 6.70710678,5.29289322 L12,10.5857864 Z"></path></svg>`
        b.addEventListener('click', (e:MouseEvent) =>{
            // unstable_flushDiscreteUpdates warning handled
            setcloseClicked(true);
            props.parentStateHideAlertMethod && (props.parentStateHideAlertMethod());
        })
        c?.parentNode?.insertBefore(b,c?.nextSibling)
        
      }
    }
    const insertAlertBodyHTML = (elemId: string)=> {
      let d = document.getElementById(elemId)?.querySelector("pearson-alert");      
       let s = d && d.shadowRoot;    
      if(s) {
        let c = d?.querySelector('#'+elemId+'-alert-body');           
        if(c){
          c.innerHTML=`
          <span>${props.alertBody?.text+''}</span>
          `;
        }
      }
    }

    const alertJSX = render();
    props.containerId && removerAlertCloseBtn(props.containerId);
    props.containerId && addOwnAlertCloseBtn(props.containerId); 
    props.containerId && insertAlertBodyHTML(props.containerId); 
    if(props.closeAfterSc && parseInt(props.closeAfterSc)>0) {
      setTimeout(() => {
        props.parentStateHideAlertMethod && (props.parentStateHideAlertMethod());
      }, parseInt(props.closeAfterSc)*1000);
    }
  
    return alertJSX;          
  }

  
  export default ShowAlert;