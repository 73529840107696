import * as React from 'react';
import { FormEvent } from 'react';
import * as _ from 'lodash';
import { Option } from "../../models/option";

interface SelectProps {
  id: string;
  label?: string;
  placeholder?: string;
  value?: string;
  errors?: string;
  disabled?: boolean;
  options: Option[],
  onChange?: (event: FormEvent<HTMLSelectElement>) => void;
  learner?: boolean
}

const Select = ({
  id,
  label,
  placeholder,
  value,
  errors,
  disabled,
  options,
  onChange,
  learner
}: SelectProps) => {
  return (
    <div className="select">
      <div className="gr-form-element">
        <label className="gr-label" htmlFor={id}>{label}</label>
        <div className={`gr-select-container ${errors ? "error" : ""}`}>
          <select className="gr-select"
            {...id && { id: id }}
            value={value}
            disabled={disabled}
            onChange={onChange}
          >
            <>
              {placeholder && (
                <option key={0} value={""} disabled={true} >
                  {placeholder}
                </option>
              )}
              {_.map(options, (option, index) => (
                <option key={index + 1} value={option.key} disabled={disabled} >
                 { learner ?
                  option.key+ " " +option.value
                  :
                  option.value}
                </option>)
              )}
            </>
          </select>
          <svg focusable="false" className="icon-18" aria-hidden="true">
            <use xmlnsXlink="http://www.w3.org/1999/xlink" href="#drop-down-18" />
          </svg>
        </div>
        <div className="error-state">
          <svg focusable="false" className="icon-18" aria-hidden="true">
            <use xmlnsXlink="http://www.w3.org/1999/xlink" href="#warning-18" />
          </svg>
          <span className="gr-meta">{errors}</span>
        </div>
     </div>
    </div>
  )
};

Select.defaultProps = {
  disabled: false
}

export default Select;