import { connect } from "react-redux";
import { StoreState } from "../../store/store-state";
import {
  getExamRequestsSummary,
  getExamRequests,
  ExamRequestsAction,
  submitAllocation,
  checkSubmitAllowed
} from "../dashboard/ducks/dashboard.actions";
import { ThunkDispatch } from "redux-thunk";
import ExamRequest from "../../models/exam-request";
import { ExamRequestsSummary } from "../../models/exam-request";
import SubmitAllocation, {
  SubmitAllowed
} from "../../models/submit-allocation";
import DashboardComponent from "./dashboard.component";

export interface StateProps {
  examRequests?: ExamRequest[];
  examRequestStatusCode?: number;
  examRequestsSummary: ExamRequestsSummary;
  submitAllocation: SubmitAllocation;
  submitAllowed: SubmitAllowed;
}

export interface DispatchProps {
  getExamRequests: (
    learningProviderCode: string,
    status: number[],
    searchText: string
  ) => Promise<void>;
  getExamRequestsSummary: (learningProviderCode: string) => Promise<void>;
  submitAllocation: (
    allocationId: number,
    assessmentMethodType: number
  ) => Promise<void>;
  checkSubmitAllowed: (
    allocationId: number,
    assessmentMethodType: number
  ) => Promise<void>;
}

const mapStateToProps = (state: StoreState): StateProps => {
  return {
    examRequests: state.examRequests,
    examRequestStatusCode: state.examRequestStatusCode,
    examRequestsSummary: state.examRequestsSummary,
    submitAllocation: state.submitAllocation,
    submitAllowed: state.submitAllowed
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<StoreState, void, ExamRequestsAction>
): DispatchProps => ({
  getExamRequests: (
    learningProviderCode: string,
    status: number[],
    searchText: string
  ) => dispatch(getExamRequests(learningProviderCode, status, searchText)),
  getExamRequestsSummary: (learningProviderCode: string) =>
    dispatch(getExamRequestsSummary(learningProviderCode)),
  submitAllocation: (allocationId: number, assessmentMethodType: number) =>
    dispatch(submitAllocation(allocationId, assessmentMethodType)),
  checkSubmitAllowed: (allocationId: number, assessmentMethodType: number) =>
    dispatch(checkSubmitAllowed(allocationId, assessmentMethodType))
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);
