import React from 'react';
import axios from "axios";
import config from "../app-config";
import  FeatureStatus  from "../models/features";
// import { useEffect } from "react";


export const getFeatures = () => {    
    if(!localStorage.getItem('featureStatus')) {
        getFeatureStatusValues();
    }
}

export const getInitialDisplayFromStorage = (featureName="all") => {
  if(localStorage.getItem('featureStatus')) {
    const featureStatusList:FeatureStatus[] = JSON.parse(localStorage.getItem("featureStatus")!)
    if(featureName.toLowerCase()==='all') {
      return featureStatusList;
    }
    if(featureStatusList.length )
    {
      const userDisplayText:any = featureStatusList.find(f => f.feature.toLowerCase() === featureName.toLowerCase() && f.isEnabled)
      if(userDisplayText){
      return userDisplayText.displayText;
      }    
    }
    return null;
    
  }   
}

export const getFeatureFromStorage = (featureName="all") => {
  return true;
  // if(localStorage.getItem('featureStatus')) {
  //   const featureStatusList = JSON.parse(localStorage.getItem("featureStatus")!)
  //   if(featureName.toLowerCase()==='all') {
  //     return featureStatusList;
  //   }
  //   return getFeatureStatus(featureStatusList, featureName)
  // }  
}

function getFeatureStatus(featureStatusList:FeatureStatus[]=[], searchText=''){
  if(featureStatusList.length && featureStatusList.some(f => f.feature.toLowerCase() === searchText.toLowerCase() && f.isEnabled))
  {
    return true;
  }
    else
  {
  return false;
  }
}

export const getFeatureStatusValues = async () => {
  const axiosConfig = {
    headers: {
      "content-type": "application/json",
    },
  };

  axios
    .get(`${config.API_GATEWAY.URL}/get-features/list`, axiosConfig)
    .then((response: any) => {
        putFeatureStatusToStorage(response.data);
    })
    .catch((error: any) => {
      console.error("Get Feature Status Values Error", error);
      // return error
    });
};

export const putFeatureStatusToStorage = (response: FeatureStatus[]) => {
  if (response) {
    localStorage.setItem('featureStatus', JSON.stringify(response))
  }
};