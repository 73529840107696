import React, { useEffect, useMemo } from "react";
import { useHistory } from "react-router";
import ExamRequest from "../../models/exam-request";

import Icon from "../../components/pearson/icon";
import Loader from "../../components/common/loader/loader";
import ViewRequestHeader from "../../components/pearson/viewRequestHeader";
import ViewRequestCard from "../../components/pearson/viewRequestCard";
import Pagination from "../../components/pearson/pagination";
import Button from "../../components/pearson/button";
import * as dbrdHelper from '../../helpers/dashboard-helper'



import ViewLearnerSearchBar from "../../components/pearson/viewLearnerSearchBar";
import FileUploadViewerLearner from "../../components/pearson/file-upload-viewer_learner";
import SavedSearch from "../../models/saved-search";
import {
  getViewPapersSavedSearch,
  getSavedSearch,
  dateFormatter,
  clearIntervals,
  removeAllErrorText
} from "../../helpers/dashboard-request-helper";
import { ViewPapersSavedSearch } from "../../models/saved-search";
import { LearnersList } from "../../models/learner";
import LearnerMaterial from "../../models/learner-material";
import Dropdown, { DropdownOption } from "../../components/pearson/dropdown";
import Modal from "../../components/pearson/CommonModal";
import { SubmitAllowed } from "../../models/submit-allocation";
import Impersonating from "../Impersonating/impersonating.component";
import { IsUserInternal,HasWriteAccess } from "../../components/common/user-validation";
import { initialStateType } from "../../shared/context-api/provider";
import { useUserLoginState } from "../../shared/context-api/hooks";
import config from '../../app-config'
import { ManageLearnersButton, getCurrentAssessMethodNumber } from "../../helpers/dashboard-helper";
import FileUploadAdminMaterial from "../../components/pearson/file-upload-admin-material";
import FileUploadSharedFiles from "../../components/pearson/file-upload-shared-files";
import { LINKED_LEARNER_FILTER, REMOVE_RESPONSE_CODE, REQUSEST_STATUS } from '../../shared/app-enums';
import './view-learners.css';
import _ from "lodash";
import AdminMaterial, { AdminSharedMaterialSummary } from "../../models/admin-material";
import ShowAlert from '../../components/common/alert';
import SharedFileLearners from "../../models/shared-file";
import { sortLearners } from "../link-learners/link-learners.helper";


export interface StateProps {
  examRequests?: ExamRequest[];
  learnerDetails: LearnersList;
  learnerMaterial: LearnerMaterial;
  learnerStatus: any;
  adminMaterial: AdminMaterial;
  downloadAdminMaterialURL: string;
  adminSharedMaterial: AdminSharedMaterialSummary;
  sharedFileLearnersResponse: SharedFileLearners;
  submitAllowed: SubmitAllowed
}

export interface DispatchProps {
  getViewLearnersDetails: (
    allocationId: string,
    searchText: string,
    learnerStatus: number,
    pageNumber: number,
    pageSize: number
  ) => Promise<void>;
  addLearnerMaterial: (
    allocationId: number,
    fileId: string,
    fileKey: string,
    fileName: string,
    fileSize: string,
    learnerId: string
  ) => Promise<void>;
  removeLearnerMaterial: (
    allocationId: number,
    fileKey: string,
    learnerId: string,
    isRemoveAll: boolean
  ) => Promise<void>;
  updateLearnerStatus: (
    allocationId: number,
    learnerId: number,
    learnerStatusCode: number,
    assessmentMethodType: number
  ) => Promise<void>;
  submitAllocation: (
    allocationId: number,
    assessmentMethodType: number
  ) => Promise<void>;
  checkSubmitAllowed: (
    allocationId: number,
    assessmentMethodType: number
  ) => Promise<void>;
  submitAllowed: SubmitAllowed;
  addAdminMaterial: (
    allocationId: number,
    fileId: string,
    fileKey: string,
    fileName: string,
    fileSize: string,
    fileType: number
  ) => Promise<void>;
  downloadAdminMaterial: (allocationId: number, fileKey: string, fileUserType: number, assessmentMethodType: number, duration: number, fileName: string) => Promise<void>;

  removeAdminMaterial: (
    allocationId: number,
    fileKey: string,
    isRemoveAll: boolean,
    fileType: number
  ) => Promise<void>;
  getAdminMaterialSummary: (allocationId: number) => Promise<void>;
  getSharedFileLearners: (
    allocationId: number,
    fileKey: string,
    assessmentMethodType: number,
    linkedLearnerFilter: number
  ) => Promise<void>;
}

interface Props extends DispatchProps, StateProps { }

const ViewLearner = (props: Props) => {
  const savedSearch = getSavedSearch() as SavedSearch;
  const savedSearchViewAll = getViewPapersSavedSearch() as SavedSearch;
  // const pageURL = window.location.href;
  // const lastURLSegment = pageURL.substr(pageURL.lastIndexOf("/") + 1);
  const viewPaperScreen = localStorage.getItem("viewPaperScreen")

  let isSubmitAllowed = props && props.submitAllowed && props.submitAllowed.isSubmitAllowed ? props.submitAllowed.isSubmitAllowed : false;

  let isRequestSubmitted = _.includes(['Submitting', 'Submitted'], props.learnerDetails.status)
  // const statusId = props.learnerDetails && props.learnerDetails.statusId
  // if(statusId){
  //   isSubmitAllowed = !(statusId.toString() == "1" || statusId.toString() == "4" || statusId.toString() == "5" || statusId.toString() == "7")
  // }
  document.title = "Learner Work Transfer - View Learners";
  const [loading, setLoading] = React.useState(true);
  const learnersData = props && props.learnerDetails;
  const [loadingContent, setLoadingContent] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [filterValue, setFilterValue] = React.useState("All");

  const [openModal, setOpenModal] = React.useState(false);
  const [learnerStatus, setLearnerStatus] = React.useState(0);
  const [allocationId] = React.useState(viewPaperScreen !== "true" ? savedSearch.selectedAllocationId as string : savedSearchViewAll.selectedAllocationId as string);
  const [paginationData, setPaginationData] = React.useState(false)

  const [learnerCount, setLearnerCount] = React.useState(
    learnersData && learnersData.totalPages
  );
  const [removeError, setRemoveError] = React.useState("");
  const [showRemoveErrorMessage, setShowRemoveErrorMessage] = React.useState(false);

  /*=======================================*/
  useEffect(() => {
    const fileName = localStorage.getItem("VLIconClicksharedFilename");
    if (fileName &&
      props.sharedFileLearnersResponse &&
      props.sharedFileLearnersResponse.learners &&
      props.sharedFileLearnersResponse.learners.length) {
      setShowSharedIconClickedLoader(false);
      openSharedFileLearnersMsg();
    }

  }, [props.sharedFileLearnersResponse]);

  useEffect(() => {
    // const fileName = localStorage.getItem("VLIconClicksharedFilename");
    return () => {
      localStorage.setItem("VLIconClicksharedFilename", '');
    }
  }, [])

  const [showSharedFileLearnersMsg, setShowSharedFileLearnersMsg] = React.useState(false);
  const [sharedFileLearnersMsg, setSharedFileLearnersMsg] = React.useState("");
  const [showSharedIconClickedLoader, setShowSharedIconClickedLoader] = React.useState(false)

  const openSharedFileLearnersMsg = () => {
    const fileName = localStorage.getItem("VLIconClicksharedFilename");
    let htmlText = `<div style="font-size:12px;">
    <div>The following learners are linked with the file:&nbsp;
    <strong>${fileName}</strong></div>
      <span class="scroller"  style="width:450px; height: 110px; margin-top:5px">
         <ul class="shared-file-learners-list">`;
    if (
      props.sharedFileLearnersResponse &&
      props.sharedFileLearnersResponse.learners &&
      props.sharedFileLearnersResponse.learners.length
    ) {
      const sortedsharedFileLearners = sortLearners(
        props.sharedFileLearnersResponse.learners
      );
      sortedsharedFileLearners.forEach((l) => {
        // if(l.isSharedFile){
        htmlText += `<li>${l.learnerCode}&nbsp;&nbsp;${l.learnerFirstName + " " + l.learnerLastName
          }</li>`;
        // }
      });
    } else {
      htmlText += "Error! No Learners Found.";
    }

    htmlText += `</ul>
     <span>
  </div>`;
    setSharedFileLearnersMsg(htmlText);
    setShowSharedFileLearnersMsg(true)
  }

  const closeSharedFileLearnersMsg = () => {
    setShowSharedFileLearnersMsg(false);
  }

  const handleSharedIconClick = async (e: any, fileKey: string) => {
    const assessmentMethodType = getCurrentAssessMethodNumber() || 1;
    props.getSharedFileLearners(
      +allocationId, fileKey, assessmentMethodType, LINKED_LEARNER_FILTER.LINKED
    )
    setShowSharedIconClickedLoader(true)
  }
  /*======================================*/

  const learnersCount = learnersData && learnersData.learnersCount;
  const navigateToViewLearners = (selectedAllocationId: string) => {
    // console.log(learnersCount);
  };

  // let allocationId = lastURLSegment === "1" ? savedSearch.selectedAllocationId as string : savedSearchViewAll.selectedAllocationId as string;
  // let allocationIdViewall = savedSearchViewAll.selectedAllocationId as string;
  const cPage = localStorage.getItem("currentLearnerPage");

  const initPageNumber = cPage ? parseInt(cPage) : 1;
  const activePage = cPage ? cPage : "1";
  const [currentPageNumber, setCurrentPageNumber] = React.useState(
    initPageNumber
  );
  const pageMaxSize = 10;
  const pageNumber = 1;

  useEffect(() => {
    localStorage.setItem("viewLearnerGetData", "false")
  }, []);

  useEffect(() => {
    // Save the current request status
    localStorage.setItem("VLReqStatusId", learnersData.statusId);
    // saveSelectedRequestData(learnersData);
  }, [learnersData.statusId])

  useEffect(() => {
    // const allocatId =  lastURLSegment === "1" ? allocationIdViewall : allocationId;
    setLoading(true);
    window.scrollTo(0, 0);
    if (!allocationId) {
      history && history.push("/");
    }
    props.getAdminMaterialSummary(parseInt(allocationId));
    props.getViewLearnersDetails(allocationId, "", 0, currentPageNumber, pageMaxSize);
    checkSubmitAllowed()
    return () => {
      // props.getViewLearnersDetails("0", "", 0, pageNumber, pageMaxSize); //during unmount setting the states to intial state
      localStorage.setItem("showLearnerDetails", "false");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {


    const adminMaterial = props.adminMaterial;
    if (adminMaterial.responseCode === REMOVE_RESPONSE_CODE.FILE_NOT_DELETED || adminMaterial.responseCode === REMOVE_RESPONSE_CODE.FILE_ALREADY_DELETED) {
      const removeErr = localStorage.getItem("removeError");
      if (removeErr === "true") {
        setShowRemoveErrorMessage(true);
        setRemoveError(removeAllErrorText);
        localStorage.setItem("removeError", "false")
      }

    }
    if (adminMaterial.responseCode === 409) {
      setShowRemoveErrorMessage(true);
      setRemoveError("File already exists. Please rename this file so it can be uploaded");

    }

  }, [props.adminMaterial]);

  useEffect(() => {
    const learnerMaterialData = props.learnerMaterial;
    if (learnerMaterialData.responseCode === REMOVE_RESPONSE_CODE.FILE_NOT_DELETED || learnerMaterialData.responseCode === REMOVE_RESPONSE_CODE.FILE_ALREADY_DELETED) {
      const learnerRemoveError = localStorage.getItem("learnerRemoveError");
      if (learnerRemoveError === "true") {
        setShowRemoveErrorMessage(true);
        setRemoveError(removeAllErrorText);
        localStorage.setItem("learnerRemoveError", "false")
      }
    }
    if (learnerMaterialData.responseCode === 409) {
      setShowRemoveErrorMessage(true);
      setRemoveError("File already exists. Please rename this file so it can be uploaded");

    }
  }, [props.learnerMaterial]);

  const learnerDetails = props && props.learnerDetails;
  const qualificationName = learnerDetails && learnerDetails.qualificationName;
  const qualificationCode = learnerDetails && learnerDetails.qualificationCode;
  const qualificationTypeCode = learnerDetails && learnerDetails.qualificationTypeCode;
  const unitCode = learnerDetails && learnerDetails.unitCode;
  const [requestStatus, setRequestStatus] = React.useState(learnerDetails.status);
  const [requestStatusId, setRequestStatusId] = React.useState(learnerDetails.statusId + "");
  const totalRecords = learnersData && learnersData.totalRecords;
  const totalPages = learnersData && learnersData.totalPages;
  // let showCancelAllIcon = false;
  // let cancelAll = false;

  useEffect(() => {
    window.addEventListener('beforeunload', dbrdHelper.alertUser)
    window.addEventListener('unload', dbrdHelper.handleTabClosing)
    return () => {
      window.removeEventListener('beforeunload', dbrdHelper.alertUser)
      window.removeEventListener('unload', dbrdHelper.handleTabClosing)
    }
  })

  useEffect(() => {

    setLoading(false);
    setLoadingContent(false);
    const vlpaginationData = localStorage.getItem("vlpaginationData");

    setPaginationData(vlpaginationData === "true");
    setLearnerCount(totalPages);
    const interval = setInterval(() => {
      props.getViewLearnersDetails(
        allocationId,
        searchText,
        learnerStatus,
        currentPageNumber,
        pageMaxSize
      );
      props.getAdminMaterialSummary(parseInt(allocationId));

    }, config.refreshTime);
    setRequestStatus(props.learnerDetails.status);
    setRequestStatusId(props.learnerDetails.statusId + "");
    // console.log("props.learnerDetails",props.learnerDetails)
    localStorage.setItem("VLReqData", JSON.stringify({
      allocationId: props.learnerDetails.allocationId,
      statusId: props.learnerDetails.statusId,
      evidenceSubmittedOn: props.learnerDetails.evidenceSubmittedOn,
      returnedOn: props.learnerDetails.returnedOn
      // MOCK MOCK MCOK
      // evidenceSubmitted: evidenceSubmittedOnMOCK, 
      // returnedOn: returnedOnMOCK
    }));
    const submitAllowedInterval = setInterval(() => {
      checkSubmitAllowed();
    }, config.refreshTime3);
    return () => clearIntervals([interval, submitAllowedInterval]);

    // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, [props && props.learnerDetails, totalPages]);

  useEffect(() => {
    setRequestStatus(props.learnerMaterial.status);
    setRequestStatusId(props.learnerMaterial.statusId + "");
  }, [props.learnerMaterial]);

  useEffect(() => {
    setRequestStatus(props.adminMaterial.status);
    setRequestStatusId(props.adminMaterial.statusId + "");
  }, [props.adminMaterial]);

  useEffect(() => {
    if (qualificationName) {
      setLoading(false);
    }
  }, [qualificationName]);


  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     props.getViewLearnersDetails(
  //       allocationId,
  //       searchText,
  //       learnerStatus,
  //       pageNumber,
  //       pageMaxSize
  //     );
  //   }, 15000);
  //   return () => clearInterval(interval);
  // }, []);

  // const saveSelectedRequestData = (learnerData:LearnersList) => {
  //   const {statusId, evidenceSubmittedOn, returnedOn} = learnerData;
  //   const allocId = learnerData.allocationId; // code smell circus!!!
  //   // MOCK MOCK MCOK
  //   // const evidenceSubmittedOnMOCK="2021-04-15";
  //   // const returnedOnMOCK = "2021-01-15";

  //   // localStorage.setItem("VLReqData", JSON.stringify({
  //   //   allocationId:allocId,
  //   //   statusId,
  //   //   evidenceSubmittedOn, 
  //   //   returnedOn
  //   //   // MOCK MOCK MCOK
  //   //   // evidenceSubmitted: evidenceSubmittedOnMOCK, 
  //   //   // returnedOn: returnedOnMOCK
  //   // }));
  // }

  const onSearch = (event: any, searchTextOnSearch: string) => {
    setCurrentPageNumber(1);
    setLoadingContent(true);
    props.getViewLearnersDetails(
      allocationId,
      searchTextOnSearch,
      learnerStatus,
      pageNumber,
      pageMaxSize
    );
    setLearnerCount(0);
    localStorage.setItem("viewLearnerSearchString", searchTextOnSearch)

  };
  const allLearnersFilter = (e: any) => {
    setLoadingContent(true);
    const allLearnersStatus = 0;
    setFilterValue("All");
    props.getViewLearnersDetails(
      allocationId,
      searchText,
      allLearnersStatus,
      pageNumber,
      pageMaxSize
    );
    setLearnerStatus(allLearnersStatus);
    setLearnerCount(0);
  };

  const onTextChange = (event: any) => {
    const stxt =
      event && event.target && (event.target as HTMLInputElement).value;
    setSearchText(stxt);
  };

  const updateFilter = (learnerStatusCount: number, stautsText: string) => {
    setCurrentPageNumber(1);
    setLoadingContent(true);
    setFilterValue(stautsText);
    props.getViewLearnersDetails(
      allocationId,
      searchText,
      learnerStatusCount,
      pageNumber,
      pageMaxSize
    );
    setLearnerStatus(learnerStatusCount);
    setLearnerCount(0);
  };

  const acceptedFilter = (event: any) => {
    updateFilter(2, "Accepted");
  };

  const scanningFilter = (event: any) => {
    updateFilter(1, "Scanning");
  };

  const rejectedFilter = (event: any) => {
    updateFilter(3, "Rejected");
  };

  const nofilesFilter = (event: any) => {
    updateFilter(4, "NoFiles")
  }
  const learnerStatusChange = (
    learnerId: string,
    learnerStatusCode: number
  ) => {
    // return (props.adminMaterial && props.adminMaterial.length) ? props.adminMaterial : learnersData;
    props.updateLearnerStatus(
      parseInt(allocationId),
      parseInt(learnerId),
      learnerStatusCode,
      1
    );
  };

  const onPaginationFetchLearner = (newPageNumber: number) => {
    setCurrentPageNumber(newPageNumber);
    setLoadingContent(true);
    localStorage.setItem("currentLearnerPage", newPageNumber.toString());
    const viewLearnerSearchString = localStorage.getItem("viewLearnerSearchString") as string
    props.getViewLearnersDetails(
      allocationId,
      viewLearnerSearchString,
      learnerStatus,
      newPageNumber,
      pageMaxSize
    );
  };

  const checkSubmitAllowed = () => {
    // const assessmentMethodType = 1;
    // const assessmentMethodType = localStorage.getItem("currentAssessMethod") !==  'examination' ? 2 : 1;
    const assessmentMethodType = getCurrentAssessMethodNumber() || 1;
    props.checkSubmitAllowed(parseInt(allocationId), assessmentMethodType);
  };

  const removeSpecialCharacters = (value: string) => {
    return value.replace(/[^\w\d_\-\.]+/gi, "")
  };

  const createfileKey = (allocationId:string,qualificationCode:string,unitCode:string,learnerCode:string,qualificationTypeCode:string) => {
   
    return `${allocationId}/${qualificationCode !== null ? removeSpecialCharacters(qualificationCode) : removeSpecialCharacters(qualificationTypeCode)}/${unitCode !== null ? removeSpecialCharacters(unitCode) : ""}/${removeSpecialCharacters(learnerCode)}/`

  }


  const getAdminMeterial = (learnerEvidance: any, learnerId: number) => {
    let meterials: any = [];
    let isVisited = localStorage.getItem("isAddUpload");
    //if ((learnerId == (props.adminMaterial && props.adminMaterial.learnerId)) && isVisited == "true") {
    if (isVisited === "true") {
      localStorage.setItem("isAddUpload", "false")

      props.getViewLearnersDetails(
        allocationId,
        searchText,
        learnerStatus,
        currentPageNumber,
        pageMaxSize
      );

      // meterials = (props.adminMaterial && props.adminMaterial.evidence && (props.adminMaterial.evidence.length > 0))
      //   && props.adminMaterial.evidence
      // meterials =  learnerEvidance
      // props.getViewLearnersDetails(
      //   allocationId,
      //   searchText,
      //   learnerStatus,
      //   currentPageNumber,
      //   pageMaxSize
      // );
    } else {
      meterials = learnerEvidance;
    }
    return meterials;
  }

  const getLPAdminMeterial = () => {
    let meterial: any = [];
    let isVisited = localStorage.getItem("isAddUploadAdminMatrial")
    //props.adminMaterial && props.adminMaterial.length && 
    if (isVisited === "true") {
      // meterial = props.adminMaterial
      localStorage.setItem("isAddUploadAdminMatrial", "false")
      props.getAdminMaterialSummary(parseInt(allocationId));
      // localStorage.setItem("isAddUpload", "false")
    } else {

      meterial = props.adminSharedMaterial.lpAdminMaterial;
    }
    return meterial;
  }

  const closeRemoveError = () => {

    setShowRemoveErrorMessage(false);
  }

  const getLPSharedFiles = () => {
    let meterial: any = [];
    let isVisited = localStorage.getItem("isAddUploadAdminMatrial")


    if (isVisited === "true") {
      // meterial = props.adminMaterial
      localStorage.setItem("isAddUploadAdminMatrial", "false")
      props.getAdminMaterialSummary(parseInt(allocationId));
      // localStorage.setItem("isAddUpload", "false")

      props.getViewLearnersDetails(
        allocationId,
        searchText,
        learnerStatus,
        currentPageNumber,
        pageMaxSize
      );
    } else {

      meterial = props.adminSharedMaterial.learnerSharedMaterial;
    }

    return meterial;
  }

  // const getLPSharedFiles = () => {
  //   let meterial: any = [];

  //   meterial.push({
  //     fileKey: 'asdf089df7sa6', 
  //     fileName: 'test.txt', 
  //     fileStatusCode:2, 
  //     fileSize: 23506
  //   });
  //   // let isVisited = localStorage.getItem("isAddUploadAdminMatrial")
  //   // console.log("isVisited",isVisited)
  //   // //props.adminMaterial && props.adminMaterial.length && 

  //   // if (isVisited !== null && isVisited === "true") {
  //   //   // meterial = props.adminMaterial
  //   //   localStorage.setItem("isAddUploadAdminMatrial", "false")
  //   //   props.getAdminMaterialSummary(parseInt(allocationId));
  //   //   // localStorage.setItem("isAddUpload", "false")
  //   // } else {
  //   //   console.log(props && props.adminSharedMaterial)
  //   //  meterial = props && props.adminSharedMaterial.lpAdminMaterial;
  //   // }
  //   return meterial;
  // }
  const openModalWindow = () => {
    // console.log("submitAllowed",isSubmitAllowed)
    checkSubmitAllowed();
    setOpenModal(true);
    // if(!isSubmitAllowed){
    // // checkSubmitAllowed();

    // }else{
    //   setOpenModal(true);
    //   // submitRequest();
    // }
  };
  const getActions = () => {
    const actions: DropdownOption[] = [];
    if (isSubmitAllowed) {
      actions.push({
        name: "Submit Request",
        onClick: () => openModalWindow()
      });
    } else {
      actions.push({ name: "Submit Request", onClick: () => { } });
    }
    return actions;
  };
  const history = useHistory();
  const submitRequest = () => {
    //const assessmentMethodType = localStorage.getItem("currentAssessMethod") !==  'examination' ? 2 : 1;
    const assessmentMethodType = getCurrentAssessMethodNumber() || 1;
    props.submitAllocation(parseInt(allocationId), assessmentMethodType)
    setOpenModal(false);

    //lastURLSegment === "1" ? history.push("/view-papers") : history.push("/");
    viewPaperScreen === "true" ? history.push("/view-papers") : history.push("/");
  };

  const manageLearners = (e: any) => {
    // e.preventDefault();
    //lastURLSegment === "1"?history.push("/manage-learners/1"):history.push("/manage-learners");
    viewPaperScreen === "true" ? history.push("/manage-learners/1") : history.push("/manage-learners");
  }
  const currentAssesmentType = getCurrentAssessMethodNumber()

  // const centerName = localStorage.getItem("centerName")
  const userLoginstate = useUserLoginState();
  // const learningCenter = 
  //   (userLoginstate && 
  //   userLoginstate.userData && 
  //   userLoginstate.userData.associatedLearningProviders) || [];

  const viewLearnerGetData = localStorage.getItem("viewLearnerGetData");
  localStorage.setItem('actOnBehalfLP', learnerDetails.learningProviderCode + " " + learnerDetails.learningProviderName);
  const assessmentMethodType = getCurrentAssessMethodNumber();

  const learners = useMemo(() => {
    const padded = _.map(learnersData.learners, (learner: any) => {
      return {
        ...learner,
        learnerCode: _.padStart(learner.learnerCode, 4, '0')
      }
    });
    return _.orderBy(padded, 'learnerCode');
  }, [learnersData]);

  return (
    <div>
      {showSharedIconClickedLoader ?
        (<div
          className="scroll-top-middle-float-loader"
          style={{ top: window.pageYOffset + 20 }}>
          <Loader
            height={"50vh"}
            timeoutSecs={2000}
            message="."
          />
        </div>) : (<></>)
      }
      <div>
        <ShowAlert
          containerId='remove-file-fail' type='error'
          parentStateShowAlertVar={showRemoveErrorMessage}
          parentStateHideAlertMethod={closeRemoveError}
          alertBody={{ styles: { fontSize: "12px" }, text: removeError }}
        />
        <ShowAlert
          type="important"
          containerId='shared-file-learners'
          parentStateShowAlertVar={showSharedFileLearnersMsg}
          parentStateHideAlertMethod={closeSharedFileLearnersMsg}
          alertBody={{ styles: { fontSize: "12px" }, text: sharedFileLearnersMsg }}
        />
      </div>
      {!loading && (viewLearnerGetData === 'true') ? (
        <div style={{ marginTop: "30px" }}>
          {IsUserInternal(userLoginstate as initialStateType) &&
            <Impersonating behalfOf={learnerDetails.learningProviderCode + " " + learnerDetails.learningProviderName} backText="Back to Dashboard" backLink="/" page="VL" />
          }
          {!IsUserInternal(userLoginstate as initialStateType) &&
            <BackToDashboardLink viewPaperScreen={viewPaperScreen} />
          }
          {/* {(learningCenter.length > 1 && centerName) && (
            <div className="gr-grid-container ie-flex margin0" style={{ backgroundColor: "#d9d9d9", padding: "10px" }}>
              <div className="gr-col-one gr-col-span-two " style={{ paddingLeft: "18px" }}>Learning Provider</div>
              <div className="gr-col-one gr-col-span-five " style={{ paddingLeft: "18px", fontWeight: "bold" }}>  {centerName}
              </div>
            </div>)} */}
          {learnersData && learnersData.qualificationTypeName !== undefined && (

            <ViewRequestHeader
              label={
                learnerDetails.qualificationTypeName +
                " " 
                +
                //condition check
                (learnerDetails.qualificationName ? learnerDetails.qualificationName : "") 
              }
              name={
                learnerDetails.unitCode +
                " " +
                learnerDetails.unitName
              }
              series={learnerDetails.seriesName}
              viewRequestHeaderContainerStyle={{
                marginTop: "20px",
                float: "left",
                width: "100%"
              }}
              isLearner={true}
            />
          )}
          <br />

          {learnersData && learnersData.qualificationTypeName !== undefined && (
            <div style={{ width: "100%", float: "left" }}>
              <ViewRequestCard
                key={1}
                name=""
                unitInfo={
                  learnerDetails.learningProviderCode +
                  " " +
                  learnerDetails.learningProviderName 
                }
                status={requestStatus}
                // status={(props.adminMaterial && props.adminMaterial.status) ? props.adminMaterial.status
                //   : learnerDetails.status}
                statusId={requestStatusId}
                totalLearnerCount={learnerDetails.expectedLearnerCount}
                learnerCount={learnerDetails.actualLearnerEvidenceCount}
                // recLabel={`Allocated AA:`}
                // recInfo={learnerDetails.aaNumber + " " + learnerDetails.aaName}
                compLabel={`Submitted on`}
                compInfo={`${dateFormatter(learnerDetails.evidenceSubmittedOn).date
                  } at ${dateFormatter(learnerDetails.evidenceSubmittedOn).reqTime
                  } by ${learnerDetails.evidenceSubmittedBy}`}
                completionDate=""
                submissionDate=""
                submissionDeadLineDate={
                  dateFormatter(learnerDetails.submissionDeadlineDate).date
                }
                AADeadlineDate=""
                allocationId={learnerDetails.allocationId}
                onViewLearners={navigateToViewLearners}
                aaName={learnerDetails.aaName}
                aaNumber={learnerDetails.aaNumber}
                isThreeDotRequired={false}
                onSubmitRequest={submitRequest}
                isSubmitAllowed={isSubmitAllowed}
                returnedBy={learnerDetails.returnedBy}
                returnedOn={learnerDetails.returnedOn}
                userClassType={learnerDetails.userClassType}
                returnUserClassType={learnerDetails.returnUserClassType}
              />
              {currentAssesmentType === 2 && (
                <p style={{ fontWeight: "bold" }}>This request <span style={{ color: "red" }}>must</span> include evidence for the highest and lowest marked learners.  Please refer to subject specific guidance about how to identify these learners.  If these learners are not currently displayed, use the Manage Learner functionality to include the learner(s).</p>)}
            </div>)}

          <div style={{ marginTop: "35px", float: "left", width: "100%" }}>
            {learnersCount && (
              <ViewLearnerSearchBar
                searchText={searchText}
                onSearch={onSearch}
                onTextChange={onTextChange}
                allLearnersFilter={allLearnersFilter}
                acceptedFilter={acceptedFilter}
                scanningFilter={scanningFilter}
                rejectedFilter={rejectedFilter}
                nofilesFilter={nofilesFilter}
                filterValue={filterValue}
                //learnerSummary={props.learnerDetails.learnersCount}
                placeholderText="Search"
                learnersCount={learnersCount}
              />
            )}
          </div>
          <div className="action-button" style={{ float: "left", width: "17%" }}>
            {learnersCount && (
              // <Dropdown
              //   buttonText="Actions"
              //   type="attention"
              //   options={getActions()}
              //   isSubmitAllowed={isSubmitAllowed}

              // />
              <Button
                label="Submit Request"
                type="submit"
                className="attention"
                customClass="saveReqBut"
                onClick={openModalWindow}
                disabled={isRequestSubmitted || !HasWriteAccess(userLoginstate)}

              />
              // <button
              //       className="cancel"
              //       onClick={(e) => getActions( )}
              //     >
              //       Cancel
              //     </button>
            )}
          </div>
          {learnersCount && !_.includes(['Submitting', 'Submitted', 'Completed'], learnerDetails.status) && 
            ( <ManageLearnersButton onClick={manageLearners} />)}
          {learnersData && learnersData.qualificationTypeName !== undefined && (
            <div className="fileUploadContainter">
              <FileUploadAdminMaterial
                allocationId={allocationId}
                addAdminMaterial={props.addAdminMaterial}
                removeAdminmaterial={props.removeAdminMaterial}
                prevUploadedAdminFiles={getLPAdminMeterial()}
                handlePostAddRemoveFiles=""
                downloadAdminMaterial={props.downloadAdminMaterial}
                status={learnersData && learnersData.statusId}
                adminMaterial={props.adminMaterial}

              />
            </div>)}
          {learnersData && learnersData.qualificationTypeName !== undefined && (
            <div className="fileUploadContainter">
              <FileUploadSharedFiles
                allocationId={allocationId}
                addAdminMaterial={props.addAdminMaterial}
                removeAdminmaterial={props.removeAdminMaterial}
                prevUploadedFiles={getLPSharedFiles()}
                handlePostAddRemoveFiles=""
                downloadAdminMaterial={props.downloadAdminMaterial}
                status={learnersData && learnersData.statusId}
                onSharedIconClick={handleSharedIconClick}
                adminMaterial={props.adminMaterial}
              />
            </div>)}
          <div style={{ marginTop: "8px", float: "left", width: "100%" }}>
            {!loadingContent ? (
              <div>
                {learnersData &&
                  learnersData.totalRecords > 0 ?
                  (
                    learners.map((learner: any, key2: any) => {
                      return (
                        <FileUploadViewerLearner
                          key={key2}
                          allocationId={allocationId}
                          addLearnerMaterial={props.addLearnerMaterial}
                          removeLearnerMaterial={props.removeLearnerMaterial}
                          prevUploadedFiles=""
                          handlePostAddRemoveFiles=""
                          learnerId={learner.learnerId}
                          learningProviderCode={learner.learningProviderCode}
                          learnerName={learner.learnerFirstName + " " + learner.learnerLastName}
                          learnerDOB={learner.learnerDob && learner.learnerDob !== null ? dateFormatter(learner.learnerDob).date : "NA"}
                          learnerLateEntryDate={dateFormatter(learner.learnerLateEntryDate).date + " " + dateFormatter(learner.learnerLateEntryDate).reqTime}
                          learnerLateEntryOrgDate={learner.learnerLateEntryDate}
                          learnerIsLateEntry={learner.learnerIsLateEntry}
                          evidence={getAdminMeterial(learner.evidence, learner.learnerId)}
                          status={learnersData && learnersData.statusId}
                          learnerStatusChange={learnerStatusChange}
                          learnerStatusCode={learner.learnerStatusCode}
                          learnerCode={learner.learnerCode}
                          qualificationCode={qualificationCode}
                          unitCode={unitCode}
                          learnerStatusTitle={learner.learnerStatusTitle}
                          evidenceSubmittedOn={learnerDetails.evidenceSubmittedOn}
                          evidenceReturnedOn={learnerDetails.returnedOn}
                          isSystemSelected={learner.isSystemSelected}
                          onSharedIconClick={handleSharedIconClick}
                          learnerMaterial={props.learnerMaterial}
                          fileKey={createfileKey(allocationId, qualificationCode, unitCode, learner.learnerCode,qualificationTypeCode)}
                          filterValue={filterValue}
                          isSubmitAllowed={isSubmitAllowed}
                          downloadAdminMaterial={props.downloadAdminMaterial}


                        />
                      );
                    })
                  ) : (<div>
                    {learnersData &&
                      learnersData.totalRecords === 0 && (
                        assessmentMethodType === 2 ? "Please use the Manage Learners button to add the required learners to this request and upload material as required." : "There are no learners available.")}
                  </div>)}
              </div>
            ) : (
              <Loader
                height={"50vh"}
                timeoutSecs={
                  learnersData && learnersData.totalRecords === 0
                    ? 2000
                    : 5000
                }
                message={"There are no learners available"}
              />
            )}

            <div className="paginationWrapper">
              {paginationData && learnerCount > 0 && totalRecords > 10 && (
                <Pagination
                  id="learner_pagination"
                  key={""}
                  totalPageCount={learnerCount}
                  onPageChange={onPaginationFetchLearner}
                  totalRecords={totalRecords}
                  activePage={activePage}
                />
              )}
            </div>
          </div>
          <div style={{ minHeight: "15vh" }}>
            {/* Place the learners list here*/}
          </div>
          <BackToDashboardLink viewPaperScreen={viewPaperScreen} />
        </div>
      ) : (
        <Loader />
      )}
      <br /> <br /> <br />
      <Modal
        isConfirmBut={false}
        show={openModal}
        title={isSubmitAllowed ? "Submit Request" : "Request cannot be submitted"}
        submitAllowed={isSubmitAllowed}
        modalData="submitRequest"
        onCancel={() => setOpenModal(false)}
        onConfirm={() => submitRequest()}
      />
    </div>
  );
};

const BackToDashboardLink = (props: any) => {
  const { viewPaperScreen } = props;
  const savedSearch = getViewPapersSavedSearch() as ViewPapersSavedSearch;
  const qualificationName = savedSearch && savedSearch.qualificationTypeName;
  const history = useHistory();
  const backToDashBoard = () => {
    localStorage.removeItem("viewLearnerGetData")
    localStorage.removeItem("currentLearnerPage");
    localStorage.removeItem("vlpaginationData");
    //  localStorage.setItem("currentPaperPage","1");

    viewPaperScreen === "true" ? history.push("/view-papers") : history.push("/"); // history.push("/");
  };

  return (
    <span style={{ marginTop: "3px", width: "100%", float: "left" }}>
      <a
        onClick={backToDashBoard.bind(null)}
        style={{ textDecoration: "none", cursor: "pointer" }}
      >
        <Icon size="18" icon="back" />
        <span className="backToDashBoardLink gr-semi-bold">
          &nbsp;&nbsp;&nbsp;Back to{" "}
          {viewPaperScreen === "true" ? qualificationName : "Dashboard"}
        </span>
      </a>
    </span>
  );
};

export default ViewLearner;
