import * as React from "react";
// import * as _ from 'lodash';
import Modal from "react-bootstrap/Modal";
import Button from "./button";
import "./modal.css";
import parse from 'html-react-parser';
interface ModalProps {
    title?:string;
    modalData: string;
  show: boolean;
  onCancel: () => void;
  onConfirm?: () => void;
  isConfirmBut?:boolean;
  styleClass?:string ;
}


const InitialModal = ({
    title,
    modalData,
  show,
  onCancel,
  onConfirm,
  isConfirmBut,
  styleClass
}: ModalProps) => {
    const  htmlDecode:any = (input:any) => {
        var e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
      }
    
  return (
      
    <div className="re-open-appeal-modal">
      <Modal
        show={show}
        size="lg"
        onHide={onCancel}
        dialogClassName={styleClass}
        aria-labelledby="logout confirm">
        <Modal.Header closeButton>
          <Modal.Title><h3>Update about submitting your requests

</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="outcome">
            <div >{parse(modalData)}</div>
            </div>                          
        </Modal.Body>
        <Modal.Footer>
          <Button 
            label="Ok"
            type="reset"
            onClick={onCancel}
          /> 
         
        </Modal.Footer>
      </Modal>
    </div>
  );
};

InitialModal.defaultProps = {
    size: "sm",
    
  };


export default InitialModal;