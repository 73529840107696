export default class User {
    associatedLearningProviders: associatedLearningProviders[];
    isUserPermissionValid: boolean;
    lockedUntil?: Date = new Date();
    jobRoles?: jobRole[];
    isAATeamLead?: boolean;
    lpExclusions? : string[];
    internalUser?: boolean;
    firstName?: string='';
    lastName?: string='';
    email?:string;
    userPermissions: getUserPermissionDetails;
}

export class getUserPermissionDetails {
    lwtReadAccess: boolean;
    lwtWriteAccess: boolean;
    dlwtSetupReadAccess: boolean;
    dlwtSetupPreResultsWriteAccess: boolean;
    dlwtSetupPostResultsWriteAccess: boolean;
  }

export class learningProvider {
    vqLearningProviderCode: string;
    gqLearningProviderCode: string;
    learningProviderId: number;
    learningProviderName: string;
}

export class associatedLearningProviders {
    learningProviderCode: string;
    learningProvider: learningProvider[];
}

export class permissionGroups {
    permissionGroupId: number;
    permissionGroupName: string;
}
 export class jobRole {
    jobRoleCode: string;
    jobRoleDescription?: string;
    jobRoleId: number;
    lastModifiedDateTime?: Date = new Date();
 }
	
