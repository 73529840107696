import * as React from "react";
import * as _ from "lodash";
import Icon from "./icon";
import Input from "./input";
import { isLineBreak } from "typescript";
import { filter } from "lodash";
// import { ViewLearnersSummary } from "../../models/learner";
// import { getSavedSearch, updateSavedSearch } from "../../helpers/exam-request-helper";
// import SavedSearch from '../../models/saved-search';
import {FILTER_LEARNER} from '../../shared/app-enums';
import { learnerFilterType } from "../../models/learner";



interface manageLearnerSearchBarProps {
  startDisabled?: boolean;
  placeholderText?: string;
  searchText: string;
  onSearch: (e: any, searchText: string) => void;
  onTextChange: (e: any) => void;
  // onSearchUpdate: (T:string, O:string) => void;
  
  filterValue?:string;
  isLinkLearner?:boolean;
  newlySelLearnersFilterCount?:number;
  unSelLearnersFilterCount?:number;
  allLearnersFilterCount?:number;
  linkedLearnersFilterCount?:number;
  handleFilterClick?: (filter:learnerFilterType)=>void
  // getAllUnlinkedLearnersFilterCount?: ()=>void
}

interface LocalState {
  // searchOption: string;
  // searchText: string;
  inputDisabled: boolean;
}

export class ManageLearnerSearchBar extends React.Component<
  manageLearnerSearchBarProps,
  LocalState
> {
  static defaultProps = {
    startDisabled: false,
    placeholderText: "",
    selectedOption: "",
    selectedText: "",
    filterValue :"All",
    isLinkLearner: false
  };
  
  
  raiseDoSearchWhenUserStoppedTyping = _.debounce(() => {
    // this.props.onSearch(this.props.searchOption, this.state.searchText);
  }, 300);

  constructor(props: manageLearnerSearchBarProps) {
    super(props);
    this.state = {
      // searchOption: this.props.searchOption,
      // searchText: this.props.searchText,
      inputDisabled: false,
    }; 

  }

  // handleCriteriaChange = (e: React.FormEvent<HTMLInputElement>) => {
  //   this.setState({ searchText: e.currentTarget.value }, () => {
  //     this.raiseDoSearchWhenUserStoppedTyping();
  //   });
  // };

  componentDidUpdate(){
    if(!this.props.searchText && localStorage.getItem("LLFilter") === FILTER_LEARNER.ALL){
      this.activateFilter();
      this.handleFilterBtnClick({
        target: { dataset: { value: FILTER_LEARNER.ALL } },
      }); 
    } 
  }  
  
  _handleKeyDown = (e: any) => {
    if (e.key === "Enter" && this.props.searchText) {
      this.props.onSearch(e, this.props.searchText);
    }
  };

  resetSearchText = (e: any) => {    
   if (this.props.searchText) {
     this.deactivateFilters();
     setTimeout(()=>{
      this.activateFilter();      
     },300)
     this.props.onTextChange("");
     this.props.onSearch(e, "");
     // #RETAIN_FILTER IS NOT RETAINING filter
     // IF reset should retain filter comment following call
     // also goto link-learners compoenent and search #RETAIN_FILTER
    //  this.handleFilterBtnClick({
    //    target: { dataset: { value: FILTER_LEARNER.ALL } },
    //  });     
   } 
  }

  handleFilterBtnDisableCheck = (event:any) => {
    this.props.onTextChange("");
    const filterString = event.target.dataset.value;
    switch(filterString){
      case FILTER_LEARNER.ALL :
        return this.props.allLearnersFilterCount ? this.handleFilterBtnClick(event) : '';
      case FILTER_LEARNER.SEL :
        return this.props.newlySelLearnersFilterCount ? this.handleFilterBtnClick(event) : '';
      case FILTER_LEARNER.UNSEL :
        return this.props.unSelLearnersFilterCount ? this.handleFilterBtnClick(event) : '';
      case FILTER_LEARNER.LNKD :
        return this.props.linkedLearnersFilterCount ? this.handleFilterBtnClick(event) : '';
    }  
  }
  handleFilterBtnClick = (e:any) => {
    this.props.onTextChange("");
    const filterString = e.target.dataset.value;   
    localStorage.setItem("LLFilter",filterString); 
    const elemId = filterString+"Learners";
    this.deactivateFilters();
    
    document.getElementById(elemId)?.classList.add('active')   
    // console.log("FILETR CLICKED=",filterString)
    this.props.handleFilterClick && 
    this.props.handleFilterClick(FILTER_LEARNER.ALL);    
    this.props.handleFilterClick && 
    this.props.handleFilterClick(filterString);
  }

  deactivateFilters = () => {
    document.querySelectorAll(".allLearnersFilter").forEach(el=>{
      el.classList.remove("active");
    });    
  }

  activateFilter = () => {
    const currFilter = localStorage.getItem("LLFilter")
    const elemId = currFilter+"Learners";
    document.getElementById(elemId)?.classList.add('active')  
  }
  
  onSearch = (e:any) => {
    if(this.props.searchText) {
      this.props.onSearch(e, this.props.searchText)
    }
    
  }

  render() {
    return (
      <div className=" gr-grid-container margin0 topPad10">
        {this.props.isLinkLearner ? (<>
        <div className="gr-form-element input gr-col-one gr-col-span-five  ">
          <div className={'allLearnersFilter' + (this.props.allLearnersFilterCount? ' ': ' disabled-filter')} id="allLearners" data-value={FILTER_LEARNER.ALL} onClick={this.handleFilterBtnDisableCheck}> All ({this.props.allLearnersFilterCount})</div>
          <div className={'allLearnersFilter' + (this.props.newlySelLearnersFilterCount? ' ': ' disabled-filter')} id="selectedLearners" data-value={FILTER_LEARNER.SEL} onClick={this.handleFilterBtnDisableCheck}> Selected ({this.props.newlySelLearnersFilterCount})</div>
          <div className={'allLearnersFilter' + (this.props.unSelLearnersFilterCount? ' ': ' disabled-filter')} id="unselectedLearners" data-value={FILTER_LEARNER.UNSEL} onClick={this.handleFilterBtnDisableCheck}> Unselected ({this.props.unSelLearnersFilterCount})</div>
          <div className={'allLearnersFilter' + (this.props.linkedLearnersFilterCount? ' ': ' disabled-filter')} id="linkedLearners" data-value={FILTER_LEARNER.LNKD} onClick={this.handleFilterBtnDisableCheck}> Linked ({this.props.linkedLearnersFilterCount})</div>
        </div>
        </>):<></>}


        <div className="gr-form-element input gr-col-one gr-col-span-three ">
          <Input
            id={"searchText" + Date.now()}
            placeholder={this.props.placeholderText}
            value={this.props.searchText||""}
            disabled={this.props.startDisabled}
            onChange={this.props.onTextChange}
            onKeyDown={this._handleKeyDown}
          />
          <button
            className="search-bar-btn"
            style={{ position: "relative" }}
            onClick={this.resetSearchText}
          >
            <Icon size="18" icon="close" styleCss="gr-condition-one" />
          </button>
        </div>
        <div className="gr-form-element button gr-col-one gr-col-span-one ">
          <button
            style={{ marginTop: "10px" }}
            onClick={this.onSearch}
          >
            <Icon icon="search" size="18" />
          </button>
        </div>     
      </div>
    );
  }
}

export default ManageLearnerSearchBar;
