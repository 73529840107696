import React, { CSSProperties } from "react";
import _ from "lodash";

interface RequestCardHeaderProps {
  label: string;
  name: string;
  series: string;
  subjectCount?: number;
  paperCount?: number;
  viewRequestHeaderContainerStyle?:CSSProperties;
  isLearner?: boolean;
}

// function MyRequestHeader() {
const ViewRequestHeader = (props: RequestCardHeaderProps) => {
   localStorage.setItem("viewPaperDetails",props.label+" "+ props.name)
  return (
    <div
      style={
        _.isEmpty(props.viewRequestHeaderContainerStyle)
          ? { marginTop: "80px" }
          : props.viewRequestHeaderContainerStyle
      }
    >
      <div className="gr-grid-container ie-flex margin0">
        <div className="gr-col-one gr-col-span-nine">
          <span className="gr-neutral-med-one gr-font-normal pTop19">
            {props.label}
          </span>{" "}
          <span className="semi-bold-500 gr-font-normal">{props.isLearner ? props.name :  `(Total ${props.subjectCount} subject(s), ${props.paperCount} paper(s))` }</span>
        </div>
        <div
          className="gr-col-ten gr-col-span-three "
          style={{
            position: "relative",
            display: "inline-block",
            paddingLeft: "25%",
            textAlign: "right",
          }}
        >
          <span className="gr-neutral-med-one gr-font-normal" style={{}}>
            {props.series && props.series.length ? "Series" : ""}
          </span>
          &nbsp;
          <span
            className="semi-bold-500"
            style={{ paddingTop: "2px", whiteSpace: "nowrap" }}
          >
            {props.series}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ViewRequestHeader;
