import * as React from "react";

interface Props {
  onPageChange: (newPageNumber: number) => void;
  totalPageCount: number;
  key?: string;
  id: string;
  totalRecords?: number;
  activePage ?:string;
  pageCount?:number;
}

interface LocalState {currentPage:string}

export default class Pagination extends React.Component<Props, LocalState> {
  constructor(props: Props) {
    super(props);
    
    // const activePage = this.getActivePage();
    // console.log("learnerDataLen",this.props.totalRecords)
    this.state = {
      currentPage : this.props.activePage?this.props.activePage:"1"
    };
    // this.state = {
    //   currentPage :"1"
    // };
  }

  nextPgCallback = () => {
    const pagination:any = document.querySelector(`pearson-pagination#${this.props.id}`);
    this.setState({currentPage:pagination.attributes["currentpage"].value})
        
    this.props.onPageChange(pagination.attributes["currentpage"].value);
   // this.props.onPageChange(parseInt(pagination!.attributes[3].value));
  }

  prevPageCallback = () => {
    const pagination:any = document.querySelector(`pearson-pagination#${this.props.id}`);
    this.setState({currentPage:pagination.attributes["currentpage"].value})
        this.props.onPageChange(pagination.attributes["currentpage"].value);
        // this.props.onPageChange(parseInt(pagination!.attributes[3].value));
  }
  
  newPgCallback = () => {
    const pagination:any = document.querySelector(`pearson-pagination#${this.props.id}`);
    this.setState({currentPage:pagination.attributes["currentpage"].value})
    this.props.onPageChange(pagination.attributes["currentpage"].value);
  }

  componentWillUnmount() {
    this.RemoveEvents();
  }

  componentDidMount() {
    this.AttachEvents();
    const activePage  =  this.props.activePage?this.props.activePage:"1"
    
    const pagination:any = document.querySelector(`pearson-pagination#${this.props.id}`);
    if(activePage !== null){
          pagination && pagination.setAttribute("currentpage", activePage)
    }
    // this.setState({currentPage:'1'});   
  }
  // getActivePage(){
  //   let activePage:any ;
  //   if(this.props.page === 'viewPaper'){
  //     activePage = localStorage.getItem('currentPaperPage');
  //   }else if(this.props.page === 'viewLearner'){
  //     activePage = localStorage.getItem('currentLearnerPage');
  //   }else{
  //     activePage = "1"
  //   }
  //   return activePage;
  // }
  RemoveEvents = () => {
    const { id } = this.props;  
    const pagination:any = document.querySelector(`pearson-pagination#${id}`);
    if (pagination) {
      pagination.removeEventListener("nextPage", this.nextPgCallback);

      pagination.removeEventListener("prevPage", this.prevPageCallback);

      pagination.removeEventListener("newPage", this.newPgCallback);
    }
  }

  AttachEvents = () => {
    const { id } = this.props;  
    const pagination:any = document.querySelector(`pearson-pagination#${id}`);
    if (pagination) {
      pagination.addEventListener("nextPage", this.nextPgCallback);

      pagination.addEventListener("prevPage", this.prevPageCallback);

      pagination.addEventListener("newPage", this.newPgCallback);
    }
  };

  render() {
    let pearsonPagination = null;
    const { id, totalPageCount, totalRecords, pageCount } = this.props;
    const { currentPage } = this.state;
    const maxPageCount = pageCount ? pageCount : 10;
    if (totalPageCount > 1 && (totalRecords && totalRecords > maxPageCount)) {
      if (totalPageCount >= maxPageCount) {
        pearsonPagination = (
          
          <pearson-pagination
            id={id}
            firstpage="1"
            lastpage={totalPageCount}
            ellipsisat="10"
          ></pearson-pagination>
          
        );
      } else {
        pearsonPagination = (
          <pearson-pagination
            id={id}
            firstpage="1"
            lastpage={totalPageCount}
          ></pearson-pagination>
        );
      }
    }
    return <div><div style={{"float":"left"}}>{pearsonPagination}</div>{(totalRecords && totalRecords > maxPageCount) ? <div className="showingRecords" style={{"float":"left","marginTop":"3px"}}>&nbsp;&nbsp;&nbsp;Showing {currentPage} of {totalPageCount}</div>: ""}</div>;
  }
}
