import React from 'react';
import './App.css';
import "./index.css";
import "./index.scss";
import UserDataProvider, { initialStateType } from "./shared/context-api/provider";
import Routes from "./components/common/routes";
import { BrowserRouter as Router } from "react-router-dom";
import Header from '../src/components/pearson/header';
import Footer from '../src/components/pearson/footer';
import Modal from '../src/components/pearson/LogOutModal';
import Config from './app-config'
import * as auth from "./actions/auth/auth";
import GetData from './components/common/get-data.container'
import { deleteSavedSearch,deleteViewPapersSavedSearch } from './helpers/dashboard-request-helper';
import { IsUserInternal } from './components/common/user-validation';
import { useUserLoginState } from "./shared/context-api/hooks";
import { useIdleTimer } from "react-idle-timer";
import { logoutWithRefresh } from "./actions/auth/auth";
import Redirect from "./features/redirect/redirect";
import Maintenance from './components/maintenance/maintenance';

function App() {
  // const userdata = JSON.parse(localStorage.getItem("payload")!)
  const handleOnIdle = () => {
    let localStorageFileUploaded = localStorage.getItem("fileUploaded")
      ? JSON.parse(localStorage.getItem("fileUploaded")!)
      : []; 
      let localStorageAdminFileUploaded = localStorage.getItem("adminfileUploaded")
      ? JSON.parse(localStorage.getItem("adminfileUploaded")!)
      : []; //shared file also included in adminfileUploaded     
      
      if((localStorageFileUploaded && localStorageFileUploaded.length > 0) ||
      (localStorageAdminFileUploaded && localStorageAdminFileUploaded.length >0))
        {
          reset();
        }
        else
        {
          logoutWithRefresh();
        }
    // logoutWithRefresh();
  };

  const { reset } = useIdleTimer({
    timeout: 1000 * 60 * 60,
    onIdle: handleOnIdle,
    debounce: 500,
    events: ["keydown", "mousedown"]
  });
  const logout = () => {
    deleteSavedSearch();
    deleteViewPapersSavedSearch();
    auth.auditedLogout();
  };
  //LogOutModal changes start.
  const [openModal,show] = React.useState<boolean>(false); 
  const [openSetupModal,showSetupModal] = React.useState<boolean>(false);   

  const logOutModal = () => {
    
    // e.stopPropagation();
    logout();
    closeOutModalWindow();
  };  
  
  const closeOutModalWindow = () => {
    show(false);
    
  }; 
  const openModalWindow = () => {    
    show(true);    
  };

  const closeOutSetupModalWindow = () => {
    showSetupModal(false);
    
  }; 
  const openSetupModalWindow = () => {    
    showSetupModal(true);    
  };
  
  const LogOutModalWindow = () => {
     
    let localStorageFileUploaded = localStorage.getItem("fileUploaded")
      ? JSON.parse(localStorage.getItem("fileUploaded")!)
      : []; 
      let localStorageAdminFileUploaded = localStorage.getItem("adminfileUploaded")
      ? JSON.parse(localStorage.getItem("adminfileUploaded")!)
      : []; //shared file also included in adminfileUploaded     
      let IsSetupChanged=localStorage.getItem("IsSetupChanged");
      if((localStorageFileUploaded && localStorageFileUploaded.length > 0) ||
        (localStorageAdminFileUploaded && localStorageAdminFileUploaded.length >0))
      {        
        closeOutSetupModalWindow();
        openModalWindow();
      }
      else if(IsSetupChanged=="true")
      {
        closeOutModalWindow();
        openSetupModalWindow();
      } 
      else{       
        logOutModal();
      }    
  };  
//LogOutModal changes end.
  const notifications = 0; //Hardcoded, will revisit
  
  return (
    <UserDataProvider>
      <Router>
        <GetData />
        <Redirect />
        <div className="app-container">
          <HeaderData notifications={notifications} logout={LogOutModalWindow}  />
          <Modal isConfirmBut={true} show={openModal} title="Confirmation" modalData="Log Out" onCancel={() => closeOutModalWindow()} onConfirm={() => logOutModal()} />
          <Modal isConfirmBut={true} show={openSetupModal} title="Confirmation" modalData="Log Out" isSetupModal={true} onCancel={() => closeOutSetupModalWindow()} onConfirm={() => logOutModal()} />
          <main id="main" className="main ">
            <Routes />
          </main>
          <div className="footer">
            <Footer links={Config.FOOTER_LINKS} />
          </div>
        </div>
      </Router>
    </UserDataProvider>
  );
}

interface HeaderDataProps {
  notifications: number;
  logout: () => void;
}

let count = 0; // to render the pearson-header webcomponent in header.tsx

const HeaderData = React.memo(({ notifications, logout }: HeaderDataProps) => {
  const loggedInUserData = useUserLoginState();
  let text = (loggedInUserData && loggedInUserData.userName) || "NA";
  if(loggedInUserData.userData && 
     loggedInUserData.userData.email === 'ERRORa2e1dd272bd2fed9361a449337d6c29dd8b18c6811acc5e836c7eb0e3af633a9') {
    text = 'Error!!'
  }
  count++; // as we used memo, this will be rendered only when props change

  const helpIconLink = IsUserInternal(loggedInUserData as initialStateType) ? Config.HELP_LINK.HELP_INTERNAL : Config.HELP_LINK.HELP

  return (
    <Header
      key={count}
      className="header"
      applicationName="Learner Work Transfer"
      loggedIn={auth.isAuthenticated()}
      notifications={notifications}
      name={text}
      logout={logout}
      helpIconLink={helpIconLink}
    />
  );
});

export default App;
