import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { StoreState } from "../../store/store-state";
import ViewPapersDetails from "./view-papers.component"
import ExamRequest, { ViewPapers } from "../../models/exam-request";
import {
  ViewPapersDetailsAction,
  getViewPapersDetails

} from "./ducks/view-papers.actions";
import { submitAllocation, checkSubmitAllowed } from "../../components/dashboard/ducks/dashboard.actions";
import { SubmitAllowed } from "../../models/submit-allocation";


export interface StateProps {
  examRequests?: ExamRequest[];
  viewPapers: ViewPapers;
  submitAllowed: SubmitAllowed;
  submitAllocationResponse: any
}

const mapStateToProps = (state: StoreState): StateProps => {
  return {
    examRequests: state.examRequests,
    viewPapers: state.viewPapers,
    submitAllowed: state.submitAllowed,
    submitAllocationResponse :state.submitAllocation
  };
};
export interface DispatchProps {
  getViewPapersDetails: (
    learningProviderCode: string,
    qualificationTypeCode: string,
    seriesCode: string,
    searchText: string,
    status: number[],
    pageNumber: number,
    pageSize: number
  ) => Promise<void>;
  submitAllocation: (allocationId: number, assessmentMethodType: number) => Promise<void>;
  checkSubmitAllowed: (allocationId: number, assessmentMethodType: number) => Promise<void>;

}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<StoreState, undefined, ViewPapersDetailsAction>
): DispatchProps => {
  return {
    getViewPapersDetails: (
      learningProviderCode: string,
      qualificationTypeCode: string,
      seriesCode: string,
      searchText = "",
      status = [0],
      pageNumber = 1,
      pageSize = 10
    ) =>
      dispatch(
        getViewPapersDetails(
          learningProviderCode,
          qualificationTypeCode,
          seriesCode,
          searchText,
          status,
          pageNumber,
          pageSize
        )
      ),
    submitAllocation: (allocationId: number, assessmentMethodType: number) =>
      dispatch(submitAllocation(allocationId, assessmentMethodType)),
    checkSubmitAllowed: (allocationId: number, assessmentMethodType: number) =>
      dispatch(checkSubmitAllowed(allocationId, assessmentMethodType)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewPapersDetails);
