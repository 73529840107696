import axios, { AxiosRequestConfig } from "axios";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import config from "../../../app-config";
import { ThunkDispatch } from "redux-thunk";
import { actionTypes } from "../../../actions/action-types";
import { StoreState } from "../../../store/store-state";
import { ExamRequestsSummary } from "../../../models/exam-request";
import ExamRequest from "../../../models/exam-request";
import SubmitAllocation, { SubmitAllowed } from "../../../models/submit-allocation";
import { getCurrentAssessMethodNumber } from "../../../helpers/dashboard-helper";

interface GetExamRequestsSummaryAction extends Action {
  type: actionTypes.GET_EXAM_REQUESTS_SUMMARY_SUCCESS;
  examRequestsSummaryData: ExamRequestsSummary;
  examRequestStatusCode: number;
}

interface GetExamRequstsAction extends Action {
  type: actionTypes.GET_EXAM_REQUESTS_SUCCESS
  examRequests: ExamRequest[];
  examRequestStatusCode: number
}

interface SubmitAllocationAction extends Action {
  type: actionTypes.SUBMIT_ALLOCATION_SUCCESS
  submitAllocation: SubmitAllocation;
}

interface CheckSubmitAllowedAction extends Action {
  type: actionTypes.CHECK_SUBMIT_ALLOWED_SUCCESS
  submitAllowed: SubmitAllowed;
}


export type ExamRequestsAction =
  | GetExamRequestsSummaryAction
  | GetExamRequstsAction
  | SubmitAllocationAction
  | CheckSubmitAllowedAction

type ThunkResult<R> = ThunkAction<R, StoreState, undefined, ExamRequestsAction>;

export const GetExamRequestsSummarySuccessAction = (
  examRequestsSummaryData: ExamRequestsSummary,
  examRequestStatusCode: number
): GetExamRequestsSummaryAction => ({
  examRequestsSummaryData,
  examRequestStatusCode,
  type: actionTypes.GET_EXAM_REQUESTS_SUMMARY_SUCCESS,
});

export const GetExamRequstsSuccessAction = (
  examRequests: ExamRequest[],
  examRequestStatusCode: number
): GetExamRequstsAction => ({
  examRequests,
  examRequestStatusCode,
  type: actionTypes.GET_EXAM_REQUESTS_SUCCESS
});

export const SubmitAllocationSuccessAction = (
  submitAllocation: SubmitAllocation,
): SubmitAllocationAction => ({
  submitAllocation,
  type: actionTypes.SUBMIT_ALLOCATION_SUCCESS
});

export const CheckSubmitAllowedSuccessAction = (
  submitAllowed: SubmitAllowed,
): CheckSubmitAllowedAction => ({
  submitAllowed,
  type: actionTypes.CHECK_SUBMIT_ALLOWED_SUCCESS
});

export const getExamRequestsSummary = (learningProviderCode: string) => {
  return async (
    dispatch: ThunkDispatch<StoreState, void, ExamRequestsAction>
  ) => {
    try {
      const axiosConfig: AxiosRequestConfig = {
        headers: { "content-type": "application/json" },
      };
      const response = await axios.post(
        `${config.API_GATEWAY.URL}/exam-requests/summary/0`,
        JSON.stringify({ learningProviderCode }),
        axiosConfig
      );
      const examRequestsSummaryData = response.data as ExamRequestsSummary;
      const examRequestStatusCode = response.status;
      dispatch(GetExamRequestsSummarySuccessAction(examRequestsSummaryData, examRequestStatusCode));
    } catch (error) {
      
    }
  };
};

export const getExamRequests = (
  learningProviderCode: string,
  status: number[],
  searchText: string
) => {
  const mockUsers = false ? 1 : 0;

  return async (dispatch: ThunkDispatch<StoreState, void, ExamRequestsAction>) => {
    try {
      const axiosConfig: AxiosRequestConfig = {
        headers: { "content-type": "application/json" }
      };
      const response = await axios
        .post(`${config.API_GATEWAY.URL}/exam-requests/get/${mockUsers}`,
          JSON.stringify({
            learningProviderCode,
            status,
            searchText
          }), axiosConfig);
      const examDetails = response.data && response.data.allocations;
      const examRequestStatusCode = response.status;
      
      dispatch(GetExamRequstsSuccessAction(examDetails, examRequestStatusCode));
    }
    catch (error) {
      // throw error;
     // console.log("Get Exam Request Action Error:", error)
    }
  };
};

export const submitAllocation = (allocationId: number, assessmentMethodType: number) => {
  const userData = JSON.parse(localStorage.getItem("userData")!)
  const submittedByFirstName = userData && userData.firstName;
  const submittedByLastName = userData && userData.lastName;

  return async (
    dispatch: ThunkDispatch<StoreState, void, SubmitAllocationAction>
  ) => {
    try {
      const axiosConfig: AxiosRequestConfig = {
        headers: { "content-type": "application/json" },
      };
      const response = await axios.post(
        `${config.API_GATEWAY.URL}/exam-requests/submitAllocation/0`,
        JSON.stringify({ allocationId, assessmentMethodType, submittedByFirstName, submittedByLastName }),
        axiosConfig
      );
      const submitAllocationResponse = response.data as SubmitAllocation;
      
      
      
      dispatch(SubmitAllocationSuccessAction(submitAllocationResponse));
    } catch (error) {
      
    }
  };
};

export const checkSubmitAllowed = (allocationId: number, assessmentMethodType: number) => {
  return async (
    dispatch: ThunkDispatch<StoreState, void, CheckSubmitAllowedAction>
  ) => {
    try {
      const axiosConfig: AxiosRequestConfig = {
        headers: { "content-type": "application/json" },
      };
      let response = {
        data: {
          allocationId: 0,
          isSubmitAllowed: false
        }
      };
      if (allocationId !== 0) {
        
        const currentAssessMethodType = getCurrentAssessMethodNumber();
       
        response = await axios.post(
          `${config.API_GATEWAY.URL}/exam-requests/checkSubmitAllowed/0`,
          JSON.stringify({ allocationId, assessmentMethodType:currentAssessMethodType }),
          axiosConfig
        );
      }

      const checkSubmitAllowedResponse = response && response.data as SubmitAllowed;
      dispatch(CheckSubmitAllowedSuccessAction(checkSubmitAllowedResponse));
    } catch (error) {
      
    }
  };
};

