import * as Evaporate from 'evaporate';
import crypto from 'crypto';
import config from '../../../app-config'
const aws = require('aws-sdk'); 

// import { MediaUploadCredentials, MediaUploadFile } from '../models/media-upload';

export class S3UploadMultipart {

    

    constructor(mediaUploadCredentials, mediaUploadFile, fileKey) {
        this.bucket = mediaUploadCredentials.uploadBucket;
        this.region = mediaUploadCredentials.region;
        this.awskey = mediaUploadCredentials.accessKeyId;
        this.awssecret = mediaUploadCredentials.secretAccessKey;
        this.awstoken = mediaUploadCredentials.sessionToken;
        this.mediaUploadFile = mediaUploadFile;
        this.filekey = fileKey;
        this.capabilityCode = mediaUploadCredentials.capabilityCode;
        this.environment = mediaUploadCredentials.environment; //to be removed later post avats migration
    }
    
    onFinishS3Put = (signResult) => {
        return console.log('File upload complete: ', signResult);
    };

    onProgress = (percent, status) => {
        return console.log('Progress: ', percent, status);
    };

    onStart = () => {
        return console.log('Starting');
    }

    onError = (error) => {
        return console.log('Error: ', error);
    };

    addFileToUpload = () =>  {
        const self = this;

        this.amzHeadersCommon = {};
        this.amzHeadersCommon['x-amz-security-token'] = self.awstoken;

        this.amzHeadersAtInitiate = {};
        this.amzHeadersAtInitiate['x-amz-security-token'] = self.awstoken;

        this.notSignedHeadersAtInitiate = {};
		 // eslint-disable-next-line
        this.notSignedHeadersAtInitiate['Content-Disposition'] = `attachment; filename="${this.mediaUploadFile.file.name.replace(/[^\w\d_\-\.]+/ig, '')}"`;

        const metadataString = `LearningProviderId=${this.mediaUploadFile.learningProviderId}&AllocationId=${this.mediaUploadFile.AllocationId}&UnitId=${this.mediaUploadFile.unitId}${this.mediaUploadFile.learnerId && '&LearnerId='+this.mediaUploadFile.learnerId}&SourceSystem=evidence-capture&CapabilityCode=${this.capabilityCode}&Environment=${this.environment}`;
        console.log("metadataString", metadataString)
        this.amzHeadersAtInitiate['x-amz-tagging'] = metadataString;

        self.file = this.mediaUploadFile.file;

        self.onStart();
    };

    startUpload = () => {
        const self = this;
        self.uploadToS3(self.file);
    }

    abortUpload = () => {
        const self = this;
        console.log('inside abort')
        return self.evaporate && self.evaporate.cancel();
    };

    authBysecret = (signParams, signHeaders, stringToSign, dateString, canonicalRequest) => {
        const self = this;

        const stringToSignDecoded = decodeURIComponent(stringToSign)
        const requestScope = stringToSignDecoded.split('\n')[2];
        const [date, region, service, signatureType] = requestScope.split('/');

        return new Promise((resolve, reject) => { 
            const kDate = crypto.createHmac('sha256', `AWS4${self.awssecret}`).update(date).digest();
            const kRegion = crypto.createHmac('sha256', kDate).update(region).digest();
            const kService = crypto.createHmac('sha256', kRegion).update(service).digest();
            const kType = crypto.createHmac('sha256', kService).update(signatureType).digest();
            const kSigning = crypto.createHmac('sha256', kType).update(stringToSignDecoded).digest('hex');

            resolve(kSigning);
        });
    };
    
    uploadToS3 = (file) => {
        const self = this;

        const evaporateOptions = {
            customAuthMethod: self.authBysecret,
            aws_key: self.awskey,            
            bucket: self.bucket,
            cloudfront: true,
            computeContentMd5: true,
            awsSignatureVersion: '4',
            awsRegion: self.region,     
            s3FileCacheHoursAgo: config.MEDIA_FILES.UPLOAD_RESUME_MAX_HOURS, // Number of hours a file can be resumed for (incase of upload failure)
            onlyRetryForSameFileName: true,       
            cryptoMd5Method: (data) => {
                console.log("cryptoMd5Method data", data)
                return aws.util.crypto.md5(data, 'base64');
            },
            cryptoHexEncodedHash256: (data) => {
                console.log("cryptoHexEncodedHash256 data", data)
                return aws.util.crypto.sha256(data, 'hex');
                
            }
        };
        return Evaporate.create(evaporateOptions).then((evaporate) => {
            const addConfig = {
                name: self.filekey,
                file,
                notSignedHeadersAtInitiate: self.notSignedHeadersAtInitiate,
                xAmzHeadersCommon: self.amzHeadersCommon,
                xAmzHeadersAtInitiate: self.amzHeadersAtInitiate,
                progress: (stats, progressValue=100) => {
                    return self.onProgress(progressValue, progressValue === 100 ? 'Finalizing' : 'Uploading');
                },
                complete: (xhr, awsKey, stats) => {
                    if (xhr.status === 200) {
                        self.onProgress(100, 'Upload completed');
                    } else {
                        return self.onError('Upload error: ' + xhr.status);
                    }
                },
                error: (msg) => {
                    return self.onError(msg);
                }
            };
            self.evaporate = evaporate;
            evaporate.add(addConfig).then(
                (awsKey) => {
                    return self.onFinishS3Put(awsKey);
                },
                (errorReason) => {
                    return self.onError(errorReason);
                }
            );
        });
    };
}

export default S3UploadMultipart;
