import axios, { AxiosRequestConfig } from "axios";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import config from "../../../app-config";

import { actionTypes } from "../../../actions/action-types";
import { StoreState } from "../../../store/store-state";
import { Series } from "../../../models/series";
import { EvidenceSearchCriteria, EvidenceSearchResult } from "../../../models/evidence";
import { PQS_EMS } from "../../../shared/app-enums";

interface GetSeriesAction extends Action {
  type: actionTypes.GET_SERIES_SUCCESS;
  seriesData: Series[];
}

interface GetEvidenceSearchResultAction extends Action {
  type: actionTypes.GET_EVIDENCE_SEARCH_RESULT_SUCCESS;
  evidenceSearchResultData: EvidenceSearchResult | null;
}

export type SearchEvidenceActions =
  | GetSeriesAction
  | GetEvidenceSearchResultAction;

type ThunkResult<R> = ThunkAction<R, StoreState, undefined, SearchEvidenceActions>;

export const getSeriesSuccessAction = (
    seriesData: Series[]
): GetSeriesAction => ({
    seriesData,
    type: actionTypes.GET_SERIES_SUCCESS,
});

export const getEvidenceSearchResultSuccessAction = (
  evidenceSearchResultData: EvidenceSearchResult | null
): GetEvidenceSearchResultAction => ({
  evidenceSearchResultData,
  type: actionTypes.GET_EVIDENCE_SEARCH_RESULT_SUCCESS,
});

export const getSeries = (): ThunkResult<Promise<void>> => {

  return async ( dispatch) => {
    try {
      const axiosConfig: AxiosRequestConfig = {
        headers: { "content-type": "application/json" }
      };
      const sourcesystem = PQS_EMS;
      const response = await axios
        .post(
          `${config.API_GATEWAY.URL}/series`,
          JSON.stringify({
            sourcesystem
          }),
           axiosConfig
        )
        const seriesData = response.data as Series[];
       dispatch(getSeriesSuccessAction(seriesData));
    }
    catch (error) {
      // console.log("Get Evidence Search Result Action Error==>", error);
    // throw error;
    }
  };
};

export const searchEvidence = (criteria: EvidenceSearchCriteria): ThunkResult<Promise<void>> => {
  return (dispatch) => {
    const axiosConfig: AxiosRequestConfig = {
      headers: { "content-type": "application/json" },
    };
    return axios
      .post(
        `${config.API_GATEWAY.URL}/evidence/search`,
        criteria,   
        axiosConfig
      )
      .then((postResponse) => {
        const evidenceSearchResultData = postResponse.data as EvidenceSearchResult;
        dispatch(getEvidenceSearchResultSuccessAction(evidenceSearchResultData));
      })
      .catch((error) => {
        // console.log("Search Evidence Action Error==>", error);
        // throw error;
      });
  };
};
