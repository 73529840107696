
export class Option {
  key: string;
  value: string;
  disabled?: boolean = false;
}

export const ToDoSearchOptions: Option[] = [
  { value: "New", key: "New" }  
];

export const InProgressSearchOptions: Option[] = [
  { value: "All", key: "All" },
  { value: "In Progress", key: "InProgress" },
  { value: "In Progress (C)", key: "InProgress(LE)" },
  { value: "Submitting", key: "Submitting" },
  { value: "Not Submitted", key: "NotSubmitted" },
  { value: "Returned", key: "Returned" },
];
export const CompletedSearchOptions: Option[] = [
  { value: "Submitted", key: "Submitted" }
];

export const viewLearnerStatusOption : Option[] = [
  { value: "Present", key: "1" },
  { value: "Absent", key: "2" },
  { value: "No Evidence", key: "3" }  
];