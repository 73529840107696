import React, { useState } from "react";
import Input from "../../components/pearson/input";
import Icon from "../../components/pearson/icon";

interface msfSearchProps {
    placeholderText?: string;
    searchText: string;
    onSearch: (e: any, searchText: string) => void;
}
const MSFSearch = (props: msfSearchProps) => {

    const [searchtext, setsearchtext] = useState('')
    
    const _handleKeyDown = (e: any) => {
        if (e.key === "Enter" && searchtext) {
          props.onSearch(e, searchtext);
        }
      };
    
    const resetSearchText = (e: any) => {    
        setsearchtext("");
        props.onSearch(e, ""); 
       }
    
    const onSearch = (e:any) => {
        if(searchtext) {
            props.onSearch(e, searchtext)
        }    
    }

    const onTextChange = (e:any) => {
        setsearchtext(e.target.value)       
    }

    return (
        <div style={{width:"100%"}}>
            <div className="gr-form-element input" style={{width:"78%", float:"left"}}>                
                <Input
                    id={"searchText" + Date.now()}
                    placeholder={props.placeholderText}
                    value={searchtext || ""}
                    onChange={onTextChange}
                    onKeyDown={_handleKeyDown}
                />
                <button
                    className="search-bar-btn"
                    style={{ position: "relative" }}
                    onClick={resetSearchText}
                >
                <Icon size="18" icon="close" styleCss="gr-condition-one" />
                </button>
            </div>
            <div className="gr-form-element button " style={{float:"left", marginLeft:"25px"}}>
                <button
                    style={{ marginTop: "10px" }}
                    onClick={onSearch}
                >
                    <Icon icon="search" size="18" />
                </button>
            </div>
        </div>
    );
    
}

export default MSFSearch;