import React from "react";
import Button from "../../components/pearson/button";
import * as LLHlpr from "./link-learners.helper";
import { getAllocId } from './link-learners.helper';
import { LearnerLL, SaveLinkLearners } from "../../models/learner";
import _ from "lodash";
import { FILE_STATUS, REQUSEST_STATUS } from "../../shared/app-enums";
import moment from "moment";
import FileDetails from "../../models/file-details";
import { FILE } from "dns";
import { useUserLoginState } from "../../shared/context-api/hooks";
import { HasWriteAccess  } from "../../components/common/user-validation";

// TOTAL LEARNERS = all learners recieved from API response for this request
// DB SEL LEARNERS = all learners linked to the selected file
interface Props {
  msfSaveResponse: SaveLinkLearners;
  totalLearners: LearnerLL[];
  currentSelectedLIds: number[];
  handlePostSave:()=>void; 
  selectedFile: string;
  selectedFileDetails: FileDetails;
  msfSaveChanges: (
    allocationId: number, 
    selectedFile: string, 
    selectedLearners: number[], 
    unSelectedLearners: number[]
  ) => void
}

const MSFSave = (props:Props) => {
  const saveClicked = React.useRef(false);
  const userLoginstate = useUserLoginState();
  React.useMemo(() => {
    if(saveClicked.current === true){
      props.handlePostSave();       
      saveClicked.current = false;
    }    
  }, [props.msfSaveResponse])
 
  const {totalLearners, currentSelectedLIds} = props; 
  
  const handleSaveClick = (event: any) => {
    const allocId= getAllocId();
    const {newlySelected,newlyUnselected} = getNewlyUpdatedLearners();   
// console.log("ddd",getNewlyUpdatedLearners())
    props.msfSaveChanges(
      allocId,
      props.selectedFile,
      newlySelected,
      newlyUnselected
    )
    saveClicked.current = true;
  }; 

  const getPreviousEntries = () => {
    let previousEntries: number[] =[];
    const requestDetails = LLHlpr.getSelectedReqDetails();
    totalLearners.forEach((learner) => {
      // console.log("isnot ",!learner.isLateEntry+  "   learner  "+learner.learnerCode)
      if(!learner.isLateEntry || (learner.isLateEntry === true && learner.isSharedFile === true &&  !moment(learner.learnerLateEntryDate).isAfter(moment(requestDetails.evidenceSubmittedOn)))){
        previousEntries.push(learner.learnerId);
      }
      // && previousEntries.push(learner.learnerId);
    })
    return previousEntries;
  }

  const getNewlyUpdatedLearners = () => {
    let newlySelected: number[] = [];
    let newlyUnselected: number[] = [];

    if (props.selectedFileDetails.fileStatusCode === FILE_STATUS.REJECTED)
    {
      // Unlink all learners
      newlySelected = [];
      newlyUnselected = LLHlpr.getDBSelectedLearners(totalLearners);
    }
    else
    {
      // Calculate changes
      let dbSelectedLearners: number[] = LLHlpr.getDBSelectedLearners(totalLearners);
      let currentSelected: number[] = currentSelectedLIds

      newlySelected = _.difference(currentSelected, dbSelectedLearners);
      newlyUnselected = _.difference(dbSelectedLearners,currentSelected);
      const requestDetails = LLHlpr.getSelectedReqDetails();

      if(requestDetails.statusId === REQUSEST_STATUS.IN_PROGRESS_C && !(moment(requestDetails.returnedOn).isAfter(requestDetails.evidenceSubmittedOn))) {
        newlyUnselected = _.difference(newlyUnselected,getPreviousEntries());
      }
    }

    return {newlySelected, newlyUnselected};
  } 

  if(LLHlpr.isRequestSubmitted() || totalLearners.length===0) {
    return (<></>);
  }

  // if(totalLearners.length===0) {
  //   return (<></>);
  // }

  const saveText = props.selectedFileDetails.fileStatusCode === FILE_STATUS.REJECTED ? "Unlink all learners" : "Save";
  return (
    <Button
        label={saveText}
        type="submit"
        className="attention"
        customClass="saveLrBut" 
        onClick={handleSaveClick}
        disabled={!HasWriteAccess(userLoginstate)}
      />
  );
};

export default MSFSave;
