import React, {  useEffect, ChangeEvent } from "react";
import parse from 'html-react-parser';
import { Button } from "ukas.uux.react-components";
import './manage-assessment-deadline-config.css';
import { AssessmentDeadlines, SubmitSetupResponse,SubmitSetupPrsResponse } from "../../models/config-models";
import { useHistory } from "react-router";
import { AssessmentDeadlineConfigSavedSearch } from "../../models/saved-search";
import moment from "moment";
import Icon from "../../components/pearson/icon";
import ShowAlert from '../../components/common/alert';
import Modal from '../../components/pearson/LogOutModal';
import DatePicker from "../../components/pearson/date-picker/date-picker";
import AmendModal from "../../components/pearson/CommonModal";
import { useUserLoginState } from "../../shared/context-api/hooks";
import { GetUserPermissions  } from "../../components/common/user-validation";

export interface StateProps {
    selectedAssessmentDeadline: AssessmentDeadlines | null;
    submitSetupResponse: SubmitSetupResponse | null;
    submitSetupPrsResponse: SubmitSetupPrsResponse | null;
    isDlwtManaged: String;
}

export interface DispatchProps {
    getAssessmentDeadline: (assessmentDeadlineId: number) => Promise<void>;
    submitAssessmentDeadline: (assessmentDeadlineId: number, isDlwtEnabled: boolean, accessBeginDate?: Date,accessEndDate?: Date, submissionDeadline?: Date, aaDeadline?: Date, createRequestEndDate?: Date, updateRequestEndDate?: Date, isSetupSubmitted?: boolean) => Promise<void>;
    submitPrsAssessmentDeadline: (assessmentDeadlineId: number, prsInternalUserAccessBeginDate?: Date,prsInternalUserAccessEndDate?: Date, prsExternalUserAccessBeginDate?: Date, prsExternalUserAccessEndDate?: Date) => Promise<void>;
}

interface Props extends DispatchProps, StateProps { }

const ManageAssessmentDeadlineConfig = (props: Props) => {
    const userLoginstate = useUserLoginState();
    document.title = "Learner Work Transfer - DLWT Setup";
    const userPermissions = GetUserPermissions(userLoginstate);
    const internalUserHasValidPermissions = window.localStorage.getItem("internalUserHasValidPermissions");
    const [selectedAssessmentDeadline, setSelectedAssessmentDeadline] = React.useState(props.selectedAssessmentDeadline);
    const [isDlwtManaged, setIsDlwtManaged] = React.useState(props.isDlwtManaged);
    const [isDataLoaded, setIsDataLoaded] = React.useState(false);
    const [selectedAssessmentId, setSelectedAssessmentId] = React.useState(0);
    const [accessBeginDate, setAccessBeginDate] = React.useState(props.selectedAssessmentDeadline?.accessBeginDate);
    const [lwtRequestSubmissionDeadline, setLwtRequestSubmissionDeadline] = React.useState(props.selectedAssessmentDeadline?.submissionDeadline);
    const [lwaCompletionDeadline, setLwaCompletionDeadline] = React.useState(props.selectedAssessmentDeadline?.aADeadline);
    const [accessEndDate, setRequestAccessEndDate] = React.useState(props.selectedAssessmentDeadline?.accessEndDate);
    const [newRequestEndDate, setNewRequestEndDate] = React.useState(props.selectedAssessmentDeadline?.createRequestEndDate);
    const [updateRequestEndDate, setupdateRequestEndDate] = React.useState(props.selectedAssessmentDeadline?.updateRequestEndDate);
    const [accessBeginDateError, setAccessBeginDateError]=React.useState("");
    const [lwtRequestSubmissionDeadlineError, setlwtRequestSubmissionDeadlineError]=React.useState("");
    const [lwaCompletionDeadlineError, setlwaCompletionDeadlineError]=React.useState("");
    const [newRequestEndDateError, setNewRequestEndDateError] = React.useState("");
    const [updateRequestEndDateError, setUpdateRequestEndDateError] = React.useState("");
    const [accessEndDateError, setAccessEndDateError] = React.useState("");
    const [isDlwtManagedFlagChanged, setIsDlwtManagedFlagChanged]=React.useState(false);
    const [accessBeginDateChanged, setAccessBeginDateChanged] = React.useState(false);
    const [lwtRequestSubmissionDeadlineChanged, setLwtRequestSubmissionDeadlineChanged] = React.useState(false);
    const [lwaCompletionDeadlineChanged, setLwaCompletionDeadlineChanged] = React.useState(false);
    const [accessEndDateChanged, setRequestAccessEndDateChanged] = React.useState(false);
    const [newRequestEndDateChanged, setNewRequestEndDateChanged] = React.useState(false);
    const [updateRequestEndDateChanged, setUpdateRequestEndDateChanged] = React.useState(false);
    const [showSaveSuccessMessage, setShowSaveSuccessMessage] = React.useState(false);
    const [saveSuccessMessage, setSaveSuccessMessage] = React.useState("");  
    const [showSaveFailMessage, setShowSaveFailMessage] = React.useState(false);
    const [setupSubmitted, IsSetupSubmitted] = React.useState(false);
    const [openSetupModal,showSetupModal] = React.useState(false);   
    const [saveFailMessage, setSaveFailMessage] = React.useState("");
    const [isSetupAmend, setIsSetupAmend] = React.useState(false);
    const [openModal, setOpenAmendModal] = React.useState(false);
    const [openAmendConfirmModal, setOpenAmendConfirmModal] = React.useState(false);
    const [isEditButtonClicked, setEditButtonClicked] = React.useState(false);
    const [openPrsSubmitConfirmModal, setPrsOpenSubmitConfirmModal] = React.useState(false);
    const [cancelButton,setCancelButton] = React.useState(false); 

    const [prssetupSubmitted, IsPrsSetupSubmitted] = React.useState(false);

    const [prsInternalUserAccessBeginDate, setPrsInternalUserAccessBeginDate] = React.useState(props.selectedAssessmentDeadline?.prsInternalUserAccessBeginDate);
    const [prsInternalUserAccessBeginDateError, setPrsInternalUserAccessBeginDateError]=React.useState("");
    const [prsInternalUserAccessBeginDateChanged, setPrsInternalUserAccessBeginDateChanged] = React.useState(false);
    
    const [prsInternalUserAccessEndDate, setPrsInternalUserAccessEndDate] = React.useState(props.selectedAssessmentDeadline?.prsInternalUserAccessEndDate);
    const [prsInternalUserAccessEndDateError, setPrsInternalUserAccessEndDateError]=React.useState("");
    const [prsInternalUserAccessEndDateChanged, setPrsInternalUserAccessEndDateChanged] = React.useState(false);

    const [prsExternalUserAccessBeginDate, setPrsExternalUserAccessBeginDate] = React.useState(props.selectedAssessmentDeadline?.prsExternalUserAccessBeginDate);
    const [prsExternalUserAccessBeginDateError, setPrsExternalUserAccessBeginDateError]=React.useState("");
    const [prsExternalUserAccessBeginDateChanged, setPrsExternalUserAccessBeginDateChanged] = React.useState(false);

    const [prsExternalUserAccessEndDate, setPrsExternalUserAccessEndDate] = React.useState(props.selectedAssessmentDeadline?.prsExternalUserAccessEndDate);
    const [prsExternalUserAccessEndDateError, setPrsExternalUserAccessEndDateChangedError]=React.useState("");
    const [prsExternalUserAccessEndDateChanged, setPrsExternalUserAccessEndDateChanged] = React.useState(false);

    const isFirstRun = React.useRef(true);
    const history = useHistory();



    useEffect(() => {
        if(internalUserHasValidPermissions === "false")
        {
            history.push('/');
        }
        const assessmentDeadlineConfigSavedSearch = JSON.parse(localStorage.getItem("assessmentDeadlineConfigSavedSearch") + "") as AssessmentDeadlineConfigSavedSearch;
        setSelectedAssessmentId(assessmentDeadlineConfigSavedSearch.assessmentDeadlineId);
        props.getAssessmentDeadline(assessmentDeadlineConfigSavedSearch.assessmentDeadlineId).then(()=>{
            setIsDataLoaded(true);
            setTimeout(() =>{
                isFirstRun.current = false
            }, 5);
        });
    }, []);

    const beforeunload = (event:any) => {
        let IsSetupChanged=localStorage.getItem("IsSetupChanged");
        if (IsSetupChanged==="true") {
            event.preventDefault();
            event.returnValue = '';
        }
    }

    const unload = (event:any) => {
        localStorage.removeItem("IsSetupChanged");
    }

    useEffect(()=>{
        window.addEventListener('beforeunload', beforeunload)
        window.addEventListener('unload', unload)
        return () => {
            window.removeEventListener('beforeunload', beforeunload)
            window.removeEventListener('unload', unload)
        }
    })

    useEffect(() => {
        if (isFirstRun.current) {
            return;
        }
        if(isDataLoaded && !setupSubmitted && (isDlwtManagedFlagChanged || IsDatesChanged()))
        {
            localStorage.setItem("IsSetupChanged","true");
        }
    }, [isDlwtManagedFlagChanged, accessBeginDate,lwtRequestSubmissionDeadline,lwaCompletionDeadline,accessEndDate,newRequestEndDate,updateRequestEndDate]);

    useEffect(() => {
        if (isFirstRun.current) {
            return;
        }
        if(isDataLoaded && !prssetupSubmitted && (isDlwtManagedFlagChanged || IsPrsDatesChanged()))
        {
            localStorage.setItem("IsPrsSetupChanged","true");
        }
    }, [isDlwtManagedFlagChanged, prsInternalUserAccessBeginDate,prsInternalUserAccessEndDate,prsExternalUserAccessBeginDate,prsExternalUserAccessEndDate]);

    const IsDatesChanged = () : boolean =>
    {
        return accessBeginDateChanged || lwtRequestSubmissionDeadlineChanged || lwaCompletionDeadlineChanged || accessEndDateChanged || newRequestEndDateChanged || updateRequestEndDateChanged;
    }

    const IsPrsDatesChanged = () : boolean =>
    {
        return prsInternalUserAccessBeginDateChanged || prsInternalUserAccessEndDateChanged || prsExternalUserAccessBeginDateChanged || prsExternalUserAccessEndDateChanged ;
    }


    useEffect(() => {
        if(setupSubmitted)
        {
            if(props.submitSetupResponse?.hasError)
            {
                setSaveFailMessage("Setup has not been submitted. Please make the necessary change(s) and resubmit.");
                setShowSaveFailMessage(true);
            }
            else
            {
                if(isDlwtManaged==="0")
                {
                    setSaveSuccessMessage("Setup has been submitted. Learner Work Transfer and Learner Work Assessment will not be used to manage evidence.");
                }                    
                else
                {
                    setSaveSuccessMessage("Setup has been submitted and will be used to enable evidence management by Learner Work Transfer and Learner Work Assessment.");
                }                    
                setShowSaveSuccessMessage(true);
                setShowSaveFailMessage(false);
                setIsSetupAmend(false);
                setAccessBeginDateChanged(false);
                setLwtRequestSubmissionDeadlineChanged(false);
                setLwaCompletionDeadlineChanged(false);
                setRequestAccessEndDateChanged(false);
                setNewRequestEndDateChanged(false);
                setUpdateRequestEndDateChanged(false);
                setIsDlwtManagedFlagChanged(false);
            }
        }
    }, [props.submitSetupResponse]);


    useEffect(() => {
        if(prssetupSubmitted)
        {
            if(props.submitSetupPrsResponse?.hasError)
            {
                setSaveFailMessage("Update has not been submitted. Please make the necessary change(s) and resubmit.");
                setShowSaveFailMessage(true);
            }
            else
            {
               setSaveSuccessMessage("Update has been submitted and will be used to enable PRS/RoMM evidence management by Learner Work Assessment.");
                                
                setShowSaveSuccessMessage(true);
                setShowSaveFailMessage(false);
                setEditButtonClicked(false);
                setPrsInternalUserAccessBeginDateChanged(false);
                setPrsInternalUserAccessEndDateChanged(false);
                setPrsExternalUserAccessBeginDateChanged(false);
                setPrsExternalUserAccessEndDateChanged(false);
            }
        }
    }, [props.submitSetupPrsResponse]);


   
    useEffect(() => {
        const selectedAssesmentDeadline = props.selectedAssessmentDeadline;
        setAccessBeginDate(selectedAssesmentDeadline?.accessBeginDate);
        setLwtRequestSubmissionDeadline(selectedAssesmentDeadline?.submissionDeadline);
        setLwaCompletionDeadline(selectedAssesmentDeadline?.aADeadline);
        setRequestAccessEndDate(selectedAssesmentDeadline?.accessEndDate);
        setNewRequestEndDate(selectedAssesmentDeadline?.createRequestEndDate);
        setupdateRequestEndDate(selectedAssesmentDeadline?.updateRequestEndDate);
        setSelectedAssessmentDeadline(selectedAssesmentDeadline);
        setIsDlwtManaged(selectedAssesmentDeadline ? (selectedAssesmentDeadline.isDlwtEnabled ? "1" : "0") : "");
        setPrsInternalUserAccessBeginDate(selectedAssesmentDeadline?.prsInternalUserAccessBeginDate);
        setPrsInternalUserAccessEndDate(selectedAssesmentDeadline?.prsInternalUserAccessEndDate);
        setPrsExternalUserAccessBeginDate(selectedAssesmentDeadline?.prsExternalUserAccessBeginDate);
        setPrsExternalUserAccessEndDate(selectedAssesmentDeadline?.prsExternalUserAccessEndDate);
    }, [props.selectedAssessmentDeadline,cancelButton]);

    const isDlwtManagedChanged = (event: ChangeEvent<HTMLInputElement>) => {
        document.getElementById(event.currentTarget.id)?.click();
        setIsDlwtManaged(event.currentTarget.value);
        setIsDlwtManagedFlagChanged(true);
    }
    const preventTabBodyClick = (e: any) => {
        e.stopPropagation();
      }
      const tabClickHandler = () => {
        const tab = document.querySelector("pearson-tabs");
        let activeTabIndex = tab && tab.getAttribute("activeidx");
  
      };

    const userCanManageSetup = () : boolean =>{
        return isSetupAmend || !selectedAssessmentDeadline?.isSetupSubmitted && userPermissions?.dlwtSetupPreResultsWriteAccess === true;
    }

    const SubmitAssessmentDeadline = () => {
        if(ValidateDate())
        {
            IsSetupSubmitted(true);
            props.submitAssessmentDeadline(selectedAssessmentId, IsDlwtEnabled(),adjustTimeZoneOffset(moment(accessBeginDate).toDate()), adjustTimeZoneOffset(moment(accessEndDate).toDate()), adjustTimeZoneOffset(moment(lwtRequestSubmissionDeadline).toDate()), adjustTimeZoneOffset(moment(lwaCompletionDeadline).toDate()), adjustTimeZoneOffset(moment(newRequestEndDate).toDate()), adjustTimeZoneOffset(moment(updateRequestEndDate).toDate()), true).then(()=>{
                props.getAssessmentDeadline(selectedAssessmentId);
                localStorage.removeItem("IsSetupChanged");
            });
        }
        else{
            setSaveFailMessage("Setup has not been submitted. Please make the necessary change(s) and resubmit.");
            setShowSaveFailMessage(true);
        }
    }

    const SubmitPrsAssessmentDeadline = () => {
        if(ValidatePrsDates())
        {
             IsPrsSetupSubmitted(true);
            props.submitPrsAssessmentDeadline(selectedAssessmentId,adjustTimeZoneOffset(moment(prsInternalUserAccessBeginDate).toDate()), adjustTimeZoneOffset(moment(prsInternalUserAccessEndDate).toDate()), adjustTimeZoneOffset(moment(prsExternalUserAccessBeginDate).toDate()), adjustTimeZoneOffset(moment(prsExternalUserAccessEndDate).toDate())).then(()=>{
                props.getAssessmentDeadline(selectedAssessmentId);
                localStorage.removeItem("IsPrsSetupChanged");
            });
        }
        else{
            setSaveFailMessage("Update has not been submitted. Please make the necessary change(s) and resubmit.");
            setShowSaveFailMessage(true);
        }
    }

    const ValidateDate = () : boolean =>
    {
        let validationResult = true;
        setAccessBeginDateError("");
        setlwtRequestSubmissionDeadlineError("");
        setlwaCompletionDeadlineError("");
        setNewRequestEndDateError("");
        setUpdateRequestEndDateError("");
        setAccessEndDateError("");
        if(isSetupAmend && isDlwtManaged==="0")
        {
            return true;
        }
        if(!IsAllDatesEntered())
        {
            validationResult = false;
        }
        else
        {
            let accessBeginDateErrors="";
            let lwtRequestSubmissionDeadlineErrors="";
            let lwaCompletionDeadlineErrors="";
            let newRequestEndDateErrors="";
            let updateRequestEndDateErrors="";
            let accessEndDateErrors="";
            
            if(!accessBeginDate || accessBeginDate===null)
            {
                accessBeginDateErrors="<li>Provide a valid date</li>";
                validationResult=false; 
            }
            if(accessBeginDate && accessEndDate && moment(accessBeginDate).toDate()>moment(accessEndDate).toDate())
            {
                accessBeginDateErrors="<li>Provide a date that is the same or earlier than the date for ‘Requests are unavailable to Centre users and Assessment Associates from…’</li><br>";
                validationResult=false;
            }
            if(accessBeginDateErrors && accessBeginDateErrors!="")
            {
                setAccessBeginDateError("<ul class='error-messages'>"+accessBeginDateErrors+"</ul>")
            }

            
            if(!lwtRequestSubmissionDeadline || lwtRequestSubmissionDeadline===null)
            {
                lwtRequestSubmissionDeadlineErrors="<li>Provide a valid date</li>";
                validationResult=false; 
            }
            if(lwtRequestSubmissionDeadline && accessBeginDate && moment(lwtRequestSubmissionDeadline).toDate()<moment(accessBeginDate).toDate())
            {
                lwtRequestSubmissionDeadlineErrors="<li>Provide a date that is the same or later than the date for ‘Requests are available to all users from…’</li><br>";
                validationResult=false;
            }
            if(lwtRequestSubmissionDeadline && accessEndDate &&  moment(lwtRequestSubmissionDeadline).toDate()> moment(accessEndDate).toDate())
            {
                lwtRequestSubmissionDeadlineErrors+="<li>Provide a date that is the same or earlier than the date for ‘Requests are unavailable to centre users and assessment associates from…’</li><br>";
                validationResult=false;
            }
            
            if(lwtRequestSubmissionDeadline && lwaCompletionDeadline &&  moment(lwtRequestSubmissionDeadline).toDate()> moment(lwaCompletionDeadline).toDate())
            {
                lwtRequestSubmissionDeadlineErrors+="<li>Provide a date that is the same or earlier than the date for ‘Learner work assessment request completion deadline is...’</li><br>";
                validationResult=false;
            }
            if(lwtRequestSubmissionDeadlineErrors && lwtRequestSubmissionDeadlineErrors!="")
            {
                setlwtRequestSubmissionDeadlineError("<ul class='error-messages'>"+lwtRequestSubmissionDeadlineErrors+"</ul>")
            }

            if(!lwaCompletionDeadline || lwaCompletionDeadline===null)
            {
                lwaCompletionDeadlineErrors="<li>Provide a valid date</li>";
                validationResult=false; 
            }
            if(lwaCompletionDeadline && accessBeginDate &&  moment(lwaCompletionDeadline).toDate()< moment(accessBeginDate).toDate())
            {
                lwaCompletionDeadlineErrors="<li>Provide a date that is the same or later than the date for ‘Requests are available to all users from…’</li><br>";
                validationResult=false;
            }
            if(lwaCompletionDeadline && accessEndDate &&  moment(lwaCompletionDeadline).toDate()> moment(accessEndDate).toDate())
            {                
                lwaCompletionDeadlineErrors+="<li>Provide a date that is the same or earlier than the date for ‘Requests are unavailable to centre users and assessment associates from…’</li><br>";
                validationResult=false;
            }
            if(lwaCompletionDeadline && lwtRequestSubmissionDeadline &&  moment(lwaCompletionDeadline).toDate()< moment(lwtRequestSubmissionDeadline).toDate())
            {
                lwaCompletionDeadlineErrors+="<li>Provide a date that is the same or later than the date for ‘Learner Work Transfer request submission deadline is...’</li><br>";
                validationResult=false;
            }
            if(lwaCompletionDeadlineErrors && lwaCompletionDeadlineErrors!="")
            {
                setlwaCompletionDeadlineError("<ul class='error-messages'>"+lwaCompletionDeadlineErrors+"</ul>");
            }

            if(!newRequestEndDate || newRequestEndDate===null)
            {
                newRequestEndDateErrors="<li>Provide a valid date</li>";
                validationResult=false; 
            }
            if(newRequestEndDate && accessBeginDate &&  moment(newRequestEndDate).toDate()< moment(accessBeginDate).toDate())
            {
                newRequestEndDateErrors="<li>Provide a date that is the same or later than the date for ‘Requests are available to all users from…’</li><br>";
                validationResult=false;
            }
            if(newRequestEndDate && accessEndDate &&  moment(newRequestEndDate).toDate()> moment(accessEndDate).toDate())
            {
                newRequestEndDateErrors+="<li>Provide a date that is the same or earlier than the date for ‘Requests are unavailable to centre users and assessment associates from…’</li><br>";
                validationResult=false;
            }
            if(newRequestEndDateErrors && newRequestEndDateErrors!="")
            {
                setNewRequestEndDateError("<ul class='error-messages'>"+newRequestEndDateErrors+"</ul>");
            }

            if(!updateRequestEndDate || updateRequestEndDate===null)
            {
                updateRequestEndDateErrors="<li>Provide a valid date</li>";
                validationResult=false; 
            }
            if(updateRequestEndDate && accessBeginDate &&  moment(updateRequestEndDate).toDate()< moment(accessBeginDate).toDate())
            {
                updateRequestEndDateErrors="<li>Provide a date that is the same or later than the date for ‘Requests are available to all users from…’</li><br>";
                validationResult=false;
            }
            if(updateRequestEndDate && accessEndDate &&  moment(updateRequestEndDate).toDate()> moment(accessEndDate).toDate())
            {
                updateRequestEndDateErrors+="<li>Provide a date that is the same or earlier than the date for ‘Requests are unavailable to centre users and assessment associates from…’</li><br>"
                validationResult=false;
            }
            if(updateRequestEndDateErrors && updateRequestEndDateErrors!="")
            {
                setUpdateRequestEndDateError("<ul class='error-messages'>"+updateRequestEndDateErrors+"</ul>");
            }

            if(!accessEndDate || accessEndDate===null)
            {
                accessEndDateErrors="<li>Provide a valid date</li>";
                validationResult=false; 
            }
            if(accessEndDate && newRequestEndDate &&  moment(accessEndDate).toDate()< moment(newRequestEndDate).toDate())
            {
                accessEndDateErrors="<li>Provide a date that is the same or later than the date for ‘New requests are unavailable from…’</li><br>";
                validationResult=false;
            }
            if(accessEndDate && updateRequestEndDate &&  moment(accessEndDate).toDate()< moment(updateRequestEndDate).toDate())
            {
                accessEndDateErrors+="<li>Provide a date that is the same or later than the date for ‘Existing requests stop being updated from…’</li><br>";
                validationResult=false;
            }
            if(accessEndDate && accessBeginDate &&  moment(accessEndDate).toDate()< moment(accessBeginDate).toDate())
            {
                accessEndDateErrors+="<li>Provide a date that is the same or later than the date for ‘Requests are available to all users from…’</li><br>";
                validationResult=false;
            }
            if(accessEndDateErrors && accessEndDateErrors!="")
            {
                setAccessEndDateError("<ul class='error-messages'>"+accessEndDateErrors+"</ul>");
            }
        }
        return validationResult;
    }

    const ValidatePrsDates = () : boolean =>
    {
        
        let validationPrsResult = true;
        setPrsInternalUserAccessBeginDateError("");
        setPrsInternalUserAccessEndDateError("");
        setPrsExternalUserAccessBeginDateError("");
        setPrsExternalUserAccessEndDateChangedError("");
        if(!IsAllPrsDatesEntered())
        {
           
            validationPrsResult = false;
        }
        else{
            let prsInternalUserAccessBeginDateErrors="";
            let prsInternalUserAccessEndDateErrors="";
            let prsExternalUserAccessBeginDateErrors="";
            let prsExternalUserAccessEndDateErrors="";
            if(!prsInternalUserAccessBeginDate || prsInternalUserAccessBeginDate===null)
            {
                prsInternalUserAccessBeginDateErrors="<li>Provide a valid date</li>";
                validationPrsResult=false; 
            }
            if(prsInternalUserAccessBeginDate && prsInternalUserAccessEndDate && moment(prsInternalUserAccessBeginDate).toDate()>moment(prsInternalUserAccessEndDate).toDate())
            {
                prsInternalUserAccessBeginDateErrors="<li>Provide a date that is the same or earlier than the date for Pearson Users 'Post Results/RoMM requests are unavailable in Learner Work Assessment from...’</li><br>";
                validationPrsResult=false;
            }
            if(prsInternalUserAccessBeginDateErrors && prsInternalUserAccessBeginDateErrors!="")
            {
                setPrsInternalUserAccessBeginDateError("<ul class='error-messages'>"+prsInternalUserAccessBeginDateErrors+"</ul>")
            }

            if(!prsInternalUserAccessEndDate || prsInternalUserAccessEndDate===null)
            {
                prsInternalUserAccessEndDateErrors="<li>Provide a valid date</li>";
                validationPrsResult=false; 
            }
            if(prsInternalUserAccessBeginDate && prsInternalUserAccessEndDate && moment(prsInternalUserAccessEndDate).toDate()<moment(prsInternalUserAccessBeginDate).toDate())
            {
                prsInternalUserAccessEndDateErrors="<li>Provide a date that is the same or later than the date for Pearson Users 'Post Results/RoMM requests are available in Learner Work Assessment from...’</li><br>";
                validationPrsResult=false;
            }
            if(prsInternalUserAccessEndDateErrors && prsInternalUserAccessEndDateErrors!="")
            {
                setPrsInternalUserAccessEndDateError("<ul class='error-messages'>"+prsInternalUserAccessEndDateErrors+"</ul>")
            }

            if(!prsExternalUserAccessBeginDate || prsExternalUserAccessBeginDate===null)
            {
                prsExternalUserAccessBeginDateErrors="<li>Provide a valid date</li>";
                validationPrsResult=false; 
            }
            if(prsExternalUserAccessBeginDate && prsExternalUserAccessEndDate && moment(prsExternalUserAccessBeginDate).toDate()>moment(prsExternalUserAccessEndDate).toDate())
            {
                prsExternalUserAccessBeginDateErrors="<li>Provide a date that is the same or earlier than the date for Assessment Associates 'Post Results/RoMM requests are unavailable in Learner Work Assessment from...’</li><br>";
                validationPrsResult=false;
            }
            if(prsExternalUserAccessBeginDateErrors && prsExternalUserAccessBeginDateErrors!="")
            {
                setPrsExternalUserAccessBeginDateError("<ul class='error-messages'>"+prsExternalUserAccessBeginDateErrors+"</ul>")
            }

            if(!prsExternalUserAccessEndDate || prsExternalUserAccessEndDate===null)
            {
                prsExternalUserAccessEndDateErrors="<li>Provide a date</li>";
                validationPrsResult=false; 
            }
            if(prsExternalUserAccessBeginDate && prsExternalUserAccessEndDate && moment(prsExternalUserAccessEndDate).toDate()<moment(prsExternalUserAccessBeginDate).toDate())
            {
                prsExternalUserAccessEndDateErrors="<li>Provide a date that is the same or later than the date for Assessment Associate 'Post Results/RoMM requests are available in Learner Work Assessment from...’</li><br>";
                validationPrsResult=false;
            }
            if(prsExternalUserAccessEndDateErrors && prsExternalUserAccessEndDateErrors!="")
            {
                setPrsExternalUserAccessEndDateChangedError("<ul class='error-messages'>"+prsExternalUserAccessEndDateErrors+"</ul>")
            }
            

        }
        
        return validationPrsResult;

    }

    const adjustTimeZoneOffset =(date: Date): Date=>{
        date.setTime(date.getTime() - date.getTimezoneOffset()*60*1000);
        return date;
    }

    const IsAllDatesEntered = () : boolean =>{
        return !(!(selectedAssessmentDeadline?.accessBeginDate || accessBeginDateChanged) || !(selectedAssessmentDeadline?.accessEndDate || accessEndDateChanged) 
        || !(selectedAssessmentDeadline?.submissionDeadline || lwtRequestSubmissionDeadlineChanged) || !(selectedAssessmentDeadline?.aADeadline || lwaCompletionDeadlineChanged)
        || !(selectedAssessmentDeadline?.createRequestEndDate || newRequestEndDateChanged) || !(selectedAssessmentDeadline?.updateRequestEndDate || updateRequestEndDateChanged))
        || !(accessBeginDate && accessEndDate && lwtRequestSubmissionDeadline && lwaCompletionDeadline && newRequestEndDate && updateRequestEndDate)
    }

    const IsAllPrsDatesEntered = () : boolean => {
        return !(!(selectedAssessmentDeadline?.prsInternalUserAccessBeginDate || prsInternalUserAccessBeginDateChanged) 
        || !(selectedAssessmentDeadline?.prsInternalUserAccessEndDate || prsInternalUserAccessEndDateChanged) 
        || !(selectedAssessmentDeadline?.prsExternalUserAccessBeginDate || prsExternalUserAccessBeginDateChanged) || !(selectedAssessmentDeadline?.prsExternalUserAccessEndDate || prsExternalUserAccessEndDateChanged))
        || !(prsInternalUserAccessBeginDate && prsInternalUserAccessEndDate && prsExternalUserAccessBeginDate && prsExternalUserAccessEndDate );
    }

    const backToDashBoard = () => {
        localStorage.removeItem("IsSetupChanged");
        history.push("/assessment-deadline-config");          
    };

    const disablePrsSubmitButton= (): boolean =>{
    return true;
    }

   const disableCancelButton= () =>{
    setCancelButton(true);
    setShowSaveFailMessage(false);
    setPrsInternalUserAccessBeginDateError("");
    setPrsInternalUserAccessEndDateError("");
    setPrsExternalUserAccessBeginDateError("");
    setPrsExternalUserAccessEndDateChangedError("");
    localStorage.setItem("IsPrsSetupChanged","false");
    if(IsAllPrsDatesEntered() || isEditButtonClicked)
    {
        setEditButtonClicked(false);
       
    }
        }
    
    const disableSubmitButton= (): boolean =>{
        if(isSetupAmend && (IsDatesChanged() || isDlwtManagedFlagChanged))
        {
            return false;
        }  
        if(!isSetupAmend)
            return disableControls() || !IsAllDatesEntered();
        return true;
    }

    const disablePrsEditButton = (): boolean => {
         if ((userPermissions?.dlwtSetupPostResultsWriteAccess === true) && (selectedAssessmentDeadline?.isSetupSubmitted === true && selectedAssessmentDeadline?.isDlwtEnabled === true))
         {
            return true;
           
         }
         else{
            return false;
         }
    }

    const disableAmendButton= (): boolean =>{
        return userPermissions?.dlwtSetupPreResultsWriteAccess === true;
    }

    const disableControls = () : boolean =>{
        return (isSetupAmend && isDlwtManaged==="1"?false : (selectedAssessmentDeadline?.isSetupSubmitted) || (!userCanManageSetup() || (userCanManageSetup() && (!IsDlwtEnabled()))));
    }

    const disablePrsDateControls = () : boolean =>{
        return isEditButtonClicked ;
    }

    const IsDlwtEnabled = () : boolean =>{
        return isDlwtManaged === "1";
    }

    const SetIsAccessBeginDateChanged = (e: any) =>{
        if (isFirstRun.current) {
            return;
        }
        setAccessBeginDate(e.currentTarget.value);
        setAccessBeginDateChanged(true);
    }

    const SetIsPrsInternalUserAccessBeginDateChanged = (e: any) =>{
        if (isFirstRun.current) {
            return;
        }
        setPrsInternalUserAccessBeginDate(e.currentTarget.value);
        setPrsInternalUserAccessBeginDateChanged(true);
    }

    const SetIsPrsExternalUserAccessBeginDateChanged = (e: any) =>{
        if (isFirstRun.current) {
            return;
        }
        setPrsExternalUserAccessBeginDate(e.currentTarget.value);
        setPrsExternalUserAccessBeginDateChanged(true);
    }

    const SetIsPrsInternalUserAccessEndDateChanged = (e: any) =>{
        if (isFirstRun.current) {
            return;
        }
        setPrsInternalUserAccessEndDate(e.currentTarget.value);
        setPrsInternalUserAccessEndDateChanged(true);
    }

    const SetIsPrsExternalUserAccessEndDateChanged = (e: any) =>{
        if (isFirstRun.current) {
            return;
        }
        setPrsExternalUserAccessEndDate(e.currentTarget.value);
        setPrsExternalUserAccessEndDateChanged(true);
    }

    const SetIsLwtRequestSubmissionDeadlineChanged = (e: any) =>{
        if (isFirstRun.current) {
            return;
        }
        setLwtRequestSubmissionDeadline(e.currentTarget.value);
        setLwtRequestSubmissionDeadlineChanged(true);
    }

    const SetIsLwaRequestSubmissionDeadlineChanged = (e: any) =>{
        if (isFirstRun.current) {
            return;
        }
        setLwaCompletionDeadline(e.currentTarget.value);
        setLwaCompletionDeadlineChanged(true);
    }

    const SetIsRequestAccessEndDateChanged = (e: any) =>{
        if (isFirstRun.current) {
            return;
        }
        setRequestAccessEndDate(e.currentTarget.value);
        setRequestAccessEndDateChanged(true);
    }

    const SetIsNewRequestEndDateChanged = (e: any) =>{
        if (isFirstRun.current) {
            return;
        }
        setNewRequestEndDate(e.currentTarget.value);
        setNewRequestEndDateChanged(true);
    }

    const SetIsUpdateRequestEndDateChanged = (e: any) =>{
        if (isFirstRun.current) {
            return;
        }
        setupdateRequestEndDate(e.currentTarget.value);
        setUpdateRequestEndDateChanged(true);
    }

    const closeSaveSuccess = () => {
        setShowSaveSuccessMessage(false); 
    }

    const closeSaveFail = () => {
        setShowSaveFailMessage(false)
    }

    const closeOutSetupModalWindow = () => {
        showSetupModal(false);
        
      }; 
      const openSetupModalWindow = () => {    
        let IsSetupChanged=localStorage.getItem("IsSetupChanged");
        if(IsSetupChanged==="true")
        {
            showSetupModal(true);    
        }
        else
        {
            backToDashBoard();
        }
      };
    const openAmendModalWindow = () => {
        setOpenAmendModal(true);
    }
    const amendRequest = () => { 
        setIsSetupAmend(true);
        setOpenAmendModal(false);
    };

    const openConfirmAmendModalWindow = () => {
        if(isSetupAmend===true)
            setOpenAmendConfirmModal(true);
        else
            SubmitAssessmentDeadline();
    }
    
    const confirmAmendRequest = () => { 
        SubmitAssessmentDeadline();
        setOpenAmendConfirmModal(false);
    };
   const onEditClick = () => { 
    setEditButtonClicked(true)
    setCancelButton(false);
    setShowSaveSuccessMessage(false);
    };

    const openPrsConfirmSubmitModalWindow = () => {
        setPrsOpenSubmitConfirmModal(true);
        SubmitPrsAssessmentDeadline();
        return true;
    }

    return (
        <div>
            <AmendModal isConfirmBut={false} show={openModal} title="Amendment Confirmation" modalData="Amend Setup" 
            onCancel={() => setOpenAmendModal(false)} onConfirm={() => amendRequest()} />

            <AmendModal isConfirmBut={false}  show={openAmendConfirmModal} title="Confirm the Change" modalData="Amend Confirmation" 
            onCancel={() => setOpenAmendConfirmModal(false)} onConfirm={() => confirmAmendRequest()} />
            
          <Modal hideModalData={true} isConfirmBut={true} show={openSetupModal} title="Confirmation" modalData="Log Out" isSetupModal={true} onCancel={() => closeOutSetupModalWindow()} onConfirm={() => backToDashBoard()} />
           <ShowAlert 
            containerId='save-success' 
            parentStateShowAlertVar={showSaveSuccessMessage} 
            parentStateHideAlertMethod={closeSaveSuccess} 
            alertBody={{styles:{fontSize:"12px"}, text:saveSuccessMessage}} 
            />
            <ShowAlert 
            containerId='save-fail' type='error'
            parentStateShowAlertVar={showSaveFailMessage} 
            parentStateHideAlertMethod={closeSaveFail} 
            alertBody={{styles:{fontSize:"12px"}, text:saveFailMessage}} 
            alertClassName="alert-msg-1"  
            />
            {isDataLoaded && selectedAssessmentDeadline &&
                (<div className="manage-setup">
                    <br />
                    <div className="gr-grid-container config-screen" style={{padding:"0 5.4em"}}>
                        <div className="gr-row-one gr-col-one gr-col-span-six">
                            <h3 className="gr-h3 semi-bold-650" style={{ lineHeight: "0px" }}>Manage setup so a product can be used by LWT and LWA</h3>
                        </div><br />
                        <div className="gr-row-two gr-col-one gr-col-span-four">
                    <span style={{ marginTop: "3px", width: "100%", float: "left" }}>
                        <a onClick={openSetupModalWindow.bind(null)} style={{ textDecoration: "none", cursor: "pointer" }}>
                            <span className="backToDashBoardLink gr-semi-bold">
                             Back to Select a Product
                            </span>
                        </a>
                        </span>
                        </div> 
                    </div>

                    {/* Tabs */}
                <div className="gr-grid-container config-screen" style={{padding:"0 4.3em"}}>
                <pearson-tabs
                    id="tab"
                    key="topTabs"
                    style={{ backgroundColor: "#f5f5f5" }}
                    onClick={tabClickHandler}
                >
                    <ul className="tabs mytabs" slot="tabs">
                        <li key="request">Pre Results Assessment</li>
                        <li key="teamView">Review of Marking and Moderation</li>
                    </ul>
                    <div className="panels" slot="panels">
                        <div data-panel key="0" onClick={preventTabBodyClick}>
                        <div className="gr-grid-container config-screen ie-flex" style={{ marginBottom: "8px" }}>
                        <div className="gr-col-one gr-col-span-twelve ">
                            <pearson-card>
                                <div className="gr-grid-container config-screen ie-flex requestCard"  >
                                    <div className="gr-row-one gr-col-one gr-col-span-twelve" style={{ paddingTop: "10px" }}>
                                        <span className="gr-h3 gr-neutral-med-one gr-font-normal" style={{ lineHeight: "22px" }}>
                                            <span className="gr-neutral-med-none semi-bold-650">{selectedAssessmentDeadline?.qualificationTypeName} {selectedAssessmentDeadline?.qualificationName}{selectedAssessmentDeadline?.unitCode} {selectedAssessmentDeadline?.unitName}</span>
                                        </span>
                                    </div>
                                    <div className="gr-row-two gr-col-one gr-col-span-four">
                                        <div>
                                            <span className="gr-neutral-med-none gr-font-normal">Series: </span>
                                            <span className="gr-neutral-med-none semi-bold-650">{selectedAssessmentDeadline?.seriesName}</span>
                                        </div>
                                    </div>
                                    <div className="gr-row-two gr-col-one gr-col-span-three">
                                        <div>
                                            <span className="gr-neutral-med-none gr-font-normal">Marking Method: </span>
                                            <span className="gr-neutral-med-none semi-bold-650">{selectedAssessmentDeadline?.markingMethod}</span>
                                        </div>
                                    </div>
                                    <div className="gr-row-two gr-col-one gr-col-span-five">
                                        <div>
                                            <span className="gr-neutral-med-none gr-font-normal">Setup Submitted: </span>
                                            <span className="gr-neutral-med-none semi-bold-650">{selectedAssessmentDeadline?.isSetupSubmitted ? "Yes" : "No"}</span>
                                        </div>
                                    </div>
                                    <div className="gr-row-three gr-col-one gr-col-span-four" style={{ paddingBottom: "10px" }}>
                                        <div>
                                            <span className="gr-neutral-med-none gr-font-normal">Assessment Method: </span>
                                            <span className="gr-neutral-med-none semi-bold-650">{selectedAssessmentDeadline?.assessmentMethodType === 1 ? "Examination" : "Moderation"}</span>
                                        </div>
                                    </div>
                                    {selectedAssessmentDeadline?.assessmentMethodType === 2 && (<div className="gr-row-three gr-col-one gr-col-span-three" style={{ paddingBottom: "10px" }}>
                                        <div>
                                            <span className="gr-neutral-med-none gr-font-normal">Sample selected by: </span>
                                            <span className="gr-neutral-med-none semi-bold-650">{selectedAssessmentDeadline?.sampleSelectedBy}</span>
                                        </div>
                                    </div>)}
                                    <div className="gr-row-three gr-col-one gr-col-span-six" style={{ paddingBottom: "10px" }}>
                                        <div>
                                            <span className="gr-neutral-med-none gr-font-normal">Last updated: </span>
                                            {selectedAssessmentDeadline?.submittedOn && selectedAssessmentDeadline?.submittedBy && <span className="gr-neutral-med-none semi-bold-650">{moment(selectedAssessmentDeadline?.submittedOn).format("DD/MM/YYYY")} at {moment(selectedAssessmentDeadline?.submittedOn).format("HH:mm")} by {selectedAssessmentDeadline?.submittedBy}</span>}
                                        </div>
                                    </div>
                                </div>
                            </pearson-card>
                        </div>
                    </div>
                    <br /> <br />

                    <div className="gr-grid-container config-screen" >
                        <div className="gr-row-one gr-col-one gr-col-span-twelve">
                            <h3 className="gr-h3 semi-bold-650" style={{ lineHeight: "0px" }}>Pre Results Assessment Activities <span style={{ fontStyle: "italic" }}>(Original Marking and Moderation)</span></h3>
                        </div>
                    </div>
                    <div className="gr-grid-container config-screen">
                        <div className="gr-row-one gr-col-one gr-col-span-twelve">
                            <span className="gr-font-normal">Provide the confirmed date that will control activity in Learner Work Transfer and Learner Work Assessment.<br />
                        Press 'Submit Setup' to confirm the setup.</span>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div className="gr-grid-container config-screen">
                        <div className="gr-row-one gr-col-one gr-col-span-seven">
                            <div>
                                <span className="gr-font-normal">Evidence submission is managed by Learner Work Transfer and Learner Work Assessment?</span>
                            </div>
                        </div>
                        <div className="gr-row-one gr-col-one gr-col-span-one">
                        <div className="tooltip" style={{ paddingLeft: "10px", paddingTop: "5px" }}>
                                <Icon size="18" icon="information" />
                                <span className="tooltiptext tooltipLearnerStatus" style={{ width: "500px" }}>
                                <ul>
                                    <li>Learner Work Transfer and Learner Work Assessment will not be used to manage the submission of evidence.</li>
                                    <li>After being set to 'Yes' and successfully submitted:</li>
                                        <ul>
                                            <li> Before the date shown for 'Requests are available for all users from...', no requests will be available within Learner Work Transfer and Learner Work Assessment for all users.</li>
                                            <li> After the date shown for 'Requests are available for all users from...', any available requests will be removed from Learner Work Transfer and Learner Work Assessment for all users.</li>
                                        </ul>
                                </ul>
                                </span>&nbsp;&nbsp;
                                
                            </div>
                            <span className="gr-font-normal">
                                    <input type="radio" name="dlwtmanaged" id="isnotdlwtmanaged" value={0} onChange={isDlwtManagedChanged} disabled={!userCanManageSetup()} 
                                    defaultChecked={!selectedAssessmentDeadline.isDlwtEnabled}/>&nbsp;<label>No</label></span>
                        </div>
                        <div className="gr-row-one gr-col-one gr-col-span-three">
                            <div className="tooltip" style={{ paddingLeft: "10px", paddingTop: "5px" }}>
                                <Icon size="18" icon="information" />
                                <span className="tooltiptext tooltipLearnerStatus" style={{ width: "500px" }}>
                                If set to Yes, Learner Work Transfer and Learner Work Assessment will be used to manage the submission of evidence.
                                </span>&nbsp;&nbsp;
                            </div>
                            <span className="gr-font-normal"> <input type="radio" name="dlwtmanaged" id="isdlwtmanaged" value={1} onChange={isDlwtManagedChanged} disabled={!userCanManageSetup()} defaultChecked={selectedAssessmentDeadline.isDlwtEnabled}/>&nbsp;<label>Yes</label></span>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div className="gr-grid-container config-screen">
                        <div className="gr-row-one gr-col-one gr-col-span-seven">
                            <div>
                                <span className="gr-font-normal">1. Requests are available for all users from...</span><br />
                                <span className="gr-neutral-med-none gr-font-normal"> From this date requests can be accessed by Pearson users, Centre users and Assessment Associates.
                        </span>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="gr-grid-container config-screen">
                        <div className="gr-row-one gr-col-one gr-col-span-two">
                        </div>
                        <div className="gr-row-one gr-col-one gr-col-span-two">
                            <div style={{ paddingTop: "10px" }}>
                                <span className="gr-font-normal semi-bold-650">Confirmed Date
                                            </span>
                            </div>
                        </div>
                        <div className="gr-row-one gr-col-one gr-col-span-six">
                            <div>
                                <DatePicker id="accessBeginDate" disabled={disableControls()} size="lg"
                                aria-label="Requests are available for all users from..."
                                value={accessBeginDate ? moment(accessBeginDate).format("yyyy-MM-DD"): ""} 
                                onChange={SetIsAccessBeginDateChanged}
                                feedback={accessBeginDateError ? parse(accessBeginDateError) : ""} feedbackType="invalid"  /><br/>
                            </div>
                        </div>
                        <div className="gr-row-one gr-col-one gr-col-span-one">
                            <div style={{ paddingTop: "10px" }}>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="gr-grid-container config-screen">
                        <div className="gr-row-one gr-col-one gr-col-span-seven">
                            <div>
                                <span className="gr-font-normal">2. Learner Work Transfer request submission deadline is...</span><br />
                                <span className="gr-neutral-med-none gr-font-normal">By this date requests, including any evidence, are submitted for assessment.
                        </span>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="gr-grid-container config-screen">
                        <div className="gr-row-one gr-col-one gr-col-span-two">
                        </div>
                        <div className="gr-row-one gr-col-one gr-col-span-two">
                            <div style={{ paddingTop: "10px" }}>
                                <span className="gr-font-normal semi-bold-650">Confirmed Date
                                            </span>
                            </div>
                        </div>
                        <div className="gr-row-one gr-col-one gr-col-span-six">
                            <div>
                                <DatePicker id="lwtRequestSubmissionDeadline" disabled={disableControls()} size="lg"
                                aria-label="Learner Work Transfer request submission deadline is..."
                                value={lwtRequestSubmissionDeadline ? moment(lwtRequestSubmissionDeadline).format("yyyy-MM-DD"): ""} 
                                onChange={SetIsLwtRequestSubmissionDeadlineChanged}
                                feedback={lwtRequestSubmissionDeadlineError ? parse(lwtRequestSubmissionDeadlineError) : ""}  feedbackType="invalid"  />
                            </div>
                        </div>
                        <div className="gr-row-one gr-col-one gr-col-span-one">
                            <div style={{ paddingTop: "10px" }}>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="gr-grid-container config-screen">
                        <div className="gr-row-one gr-col-one gr-col-span-seven">
                            <div>
                                <span className="gr-font-normal">3. Learner Work Assessment completion deadline is...</span><br />
                                <span className="gr-neutral-med-none gr-font-normal"> By this date Assessment Associates are required to complete assessment activities.
                        </span>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="gr-grid-container config-screen">
                        <div className="gr-row-one gr-col-one gr-col-span-two">
                        </div>
                        <div className="gr-row-one gr-col-one gr-col-span-two">
                            <div style={{ paddingTop: "10px" }}>
                                <span className="gr-font-normal semi-bold-650">Confirmed Date
                                            </span>
                            </div>
                        </div>
                        <div className="gr-row-one gr-col-one gr-col-span-six">
                            <div>
                                <DatePicker id="lwaRequestSubmissionDeadline" disabled={disableControls()} size="lg"
                                aria-label="Learner Work Assessment completion deadline is..."
                                value={lwaCompletionDeadline ? moment(lwaCompletionDeadline).format("yyyy-MM-DD"): ""} 
                                onChange={SetIsLwaRequestSubmissionDeadlineChanged}
                                feedback={lwaCompletionDeadlineError ? parse(lwaCompletionDeadlineError) : ""}  feedbackType="invalid"  />
                            </div>
                        </div>
                        <div className="gr-row-one gr-col-one gr-col-span-one">
                            <div style={{ paddingTop: "10px" }}>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="gr-grid-container config-screen">
                        <div className="gr-row-one gr-col-one gr-col-span-seven">
                            <div>
                                <span className="gr-font-normal">4. New requests are unavailable from...</span><br />
                                <span className="gr-neutral-med-none gr-font-normal">From this date no new requests are created for use by Pearson users, Centre users and Assessment Associates.  Any required evidence should be submitted outside of this service.
                        </span>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="gr-grid-container config-screen">
                        <div className="gr-row-one gr-col-one gr-col-span-two">
                        </div>
                        <div className="gr-row-one gr-col-one gr-col-span-two">
                            <div style={{ paddingTop: "10px" }}>
                                <span className="gr-font-normal semi-bold-650">Confirmed Date
                                            </span>
                            </div>
                        </div>
                        <div className="gr-row-one gr-col-one gr-col-span-six">
                            <div>
                                <DatePicker id="lwaRequestSubmissionDeadline" disabled={disableControls()} size="lg"
                                aria-label="New requests are unavailable from..."
                                value={newRequestEndDate ? moment(newRequestEndDate).format("yyyy-MM-DD"): ""} 
                                onChange={SetIsNewRequestEndDateChanged}
                                feedback={newRequestEndDateError ? parse(newRequestEndDateError) : ""} feedbackType="invalid"  />
                            </div>
                        </div>
                        <div className="gr-row-one gr-col-one gr-col-span-one">
                            <div style={{ paddingTop: "10px" }}>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="gr-grid-container config-screen">
                        <div className="gr-row-one gr-col-one gr-col-span-seven">
                            <div>
                                <span className="gr-font-normal">5. Existing requests stop being updated from...</span><br />
                                <span className="gr-neutral-med-none gr-font-normal">From this date existing requests are not updated with additional/late entries.  Any required evidence should be submitted outside of this service.
                        </span>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="gr-grid-container config-screen">
                        <div className="gr-row-one gr-col-one gr-col-span-two">
                        </div>
                        <div className="gr-row-one gr-col-one gr-col-span-two">
                            <div style={{ paddingTop: "10px" }}>
                                <span className="gr-font-normal semi-bold-650">Confirmed Date
                                            </span>
                            </div>
                        </div>
                        <div className="gr-row-one gr-col-one gr-col-span-six">
                            <div>
                                <DatePicker id="lwaRequestSubmissionDeadline" disabled={disableControls()} size="lg"
                                aria-label="New requests are unavailable from..."
                                value={updateRequestEndDate ? moment(updateRequestEndDate).format("yyyy-MM-DD"): ""} 
                                onChange={SetIsUpdateRequestEndDateChanged}
                                feedback={updateRequestEndDateError ? parse(updateRequestEndDateError) : ""} feedbackType="invalid"  />
                            </div>
                        </div>
                        <div className="gr-row-one gr-col-one gr-col-span-one">
                            <div style={{ paddingTop: "10px" }}>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="gr-grid-container config-screen">
                        <div className="gr-row-one gr-col-one gr-col-span-seven">
                            <div>
                                <span className="gr-font-normal">6. Requests are unavailable to Centre users and Assessment Associates from...</span><br />
                                <span className="gr-neutral-med-none gr-font-normal">From this date requests cannot be accessed by Centre users and Assessment Associates.  Requests can still be accessed by Pearson users.
                        </span>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="gr-grid-container config-screen">
                        <div className="gr-row-one gr-col-one gr-col-span-two">
                        </div>
                        <div className="gr-row-one gr-col-one gr-col-span-two">
                            <div style={{ paddingTop: "10px" }}>
                                <span className="gr-font-normal semi-bold-650">Confirmed Date
                                            </span>
                            </div>
                        </div>
                        <div className="gr-row-one gr-col-one gr-col-span-six">
                            <div>
                                <DatePicker id="requestAccessEndDate" disabled={disableControls()} size="lg"
                                aria-label="Requests are unavailable to Centre users and Assessment Associates from..."
                                value={accessEndDate ? moment(accessEndDate).format("yyyy-MM-DD"): ""} 
                                onChange={SetIsRequestAccessEndDateChanged}                                
                                feedback={accessEndDateError ? parse(accessEndDateError) : ""}
                                feedbackType="invalid"  />
                            </div>
                        </div>
                        <div className="gr-row-one gr-col-one gr-col-span-one">
                            <div style={{ paddingTop: "10px" }}>
                            </div>
                        </div>
                    </div>
                    <br /><br />
                    <div className="gr-grid-container config-screen">
                        <div className="gr-row-one gr-col-one gr-col-span-seven">
                            &nbsp;
                                    </div>
                        <div className="gr-row-one gr-col-one gr-col-span-one">
                            &nbsp;
                                    </div>
                        <div style={{ float: "right" }} className="gr-row-one gr-col-one gr-col-span-eleven">
                            <div>
                                {(!props.selectedAssessmentDeadline?.isSetupSubmitted || isSetupAmend) && (<Button color="secondary" type="button" disabled={disableSubmitButton()} onClick={openConfirmAmendModalWindow}>Submit setup</Button>)}
                                {(props.selectedAssessmentDeadline?.isSetupSubmitted && !isSetupAmend) && (<Button color="secondary" type="button" disabled={!disableAmendButton()} onClick={openAmendModalWindow} >Amend setup</Button>)}
                            </div>
                        </div>
                    </div>
                        </div>
                        <div data-panel key="1" onClick={preventTabBodyClick}>
                        <div className="gr-grid-container config-screen ie-flex" style={{marginBottom: "8px" }}>
                        <div className="gr-col-one gr-col-span-twelve ">
                            <pearson-card>
                                <div className="gr-grid-container config-screen ie-flex requestCard"  >
                                    <div className="gr-row-one gr-col-one gr-col-span-twelve" style={{ paddingTop: "10px" }}>
                                        <span className="gr-h3 gr-neutral-med-one gr-font-normal" style={{ lineHeight: "22px" }}>
                                            <span className="gr-neutral-med-none semi-bold-650">{selectedAssessmentDeadline?.qualificationTypeName} {selectedAssessmentDeadline?.qualificationName}{selectedAssessmentDeadline?.unitCode} {selectedAssessmentDeadline?.unitName}</span>
                                        </span>
                                    </div>
                                    <div className="gr-row-two gr-col-one gr-col-span-four">
                                        <div>
                                            <span className="gr-neutral-med-none gr-font-normal">Series: </span>
                                            <span className="gr-neutral-med-none semi-bold-650">{selectedAssessmentDeadline?.seriesName}</span>
                                        </div>
                                    </div>
                                    <div className="gr-row-two gr-col-one gr-col-span-three">
                                        <div>
                                            <span className="gr-neutral-med-none gr-font-normal">Marking Method: </span>
                                            <span className="gr-neutral-med-none semi-bold-650">{selectedAssessmentDeadline?.markingMethod}</span>
                                        </div>
                                    </div>
                                    {/* <div className="gr-row-two gr-col-one gr-col-span-five">
                                        <div>
                                            <span className="gr-neutral-med-none gr-font-normal">Setup Submitted: </span>
                                            <span className="gr-neutral-med-none semi-bold-650">{selectedAssessmentDeadline?.isSetupSubmitted ? "Yes" : "No"}</span>
                                        </div>
                                    </div> */}
                                    <div className="gr-row-two gr-col-one gr-col-span-five" >
                                        <div>
                                            <span className="gr-neutral-med-none gr-font-normal">Assessment Method: </span>
                                            <span className="gr-neutral-med-none semi-bold-650">{selectedAssessmentDeadline?.assessmentMethodType === 1 ? "Examination" : "Moderation"}</span>
                                        </div>
                                    </div>
                                    {selectedAssessmentDeadline?.assessmentMethodType === 2 && (<div className="gr-row-three gr-col-one gr-col-span-three" style={{ paddingBottom: "10px" }}>
                                        <div>
                                            <span className="gr-neutral-med-none gr-font-normal">Sample selected by: </span>
                                            <span className="gr-neutral-med-none semi-bold-650">{selectedAssessmentDeadline?.sampleSelectedBy}</span>
                                        </div>
                                    </div>)}
                                    <div className="gr-row-three gr-col-one gr-col-span-six" style={{ paddingBottom: "10px" }}>
                                        <div>
                                            <span className="gr-neutral-med-none gr-font-normal">Last updated: </span>
                                            {selectedAssessmentDeadline?.prsSubmittedOn && selectedAssessmentDeadline?.prsSubmittedBy && <span className="gr-neutral-med-none semi-bold-650">{moment(selectedAssessmentDeadline?.prsSubmittedOn).format("DD/MM/YYYY")} at {moment(selectedAssessmentDeadline?.prsSubmittedOn).format("HH:mm")} by {selectedAssessmentDeadline?.prsSubmittedBy}</span>}
                                        </div>
                                    </div>
                                </div>
                            </pearson-card>
                        </div>
                    </div>
                    <br /> <br />

                    <div className="gr-grid-container config-screen">
                        <div className="gr-row-one gr-col-one gr-col-span-twelve">
                            <h3 className="gr-h3 semi-bold-650" style={{ lineHeight: "0px" }}>Post Results Assessment Activities <span style={{ fontStyle: "italic" }}>(Review of Marking and Moderation)</span></h3>
                        </div>
                    </div>
                    <div className="gr-grid-container config-screen">
                        <div className="gr-row-one gr-col-one gr-col-span-twelve">
                            <span className="gr-font-normal">When:</span>
                        <ul style={{ paddingLeft: "15px",margin:"0" }}>
                            <li>the component is set to 'Yes' for Evidence submission is managed by Learner Work Transfer and Learner Work Assessment via the Pre Results Assessment tab</li>
                            <li>evidence is available in Learner Worker Assessment at the point a PRS/RoMM case is raised</li>
                        </ul>
                        <br /> 
                        <span>then the allocated PRS/RoMM Assessment Associates can access the required evidence via Learner Work Assessment.<br />Use this screen to manage the availability of PRS/RoMM requests in Learner Work Assessment.</span>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div className="gr-grid-container config-screen">
                        <div className="gr-row-one gr-col-one gr-col-span-eight">
                            <div>
                                <span className="gr-font-normal">1. Post Results/RoMM requests are available in Learner Work Assessment from...</span><br />
                                <span className="gr-neutral-med-none gr-font-normal">From the date shown PRS/RoMM requests are available to Pearson users and allocated Assessment Associates.
                        </span>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="gr-grid-container config-screen">
                    
                        <div className="gr-row-one gr-col-one gr-col-span-two">
                            <div style={{ paddingTop: "10px" }}>
                                <span className="gr-font-normal semi-bold-650">Pearson Users
                                            </span>
                            </div>
                        </div>
                        <div className="gr-row-one gr-col-one gr-col-span-six">
                            <div>
                            { !prsInternalUserAccessBeginDate && !isEditButtonClicked ? <div style={{ paddingTop: "10px" }}>Date must be provided</div> : null}
                                {prsInternalUserAccessBeginDate || isEditButtonClicked ? 
                                <><DatePicker id="prsInternalUserAccessBeginDate" disabled={!disablePrsDateControls()} size="lg"
                                                    aria-label="Requests are available for all users from..."
                                                    value={prsInternalUserAccessBeginDate ? moment(prsInternalUserAccessBeginDate).format("yyyy-MM-DD") : ""}
                                                    onChange={SetIsPrsInternalUserAccessBeginDateChanged}/>
                                                    </>
                                   : '' }
                                </div>
                        </div>
                        <div className="gr-row-one gr-col-one gr-col-span-one">
                            <div style={{ paddingTop: "10px" }}>
                            </div>
                        </div>
                        
                    </div>
                    <div className="gr-grid-container config-screen">
                        <div className="gr-row-one gr-col-one gr-col-span-two">
                            <div style={{ paddingTop: "10px" }}>
                                <span className="gr-font-normal semi-bold-650">
                                    {/* SPACING */}
                                </span>
                            </div>
                        </div>
                        <div className="gr-row-one gr-col-one gr-col-span-nine prs-invalid-feedback">
                            <div className="invalid-feedback">
                                    {prsInternalUserAccessBeginDateError ? <Icon
                                    icon="warning"
                                    size="18"
                                    fill="gr-error"
                                    styleCss="warning"/> : ""}
                                    {prsInternalUserAccessBeginDateError ? parse(prsInternalUserAccessBeginDateError) : ""}
                            </div>
                        </div>
                    </div>
                    <br />

                    <div className="gr-grid-container config-screen">
                
                        <div className="gr-row-one gr-col-one gr-col-span-two">
                            <div style={{ paddingTop: "10px" }}>
                                <span className="gr-font-normal semi-bold-650">Assessment Associates
                                            </span>
                            </div>
                        </div>
                        <div className="gr-row-one gr-col-one gr-col-span-six">
                            <div>
                            { !prsExternalUserAccessBeginDate && !isEditButtonClicked ? <div style={{ paddingTop: "10px" }}>Date must be provided</div> : null}
                                {prsExternalUserAccessBeginDate || isEditButtonClicked ? 
                                <><DatePicker id="prsExternalUserAccessBeginDate" disabled={!disablePrsDateControls()} size="lg"
                                                    aria-label="Requests are available for all users from..."
                                                    value={prsExternalUserAccessBeginDate ? moment(prsExternalUserAccessBeginDate).format("yyyy-MM-DD") : ""}
                                                    onChange={SetIsPrsExternalUserAccessBeginDateChanged}/>
                                                    </>
                                : '' }
                                </div>
                        </div>
                        <div className="gr-row-one gr-col-one gr-col-span-one">
                            <div style={{ paddingTop: "10px" }}>
                            </div>
                        </div>
                    </div>
                    <div className="gr-grid-container config-screen">
                        <div className="gr-row-one gr-col-one gr-col-span-two">
                            <div style={{ paddingTop: "10px" }}>
                                <span className="gr-font-normal semi-bold-650">
                                    {/* SPACING */}
                                </span>
                            </div>
                        </div>
                        <div className="gr-row-one gr-col-one gr-col-span-nine prs-invalid-feedback">
                            <div className="invalid-feedback">
                                    {prsExternalUserAccessBeginDateError ? <Icon
                                    icon="warning"
                                    size="18"
                                    fill="gr-error"
                                    styleCss="warning"/> : ""}
                                    {prsExternalUserAccessBeginDateError ? parse(prsExternalUserAccessBeginDateError) : ""}
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div className="gr-grid-container config-screen">
                        <div className="gr-row-one gr-col-one gr-col-span-eight">
                            <div>
                                <span className="gr-font-normal">2. Post Results/RoMM requests are unavailable in Learner Work Assessment from...</span><br />
                                <span className="gr-neutral-med-none gr-font-normal">From the date shown PRS/RoMM requests are unavailable to Pearson users and allocated Assessment Associates.
                        </span>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="gr-grid-container config-screen">
                    
                        <div className="gr-row-one gr-col-one gr-col-span-two">
                            <div style={{ paddingTop: "10px" }}>
                                <span className="gr-font-normal semi-bold-650">Pearson Users
                                            </span>
                            </div>
                        </div>
                        <div className="gr-row-one gr-col-one gr-col-span-six">
                            <div>
                            { !prsInternalUserAccessEndDate && !isEditButtonClicked ? <div style={{ paddingTop: "10px" }}>Date must be provided</div> : null}
                            {prsInternalUserAccessEndDate || isEditButtonClicked ? 
                                <><DatePicker id="prsInternalUserAccessEndDate" disabled={!disablePrsDateControls()} size="lg"
                                                    aria-label="Requests are available for all users from..."
                                                    value={prsInternalUserAccessEndDate ? moment(prsInternalUserAccessEndDate).format("yyyy-MM-DD") : ""}
                                                    onChange={SetIsPrsInternalUserAccessEndDateChanged}/>
                                                    </>
                            : '' }
                                </div>
                        </div>
                    
                    </div>
                    <div className="gr-grid-container config-screen">
                        <div className="gr-row-one gr-col-one gr-col-span-two">
                            <div style={{ paddingTop: "10px" }}>
                                <span className="gr-font-normal semi-bold-650">
                                    {/* SPACING */}
                                </span>
                            </div>
                        </div>
                        <div className="gr-row-one gr-col-one gr-col-span-nine prs-invalid-feedback">
                            <div className="invalid-feedback">
                                    {prsInternalUserAccessEndDateError ? <Icon
                                    icon="warning"
                                    size="18"
                                    fill="gr-error"
                                    styleCss="warning"/> : ""}
                                    {prsInternalUserAccessEndDateError ? parse(prsInternalUserAccessEndDateError) : ""}
                            </div>
                        </div>
                    </div>
                    <br />
                  
                    <div className="gr-grid-container config-screen">
                
                        <div className="gr-row-one gr-col-one gr-col-span-two">
                            <div style={{ paddingTop: "10px" }}>
                                <span className="gr-font-normal semi-bold-650">Assessment Associates
                                            </span>
                            </div>
                        </div>
                        <div className="gr-row-one gr-col-one gr-col-span-six">
                            <div>
                            { !prsExternalUserAccessEndDate && !isEditButtonClicked ? <div style={{ paddingTop: "10px" }}>Date must be provided</div> : null}
                            {prsExternalUserAccessEndDate || isEditButtonClicked ? 
                                <><DatePicker id="prsExternalUserAccessEndDate" disabled={!disablePrsDateControls()} size="lg"
                                                    aria-label="Requests are available for all users from..."
                                                    value={prsExternalUserAccessEndDate ? moment(prsExternalUserAccessEndDate).format("yyyy-MM-DD") : ""}
                                                    onChange={SetIsPrsExternalUserAccessEndDateChanged}/>
                                                    </>
                                : '' }
                                </div>
                        </div>
                        <div className="gr-row-one gr-col-one gr-col-span-one">
                            <div style={{ paddingTop: "10px" }}>
                            </div>
                        </div>
                    </div>
                    <div className="gr-grid-container config-screen">
                        <div className="gr-row-one gr-col-one gr-col-span-two">
                            <div style={{ paddingTop: "10px" }}>
                                <span className="gr-font-normal semi-bold-650">
                                    {/* SPACING */}
                                </span>
                            </div>
                        </div>
                        <div className="gr-row-one gr-col-one gr-col-span-nine prs-invalid-feedback">
                            <div className="invalid-feedback">
                                    {prsExternalUserAccessEndDateError ? <Icon
                                    icon="warning"
                                    size="18"
                                    fill="gr-error"
                                    styleCss="warning"/> : ""}
                                    {prsExternalUserAccessEndDateError ? parse(prsExternalUserAccessEndDateError) : ""}
                            </div>
                        </div>
                    </div>
                    <br /> 
                    <div style={{ float: "right" }} className="gr-row-one gr-col-one gr-col-span-eleven">
                     
                            <div>
                                {( userPermissions?.dlwtSetupPostResultsWriteAccess === true && !isEditButtonClicked) && (<Button color="secondary" type="button" disabled={!disablePrsEditButton()} onClick={onEditClick}>Edit</Button>)}
                                {(  isEditButtonClicked) && (<><Button color="secondary" type="button" disabled={!disablePrsSubmitButton()} onClick={openPrsConfirmSubmitModalWindow}>Submit</Button><Button color="secondary" type="button" onClick={disableCancelButton}>Cancel</Button></>)}
                            </div>
                        </div>
                        </div>
                    </div>
                </pearson-tabs>
                </div>

                   

                </div>)}
        </div>
    );
};
export default ManageAssessmentDeadlineConfig;