import React from "react";
import * as auth from "../../actions/auth/auth";
import "./access-denied.css";
import { withRouter, RouteComponentProps } from "react-router";
import {
  deleteSavedSearch,
  deleteViewPapersSavedSearch,
} from "../../helpers/dashboard-request-helper";

const timeoutSeconds = 30;
type ownProps = RouteComponentProps<{ utype: string; islocked: string }>;

const AccessDenied = (props: ownProps) => {
  const [counter, setCounter] = React.useState(timeoutSeconds);

  React.useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    counter === 0 && auth.logout();
  }, [counter]);

  const userType: string =
    props && props.match && props.match.params && props.match.params.utype;
  const userIsLocked: string =
    props && props.match && props.match.params && props.match.params.islocked;

  const isLockedUser = userIsLocked.toUpperCase() === "LOCKED" ? true : false;

  const logout = () => {
    deleteSavedSearch();
    deleteViewPapersSavedSearch();
    auth.logout();
  };

  return (
    <div className="pageContainer">
      <h1>Access Denied</h1>
      {userType.toLowerCase() === "external" && (
        <div className="messageText">
          {isLockedUser ? (
            <>
              {errMsgs.EXTLOCKED}
              <a
                href="https://qualifications.pearson.com/en/contact-us.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                contact us
              </a>
              .
            </>
          ) : (
            <>
              {errMsgs.EXTUNLOCKED}
              <a
                href="https://qualifications.pearson.com/en/contact-us.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                contact us
              </a>
              .
            </>
          )}
        </div>
      )}
      {userType.toLowerCase() === "internal" && (
        <div className="messageText">
          {isLockedUser ? (
            <span>{errMsgs.INTLOCKED}</span>
          ) : (
            <span>{errMsgs.INTUNLOCKED}</span>
          )}
        </div>
      )}
      <p>
        You will be automatically logged out in {counter < 10 ? "0" : ""}
        {counter} secs.
        <div className="animateBorder" />
      </p>
      <div className="logoutDiv">
        <button className="logoutBtn" onClick={logout}>
          Logout Now
        </button>
      </div>
    </div>
  );
};

const errMsgs = {
  EXTUNLOCKED:
    "You do not have the correct profile to access Learner Work Transfer.  Please contact your Examinations Officer to add the correct profile.  If they are unable to help, please ",
  EXTLOCKED:
    "Your account might be locked, please contact your Examinations Officer.  If they are unable to help, please ",
  INTUNLOCKED:
    "You do not have the correct profile to access Learner Work Transfer. Please raise an incident via myHelp.",
  INTLOCKED:
    "Your account might be locked, please raise an incident via myHelp.",
};

export default withRouter(AccessDenied);
