import React, { useEffect } from "react";
import { fetchToken, logout } from "../../actions/auth/auth";
import { useHistory } from "react-router";
import { useUserLoginDispatch } from "../../shared/context-api/hooks";
import { userLoggedIn } from "../../shared/context-api/actions";
import { getFeatures } from "../../helpers/get-features-helper";
import { auditUserLogin } from "../../helpers/user-audit-helper";

const CallBack = () => {
  const history = useHistory();
  const userLoginDataDispatch = useUserLoginDispatch();

  useEffect(() => {
    const isLogoutMode = sessionStorage.getItem('hasTologout')
    
    if(isLogoutMode === "true") {
      sessionStorage.setItem('hasTologout', "false");
      window.location.replace(window.location.origin + "/logout");
      return
    }

    if (
      window.location.protocol === "https:" ||
      window.location.protocol === "http:"
    ) {
      const callbackURL = new URL(window.location.href);
      if (callbackURL) {
        const authCode = callbackURL.searchParams.get("code");
        if (authCode) {
          fetchToken(authCode)
            .then((decodedToken: any) => {
              userLoginDataDispatch(
                userLoggedIn({
                  cognitoPearsonUID: decodedToken["custom:pearsonUID"],
                })
              );
              auditUserLogin();
              getFeatures();
              history.push("/");
            })
            .catch(() => {
              logout();
            });
        }
      }
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  return <React.Fragment>&nbsp;</React.Fragment>;
};

export default CallBack;
