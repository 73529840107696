import moment from "moment";
import { initialStateType } from "../../shared/context-api/provider";
import { UserLoginDataType } from "../../shared/context-api/actions";

export const IsUserValid = (loggedInUserData: initialStateType) => {

    const isValidUser = loggedInUserData && loggedInUserData.userData && loggedInUserData.userData.isUserPermissionValid;
    const UserLockData = loggedInUserData && loggedInUserData.userData && loggedInUserData.userData.lockedUntil;
    const isLockedUser = (UserLockData && moment(UserLockData).isAfter()) || false;
    if (!(isValidUser && !isLockedUser)) {
        return true;
    }
    return false;
}

export const IsUserInternal = (loggedInUserData: initialStateType) => {
    
    const isInternal = loggedInUserData && loggedInUserData.userData && loggedInUserData.userData.internalUser;
    return isInternal ?? false;
}


export const HasWriteAccess = (userData: UserLoginDataType) => {
    var userDataState = userData as initialStateType;
    return userDataState && userDataState.userData && userDataState.userData.userPermissions && userDataState.userData.userPermissions.lwtWriteAccess;
}

export const HasReadAccess = (userData: UserLoginDataType) => {
    var userDataState = userData as initialStateType;
    return userDataState && userDataState.userData && userDataState.userData.userPermissions && userDataState.userData.userPermissions.lwtReadAccess;
}


export const GetUserPermissions  = (userData: UserLoginDataType) => {
    var userDataState = userData as initialStateType;
    return userDataState && userDataState.userData && userDataState.userData.userPermissions ;
}

export const HasDlwtSetupReadAccess  = (userData: UserLoginDataType) => {
    var userDataState = userData as initialStateType;
    return userDataState && userDataState.userData && userDataState.userData.userPermissions && userDataState.userData.userPermissions.dlwtSetupReadAccess;
}
