import React from "react";
import LearnerCard from "../../components/pearson/learnerCard";
import _ from "lodash";
import {
  learnerFilterType,
  LearnerLL,
  LearnerML,
  SaveLinkLearners
} from "../../models/learner";
import {
  FILTER_LEARNER,
  LL_RESP_CODE,
  LL_SAVE_ERRORS,
  REQUSEST_STATUS
} from "../../shared/app-enums";
import moment from "moment";
import SharedFileLearners from "../../models/shared-file";
import Loader from "../../components/common/loader/loader";
import { getSavedSearch } from "../../helpers/dashboard-request-helper";
import SavedSearch from "../../models/saved-search";
import config from "../../app-config";
import axios from "axios";

export const viewLearners = (
  manageLearnerDetails: any,
  learnersPerPageNumber: number,
  currentPageNumber: number,
  onCheckboxChange: (e: any) => void,
  currentSelectedLearners: number[] = []
) => {
  // alert(currentPageNumber)
  const sortedRequests = sortLearners(manageLearnerDetails);
  let isLearnerDisabled = false;

  let viewTeamRender = sortedRequests.map((learnerData: any, index: any) => {
    //if(!requests.isIseries && !requests.isUploaded){
    const startFromIndex = learnersPerPageNumber * (currentPageNumber - 1);
    const endIndex = startFromIndex + learnersPerPageNumber - 1;
    if (startFromIndex <= index && endIndex >= index) {
      isLearnerDisabled = isDisableLearner(learnerData);
      return (
        <div key={index + "s"}>
          <LearnerCard
            key={index}
            isChecked={isCheckedManageLearner(
              learnerData,
              currentSelectedLearners
            )}
            learner={learnerData}
            onCheckboxChange={onCheckboxChange}
            isDisabled={isLearnerDisabled}
          />
        </div>
      );
    }
    //}
  });
  return viewTeamRender;
};

export const sortLearners = (learnersData: LearnerML[] | LearnerLL[]) => {
  const sortedRequests = _.orderBy(learnersData, [
    learner => learner && learner.learnerCode
  ]);
  return sortedRequests;
};

export const getLinkedLearners = (
  sharedFileLearnersData: SharedFileLearners
) => {
  // console.log("sharedFileLearnersData=====",sharedFileLearnersData);
  return (
    sharedFileLearnersData &&
    sharedFileLearnersData.learners &&
    sharedFileLearnersData.learners
      .map(sfl => (sfl.isSharedFile ? sfl.learnerId : 0))
      .filter(Boolean)
  );
};

export const getAlllearnerIds = (learnersDataP: LearnerLL[]) => {
  const currentSelected: number[] = [];
  learnersDataP &&
    learnersDataP.forEach(l => {
      currentSelected.push(l.learnerId);
    });

  return currentSelected;
};

export const makeLLSaveFullFailureMsg = () => {
  let htmlText = "";
  htmlText +=
    "<div>The change cannot be saved for the chosen learner(s).</div>";
  htmlText += `<span class="scroller"  style="height: 110px;">
                  <div class="sub-heading-3">The possible reasons are:</div>
                    <ul>
                      <li>learner(s) has already been linked or unlinked from the file</li>
                      <li>file has already been removed</li>
                      <li>learner(s) entry has been withdrawn</li>
                    </ul>
                  </div>  
                </span>`;
  return htmlText;
};

export const getAllLateEntry = (LELearnersData: LearnerLL[]) => {
  const requestDetails = getSelectedReqDetails();
  if (requestDetails.statusId === REQUSEST_STATUS.IN_PROGRESS_C) {
    const learnerIdArray = LELearnersData.map(l =>
      l.isLateEntry &&
      l.learnerLateEntryDate &&
      moment(l.learnerLateEntryDate).isAfter(requestDetails.evidenceSubmittedOn)
        ? l.learnerId
        : 0
    );
    return learnerIdArray.filter(lId => lId !== 0);
  } else {
    return [];
  }
};

export const getAlllearnerIdsWithSearchCheck = (
  learnersDataP: LearnerLL[],
  savedLearnerDataP: LearnerLL[]
) => {
  const currentSelected: number[] = [];
  if (savedLearnerDataP && savedLearnerDataP.length) {
    savedLearnerDataP.forEach(sld => {
      currentSelected.push(sld.learnerId);
    });
  } else {
    learnersDataP &&
      learnersDataP.forEach(l => {
        currentSelected.push(l.learnerId);
      });
  }

  return currentSelected;
};

export function getLearnersFromIdList(
  learnerIdsList: number[],
  learnersDataP: LearnerLL[]
): LearnerLL[] {
  return (
    learnersDataP &&
    sortLearners(
      learnersDataP.filter(l => _.includes(learnerIdsList, l.learnerId))
    )
  );
}

export const makeSaveErrorLearnersList = (
  learners: { learnerCode: string; learnerName: string }[]
) => {
  let htmlText = "";
  if (learners.length) {
    learners.forEach(l => {
      htmlText += `
          <div>
            <span>${l.learnerCode}</span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span>${l.learnerName}</span>
          </div>`;
    });
  }
  return htmlText;
};

export const showSaveErrorPossibleReasons = (
  errorType: "addfailed" | "removefailed"
) => {
  let htmlText = "";
  htmlText += `
          <div class="sub-heading-3">The possible reason(s) are:</div>
            <ul>`;
  if (errorType === "addfailed") {
    htmlText += `
              <li>learner(s) has already been linked or unlinked from the file</li>
              <li>file has already been removed</li>
              <li>learner(s) entry has been withdrawn</li>`;
  }
  if (errorType === "removefailed") {
    htmlText += `
              <li>learner(s) has already been linked or unlinked from the file</li>
              <li>file has already been removed</li>
              <li>learner(s) entry has been withdrawn</li>`;
  }
  htmlText += `
            </ul>`;
  return htmlText;
};

export const isRequestSubmitted = (): boolean => {
  const reqStatusId = +(localStorage.getItem("VLReqStatusId") + "");
  return (
    reqStatusId === REQUSEST_STATUS.SUBMITTED ||
    reqStatusId === REQUSEST_STATUS.SUBMITTING
  );
};

export const showNoLearnerFoundMessage = (noLearnerMessage = "") => {
  return (
    //   <div style={{width:"80%",float:"left"}}>
    //     <br /><br />No learners can be found.&nbsp;&nbsp;
    //     <span className="more-link">Show All</span>
    // </div>
    <div style={{ width: "80%", margin: "20px 10px 250px 0px" }}>
      {noLearnerMessage}
      {/* {noLearnerMessage.length?
          <span 
            style={{marginLeft:"25px"}} 
            className="more-link" 
            onClick={handleShowAllLinkClick}
          >Show All</span>:<></>
        }           */}
    </div>
  );
};

export const extractLIds = (learners: LearnerLL[]): number[] =>
  learners &&
  learners.map(l => (l.isDisabled ? 0 : l.learnerId)).filter(l => l > 0);

export const getDBSelLearners = (learnersDataP: LearnerLL[]) => {
  let prevSelected: LearnerLL[] = [];
  learnersDataP &&
    learnersDataP.forEach(l => {
      if (l.isSharedFile) {
        prevSelected.push(l);
      }
    });
  return prevSelected;
};
export const makeErrorMessage = (
  saveResponse: SaveLinkLearners,
  errorType: "addfailed" | "removefailed"
) => {
  let htmlText = "";
  const errorStatus = getSavePartSuccessStatus(saveResponse);
  if (errorStatus === null) {
    return "";
  }
  if (errorType.length && errorType === "addfailed") {
    const learners = (saveResponse && saveResponse.addAbortedLearners) || [];
    if (learners.length) {
      // htmlText = '<p>The change has been successfully saved for some learners.</p>';
      htmlText += "<p>The following learner(s) cannot be linked:</p>";
      htmlText += makeSaveErrorLearnersList(learners);
      htmlText +=
        errorStatus === LL_SAVE_ERRORS.PART_SUCCESS_ADD_ONLY
          ? showSaveErrorPossibleReasons(errorType)
          : "";
    }
  }
  if (errorType.length && errorType === "removefailed") {
    const learners = (saveResponse && saveResponse.removeAbortedLearners) || [];
    if (learners.length) {
      // htmlText = errorStatus === LL_SAVE_ERRORS.PART_SUCCESS_REMOVE_ONLY ?'<p>The change has been successfully saved for some learners.</p>' : '';
      htmlText += "<p>The following learner(s) cannot be unlinked:</p>";
      htmlText += makeSaveErrorLearnersList(learners);
      htmlText +=
        errorStatus !== LL_SAVE_ERRORS.PART_SUCCESS_ADD_ONLY
          ? showSaveErrorPossibleReasons(errorType)
          : "";
    }
  }
  return htmlText;
};

export const getSavePartSuccessStatus = (saveResponse: SaveLinkLearners) => {
  let errorCount = 0;
  const errorStatus = checkLinkLearnerSaveError(saveResponse);
  if (errorStatus && errorStatus !== LL_SAVE_ERRORS.FULL_SUCCESS) {
    if (saveResponse.addAbortedLearners.length) {
      errorCount = 1;
    }
    if (saveResponse.removeAbortedLearners.length) {
      errorCount = errorCount + 2;
    }
  }
  switch (errorCount) {
    case 1:
      return LL_SAVE_ERRORS.PART_SUCCESS_ADD_ONLY;
    case 2:
      return LL_SAVE_ERRORS.PART_SUCCESS_REMOVE_ONLY;
    case 3:
      return LL_SAVE_ERRORS.PART_SUCCESS_ADD_REMOVE;
    default:
      return null;
  }
};

export const checkLinkLearnerSaveError = (saveResponse: SaveLinkLearners) => {
  const { hasError, responseCode } = saveResponse;
  if (hasError) {
    switch (responseCode) {
      case LL_RESP_CODE.FILE_DELETED:
        return LL_SAVE_ERRORS.FILE_DELETED;
      case LL_RESP_CODE.FULL_FAILURE:
        return LL_SAVE_ERRORS.FULL_FAILURE;
      case LL_RESP_CODE.SUCCESS:
        return LL_SAVE_ERRORS.PART_SUCCESS;
    }

    if (responseCode === 400 && saveResponse.responseText.includes("rejected")) {
      return LL_SAVE_ERRORS.FILE_REJECTED;
    }
  } else if (!hasError && responseCode === LL_RESP_CODE.SUCCESS) {
    return LL_SAVE_ERRORS.FULL_SUCCESS;
  }
};

export const isCheckedManageLearner = (
  learnerData: LearnerLL,
  currentSelectedLearners: number[]
) => {
  const requestDetails = getSelectedReqDetails();
  // console.log(requestDetails.statusId);
  if (requestDetails.statusId === REQUSEST_STATUS.IN_PROGRESS_C) {
    if (moment(requestDetails.returnedOn).isAfter(requestDetails.evidenceSubmittedOn)) {
      return _.includes(currentSelectedLearners,learnerData && learnerData.learnerId);
    } else {
      if (learnerData.isLateEntry) { 
        return (!moment(learnerData.learnerLateEntryDate).isAfter(requestDetails.evidenceSubmittedOn) && (learnerData.isSharedFile))?true:  _.includes(
          currentSelectedLearners,
          learnerData && learnerData.learnerId
        ); 
      }else{        
        return (learnerData.isSharedFile)? true : _.includes(
           currentSelectedLearners,
           learnerData && learnerData.learnerId
         );   
       }
    }
  } else {
    return _.includes(
      currentSelectedLearners,
      learnerData && learnerData.learnerId
      );
  }

  // // console.log("isCheckedManageLearner---",learnerData.code, _.includes(currentSelectedLearners, learnerData && learnerData.code))
  // const requestDetails = getSelectedReqDetails();
  // // console.log("requestDetails",!moment(learnerData.learnerLateEntryDate).isAfter(moment(requestDetails.evidenceSubmittedOn)));

  // if(requestDetails.statusId === REQUSEST_STATUS.IN_PROGRESS_C && learnerData.isLateEntry === false && learnerData.isSharedFile === true) {
  //   return true;
  // }else if(requestDetails.statusId === REQUSEST_STATUS.IN_PROGRESS_C && learnerData.isLateEntry === true && learnerData.isSharedFile === true && !moment(learnerData.learnerLateEntryDate).isAfter(moment(requestDetails.evidenceSubmittedOn))){
  //   return true;
  // }
  // else
  // return (_.includes(currentSelectedLearners, learnerData && learnerData.learnerId))
};

export const isSelectAllSearchResults = (
  newlySelectedLearners: number[],
  searchResultLearners: number[]
): boolean => {
  if (_.difference(searchResultLearners, newlySelectedLearners).length === 0) {
    return true;
  }
  return false;
};

export const setLocalStorageEmpySelected = (e: any) => {
  localStorage.setItem("makeSelectedLearnersEmpty", "false");
  e.preventDefault();
  // e.returnValue = "";  // This will show an alert box
};

export const getDBSelectedLearners = (learnersDataP: LearnerLL[] = []) => {
  let prevSelected: number[] = [];
  learnersDataP &&
    learnersDataP.forEach(l => {
      if (l.isSharedFile) {
        prevSelected.push(l.learnerId);
      }
    });
  // setDBSelLearnersCount(prevSelected.length);
  // console.log("dbLinkedCount::::",dbSelLearnersCount);
  return prevSelected;
};

export const getPreviousSelectedLearners = (
  learnersDataP: LearnerLL[] = []
) => {
  let prevSelected: number[] = getDBSelectedLearners(learnersDataP);

  if (localStorage.getItem("makeSelectedLearnersEmpty") === "true") {
    // Ignore all selected came with API response
    prevSelected = [];
  }
  return prevSelected;
};

export const getAllSelectableWithLE = (
  sharedFileLearnersData: SharedFileLearners
) => {
  const requestDetails = getSelectedReqDetails();
  const learnerIdArray = sharedFileLearnersData.learners.map(l => {
    // not late entry and not linked
    if (!l.isLateEntry && !l.learnerLateEntryDate && !l.isSharedFile) {
      return 0;
      // late entry but submitted and not linked
    } else if (
      l.isLateEntry &&
      !l.isSharedFile &&
      l.learnerLateEntryDate &&
      moment(requestDetails.evidenceSubmittedOn).isAfter(l.learnerLateEntryDate)
    ) {
      return 0;
    } else {
      return l.learnerId;
    }
  });
  return learnerIdArray.filter(lId => lId !== 0);
};

export const searchLearners = (
  learnerData: LearnerML[],
  searchOption: string,
  searchText: string
) => {
  let returnLearners: any;

  // learnerData && learnerData.length
  returnLearners = getGroupedRequests(learnerData, searchText);

  return returnLearners;
};

export const getGroupedRequests = (
  learnerData: LearnerML[],
  searchText: string
) => {
  let searchedLearners = searchOnProperties(learnerData, searchText);
  const sortedRequests = sortLearners(searchedLearners);
  return sortedRequests;
};

const searchOnProperties = (learnerData: LearnerML[], searchTerm: string) => {
  const fields = [
    "learnerCode",
    "learnerFirstName",
    "learnerLastName",
    "searchableDob"
  ];

  return _.filter(learnerData, request => {
    return _(request)
      .pick(fields)
      .some((searchstring: any) => {
        let result = false;
        result =
          _(searchstring)
            .toLower()
            .includes(searchTerm) ||
          _(searchstring)
            .toUpper()
            .includes(searchTerm) ||
          _(searchstring).includes(searchTerm);

        if (!result) {
          let heading = searchstring
            .toString()
            .toLowerCase()
            .split(" ");
          let search = searchTerm
            .toString()
            .toLowerCase()
            .split(" ");
          let intern = search.filter(word =>
            heading.filter((term: string) => term.includes(word))
          );
          result = Boolean(
            intern.find(word => {
              return _(searchstring)
                .toLower()
                .includes(word);
            })
          );
        }
        return result;
      });
  });
};

interface RequestDetails {
  statusId: number;
  evidenceSubmittedOn: string;
  returnedOn: string;
}
interface LearnerDetails {
  isLateEntry: boolean;
  learnerLateEntryDate: string;
}

export const isDisableLearner = (learnerDetails: LearnerLL) => {
  const requestDetails = getSelectedReqDetails();
  if (requestDetails.statusId === REQUSEST_STATUS.IN_PROGRESS_C) { 
    if (moment(requestDetails.returnedOn).isAfter(requestDetails.evidenceSubmittedOn)) { 
      return false;
    }else { 
      if (learnerDetails.isLateEntry) { 
      return  (!moment(learnerDetails.learnerLateEntryDate).isAfter(requestDetails.evidenceSubmittedOn))? true:false;
      }else{
        return true;
      }
    }
    
  }else{
    return false;
  }

  // if (requestDetails.statusId === REQUSEST_STATUS.IN_PROGRESS_C) {
  //   // MOCK MOCK MCOK
  //   // if( requestDetails.statusId === REQUSEST_STATUS.IN_PROGRESS) {
  //   // FIRST CHECK is req is in IN_PROGRESS_C status
  //   const isLateEntryLearner =
  //     learnerDetails.isLateEntry &&
  //     learnerDetails.learnerLateEntryDate &&
  //     moment(learnerDetails.learnerLateEntryDate).isAfter(
  //       requestDetails.evidenceSubmittedOn
  //     );

  //   // If return date is truthy and is before submit date,
  //   // req is SUBMIT, then RETURN, then again SUBMIT
  //   // and late entries came after last SUBMIT
  //   const isReturnAfterSubmit =
  //     requestDetails.returnedOn &&
  //     moment(requestDetails.evidenceSubmittedOn).isAfter(
  //       requestDetails.returnedOn
  //     );

  //   if (
  //     !isLateEntryLearner &&
  //     (isReturnAfterSubmit || !requestDetails.returnedOn)
  //   ) {
  //     // req is IN_PROGRESS_C after last SUBMIT, so late entries exists
  //     // but this learner is not a late entry. so disable
  //     return true;
  //   } else if (isLateEntryLearner && isReturnAfterSubmit) {
  //     //learner is late entry and req submitted
  //     // after return, so keep enabled
  //     return false;
  //   } else if (isLateEntryLearner && !requestDetails.returnedOn) {
  //     // return date is NULL
  //     //learner is late entry and req is submitted
  //     // but not returned, so keep enabled
  //     return false;
  //   }
  // }
  // return false; // req is not IN_PROGRESS_C, so all enabled
};

export const isDisableLearnerLinked = (learnerDetails: LearnerLL) => {
  const isLearnerDisabled = isDisableLearner(learnerDetails);
  return isLearnerDisabled && learnerDetails.isSharedFile;
};

export const getSelectedReqDetails = () =>
  JSON.parse(localStorage.getItem("VLReqData") || "{}");

export const getCurrentFilter = () => localStorage.getItem("LLFilter");
export const setCurrentFilter = (filterString: string) =>
  localStorage.setItem("LLFilter", filterString);

export const isReqHasLateEntryAfterSubmit = () => {
  const requestDetails = getSelectedReqDetails();
  if (requestDetails.statusId === REQUSEST_STATUS.IN_PROGRESS_C) {
    // MOCK MOCK MOCK
    // if( requestDetails.statusId === REQUSEST_STATUS.IN_PROGRESS) {
    // If return date is truthy and is before submit date,
    // req is SUBMIT, then RETURN, then again SUBMIT
    // and late entries came after last SUBMIT. OR no
    // return date, so never returned, but has late entries
    const isReturnAfterSubmit =
      requestDetails.returnedOn &&
      moment(requestDetails.evidenceSubmittedOn).isAfter(
        requestDetails.returnedOn
      );

    return isReturnAfterSubmit || !requestDetails.returnedOn;
  }
  return false; // req is not IN_PROGRESS_C, so no late entry
};

export const showLoader = () => {
  return (
    <div
      style={{
        width: "100%",
        clear: "both",
        display: "block",
        paddingTop: "50px"
      }}
    >
      <span>
        <Loader height="auto" timeoutSecs={4000} message={""} />
      </span>
    </div>
  );
};

export const processDisabledOriginalLearners = (
  currentSelectedLearnerIds: number[],
  sharedFileLearnersData: SharedFileLearners,
  learnersData: LearnerLL[]
) => {
  let processedCurrentSelected = [];
  const requestDetails = getSelectedReqDetails();
  if (requestDetails.statusId === REQUSEST_STATUS.IN_PROGRESS_C) {
    if (currentSelectedLearnerIds.length) {
      processedCurrentSelected = getAllSelectableWithLE(sharedFileLearnersData);
    } else {
      processedCurrentSelected = getDBSelectedLearners(learnersData);
      const onlyLE = getAllLateEntry(
        getLearnersFromIdList(processedCurrentSelected, learnersData)
      );
      processedCurrentSelected = _.difference(processedCurrentSelected, onlyLE);
    }
    return processedCurrentSelected;
  }
  return currentSelectedLearnerIds;
};

export const getAllocId = () => {
  const savedSearch = getSavedSearch() as SavedSearch;
  return +savedSearch.selectedAllocationId! || -99;
};

export const getFilteredList = (
  searchResult: LearnerLL[],
  currentFilter: learnerFilterType,
  currSelLIds: number[] = []
): LearnerLL[] => {
  let filteredList = [];
  // const currSelLIds = props.currentlySelectedLinkLearners || [];

  if (currentFilter === FILTER_LEARNER.ALL) {
    filteredList = JSON.parse(JSON.stringify(searchResult)); //deep clone
  } else if (currentFilter === FILTER_LEARNER.SEL) {
    searchResult.forEach(l => {
      if (currSelLIds.includes(l.learnerId) && !l.isSharedFile) {
        filteredList.push(l);
      }
    });
  } else if (currentFilter === FILTER_LEARNER.UNSEL) {
    searchResult.forEach(l => {
      if (!currSelLIds.includes(l.learnerId) && !l.isSharedFile) {
        filteredList.push(l);
      }
    });
  } else if (currentFilter === FILTER_LEARNER.LNKD) {
    searchResult.forEach(l => {
      if (l.isSharedFile) {
        filteredList.push(l);
      }
    });
  }
  return filteredList;
};

export const getLLSearchResult = (
  learnersList: LearnerLL[],
  searchTxt: string
) => {
  let searchRes: LearnerLL[] = searchLearners(learnersList, "", searchTxt);
  return searchRes;
};
export const getVersion = () => {
  return axios
    .get(`${config.API_GATEWAY.URL}/version`)
    .then(resp => {
      // console.log("MSF GET VERSION RESPONSE")
    })
    .catch(err => {
      console.log("MSF GET VERSION ERROR::", err);
    });
};
