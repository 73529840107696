import { actionTypes } from "../../../actions/action-types";
import { ModerationRequestsAction } from "./moderation-dashboard.actions";
import initialState from "../../../reducers/initialState";
import ModerationRequest, { ModerationRequestsSummary } from "../../../models/moderation-request";


export function ModerationRequestsReducer(
  state: ModerationRequest[] = initialState.moderationRequests,
  action: ModerationRequestsAction
): ModerationRequest[] {
  if (action.type === actionTypes.GET_MODERATION_REQUESTS_SUCCESS) {
    return action.moderationRequest;
  }
  else {
    return state;
  }

}

export function ModerationRequestsStatusReducer(
  state: number = initialState.moderationRequestStatusCode,
  action: ModerationRequestsAction
): number {
  if (action.type === actionTypes.GET_MODERATION_REQUESTS_SUCCESS) {
    return action.moderationRequestStatusCode;
  }
  else {
    return state;
  }

}

export function GetModerationRequestsSummaryReducer(
  state: ModerationRequestsSummary = initialState.moderationRequestsSummary,
  action: ModerationRequestsAction
): ModerationRequestsSummary {
  if (action.type === actionTypes.GET_MODERATION_REQUESTS_SUMMARY_SUCCESS) {
    return action.moderationRequestsSummaryData;
  } else {
    return state;
  }
}


export function ModerationRequestsSummaryStatusReducer(
  state: number = initialState.moderationRequestStatusCode,
  action: ModerationRequestsAction
): number {
  if (action.type === actionTypes.GET_MODERATION_REQUESTS_SUMMARY_SUCCESS) {
    return action.moderationRequestStatusCode;
  }
  else {
    return state;
  }
}
