import axios, { AxiosRequestConfig } from "axios";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import config from "../../../app-config";
import { Series } from "../../../models/series";
import { actionTypes } from "../../../actions/action-types";
import { StoreState } from "../../../store/store-state";
import { ConfigSearchResult, QualificationDetails } from "../../../models/config-models";
import { PQS } from "../../../shared/app-enums";


interface GetQualificationDetailsSuccessAction extends Action {
  type: actionTypes.CONFIG_GET_QUALIFICATION_SUCCESS;
  qualificationDetailsData: QualificationDetails[];
}


interface GetAssessmentDeadlinesSuccessAction extends Action {
  type: actionTypes.CONFIG_GET_ASSESSMENT_DEADLINES;
  assessmentDeadlineDetailsData: any;
}

interface GetSeriesAction extends Action {
  type: actionTypes.CONFIG_GET_SERIES_SUCCESS;
  assesssmentDeadlineConfigseries: Series[];
}

export type AssessmentDeadlineConfigAction =
  | GetQualificationDetailsSuccessAction
  | GetAssessmentDeadlinesSuccessAction
  | GetSeriesAction

type ThunkResult<R> = ThunkAction<R, StoreState, undefined, AssessmentDeadlineConfigAction>;

export const GetQualificationDetailsSuccessAction = (
  qualificationDetailsData: QualificationDetails[]
): GetQualificationDetailsSuccessAction => ({
  qualificationDetailsData,
  type: actionTypes.CONFIG_GET_QUALIFICATION_SUCCESS,
});

export const GetAssessmentDeadlinesSuccessAction = (
  assessmentDeadlineDetailsData: any
): GetAssessmentDeadlinesSuccessAction => ({
  assessmentDeadlineDetailsData,
  type: actionTypes.CONFIG_GET_ASSESSMENT_DEADLINES,
});

export const getSeriesSuccessAction = (
  assesssmentDeadlineConfigseries: Series[]
): GetSeriesAction => ({
  assesssmentDeadlineConfigseries,
  type: actionTypes.CONFIG_GET_SERIES_SUCCESS,
});


export const getQualificationDetails = (
  availabilityValue: string,
  availabilityWindowCode: string

): ThunkResult<Promise<void>> => {
  return (dispatch) => {
    const axiosConfig: AxiosRequestConfig = {
      headers: { "content-type": "application/json" },
    };
    return axios
      .post(
        `${config.API_GATEWAY.URL}/assessment-deadline-config/get-qualification-details`,
        JSON.stringify({ availabilityValue, availabilityWindowCode }),
        axiosConfig
      )
      .then((postResponse) => {
        const qualificationDetailsData = postResponse.data as QualificationDetails[];
        dispatch(GetQualificationDetailsSuccessAction(qualificationDetailsData));
      })
      .catch((error) => {

      });
  };
};




export const getAssessmentDeadlines = (
  availabilityWindowCode: string,
  availabilityValue: string,
  qualificationTypeCode: string,
  qualificationCode: string,
  unitSearchText: string,
  pageNumber: number,
  pageSize: number

): ThunkResult<Promise<void>> => {
  return (dispatch) => {
    const axiosConfig: AxiosRequestConfig = {
      headers: { "content-type": "application/json" },
    };
    return axios
      .post(
        `${config.API_GATEWAY.URL}/assessment-deadline-config/get-assessment-deadlines`,
        JSON.stringify({ availabilityValue, availabilityWindowCode, qualificationTypeCode, qualificationCode, unitSearchText, pageNumber, pageSize }),
        axiosConfig
      )
      .then((postResponse) => {
        const assessmentDeadlines = postResponse.data as ConfigSearchResult;
        dispatch(GetAssessmentDeadlinesSuccessAction(assessmentDeadlines));
      })
      .catch((error) => {
      });
  };
};

export const getSeriesDetails = (): ThunkResult<Promise<void>> => {
 
  return async ( dispatch) => {
    try {
      const axiosConfig: AxiosRequestConfig = {
        headers: { "content-type": "application/json" }
      };
      const sourcesystem = PQS;
      const response = await axios
        .post(
          `${config.API_GATEWAY.URL}/series`,
          JSON.stringify({
            sourcesystem
          }),
           axiosConfig
        )
        const seriesData = response.data as Series[];
        dispatch(getSeriesSuccessAction(seriesData));
    }
    catch (error) {
      // console.log("Get Evidence Search Result Action Error==>", error);
    // throw error;
    }
  };
};

/** Add Learner Material */