import * as React from "react";
// import * as _ from "lodash";

interface FooterLink {
  name: string;
  href: string;
}

interface FooterProps {
  theme?: "dark";
  links: FooterLink[];
}

export const Footer = ({ theme, links }: FooterProps) => (
  <footer className={theme} role="contentinfo">
    {links.length > 0 && (
      <ul className="links">
        {links.map((link, i) => {
          // Return the element. Also pass key

          return (
            <li key={i}>
              <a href={link.href} target="_blank" rel="noopener noreferrer">
                {link.name}
              </a>
            </li>
          );
        })}
      </ul>
    )}
    <p className="copyright">
      Copyright © 1996-{new Date().getFullYear()} Pearson Education Inc. All
      Rights Reserved.
    </p>
  </footer>
);

Footer.defaultProps = {
  theme: "light",
  className: "",
};

export default Footer;
