import React, { useEffect, FormEvent } from "react";
import { useHistory } from "react-router";
import config from '../../app-config'
import {
  getSavedSearch,
  updateSavedSearch,
  updateViewPapersSavedSearch
} from "../../helpers/dashboard-request-helper";
import {
  ToDoSearchOptions,
  InProgressSearchOptions,
  CompletedSearchOptions
} from "../../models/option";
import { useUserLoginState } from "../../shared/context-api/hooks";
import Loader from "../common/loader/loader";
import SavedSearch from "../../models/saved-search";
import { SubmitAllowed } from "../../models/submit-allocation";
import * as dbrdHelper from '../../helpers/dashboard-helper'
import "../dashboard/home.css";
import ModerationRequest from "../../models/moderation-request";

export interface ModRequestProps {  
  getModerationRequests: (
    learningProviderCode: string,
    status: number[],
    searchText: string
  ) => Promise<void>;
  moderationRequests?: ModerationRequest[];
  moderationRequestsSummary: any;
  submitAllocation: (
    allocationId: number,
    assessmentMethodType: number
  ) => Promise<void>;
  checkSubmitAllowed: (
    allocationId: number,
    assessmentMethodType: number
  ) => Promise<void>;
  submitAllowed: SubmitAllowed;
  getModerationRequestsSummary: (learningProviderCode: string) => Promise<void>;
}

const ModerationDashboard = (props: any) => {
  const { moderationRequestsSummary } = props;
  const tabModCountData = moderationRequestsSummary && moderationRequestsSummary.reply;
  const history = useHistory();
  const userLoginstate = useUserLoginState();

  const zerothLPId =
    (userLoginstate &&
      userLoginstate.userData &&
      userLoginstate.userData.associatedLearningProviders &&
      userLoginstate.userData.associatedLearningProviders[0] &&
      userLoginstate.userData.associatedLearningProviders[0]
        .learningProviderCode) ||
    "";

  const learningCenter =
    (userLoginstate &&
      userLoginstate.userData &&
      userLoginstate.userData.associatedLearningProviders) ||
    [];

  let centerOptions: any = [{ value: "Select a Learning Provider", key: "" }];
  
  learningCenter.forEach(function(center) {
    let learningProvider: any = center;
    const centerObj = Object.assign({
      key: center && learningProvider.learningProviderCode,
      value: learningProvider && learningProvider.learningProviderName
    });
    centerOptions.push(centerObj);
  });
  const savedSearch = getSavedSearch() as SavedSearch;
  const getCenterNameStorage =  localStorage.getItem("centerName");
  const lsCenterName = getCenterNameStorage && getCenterNameStorage.substring(0, getCenterNameStorage.indexOf(' '));
  const [centerCode, setCenterCode] = React.useState(lsCenterName?lsCenterName:"");

  const [savedSearchText, setSaveSearchText] = React.useState(savedSearch.searchText?savedSearch.searchText:"");
  const [savedSearchOption, setSaveSearchOption] = React.useState(savedSearch.searchFilter?savedSearch.searchFilter:"");
  const [refreshInterval] = React.useState(config.refreshTime || 0);
  const [savedSearchSelectedAllocId, setsavedSearchSelectedAllocId] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [rerender, setReRender] = React.useState(1);
  
  const isSubmitAllowed =
    props && props.submitAllowed && props.submitAllowed.isSubmitAllowed
      ? props.submitAllowed.isSubmitAllowed
      : false;

  let tabStatusCount = React.useMemo(() => {
    return dbrdHelper.getTabStatusCount(tabModCountData,'Moderation');
  }, [tabModCountData]);

  useEffect (() => {
      let t = document.getElementById("tab");
      let w = t && t.shadowRoot && t.shadowRoot.querySelector("#tabs-wrapper");
      if(!w) {
        setReRender(Math.round(Date.now()/1000));
        return;        
      }
      setReRender(1);
      w?.addEventListener("click", (e:any)=>{
        e.preventDefault();
        if( e.target.querySelector('button[id^="tab-"]') ){
          e.stopPropagation();
        }
      })
  }, [rerender])

  useEffect(() => { 
    // coming back from view learners, retain last tab clicked
    let savedSearch = getSavedSearch() as SavedSearch;
    dbrdHelper.setCurrentAssessMethod("moderation");
    if (
      savedSearch &&
      savedSearch.selectedTabIndex &&
      savedSearch.selectedTabIndex.length
    ) {
      tabStatusCount = parseInt(savedSearch.selectedTabIndex + "");
    }
    let tab: any = document.querySelector("pearson-tabs");
    if (tab && tab.setAttribute) {
      tab.setAttribute("activeidx", tabStatusCount);
    }
  }, [tabStatusCount]);

  useEffect(() => {
    setLoading(false);
    let tab: any = document.querySelector("pearson-tabs");
    if (tab && tab.setAttribute) {
      tab.setAttribute(
        "activeidx",
        parseInt(savedSearch.selectedTabIndex + "")
      );
      window.scrollTo(
        0,
        parseInt(localStorage.getItem("scrollPosition") || "0")
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.moderationRequests]);

  useEffect(() => { 
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(getRefreshData, refreshInterval);
      return () => clearInterval(interval);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshInterval]);

  const updateSaveSearchText = (searchText:string) => {
    setSaveSearchText(searchText + "");
  }
  
  const updateSaveSearchOption = (searchFilter:string) => {
    setSaveSearchOption(searchFilter + "");
  }

  const updateCenterCode = (learningProviderId:string) => {
    setCenterCode(learningProviderId);
  }
  const updateLoading = (isLoading: boolean) => {
    setLoading(isLoading)
  }

  const updatesavedSearchSelectedAllocId = (allocId:string) => {
    setsavedSearchSelectedAllocId(allocId);
  }

  const getSearchBarProps = () => {
    return {
      searchOption:
        savedSearchOption.length ? savedSearchOption : "All",
      searchText:savedSearchText,
      onTextChange:onModTextChange,
      onOptionChange:onModOptionChange,
      onSearchUpdate:onModSearchUpdate
    }
  }

  const getViewRequestsProps = () => {
    return {
      requests,
      navigateToViewLearners,
      navigateToViewAll,
      submitRequest,
      checkSubmitAllowed,
      isSubmitAllowed
    }
  }
    
 const getRefreshData = () => {
   dbrdHelper.doDataRefresh(
     updateSaveSearchText,
     updateSaveSearchOption,
     zerothLPId,
     props,
     lsCenterName + ""
   );
 };

  const onModCenterChange = (event: any) => {
    dbrdHelper.setCurrentAssessMethod("examination")
    dbrdHelper.handleCenterChange(
      event,
      props,
      updateSaveSearchText,
      updateSaveSearchOption,
      updateCenterCode,
      updateLoading,
      learningCenter
    );
    history && history.push("/")
  };

  const onSearchModToDo = (e: any, searchOption: string, searchText: string) => {
    dbrdHelper.handleSearch(updateLoading, onModSearchUpdate, savedSearch, props, 'ToDo', searchText);
  }

  const onSearchModProgress = (e: any, searchOption: string, searchText: string) => {
    dbrdHelper.handleSearch(updateLoading, onModSearchUpdate, savedSearch, props, searchOption, searchText);
  }

  const onSearchModCompleted = (e: any, searchOption: string, searchText: string) => {
    dbrdHelper.handleSearch(updateLoading, onModSearchUpdate, savedSearch, props, 'Completed', searchText);
  }  

  const onModTextChange = (event: FormEvent<HTMLInputElement>) => {
    const stxt =
      event && event.target && (event.target as HTMLInputElement).value;
    setSaveSearchText(stxt);
  };

  const onModOptionChange = (opt: string = "") => {
    setSaveSearchOption(opt);
  };

  const onModSearchUpdate = (searchOption: string, searchText: string) => {
    setSaveSearchOption(searchOption);
    setSaveSearchText(searchText);
    updateSavedSearch({
      selectedAllocationId: "",
      searchFilter: searchOption,
      searchText
    });
  };

  const navigateToViewLearners = (selectedAllocationId: string) => {
    let obj = {
      searchText: savedSearchText,
      searchFilter: savedSearchOption,
      selectedAllocationId
    };
    updateSavedSearch(obj);
    history.push("/view-learner");
  }; 
  
  const navigateToViewAll = (
    e: any,
    qualificationTypeCode: string,
    seriesCode: string,
    qualificationTypeName: string
  ) => {
    const learningProviderId = savedSearch && savedSearch.learningProviderId;

    if (learningProviderId) {
      let obj = {
        searchText: savedSearchText,
        searchFilter: savedSearchOption,
        selectedTabIndex: savedSearch && savedSearch.selectedTabIndex,
        qualificationTypeCode: qualificationTypeCode,
        seriesCode: seriesCode,
        learningProviderId: learningProviderId,
        qualificationTypeName: qualificationTypeName
      }; 

      updateViewPapersSavedSearch(obj);
      history.push("/view-papers");
    }
  };

  const submitRequest = (allocationId: number) => {
    const assessmentMethodType = 2;
    props.submitAllocation(allocationId, assessmentMethodType);
  };

  const checkSubmitAllowed = (allocationId: number) => {
    const assessmentMethodType = 2;
    props.checkSubmitAllowed(allocationId, assessmentMethodType);
  };

  const preventTabBodyClick = (e: any) => {
    e.stopPropagation();
  };

  const showExaminationDashboard = (e:any) => {
    tabClickHandler();
    dbrdHelper.setCurrentAssessMethod("examination");
    history.push("/");
  }
  
  const tabClickHandler = () => {    
    const tab = document.querySelector("pearson-tabs");
    let activeTabIndex = tab && tab.getAttribute("activeidx");
    let savedSearch = getSavedSearch() as SavedSearch;
    const learningProviderId = savedSearch && savedSearch.learningProviderId;

    // DO NOT LET DOUBLE CLICK HAPPEN
    if(activeTabIndex===savedSearch.selectedTabIndex){
      return;
    }    

    dbrdHelper.handleTabClick(
     updateLoading,
     updateSaveSearchText,
     updateSaveSearchOption,
     updatesavedSearchSelectedAllocId,
     props, 
     activeTabIndex+"",
     savedSearch,
     learningProviderId+"",
     savedSearchSelectedAllocId
    )
  }

  const lpID = savedSearch.learningProviderId;
  const requests = props.moderationRequests;
  return (
    <div className="tabContainer">
      {dbrdHelper.showCenterDropdown(
        learningCenter,
        centerOptions,
        centerCode,
        onModCenterChange
      )}
      {lpID && lpID !== "" && (
        <pearson-tabs
          id="tab"
          style={{ backgroundColor: "#f5f5f5" }}
          onClick={tabClickHandler}
        >
          <dbrdHelper.ShowTopTabs />

          <div className="panels" slot="panels" onClick={preventTabBodyClick}>
            <div data-panel key="0" id="preventTab1">
              {" "}
              <div className="gr-grid-container ie-flex card-container-row">
                {dbrdHelper.showTabReqFilterByMethod(
                  'todo',
                  tabModCountData && tabModCountData.examination,
                  'Examination',
                  false,
                  showExaminationDashboard
                  )}
                  {dbrdHelper.showTabReqFilterByMethod(
                    'todo',
                    tabModCountData && tabModCountData.moderation ,
                    'Moderation',
                    true
                  )}
                </div>
                {dbrdHelper.showTabSearchBar(
                  ToDoSearchOptions, 
                  onSearchModToDo, 
                  getSearchBarProps
                )}
              {!loading ? (
                <div>
                  {dbrdHelper.showTabRequests("toDo", getViewRequestsProps)}
                </div>
              ) : (
                <Loader  />
              )}
            </div>
            <div data-panel key="1" id="preventTab2" onClick={preventTabBodyClick}>
              <div className="gr-grid-container ie-flex card-container-row">
                {dbrdHelper.showTabReqFilterByMethod(
                  'inprogress',
                  tabModCountData && tabModCountData.examination,
                  'Examination',
                  false,
                  showExaminationDashboard
                )}
                {dbrdHelper.showTabReqFilterByMethod(
                    'inprogress',
                    tabModCountData && tabModCountData.moderation,
                    'Moderation',
                    true
                )}
              </div>            
              {dbrdHelper.showTabSearchBar(
                InProgressSearchOptions, 
                onSearchModProgress, 
                getSearchBarProps
              )}            
              {!loading ? (
                <div>
                  {dbrdHelper.showTabRequests("inProgress", getViewRequestsProps)}
                </div>
              ) : (
                <Loader   />
              )}
            </div>
            <div data-panel key="2" id="preventTab3" onClick={preventTabBodyClick}>       
              <div className="gr-grid-container ie-flex card-container-row">
                {dbrdHelper.showTabReqFilterByMethod(
                  'completed',
                  tabModCountData && tabModCountData.examination,
                  'Examination',
                  false,
                  showExaminationDashboard
                )}
                {dbrdHelper.showTabReqFilterByMethod(
                  'completed',
                  tabModCountData && tabModCountData.moderation,
                  'Moderation',
                  true
                )}
              </div>
              {dbrdHelper.showTabSearchBar(
                CompletedSearchOptions, 
                onSearchModCompleted, 
                getSearchBarProps
              )}
              {!loading ? (
                <div>
                  {dbrdHelper.showTabRequests("completed", getViewRequestsProps)}
                </div>
              ) : (
                <Loader/>
              )}
            </div>
          </div>
        </pearson-tabs>
      )}
    </div>
  );
};

export default ModerationDashboard;
