export const AVATS_STATUS_INFECTED = 'INFECTED';
export const AVATS_STATUS_REJECTED = 'REJECTED';
export const AVATS_STATUS_INFECTED_TEXT = 'This file is infected with a virus and must be removed.';
export const AVATS_STATUS_INFECTED_ZIP_TEXT = 'Within this compression file, [X] file(s) are infected with a virus.  The compression file must be removed and the problem files replaced with versions that will pass security and virus checks.'

export const AVATS_STATUS_PASSWORD_PROTECTED = 'PASSWORD PROTECTED';
export const AVATS_STATUS_PASSWORD_PROTECTED_TEXT = 'This file is password protected and must be removed.';
export const AVATS_STATUS_PASSWORD_PROTECTED_ZIP_TEXT = 'One or more files within this compression file, are password protected and must be removed.';

export const AVATS_STATUS_UNSUPPORTED = 'UNSUPPORTED';
export const AVATS_STATUS_UNSUPPORTED_TEXT = 'This is an unsupported file format and must be removed.';

export const AVATS_STATUS_DEFAULT_TEXT = 'There was a unexpected error, the file cannot be uploaded and must be removed.';

export const TEAM_LEADER_TOTAL_PAGE_COUNT = 9;
export const AVATS_STATUS_FILE_EXISTS = 'FILE NAME ALREADY EXIST IN PROCESSED STORE!';
export const AVATS_STATUS_FILE_EXISTS_TEXT = 'This file cannot be uploaded and must be renamed.  A file with this name is already uploaded.';

