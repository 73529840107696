import { actionTypes } from "../../../actions/action-types";
import { ViewLearnersAction } from "./view-learner.actions";
import initialState from "../../../reducers/initialState";
import { LearnersList } from "../../../models/learner";
import LearnerMaterial from "../../../models/learner-material";
import AdminMaterial, { AdminLearnerSummary, AdminSharedMaterialSummary } from "../../../models/admin-material";


export function GetViewLearnersDetailsReducer(
  state: LearnersList = initialState.viewLearnersDetails,
  action: ViewLearnersAction | any
): LearnersList {
  if (action.type === actionTypes.GET_VIEW_LEARNER_DETAILS_SUCCESS) {
    if (action.viewLearnersDetailsData.totalRecords > 10) {
      localStorage.setItem("vlpaginationData", "true")
    }
    return action.viewLearnersDetailsData;
  } else {
    return state;
  }
}

export function UpdateLearnerStatusReducer(
  state: any = initialState.viewLearnersDetails,
  action: ViewLearnersAction | any
): LearnersList {
  if (action.type === actionTypes.UPDATE_LEARNER_STATUS_SUCCESS) {
    return action.learnerStatusData;
  } else {
    return state;
  }
}

export function AddRemoveLearnerMaterialReducer(
  state: LearnerMaterial = initialState.learnerMaterial,
  action: ViewLearnersAction | any
): LearnerMaterial {
  if (
    action.type === actionTypes.REMOVE_LEARNER_MATERIAL_SUCCESS ||
    action.type === actionTypes.ADD_LEARNER_MATERIAL_SUCCESS
  ) {
    localStorage.setItem("isAddUpload", "true")
    return action.learnerMaterialData;
  } else {
    return state;
  }
}

export function AddRemoveAdminMaterialReducer(
  state: AdminMaterial = initialState.adminMaterial,
  action: ViewLearnersAction | any
): AdminMaterial {
  if (
    action.type === actionTypes.REMOVE_ADMIN_MATERIAL_SUCCESS ||
    action.type === actionTypes.ADD_ADMIN_MATERIAL_SUCCESS
  ) {
    localStorage.setItem("isAddUploadAdminMatrial", "true")
    return action.adminMaterialData;
  } else {
    return state;
  }
}

export function DownloadAdminMaterialReducer(
  state: string = initialState.downloadAdminMaterial,
  action: ViewLearnersAction | any
): string {
  if (
    action.type === actionTypes.DOWNLOAD_ADMIN_MATERIAL_SUCCESS
  ) {
    return action.downloadAdminMaterialData;
  } else {
    return state;
  }
}


export function GetAdminMaterialSummaryReducer(
  state: AdminSharedMaterialSummary = initialState.adminSharedMaterial,
  action: ViewLearnersAction | any
): AdminSharedMaterialSummary {
  if (action.type === actionTypes.GET_ADMIN_LEARNER_SUMMARY_SUCCESS) {
    return action.getAdminLearnerData;
  } else {
    return state;
  }
}
