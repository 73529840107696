import { connect } from 'react-redux';
import { StoreState } from "../../store/store-state";
import { ThunkDispatch } from 'redux-thunk';
import GetData from './get-data.component';
import { getExamRequests, ExamRequestsAction, getExamRequestsSummary } from "../dashboard/ducks/dashboard.actions";
import { getModerationRequests, ModerationRequestsAction, getModerationRequestsSummary } from "../moderation/ducks/moderation-dashboard.actions";
import { ExamRequestsSummary } from '../../models/exam-request';
import { ModerationRequestsSummary } from '../../models/moderation-request';

export interface DispatchProps {
  getExamRequests: (learningProviderCode: string, status : number[], searchText : string) => Promise<void>;
  getExamRequestsSummary: (learningProviderCode: string) => Promise<void>;
  getModerationRequests: (
    learningProviderCode: string,
    status: number[],
    searchText: string
  ) => Promise<void>;
  getModerationRequestsSummary: (learningProviderCode: string) => Promise<void>;
}

export interface StateProps {
  examRequestsSummary: ExamRequestsSummary;
  moderationRequestsSummary: ModerationRequestsSummary
}

const mapStateToProps = (state: StoreState): StateProps => {
  return {
    examRequestsSummary: state.examRequestsSummary,
    moderationRequestsSummary:state.moderationRequestsSummary
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    StoreState,
    void,
    (ExamRequestsAction|ModerationRequestsAction)
  >
): DispatchProps => ({
  getExamRequests: (learningProviderCode: string, status : number[], searchText : string) =>
   dispatch(getExamRequests(learningProviderCode, status, searchText)),
  getExamRequestsSummary: (learningProviderCode: string) =>
   dispatch(getExamRequestsSummary(learningProviderCode)),
   getModerationRequests: (learningProviderCode: string, status : number[], searchText : string) =>
   dispatch(getModerationRequests(learningProviderCode, status, searchText)),
   getModerationRequestsSummary: (learningProviderCode: string) =>
   dispatch(getModerationRequestsSummary(learningProviderCode))

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GetData);