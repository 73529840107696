import * as React from "react";

interface ButtonProps {
  label: string;
  className?: "" | "primary" | "attention";
  type?: "submit" | "reset" | "button";
  value?: string;
  errors?: string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  customClass?: string
}

export const Button = ({
  label,
  className,
  type,
  disabled,
  onClick,
  customClass
}: ButtonProps) => (
  <button
    className={`gr-btn ${className} ${customClass}`}
    type={type}
    disabled={disabled}
    // {...(disabled && disabled=== true && { disabled: true })}      
    onClick={onClick}>
    {label}
  </button>
);

Button.defaultProps = {
  className: "",
  type: "button",
  disabled: false,
};

export default Button;