export class FileDetails {
  fileKey: string;
  fileName: string;
  fileRejectedReason: string;
  fileSize: string;
  fileStatusCode: number;
  fileStatusTitle: string;
  isFileSeen: boolean;
  transformedFileKey: string;
  transformedFileName: string;
  transformedFileSize: number;
}
export default class LearnerMaterial {
  allocationId: number;
  statusId: number;
  status:string;
  responseCode:number;
  responseText: string;
}

export class LearnerCount {
  accepted: string;
  allLearners: string;
  rejected: string;
  scanning: string;
}