import React, { useEffect, useState } from "react";
import "./maintenance.css";
import { ReactComponent as PearsonLogoSvg } from './pearson-logo.svg';
import {logout} from '../../actions/auth/auth'

const timeOutSeconds = 180;

const Maintenance =  () => {
    document.title = "Maintenance - Pearson";
    const [countdown, setCountdown] = useState(timeOutSeconds) 
    
    const head = document.getElementsByClassName("header") as HTMLCollectionOf<HTMLElement>;   
    if(head[0]){
        head[0].style.display='none';
    }

    useEffect(() => {
        if (!countdown) {
             logout();
             return;           
        }
        const intervalId = setInterval(() => {
            setCountdown(countdown - 1);
          }, 1000);

        return () => {
            clearInterval(intervalId);
        }
    }, [countdown])
    
    return (
        <div className="maintenance-container">
        <div className="maintenance-left-content">
            <div className="logo-svg">
            <div style={{ display: "block" }}>
                <PearsonLogoSvg />
            </div>
            </div>
            <div className="error-template">
                <h1>Temporarily down for maintenance</h1>            
                <h2>We’ll be back soon!</h2>
            <div className="text-props">
                <br /><br />
                <h4>
                Sorry for the inconvenience but we’re performing some 
                maintenance at the moment. We’ll be back online shortly!
                </h4>
            </div>
            </div>
        </div>
        <div className="maintenance-right-pattern">
        <div className="maintenance-logout" onClick={() => logout()}>
            Logout            
            <div className="maintenance-countdown" />
          </div>

            <svg
            xmlns="http://www.w3.org/2000/svg"
            className="svg-box"
            width="380"
            height="500"
            viewBox="0 0 837 1045"
            >
            <g fill="none">
                <path
                d="M353 9L626.7 170 626.7 487 353 642 79.3 487 79.3 170 353 9Z"
                style={{ strokeWidth: "6", stroke: "#3bafda" }}
                />
                <path
                d="M78.5 529L147 569.2 147 648.3 78.5 687 10 648.3 10 569.2 78.5 529Z"
                style={{ strokeWidth: "6", stroke: "#7266ba" }}
                />
                <path
                d="M773 186L827 217.5 827 279.6 773 310 719 279.6 719 217.5 773 186Z"
                style={{ strokeWidth: "6", stroke: "#f76397" }}
                />
                <path
                d="M639 529L773 607.8 773 763.1 639 839 505 763.1 505 607.8 639 529Z"
                style={{ strokeWidth: "6", stroke: "#00b19d" }}
                />
                <path
                d="M281 801L383 861 383 979.2 281 1037 179 979.2 179 861 281 801Z"
                style={{ strokeWidth: "6", stroke: "#fa0" }}
                />
            </g>
            </svg>
        </div>
        </div>
    );
};

export default Maintenance;
