import * as React from "react";
import { FormEvent, CSSProperties } from "react";

interface InputProps {
  id: string;
  type: "text" | "file";
  label?: string;
  placeholder?: string;
  value?: string;
  errors?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  maxLength: number;
  style?: CSSProperties;
  onChange?: (event: FormEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: FormEvent<HTMLInputElement>) => void;
}

const Input = ({
  id,
  type,
  label,
  placeholder,
  value,
  errors,
  disabled,
  autoFocus,
  maxLength,
  style,
  onChange,
  onKeyDown
}: InputProps) => {
  
  return (
    <div className="gr-form-element">
      {label && (
        <label className="gr-label" htmlFor={id}>
          {label}
        </label>
      )}
      <input
        id={id}
        type={type}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        autoFocus={autoFocus}
        {...(maxLength && { maxLength: maxLength })}
        className={`gr-input ${errors ? "error" : ""}`}
        style={style}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onClick={(e:any)=>{e.stopPropagation();}}
      />
      <div className="error-state">
        <svg focusable="false" className="icon-18" aria-hidden="true">
          <use xmlnsXlink="http://www.w3.org/1999/xlink" href="#warning-18" />
        </svg>
        <span className="gr-meta">{errors}</span>
      </div>
    </div>
  );
};

Input.defaultProps = {
  type: "text",
  autoFocus: false,
  maxLength: null,
  style: undefined,
};

export default Input;
