import FileDetails from "./file-details";
export default class AdminMaterial{
    allocationId: number;
    statusId: number;
    status:string;
    responseCode:number;
    responseText: string;
}
export class AdminSharedMaterialSummary {
    allocationId: number;
    lpAdminMaterial: FileDetails[];
    learnerSharedMaterial: FileDetails[];
}

export class AdminLearnerSummary{
    allocationId: number;
    lpAdminMaterial: FileDetails[];
    learnerSharedMaterial: FileDetails[];
}