 import cryptoBrowserify from "crypto-browserify";
//  import crypto from "crypto";
import { params } from "./auth-params";
import axios from "axios";
import { default as jwt_decode } from "jwt-decode";
import { auditUserLogout } from "../../helpers/user-audit-helper";
// import { decode } from "punycode";
// import * as toastr from "toastr";
// import { ErrorMessages } from "../../models/constants";
// import EventBus from "eventing-bus";

const SCOPE = "email+openid+profile";

const base64URLEncode = (str: any) => {
  return str
    .toString("base64")
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");
};

const sha256 = (buffer: any) => {
  return cryptoBrowserify.createHash("sha256").update(buffer).digest();
  
};

const getNewVerifier = () => {
  const newVerifier = base64URLEncode(cryptoBrowserify.randomBytes(32));
  return newVerifier;
};
const baseUrl = params.baseUrl;
const clientId = params.client_id;
const redirectUri = params.redirect_uri;
const tokenUrl = params.token;

export const logoutUrl = `${baseUrl}/logout?client_id=${clientId}&redirect_uri=${redirectUri}`;

export const refreshTokens = async (refreshToken: any) => {
  const body = `grant_type=refresh_token&client_id=${clientId}&refresh_token=${refreshToken}`;
  return axios
    .post(tokenUrl, body, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
    .then((response: any) => {
      const { access_token, id_token, expires_in } = response.data;
      window.localStorage.setItem("accessToken", access_token);
      window.localStorage.setItem("idToken", id_token);
      setExpiryTime(expires_in);
    });
};

export const getAuthCode = async () => {
  const verifier = getNewVerifier();
  // const verifier = 'gWQhrQ79apikhTUgLgCpRIOt4uj2RCBrxie0Vm9Ej_0';
  window.sessionStorage.setItem("verifier", verifier);
  const challenge = base64URLEncode(sha256(verifier));
  // if(!window.sessionStorage.getItem("verifier")) {
  window.location.replace(
    `${baseUrl}/oauth2/authorize?scope=${SCOPE}&response_type=code&client_id=${clientId}&code_challenge=${challenge}&code_challenge_method=S256&redirect_uri=${redirectUri}`
  );
  // }
};

export const fetchToken = (code: string): Promise<any> => {
  const verifier = window.sessionStorage.getItem("verifier");
  // const verifier = 'gWQhrQ79apikhTUgLgCpRIOt4uj2RCBrxie0Vm9Ej_0';
  const body = `grant_type=authorization_code&client_id=${clientId}&code_verifier=${verifier}&code=${code}&redirect_uri=${redirectUri}`;
  return new Promise((resolve, reject) => {
    axios
      .post(tokenUrl, body, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
      .then((response: any) => {
        window.sessionStorage.removeItem("verifier");
        const {
          access_token,
          id_token,
          refresh_token,
          expires_in,
        } = response.data;
        window.localStorage.setItem("accessToken", access_token);
        window.localStorage.setItem("idToken", id_token);
        window.localStorage.setItem("refreshToken", refresh_token);
        let decoded = jwt_decode(id_token);
        localStorage.setItem("payload", JSON.stringify(decoded));
        // console.log("profileDataSet", decoded);
        setExpiryTime(expires_in);
        resolve(decoded);
      });
  });
};

const setExpiryTime = (expires_in: number) => {
  const expiresAt = new Date().getTime() + (expires_in - 300) * 1000;
  window.localStorage.setItem("tokenExpires", expiresAt.toString());
};

export const isAuthenticated = () => {
  const expiresAt = localStorage.getItem("tokenExpires");
  if (expiresAt) return new Date().getTime() < parseInt(expiresAt);
  return false;
};

export const getToken = () => {
  return localStorage.getItem("idToken");
};

export const handleAuthentication = () => {
  return new Promise((resolve) => {
    if (isAuthenticated()) {
      resolve({});
    } else {
      const refresh_token = localStorage.getItem("refreshToken");
      if (refresh_token) {
        refreshTokens(refresh_token)
          .then((response) => {
            resolve({});
          })
          .catch(() => {
            // toastr.error(ErrorMessages.SESSION_TIMEOUT, undefined, {
            //   onHidden: () => { logout() }
            logout();
            // });
          });
      } else {
        // getAuthCode();
      }
    }
  });
};

export const getProfile = () => {
  const profile = localStorage.getItem("payload");
  if (!profile) {
    return new Error("No profile found");
  }
  return JSON.parse(profile);
};

export const auditedLogout = () => {
    auditUserLogout()
    .finally(() => {
        logout();
       })
  
  };

export const logout = () => {
  let endSessionEndpoint = window.location.origin;

  // Clear localStorage apart from awsUploads key (used by EvaporateJS to handle resuming partial uploads)
  var awsUploads = localStorage.getItem('awsUploads');
  localStorage.clear();
  if (awsUploads) {
    localStorage.setItem('awsUploads', awsUploads);
  }

  sessionStorage.clear();
  window.location.replace(
    `${params.sessionEndEndpoint}?client_id=${params.client_id}&redirect_uri=${endSessionEndpoint}/callback&response_type=code`
  );


};

export const getTokens = () => {
  return {
    access_token: window.localStorage.getItem("accessToken"),
    id_token: window.localStorage.getItem("idToken"),
  };
};
export const logoutWithRefresh = () => {
  sessionStorage.setItem('hasTologout', "true");
  const refresh_token = localStorage.getItem("refreshToken");
  refreshTokens(refresh_token)
  .then(() => {
    getAuthCode();
  })
};
