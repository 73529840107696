import React, { useState } from "react";

const MSFGuidance = () => { 
  const [showFullGuidanceMsg, setShowFullGuidanceMsg] = useState(false)

  const foldGuidanceMsg = () => {
    setShowFullGuidanceMsg(!showFullGuidanceMsg);
  }

  return (
    <div className="guidance-msg" style={{width:"70%"}}>
        <div>      
            <br />This screen shows files that can be shared between learners in a 'Present' state. 
            <br />Select a file to see the available learners and choose which learner(s) to link to or unlink from this file.
            {!showFullGuidanceMsg && 
              <>&nbsp;&nbsp;<span className="more-link" onClick={foldGuidanceMsg}>More...</span></>
            }
            <br/>
        </div>
        <div 
        style={{display:showFullGuidanceMsg?"block":"none"}}
        >  
            If a different file is selected before any linking and/or unlinking is saved, the changes are not retained. 
            <br />
            <br />The following will not be shown:<br/>
            <ul>
                <li>Files in a ‘Scanning’ or ‘Rejected’ state</li>
                <li>Learners who are flagged as ‘Absent’ or ‘Not providing evidence’</li>
            </ul>
            Other actions might also impact which files and/or learners are shown.
            {showFullGuidanceMsg && 
              <>&nbsp;&nbsp;<span className="more-link" onClick={foldGuidanceMsg}>...Less</span> </>
            }
        </div>  
    </div>
  );
};

export default MSFGuidance;
