import * as React from "react";
// import * as _ from 'lodash';
import Modal from "react-bootstrap/Modal";
import Button from "./button";
import "./modal.css";
interface ModalProps {
    title?:string;
    modalData: string;
  show: boolean;
  isSetupModal?: boolean;
  hideModalData?: boolean;
  onCancel: () => void;
  onConfirm?: () => void;
  isConfirmBut?:boolean;
  styleClass?:string ;
}


const LogOutModal = ({
    title,
    modalData,
  show,
  onCancel,
  onConfirm,
  isSetupModal,
  isConfirmBut,
  styleClass,
  hideModalData
}: ModalProps) => {


  return (
    <div className="re-open-appeal-modal">
      <Modal
        show={show}
        size="lg"
        onHide={onCancel}
        dialogClassName={styleClass}
        aria-labelledby="logout confirm">
        <Modal.Header closeButton>
          <Modal.Title><h3>{!hideModalData && modalData} {title}</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="outcome">
            
            {(modalData === 'Log Out' && !isSetupModal) && (
            <div>
            <p>Files are still being uploaded.  The current action will terminate the upload.</p>       
            Do you want to continue?       
            </div>
            )}
            {(modalData === 'Log Out' && isSetupModal) && (
            <div>
            <p>There are unsubmitted changes.  Navigating away from this screen before completing the submission process will remove any changes.</p>       
            Do you want to proceed?       
            </div>
            )}
            
            </div>                          
        </Modal.Body>
        <Modal.Footer>
          <Button 
            label="Cancel"
            type="reset"
            onClick={onCancel}
          /> 
         {isConfirmBut && (<Button
            label="Confirm"
            type="submit"
            className="primary"
            onClick={onConfirm}
          />        )}  
        </Modal.Footer>
      </Modal>
    </div>
  );
};

LogOutModal.defaultProps = {
    size: "sm",
    
  };


export default LogOutModal;