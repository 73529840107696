import * as React from 'react';

interface IconProps {
  icon: string;
  size: "18" | "24";
  fill: string;
  styleCss? : string;
  onClkEvt?: any;
  linkClass?: string
  toolTip?: string
}

export const Icon = ({ icon, size, fill, styleCss,onClkEvt,linkClass,toolTip }: IconProps) => (
      <div title={toolTip} onClick={onClkEvt} className={linkClass} style={{float:"left"}}>
         <svg
           focusable="false"
           className={`icon-${size} ${fill} ${styleCss}`} 
           aria-hidden="true">
           <use
             xmlnsXlink="http://www.w3.org/1999/xlink"
             xlinkHref={`#${icon}-${size}`}></use>
         </svg>
        </div>
       );         

Icon.defaultProps = {
  fill: "",
  style: ""
};

export default Icon;