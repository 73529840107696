import React from 'react';
import LearnerCard from '../../components/pearson/learnerCard';
import FileDetails from '../../models/file-details';
import { LearnerLL } from '../../models/learner';
import { FILE_STATUS } from '../../shared/app-enums';
import { sortLearners, isDisableLearner, isCheckedManageLearner, showLoader } from './link-learners.helper';

interface Props {
    learnersList: LearnerLL[];
    learnersPerPage: number;
    currentPageNumber: number;    
    updateLinkLearner: (learnersData: number[]) => void; 
    onCheckboxChange: (e:any)=>void;
    currentlySelectedLearners: number[];
    isShowLLLoader?: boolean;
    selectedFile: FileDetails;
};

const shouldDisableSharedFileLearner = (selectedFile: FileDetails) => {
    if (selectedFile.fileStatusCode === FILE_STATUS.REJECTED) {
        return true;
    }

    return false;
};

const MSFShowLearners = (props:Props) => {
    const onCheckboxChange = (event:any) => {        
        props.onCheckboxChange(event);
    }
    const sortedLearners = sortLearners(props.learnersList);
    let isLearnerDisabled = false;
    

    let learnerListRender = sortedLearners.map((learnerData: any, index: any) => {
      const startFromIndex = props.learnersPerPage*(props.currentPageNumber-1);
      const endIndex = (startFromIndex+props.learnersPerPage)-1;

      if(startFromIndex <= index && endIndex >= index){
        isLearnerDisabled = isDisableLearner(learnerData) || shouldDisableSharedFileLearner(props.selectedFile);
        return (
            <div key={index+"s"}>
                <LearnerCard 
                    key={index} 
                    isChecked={isCheckedManageLearner(learnerData, props.currentlySelectedLearners)} 
                    learner={learnerData} 
                    onCheckboxChange={onCheckboxChange} 
                    isDisabled={isLearnerDisabled}  
                />                
            </div>)
      } 
    })
    const loaderJSX = (<span>{showLoader()}</span>);
    const learnerJSX = (<div>{learnerListRender}</div>)
    const noLearnerJSX = (
        <div style={{ marginTop: "15px", float: "left", width: "100%", marginBottom:"20vh" }}>
            No learners can be found.
        </div>
    );
   
    return props.isShowLLLoader
      ? loaderJSX
      : sortedLearners.length
      ? learnerJSX
      : noLearnerJSX;   
}

export default MSFShowLearners
