interface Config {
    API_GATEWAY: { URL: string };
    ORIGIN: string;  
    FORGEROCK: { AUTHORIZE_ENDPOINT: string, SESSION_END_ENDPOINT: string, REDIRECT_URI: string, SCOPES: string[], CLIENT_ID: string, TOKEN_ENDPOINT: string};
    MEDIA_FILES : { MAX_FILE_SIZE: number, UPLOAD_RESUME_MAX_HOURS: number };
    IS_MOCK: {MOCK_USERS: boolean, MOCK_FR: boolean, MOCK_USERS_INTERNAL:boolean};
    SALT: string; // This salt is harcoded in src/server/routes/permissions.ts
    HELP_LINK:{HELP:string, HELP_INTERNAL:string};
    FOOTER_LINKS:{name:string,href:string}[];
    refreshTime: number;
    refreshTime2: number;
    refreshTime3: number;
  }
  
  const dev: Config = {
    API_GATEWAY: {
      URL: 'http://localhost:3001'
    },
    ORIGIN: "http://localhost:3000",
    FORGEROCK: {
      CLIENT_ID: '50kr8vhg36lhhrqaoqfc4qa26g',
      SCOPES: ['openid', 'profile', 'email'],
      REDIRECT_URI: `${window.location.origin}/callback`,
      AUTHORIZE_ENDPOINT: 'https://pearson-shared-acc-dev1.auth.eu-west-2.amazoncognito.com/oauth2/authorize',
      TOKEN_ENDPOINT: 'https://pearson-shared-acc-dev1.auth.eu-west-2.amazoncognito.com/oauth2/token',
      SESSION_END_ENDPOINT: 'https://pearson-shared-acc-dev1.auth.eu-west-2.amazoncognito.com/logout',
    },
    MEDIA_FILES : {
      MAX_FILE_SIZE: 8192,
      UPLOAD_RESUME_MAX_HOURS: 336 // 14 days to match S3 bucket clean-up
    },
    IS_MOCK: {
      MOCK_FR: false, 
      MOCK_USERS: true,
      MOCK_USERS_INTERNAL: false,
    },
    SALT: 'd3O0w9b1l6sd6F3Efeq',
    HELP_LINK:{
      HELP: 'https://support.pearson.com/uk/s/qualification-contactus',
      HELP_INTERNAL: 'https://pearsonnow.service-now.com/esc'      
    },
    FOOTER_LINKS :[
      {
        name: "Terms of Use",
        href: 'https://www.pearson.com/uk/legal-notice.html'
      },
      {
        name: "Privacy Policy",
        href: 'https://qualifications.pearson.com/en/privacy-policy.html' 
      },
      
    ],
    refreshTime:60000,
    refreshTime2:30000,
    refreshTime3:15000
  };
  
  // const qa: Config = {
  //   API_GATEWAY: {
  //     URL: 'http://localhost:3001'
  //   },
  //   ORIGIN: "http://localhost:3000",
  //   FORGEROCK: {
  //     CLIENT_ID: '29h4tpasu5jfg6hqovputffu13',
  //     SCOPES: ['openid', 'profile', 'email'],
  //     REDIRECT_URI: `${window.location.origin}/callback`,
  //     AUTHORIZE_ENDPOINT: 'https://pearson-ukas-qa.auth.eu-west-2.amazoncognito.com/oauth2/authorize',
  //     TOKEN_ENDPOINT: 'https://pearson-ukas-qa.auth.eu-west-2.amazoncognito.com/oauth2/token',
  //     SESSION_END_ENDPOINT: 'https://pearson-ukas-qa.auth.eu-west-2.amazoncognito.com/logout',
  //   },
  //   MEDIA_FILES : {
  //     MAX_FILE_SIZE: 8192
  //   },
  //   IS_MOCK: {
  //     MOCK_FR: false, 
  //     MOCK_USERS: true,
  //     MOCK_USERS_INTERNAL: false,
  //   },
  //   SALT: 'd3O0w9b1l6sd6F3Efeq',
  //   HELP_LINK:{
  //     HELP: 'https://support.pearson.com/uk/s/qualification-contactus',
  //     HELP_INTERNAL: 'https://pearson.service-now.com/saml_redirector.do?sysparm_uri=/support'      
  //   },
  //   FOOTER_LINKS :[
  //     {
  //       name: "Terms of Use",
  //       href: 'https://www.pearson.com/uk/legal-notice.html'
  //     },
  //     {
  //       name: "Privacy Policy",
  //       href: 'https://qualifications.pearson.com/en/privacy-policy.html' 
  //     },
      
  //   ],
  //   refreshTime:60000,
  //   refreshTime2:30000,
  //   refreshTime3:15000
  // };

  // const poc: Config = {
  //   API_GATEWAY: {
  //     // URL: 'https://evidencecapture.api.sandbox.pqs-poc.pearsondev.tech'
  //     URL:'http://localhost:3001'
  //   },
  //   ORIGIN: "http://localhost:3000",
  //   FORGEROCK: {
  //     CLIENT_ID: '13vt7hpsfk760r1pgjgae3vret',
  //     SCOPES: ['openid', 'profile', 'email'],
  //     REDIRECT_URI: `${window.location.origin}/callback`,
  //     AUTHORIZE_ENDPOINT: 'https://pearson-ukas-sb.auth.eu-west-2.amazoncognito.com/oauth2/authorize',
  //     TOKEN_ENDPOINT: 'https://pearson-ukas-sb.auth.eu-west-2.amazoncognito.com/oauth2/token',
  //     SESSION_END_ENDPOINT: 'https://pearson-ukas-sb.auth.eu-west-2.amazoncognito.com/logout',
  //   },
  //   MEDIA_FILES : {
  //     MAX_FILE_SIZE: 8192
  //   },
  //   IS_MOCK: {
  //     MOCK_FR: false, 
  //     MOCK_USERS: true,
  //     MOCK_USERS_INTERNAL: false,
  //   },
  //   SALT: 'd3O0w9b1l6sd6F3Efeq',
  //   HELP_LINK:{
  //     HELP: 'https://support.pearson.com/uk/s/qualification-contactus',
  //     HELP_INTERNAL: 'https://pearson.service-now.com/saml_redirector.do?sysparm_uri=/support'      
  //   },
  //   FOOTER_LINKS :[
  //     {
  //       name: "Terms of Use",
  //       href: 'https://www.pearson.com/uk/legal-notice.html'
  //     },
  //     {
  //       name: "Privacy Policy",
  //       href: 'https://qualifications.pearson.com/en/privacy-policy.html' 
  //     },
      
  //   ],
  //   refreshTime:60000,
  //   refreshTime2:30000,
  //   refreshTime3:15000
  // };

  
  
  const prod: Config = {
    API_GATEWAY: {
      URL: '**ENVIRONMENT_SERVER_URL**'
    },
    ORIGIN:`${window.location.protocol}//${window.location.hostname}`,
    FORGEROCK: {
      CLIENT_ID: '**COGNITO_APP_CLIENT_ID**', // need to fetch from AWS - SSM
      SCOPES: ['openid', 'profile', 'email'],
      REDIRECT_URI: `${window.location.origin}/callback`,
      AUTHORIZE_ENDPOINT: '**COGNITO_AUTH_BASE_URL**/oauth2/authorize',
      SESSION_END_ENDPOINT: '**COGNITO_AUTH_BASE_URL**/logout',
      TOKEN_ENDPOINT: '**COGNITO_AUTH_BASE_URL**/oauth2/token',
    },
    MEDIA_FILES : {
      MAX_FILE_SIZE: 8192,
      UPLOAD_RESUME_MAX_HOURS: 336 // 14 days to match S3 bucket clean-up
    },
    IS_MOCK: {
      MOCK_FR: false,
      MOCK_USERS: true,
      MOCK_USERS_INTERNAL: false,
    },
    SALT: 'd3O0w9b1l6sd6F3Efeq',
    HELP_LINK:{
      HELP: 'https://support.pearson.com/uk/s/qualification-contactus',
      HELP_INTERNAL: 'https://pearsonnow.service-now.com/esc'      
    },
    FOOTER_LINKS :[
      {
        name: "Terms of Use",
        href: 'https://www.pearson.com/uk/legal-notice.html'
      },
      {
        name: "Privacy Policy",
        href: 'https://qualifications.pearson.com/en/privacy-policy.html' 
      },
      
    ],
    refreshTime:60000,
    refreshTime2:30000,
    refreshTime3:15000
  };
  
  // @ts-ignore
  // const config: Config = process.env.NODE_ENV === 'production' ? prod : dev;
  const config: Config = process.env.NODE_ENV === 'production' ? prod : dev;
  
  export default {
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
  };
  