import React, { FormEvent, useEffect } from "react";
import { Button, Option, Pagination, Select } from "ukas.uux.react-components";
import './assessment-deadline-config.css';
import { ConfigSearchResult, AssessmentDeadlines, QualificationDetails, Qualifications } from "../../models/config-models";
import { Series } from "../../models/series";
import Icon from "../../components/pearson/icon";
import { useHistory } from "react-router";
import { AssessmentDeadlineConfigSavedSearch } from "../../models/saved-search";
import Input from "../../components/pearson/input";
import Impersonating from "../Impersonating/impersonating.component";
import { useUserLoginState } from "../../shared/context-api/hooks";
import { HasWriteAccess } from "../../components/common/user-validation";

export interface StateProps {
    selectedAvailabilityWindow: string;
    selectedQualificationType: string;
    selectedSubject: string;
    selectedSeries: string;
    configSearchResult: ConfigSearchResult | null;
    seriesResult: Series[];
    qualificationDetails: QualificationDetails[];
    searchedComponent: string;
}

export interface DispatchProps {
    // getSeries: () => Promise<void>;
    getQualificationDetails: (availabilityValue: string, availabilityWindowCode: string) => Promise<void>;
    getAssessmentDeadlines: (availabilityWindowCode: string, availabilityValue: string, qualificationTypeCode: string, qualificationCode: string, unitSearchText: string, pageNumber: number, pageSize: number) => Promise<void>;
    getSeriesDetails: () => Promise<void>;
}

interface Props extends DispatchProps, StateProps { }

const AssessmentDeadlineConfig = (props: Props) => {
    document.title = "Learner Work Transfer - DLWT Setup";
    const internalUserHasValidPermissions = window.localStorage.getItem("internalUserHasValidPermissions");
    const history = useHistory();
    const [selectedAvailabilityWindow, setSelectedAvailabilityWindow] = React.useState(props.selectedAvailabilityWindow);
    const [selectedQualificationType, setSelectedQualificationType] = React.useState(props.selectedQualificationType);
    const [selectedSubject, setSelectedSubject] = React.useState(props.selectedSubject);
    const [selectedSeries, setSelectedSeries] = React.useState(props.selectedSeries);
    const [searched, setSearched] = React.useState(false);
    const [availabilityWindowChanged, setAvailabilityWindowChanged] = React.useState(false);
    const [seriesChanged, setSeriesChanged] = React.useState(false);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [searchCompleted, setSearchCompleted] = React.useState(false);
    const [showMenuAssessmentDeadlineId, setshowMenuAssessmentDeadlineId] = React.useState(0);
    const [searchedComponent, setSearchedComponent] = React.useState(props.searchedComponent);
    const userLoginstate = useUserLoginState();

   
    useEffect(() => {
        if (internalUserHasValidPermissions === "false") {
            history.push('/');
        }
        setSelectedAvailabilityWindow("1");
        props.getSeriesDetails().then(() => {
            getSavedAssessmentDeadlineSearch();
        });
    }, []);

    useEffect(() => {
        if (isSeriesSelected() && isAvailabilityWindowSelected()) {
            getQualificationDetails();
        }
    }, [selectedAvailabilityWindow, selectedSeries]);

    useEffect(() => {
        setSearchCompleted(true);
    }, [props.configSearchResult]);

    const getSavedAssessmentDeadlineSearch = () => {
        const savedSearchCriteria: AssessmentDeadlineConfigSavedSearch = JSON.parse(localStorage.getItem("assessmentDeadlineConfigSavedSearch") + "");
        if (savedSearchCriteria && savedSearchCriteria.selectedSeries !== "0") {
            setPageNumber(savedSearchCriteria.pageNumber);
            setSelectedAvailabilityWindow(savedSearchCriteria.selectedAvailabilityWindow);
            setSelectedSeries(savedSearchCriteria.selectedSeries);
            setSelectedQualificationType(savedSearchCriteria.selectedQualificationType);
            setSelectedSubject(savedSearchCriteria.selectedSubject);
            setSearchedComponent(savedSearchCriteria.searchedComponent);
            props.getAssessmentDeadlines(savedSearchCriteria.selectedAvailabilityWindow, savedSearchCriteria.selectedSeries, savedSearchCriteria.selectedQualificationType, savedSearchCriteria.selectedSubject, savedSearchCriteria.searchedComponent, savedSearchCriteria.pageNumber, 4).then(() => {
                setSearched(true);
            });
            setSearchCompleted(false);
        }
    };

    const onAvailabilityWindowChange = (selectedAvailabilityWindow: string) => {
        setSearched(false);
        setSearchCompleted(false);
        setAvailabilityWindowChanged(true);
        setSelectedAvailabilityWindow(selectedAvailabilityWindow);
        setSelectedSeries("0");
        setSelectedQualificationType("0");
        setSelectedSubject("");
        localStorage.removeItem("assessmentDeadlineConfigSavedSearch");
    };

    const onSeriesChange = (series: string) => {
        setSearched(false);
        setSearchCompleted(false);
        setSeriesChanged(true);
        setSelectedSeries(series);
        setSelectedQualificationType("0");
        setSelectedSubject("");
        setSearchedComponent("");
        localStorage.removeItem("assessmentDeadlineConfigSavedSearch");
    };

    const onQualificationTypeChange = (selectedQualificationType: string) => {
        setSearched(false);
        setSearchCompleted(false);
        setSelectedQualificationType(selectedQualificationType);
        setSelectedSubject("");
        setSelectedSubject("");
        localStorage.removeItem("assessmentDeadlineConfigSavedSearch");
    };

    const onSubjectChange = (selectedSubject: string) => {
        setSearched(false);
        setSearchCompleted(false);
        setSelectedSubject(selectedSubject);
        localStorage.removeItem("assessmentDeadlineConfigSavedSearch");
    };

    const onComponentChange = (searchedComponent: string) => {
        setSearched(false);
        setSearchCompleted(false);
        setSearchedComponent(searchedComponent);
        localStorage.removeItem("assessmentDeadlineConfigSavedSearch");
    };

    const getQualificationDetails = () => {
        props.getQualificationDetails(selectedSeries, selectedAvailabilityWindow).then(() => {
            setAvailabilityWindowChanged(false);
            setSeriesChanged(false);
        });
    };

    const currentNoPages = (): number => {
        const totalPages = props.configSearchResult?.totalPages;
        return totalPages ? totalPages : 0;
    };

    const isAvailabilityWindowSelected = (): boolean => {
        return selectedAvailabilityWindow !== "0";
    };

    const isComponentAdded = (): boolean => {
        return searchedComponent !== "";
    };

    const isQualificationTypeSelected = (): boolean => {
        return selectedQualificationType !== "0";
    };

    const isSeriesSelected = (): boolean => {
        return selectedSeries !== "0";
    };

    const isFilterChanged = (): boolean => {
        return availabilityWindowChanged || seriesChanged;
    };

    //const isSubjectSelected = (): boolean => {
    //    return selectedSubject !== "0";
    //};

    const viewAssessmentDeadlineConfig = (e: any, assessmentDeadlineId: number) => {
        e.preventDefault();
        let savedSearch: AssessmentDeadlineConfigSavedSearch = {
            assessmentDeadlineId,
            selectedAvailabilityWindow,
            selectedSeries,
            selectedQualificationType,
            selectedSubject,
            searchedComponent,
            pageNumber
        };
        localStorage.setItem("assessmentDeadlineConfigSavedSearch", JSON.stringify(savedSearch));
        history.push('/manage-assessment-deadline-config');
    };

    const changePage = (pageNo: number) => {
        var previousdivMenuElement = document.getElementById('div' + showMenuAssessmentDeadlineId);
        previousdivMenuElement?.classList.add("hide");
        setPageNumber(pageNo);
        props && props.getAssessmentDeadlines(selectedAvailabilityWindow, selectedSeries, selectedQualificationType, selectedSubject, searchedComponent, pageNo, 4);
    };

    const clearSearch = () => {
        setSelectedAvailabilityWindow("1");
        setSelectedSeries("0");
        setSelectedQualificationType("0");
        setSelectedSubject("");
        setSearched(false);
        setSearchCompleted(false);
        setPageNumber(1);
        setSearchedComponent("");
        localStorage.removeItem("assessmentDeadlineConfigSavedSearch");
    };

    const isSearchAvailable = (): boolean => {
        return isSeriesSelected() && isQualificationTypeSelected() && isComponentAdded();// && isSubjectSelected();
    };

    const searchConfig = () => {
        setSearchCompleted(false);
        setSearched(true);
        setPageNumber(1);
        localStorage.removeItem("assessmentDeadlineConfigSavedSearch");
        props.getAssessmentDeadlines(selectedAvailabilityWindow, selectedSeries, selectedQualificationType, selectedSubject, searchedComponent, 1, 4).then(() => {
            setSearchCompleted(true);
        });
    };

    const OpenCloseMenu = (e: any, assessmentDeadlineId: number) => {
        var previousdivMenuElement = document.getElementById('div' + showMenuAssessmentDeadlineId);
        var divMenuElement = document.getElementById('div' + assessmentDeadlineId);
        if (assessmentDeadlineId === showMenuAssessmentDeadlineId) {
            previousdivMenuElement?.classList.toggle("hide");
        }
        else {
            previousdivMenuElement?.classList.add("hide");
            divMenuElement?.classList.remove("hide");
        }
        setshowMenuAssessmentDeadlineId(assessmentDeadlineId);
    }

    return (
        <div>
            {HasWriteAccess(userLoginstate) &&
                <div className="impersonating-banner" style={{ paddingTop: "0px" }}>
                    <Impersonating behalfOf="" backText="Back to main screen" backLink="" page="" />
                </div>
            }
            <br />
            {props.seriesResult && props.seriesResult.length > 0 &&
                (<div className="gr-grid-container">
                    <div className="gr-row-one gr-col-one gr-col-span-six">
                        <h3 className="gr-h3 semi-bold-650" style={{ lineHeight: "0px" }}>Select a Product so it can be used by LWT and LWA</h3>
                    </div>
                    <div className="gr-row-two gr-col-one gr-col-span-four">
                        <Select id="SeriesSelect" label="Availability Window*" value={selectedAvailabilityWindow} onChange={(e: FormEvent<HTMLSelectElement>) => onAvailabilityWindowChange(e.currentTarget.value)}>
                            <Option value="0">
                                Select
                            </Option>
                            <Option value="1">
                                Series Based
                            </Option>
                        </Select>
                    </div>
                    <div className="gr-row-three gr-col-one gr-col-span-four">
                        <Select id="SeriesSelect" label="Series*" value={selectedSeries} onChange={(e: FormEvent<HTMLSelectElement>) => onSeriesChange(e.currentTarget.value)}>
                            <Option value="0">
                                Select
                            </Option>
                            {isAvailabilityWindowSelected() && props.seriesResult
                                .sort((a: Series, b: Series) => { return a.seriesCode.localeCompare(b.seriesCode); })
                                .reverse()
                                .map(function (seriesItem: Series) {
                                    return (
                                        <Option value={seriesItem.seriesCode} key={seriesItem.seriesCode}>
                                            {seriesItem.seriesName}
                                        </Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                    <div className="gr-row-three gr-col-one gr-col-span-four">
                        <Select id="QualificationTypeSelect" label="Qualification Type*" value={selectedQualificationType} onChange={(e: FormEvent<HTMLSelectElement>) => onQualificationTypeChange(e.currentTarget.value)}>
                            <Option value="0">
                                Select
                            </Option>

                            {isSeriesSelected() && isAvailabilityWindowSelected() && !isFilterChanged() && props.qualificationDetails && props.qualificationDetails && props.qualificationDetails.length > 0 && props.qualificationDetails && props.qualificationDetails
                                .map(function (qualifications: QualificationDetails) {
                                    return (
                                        <Option value={qualifications.qualificationTypeCode} key={qualifications.qualificationTypeCode}>
                                            {qualifications.qualificationTypeName}
                                        </Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                    <div className="gr-row-three gr-col-one gr-col-span-nine">
                        {/*
                    This block will be used later for SouceSystem Id 5
                    <Select id="SubjectSelect" label="Subject*" value={selectedSubject} onChange={(e: FormEvent<HTMLSelectElement>) => onSubjectChange(e.currentTarget.value)}>
                        <Option value="0">
                            Select
                            </Option>
                        {isQualificationTypeSelected() && props.qualificationDetails && props.qualificationDetails && props.qualificationDetails.length > 0 && props.qualificationDetails && props.qualificationDetails.length > 0 &&
                            props.qualificationDetails[0].qualifications
                                .map(function (qualifications: Qualifications) {
                                    return (
                                        <Option value={qualifications.qualificationCode} key={qualifications.qualificationCode}>
                                            {qualifications.qualificationName}
                                        </Option>
                                    )
                                })
                        }
                    </Select>*/}
                        <div>
                            <label className="gr-label">Component*</label>
                            <Input id={"unitSearchText"} placeholder={"Search"} disabled={!isQualificationTypeSelected()} value={searchedComponent} onChange={(e: FormEvent<HTMLInputElement>) => onComponentChange(e.currentTarget.value)} />
                        </div>
                    </div>

                    <div className="gr-row-four gr-col-one gr-col-span-twelve">
                        <Button color="secondary" type="button" disabled={!isSearchAvailable()} onClick={searchConfig}>View</Button>
                        <Button size="large" type="button" onClick={clearSearch}>Clear</Button>
                    </div>
                </div>)}
            {/* } */}
            <br />

            {searched && searchCompleted && props.configSearchResult && (!props.configSearchResult.assessments || props.configSearchResult.assessments.length === 0) &&
                <div className="gr-grid-container ie-flex">
                    <div className="gr-row-one gr-col-one gr-col-span-five no-message">
                        <Icon size="24" icon="warning" styleCss="warningIcon" />
                        <span style={{ paddingLeft: "10px", float: "left" }}>There is no match for your search criteria.  Please try again.</span>
                    </div>
                </div>
            }

            {searched && searchCompleted && props.configSearchResult && props.configSearchResult.assessments && props.configSearchResult.assessments.length > 0 &&
                <div>
                    {searched && props.configSearchResult?.assessments.map(function (config: AssessmentDeadlines) {
                        return (
                            <div key={config.assessmentDeadlineId} className="gr-grid-container ie-flex" style={{ marginTop: "5px", marginBottom: "8px" }}>
                                <div className="gr-col-one gr-col-span-twelve ">
                                    <pearson-card>
                                        <div className="gr-grid-container ie-flex requestCard"  >
                                            <div className="gr-row-one gr-col-one gr-col-span-twelve" >
                                                <span
                                                    className="gr-h3 gr-neutral-med-one gr-font-normal"
                                                    style={{ lineHeight: "22px" }}
                                                >
                                                    <span className="gr-neutral-med-none semi-bold-650">{config.unitCode} {config.unitName}</span>
                                                </span>
                                            </div>
                                            <div className="gr-row-two gr-col-one gr-col-span-five">
                                                <div>
                                                    <span className="gr-neutral-med-none gr-font-normal">Managed by LWT/LWA: </span>
                                                    <span className="gr-neutral-med-none semi-bold-650">{config.isDlwtEnabled ? "Yes" : "No"}</span>

                                                </div>
                                            </div>
                                            <div className="gr-row-two gr-col-one gr-col-span-five">
                                                <div>
                                                    <span className="gr-neutral-med-none gr-font-normal">Setup Submitted: </span>
                                                    <span className="gr-neutral-med-none semi-bold-650">{config.isSetupSubmitted ? "Yes" : "No"}</span>
                                                </div>
                                            </div>
                                            <div className="gr-row-two gr-col-one gr-col-span-six">
                                                <div>
                                                    <span className="gr-neutral-med-none gr-font-normal">Series: </span>
                                                    <span className="gr-neutral-med-none semi-bold-650">{config.seriesName}</span>
                                                </div>
                                            </div>
                                            <div className="gr-row-two gr-col-one gr-col-span-six">
                                                {/* <ThreeDotsMenu render={menuItems(config.assessmentDeadlineId)} allocId="0" /> */}
                                                <div className="table-menu">
                                                    <div className="vdots" onClick={(e) => OpenCloseMenu(e, config.assessmentDeadlineId)}></div>
                                                    <div id={"div" + config.assessmentDeadlineId} className="bubble hide">
                                                        <div className="link-holder">
                                                            <a onClick={(e) => viewAssessmentDeadlineConfig(e, config.assessmentDeadlineId)}>Manage Setup</a>
                                                        </div>
                                                    </div>

                                                </div>
                                                {/* <div>
                                                    <div className="vdots" onClick={openMenu}></div>
                                                        <div className="bubble">	 
                                                        <div className="link-holder">
                                                        <ul>
                                                            <li>
                                                            <a onClick={(e) => viewAssessmentDeadlineConfig(e, config.assessmentDeadlineId)}>
                                                                Manage Setup
                                                            </a>
                                                            </li>
                                                        </ul>
                                                        </div>
                                                        </div>
                                                    </div> */}
                                            </div>
                                            <div className="gr-row-three gr-col-one gr-col-span-five">
                                                <span className="gr-neutral-med-none gr-font-normal">Marking Method: </span>
                                                <span className="gr-neutral-med-none semi-bold-650">{config.markingMethod}</span>
                                            </div>
                                            <div className="gr-row-three gr-col-one gr-col-span-five">
                                                <span className="gr-neutral-med-none gr-font-normal">Assessment Method: </span>
                                                <span className="gr-neutral-med-none semi-bold-650">{config.assessmentMethodType === 1 ? "Examination" : "Moderation"}</span>
                                            </div>
                                            {config?.assessmentMethodType === 2 && (<div className="gr-row-three gr-col-one gr-col-span-six">
                                                <span className="gr-neutral-med-none gr-font-normal">Sample selected by: </span>
                                                <span className="gr-neutral-med-none semi-bold-650">{config.sampleSelectedBy}</span>
                                            </div>)}
                                        </div>
                                    </pearson-card>
                                </div>
                            </div>
                        )
                    })
                    }
                    <br />
                </div>
            }
            {searched && searchCompleted && currentNoPages() > 1 &&
                <div className="gr-grid-container ie-flex">
                    {pageNumber ? (
                        <div className=" gr-col-twelve margin0">
                            <Pagination noPages={currentNoPages()} maxSize={10} newPage={changePage} nextPage={changePage} prevPage={changePage} currentPage={pageNumber} />
                            <div className="showing-messsage">Showing {pageNumber} of {currentNoPages()}</div>
                        </div>
                    ) : (
                        <div className=" gr-col-twelve margin0">
                            <Pagination noPages={currentNoPages()} maxSize={10} newPage={changePage} nextPage={changePage} prevPage={changePage} />
                            <div className="showing-messsage">Showing {pageNumber} of {currentNoPages()}</div>
                        </div>
                    )}
                    {/* {currentPage ?(
                        <div className="showing-messsage">Showing {currentPage} of {currentNoPages()}</div>):(<div className="showing-messsage">Showing {pageNumber} of {currentNoPages()}</div>)} */}
                </div>
            }
        </div>
    );
};


export default AssessmentDeadlineConfig;
function sleep(arg0: number) {
    throw new Error("Function not implemented.");
}

