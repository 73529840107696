import React from "react";
import S3Uploader from "../pearson/uploader/s3uploader";
import config from '../../../src/app-config';
import "./upload.css";

const FileUploadViewer = () => {
  const [fileUploadStatus, setFileUploadStatus] = React.useState([]);
  const apiEndPoint = `${config.API_GATEWAY.URL}/media-upload-credentials`;

  const cancelSingleUpload = (e, fileName, index) => {
    let updateUploadStatus = [...fileUploadStatus];
    updateUploadStatus[index].cancelFile();
    updateUploadStatus[index].showProgress = false;
    updateUploadStatus[index].percentage = 0;
    updateUploadStatus[index].cancelUpload = true;
    setFileUploadStatus(updateUploadStatus);
  };

  const cancelAll = () => {
    fileUploadStatus.forEach((file, index) => {
      if (file.percentage < 100  || isNaN(file.percentage)) {
        file.cancelFile();
        file.showProgress = false;
        file.percentage = 0;
        file.cancelUpload = true;
      }
    });
    const cancelAllStatus = [...fileUploadStatus];
    setFileUploadStatus(cancelAllStatus);
  };

  
  const mediaSetObj = {'learningProviderId': '1', 'mediaSetId': '0409314d-f4c0-42b4-be44-d40419e405c2', 'learnerId': '2', 'unitId': '10'}
  return (
    <div class="content">
      <S3Uploader
        setFileUploadStatus={setFileUploadStatus}
        fileUploadStatus={fileUploadStatus}
        apiEndPoint={apiEndPoint}
        uploadTitle="Upload File"
        mediaSetObj={mediaSetObj}
      />
      <div>
        <aside>
          <ul className="filesUl">
            {fileUploadStatus.map((file, index) => (
              <li key={file.name} className="fileList">
                {file.name.replace(/[^\w\d_\-\.]+/ig, '')} - ( {file.size} bytes )
                {file.showProgress && !file.isFileUploaded ? (
                  <pearson-progress-bar
                    type="loading"
                    progress={file.percentage}
                  ></pearson-progress-bar>
                ) : (
                  ""
                )}
                {file.isFileUploaded ? (<div style={{ color: "red" }}>File Uploaded Successfully</div>) : ''}
                {file.percentage > 0 && file.percentage < 100 ? (
                  <button
                    className="cancel"
                    onClick={(e) => cancelSingleUpload(e, file.name, index)}
                  >
                    Cancel
                  </button>
                ) : (
                  ""
                )}
                {file && file.cancelUpload ? (
                  <div style={{ color: "red" }}>User aborted the upload</div>
                ) : (
                  ""
                )}
                {file && file.isError && !file.cancelUpload ? (
                  <div style={{ color: "red" }}>{file.errorMsg}</div>
                ) : (
                  ""
                )}
              </li>
            ))}
          </ul>
        </aside>
        <div className="cancel-all">{fileUploadStatus.length >= 2 ? (<button id="cancel" onClick={cancelAll}>Cancel All</button>) : ''}</div>
      </div>
    </div>
  );
};

export default FileUploadViewer;
