import React from 'react';
import ReactDOM from 'react-dom';
import '../node_modules/@pearson-ux/gravity-css/style.scss'
import './index.css';
import './index.scss';
import App from './App';
import * as auth from "./actions/auth/auth";
import * as serviceWorker from './serviceWorker'; 
import {
  requireBearerToken,
  requireCorrelationId,
  requireSessionId,
  responseInterceptors,
} from "./axios-interceptors";
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import { InitialState } from './reducers/initialState';
import Config from './app-config';

requireBearerToken();
responseInterceptors();
requireCorrelationId();
requireSessionId();

// const isMaintenece = JSON.parse(localStorage.getItem("maintenance")+'');

const store = configureStore(InitialState);
function startApp() {
  // auth.handleAuthentication().then(() => {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById("root")
  );
  // });
}
const idTokenDecrypted = localStorage.getItem("payload");
if(window.location.pathname === "/logout"){
  auth.logout();
}

if(window.location.pathname === "/callback" && 
  window.location.search.indexOf("code") > -1) 
  {
    startApp();
} else {
  if(!idTokenDecrypted) {
    auth.getAuthCode();
  } else {
    startApp();
  }
}


// Promise.resolve(auth.callback()).then((data) => {
//   if (window.location.search) {
//     window.location.search = "";
//     return;
//   }
// startApp();
// });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
