import { actionTypes } from "../../../actions/action-types";
import { SearchEvidenceActions } from "./search-evidence.actions";
import initialState from "../../../reducers/initialState";
import { Series } from "../../../models/series";
import { EvidenceSearchResult } from "../../../models/evidence";

export function GetSeriesReducer(
  state: Series[] = initialState.series,
  action: SearchEvidenceActions
): Series[] {
  if (action.type === actionTypes.GET_SERIES_SUCCESS) {
    return action.seriesData;
  } else {
    return state;
  }
}

export function SearchEvidenceReducer(
  state: EvidenceSearchResult | null = initialState.evidenceSearchResult,
  action: SearchEvidenceActions
): EvidenceSearchResult | null {
  if (action.type === actionTypes.GET_EVIDENCE_SEARCH_RESULT_SUCCESS) {
    return action.evidenceSearchResultData;
  } else {
    return state;
  }
}
