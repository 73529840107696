import axios, { AxiosRequestConfig } from "axios";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import config from "../../../app-config";

import { actionTypes } from "../../../actions/action-types";
import { StoreState } from "../../../store/store-state";
import LearnersList from "../../../models/learner";
import LearnerMaterial from "../../../models/learner-material";
import { getCurrentAssessMethod } from "../../../helpers/dashboard-helper";
import AdminMaterial, { AdminLearnerSummary, AdminSharedMaterialSummary } from "../../../models/admin-material";
import { REMOVE_RESPONSE_CODE } from '../../../shared/app-enums';



interface GetViewLearnersDetailsAction extends Action {
  type: actionTypes.GET_VIEW_LEARNER_DETAILS_SUCCESS;
  viewLearnersDetailsData: LearnersList;
}


interface UpdateLearnersStatusAction extends Action {
  type: actionTypes.UPDATE_LEARNER_STATUS_SUCCESS;
  learnerStatusData: any;
}


export type ViewLearnersAction =
  | GetViewLearnersDetailsAction
  | AddLearnerMaterialAction
  | RemoveLearnerMaterialAction
  | UpdateLearnersStatusAction
  | AddAdminMaterialAction
  | DownloadAdminMaterialAction
  | RemoveAdminMaterialAction
  | GetAdminMaterialSummaryAction;

type ThunkResult<R> = ThunkAction<R, StoreState, undefined, ViewLearnersAction>;

export const GetViewLearnersDetailsSuccessAction = (
  viewLearnersDetailsData: LearnersList
): GetViewLearnersDetailsAction => ({
  viewLearnersDetailsData,
  type: actionTypes.GET_VIEW_LEARNER_DETAILS_SUCCESS,
});

export const UpdateLearnersStatusSuccessAction = (
  learnerStatusData: any
): UpdateLearnersStatusAction => ({
  learnerStatusData,
  type: actionTypes.UPDATE_LEARNER_STATUS_SUCCESS,
});



export const getViewLearnersDetails = (
  allocationId: string,
  searchText: string,
  fileStatus: number,
  pageNumber: number,
  pageSize: number
): ThunkResult<Promise<void>> => {
  return (dispatch) => {
    const axiosConfig: AxiosRequestConfig = {
      headers: { "content-type": "application/json" },
    };
    const assessMethod = getCurrentAssessMethod() === 'examination' ? 1 : 2;
    return axios
      .post(
        `${config.API_GATEWAY.URL}/view-learners/list/0`,
        JSON.stringify({
          allocationId,
          searchText,
          fileStatus,
          pageNumber,
          pageSize,
          assessMethod
        }),
        axiosConfig
      )
      .then((postResponse) => {
        const viewLearnersDetailsData = postResponse.data as LearnersList;
        // console.log("RRRRRRRRR:::::",viewLearnersDetailsData)
        localStorage.setItem("viewLearnerGetData", "true")
        dispatch(GetViewLearnersDetailsSuccessAction(viewLearnersDetailsData));
      })
      .catch((error) => {
        // console.log("View Evidence Action Error==>", error);
        // throw error;
      });
  };
};




export const updateLearnerStatus = (
  allocationId: number,
  learnerId: number,
  learnerStatusCode: number,
  assessmentMethodType: number,
): ThunkResult<Promise<void>> => {
  return (dispatch) => {
    const axiosConfig: AxiosRequestConfig = {
      headers: { "content-type": "application/json" },
    };
    return axios
      .post(
        `${config.API_GATEWAY.URL}/view-learners/update-status/0`,
        JSON.stringify({
          allocationId,
          learnerId,
          learnerStatusCode,
          assessmentMethodType,
        }),
        axiosConfig
      )
      .then((postResponse) => {
        const viewLearnersStatusData = postResponse.data as LearnersList;
        dispatch(UpdateLearnersStatusSuccessAction(viewLearnersStatusData));
      })
      .catch((error) => {
        // console.log("Update Learner Status Action Error==>", error);
        // throw error;
      });
  };
};

/** Add Learner Material */
interface AddLearnerMaterialAction extends Action {
  type: actionTypes.ADD_LEARNER_MATERIAL_SUCCESS;
  learnerMaterialData: LearnerMaterial;
}

export const addLearnerMaterialSuccessAction = (
  learnerMaterialData: LearnerMaterial
): AddLearnerMaterialAction => ({
  learnerMaterialData,
  type: actionTypes.ADD_LEARNER_MATERIAL_SUCCESS,
});

export const addLearnerMaterial = (
  allocationId: number,
  fileId: string,
  fileKey: string,
  fileName: string,
  fileSize: string,
  learnerId: string
): ThunkResult<Promise<void>> => {
  return (dispatch) => {
    const axiosConfig: AxiosRequestConfig = {
      headers: { "content-type": "application/json" },
    };
    return axios
      .post(
        `${config.API_GATEWAY.URL}/exam-file-upload-token/post-upload-add-file`,
        JSON.stringify({
          allocationId, fileId,
          fileKey, fileName, fileSize, learnerId
        }),
        axiosConfig
      )
      .then((postResponse) => {
        // const learnerMaterialEvidenceData = postResponse.data && postResponse.data.evidence as LearnerMaterial;
        const learnerMaterialData = postResponse.data && postResponse.data as LearnerMaterial;
        // learnerMaterialEvidenceData.learnerId = learnerMaterialData
        dispatch(addLearnerMaterialSuccessAction(learnerMaterialData));
      })
      .catch((error) => {
        // console.log("Add Learner Material Action Error==>", error);
        // throw error;
      });
  };
};
/** Add Learner Material */

/** Remove Learner Material */
interface RemoveLearnerMaterialAction extends Action {
  type: actionTypes.REMOVE_LEARNER_MATERIAL_SUCCESS;
  learnerMaterialData: LearnerMaterial;
}

export const removeLearnerMaterialSuccessAction = (
  learnerMaterialData: LearnerMaterial
): RemoveLearnerMaterialAction => ({
  learnerMaterialData,
  type: actionTypes.REMOVE_LEARNER_MATERIAL_SUCCESS,
});

export const removeLearnerMaterial = (
  allocationId: number,
  fileKey: string,
  learnerId: string,
  isRemoveAll: boolean
): ThunkResult<Promise<void>> => {
  return (dispatch) => {
    const axiosConfig: AxiosRequestConfig = {
      headers: { "content-type": "application/json" },
    };
    return axios
      .post(
        `${config.API_GATEWAY.URL}/exam-file-upload-token/post-upload-remove-file`,
        JSON.stringify({
          allocationId,
          fileKey,
          learnerId,
          isRemoveAll
        }),
        axiosConfig
      )
      .then((postResponse) => {
        const learnerMaterialData = postResponse.data as LearnerMaterial;
        if (learnerMaterialData.responseCode === REMOVE_RESPONSE_CODE.FILE_NOT_DELETED || learnerMaterialData.responseCode === REMOVE_RESPONSE_CODE.FILE_ALREADY_DELETED) { localStorage.setItem("learnerRemoveError", "true"); }
        dispatch(removeLearnerMaterialSuccessAction(learnerMaterialData));
      })
      .catch((error) => {
        // console.log("Remove Learner Material Action Error==>", error);
        // throw error;
      });
  };
};
/** Remove Learner Material */

/** Add Admin Material */
interface AddAdminMaterialAction extends Action {
  type: actionTypes.ADD_ADMIN_MATERIAL_SUCCESS;
  adminMaterialData: AdminMaterial;
}

export const addAdminMaterialSuccessAction = (
  adminMaterialData: AdminMaterial
): AddAdminMaterialAction => ({
  adminMaterialData,
  type: actionTypes.ADD_ADMIN_MATERIAL_SUCCESS,
});

export const addAdminMaterial = (
  allocationId: number,
  fileId: string,
  fileKey: string,
  fileName: string,
  fileSize: string,
  fileType: number
): ThunkResult<Promise<void>> => {
  return (dispatch) => {
    const axiosConfig: AxiosRequestConfig = {
      headers: { "content-type": "application/json" },
    };
    return axios
      .post(
        `${config.API_GATEWAY.URL}/admin-file-upload/post-upload-add-file`,
        JSON.stringify({
          fileId, allocationId, fileKey, fileName, fileSize, fileType
        }),
        axiosConfig
      )
      .then((postResponse) => {
        const adminMaterialData = postResponse.data && postResponse.data as AdminMaterial;
        dispatch(addAdminMaterialSuccessAction(adminMaterialData));
      })
      .catch((error) => {
        // console.log("Add Admin Material Action Error==>", error);
        // throw error;
      });
  };
};
/** Add Admin Material */

/** Remove Admin Material */
interface RemoveAdminMaterialAction extends Action {
  type: actionTypes.REMOVE_ADMIN_MATERIAL_SUCCESS;
  adminMaterialData: AdminMaterial;
}

export const removeAdminMaterialSuccessAction = (
  adminMaterialData: AdminMaterial
): RemoveAdminMaterialAction => ({
  adminMaterialData,
  type: actionTypes.REMOVE_ADMIN_MATERIAL_SUCCESS,
});

export const removeAdminMaterial = (
  allocationId: number,
  fileKey: string,
  isRemoveAll: boolean,
  fileType: number
): ThunkResult<Promise<void>> => {
  return (dispatch) => {
    const axiosConfig: AxiosRequestConfig = {
      headers: { "content-type": "application/json" },
    };
    return axios
      .post(
        `${config.API_GATEWAY.URL}/admin-file-upload/post-upload-remove-file`,
        JSON.stringify({
          allocationId,
          fileKey,
          isRemoveAll,
          fileType
        }),
        axiosConfig
      )
      .then((postResponse) => {
        const adminMaterialData = postResponse.data as AdminMaterial;
        if (adminMaterialData.responseCode === REMOVE_RESPONSE_CODE.FILE_NOT_DELETED || adminMaterialData.responseCode === REMOVE_RESPONSE_CODE.FILE_ALREADY_DELETED) { localStorage.setItem("removeError", "true"); }
        dispatch(removeAdminMaterialSuccessAction(adminMaterialData));
      })
      .catch((error) => {
        // console.log("Remove Admin Material Action Error==>", error);
        // throw error;
      });
  };
};
/** Remove Admin Material */

export const downloadLearnerMedia = (preSignedUrl: string, fileName: string) => {
  if (!preSignedUrl || !fileName) {
    return;
  }
  const extension = getExtension(preSignedUrl);
  const link = document.createElement("a");
  link.href = preSignedUrl;
  link.setAttribute("download", `${fileName}.${extension}`);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export const getExtension = (url: string) => {
  let splitUrl = url.split(/\#|\?/);
  let extension = "";
  if (splitUrl) {
    splitUrl = splitUrl[0].split(".");
    if (splitUrl && splitUrl.length > 0) {
      extension = splitUrl.pop() as string;
    }
  }
  return extension.trim();
}

/** Download Admin Material */

interface DownloadAdminMaterialAction extends Action {
  type: actionTypes.DOWNLOAD_ADMIN_MATERIAL_SUCCESS;
  downloadAdminMaterialData: any;
}

export const downloadAdminMaterialSuccessAction = (
  downloadAdminMaterialData: any
): DownloadAdminMaterialAction => ({
  downloadAdminMaterialData,
  type: actionTypes.DOWNLOAD_ADMIN_MATERIAL_SUCCESS,
});
export const downloadAdminMaterial = (
  allocationId: number,
  fileKey: string,
  fileUserType: number,
  assessmentUserType: number,
  duration: number,
  fileName: string
): ThunkResult<Promise<void>> => {
  return (dispatch) => {
    const axiosConfig: AxiosRequestConfig = {
      headers: { "content-type": "application/json" },
    };
    return axios
      .post(
        `${config.API_GATEWAY.URL}/view-learners/download-file/0`,
        JSON.stringify({
          allocationId,
          fileKey,
          fileUserType,
          assessmentUserType,
          duration
        }),

        axiosConfig
      )
      .then((postResponse) => {
        const adminMaterialData = postResponse && postResponse.data;
        downloadLearnerMedia(adminMaterialData, fileName);
        dispatch(downloadAdminMaterialSuccessAction(adminMaterialData));

      })
      .catch((error) => {
        // console.log("Download Admin Material Action Error==>", error);
        // throw error;
      });
  };
};




/** Get Admin Learner */
interface GetAdminMaterialSummaryAction extends Action {
  type: actionTypes.GET_ADMIN_LEARNER_SUMMARY_SUCCESS;
  getAdminLearnerData: AdminSharedMaterialSummary;
}

export const getAdminMaterialSummarySuccessAction = (
  getAdminLearnerData: AdminSharedMaterialSummary
): GetAdminMaterialSummaryAction => ({
  getAdminLearnerData,
  type: actionTypes.GET_ADMIN_LEARNER_SUMMARY_SUCCESS,
});

export const getAdminMaterialSummary = (
  allocationId: number
): ThunkResult<Promise<void>> => {
  return (dispatch) => {

    const axiosConfig: AxiosRequestConfig = {
      headers: { "content-type": "application/json" },
    };
    return axios
      .post(
        `${config.API_GATEWAY.URL}/admin-file-upload/get-admin`,
        JSON.stringify({
          allocationId
        }),
        axiosConfig
      )
      .then((postResponse) => {
        const adminSharedMaterial = postResponse.data as AdminSharedMaterialSummary;
        // console.log("GET RS::::", adminSharedMaterial);
        localStorage.setItem("isFileNotExist", "true")
        dispatch(getAdminMaterialSummarySuccessAction(adminSharedMaterial));
      })
      .catch((error) => {
        // console.log("Get Admin Learner Action Error==>", error);
        // throw error;
      });
  };
};
/** Get Admin Learner */


