import { actionTypes } from "../../../actions/action-types";
import { ExamRequestsAction } from "./dashboard.actions";
import initialState from "../../../reducers/initialState";
import ExamRequest, { ExamRequestsSummary } from "../../../models/exam-request";
import SubmitAllocation, {
  SubmitAllowed
} from "../../../models/submit-allocation";
import { REQUSEST_STATUS } from "../../../shared/app-enums";

export function ExamRequestsReducer(
  state: ExamRequest[] = initialState.examRequests,
  action: ExamRequestsAction
): ExamRequest[] {
  if (action.type === actionTypes.GET_EXAM_REQUESTS_SUCCESS) {
    return action.examRequests;
  } else {
    return state;
  }
}

export function ExamRequestsStatusReducer(
  state: number = initialState.examRequestStatusCode,
  action: ExamRequestsAction
): number {
  if (action.type === actionTypes.GET_EXAM_REQUESTS_SUCCESS) {
    return action.examRequestStatusCode;
  } else {
    return state;
  }
}

export function GetExamRequestsSummaryReducer(
  state: ExamRequestsSummary = initialState.examRequestsSummary,
  action: ExamRequestsAction
): ExamRequestsSummary {
  if (action.type === actionTypes.GET_EXAM_REQUESTS_SUMMARY_SUCCESS) {
    return action.examRequestsSummaryData;
  } else {
    return state;
  }
}

export function ExamRequestsSummaryStatusReducer(
  state: number = initialState.examRequestStatusCode,
  action: ExamRequestsAction
): number {
  if (action.type === actionTypes.GET_EXAM_REQUESTS_SUMMARY_SUCCESS) {
    return action.examRequestStatusCode;
  } else {
    return state;
  }
}

export function SubmitAllocationReducer(
  state: SubmitAllocation = initialState.submitAllocation,
  action: ExamRequestsAction
): SubmitAllocation {
  if (action.type === actionTypes.SUBMIT_ALLOCATION_SUCCESS) {
    if (action.submitAllocation.status === REQUSEST_STATUS.SUBMITTED) {
      localStorage.setItem("submitted", "true");
      localStorage.setItem("currentPaperPage", "1");
    }
    return action.submitAllocation;
  } else {
    return state;
  }
}

export function CheckSubmitAllowedReducer(
  state: SubmitAllowed = initialState.submitAllowed,
  action: ExamRequestsAction
): SubmitAllowed {
  if (action.type === actionTypes.CHECK_SUBMIT_ALLOWED_SUCCESS) {
    return action.submitAllowed;
  } else {
    return state;
  }
}


