import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import S3UploadMultipart from "./s3-upload-multipart";
import axios from "axios";
import ThreeDotsMenu from "../threeDotsMenu";
import Modal from  '../CommonModal';
import config from '../../../app-config'
import { REQUSEST_STATUS, FILE_USER_TYPE } from "../../../shared/app-enums";
import {HasWriteAccess } from "../../../components/common/user-validation";
import { useUserLoginState } from "../../../shared/context-api/hooks";

function S3UploaderAdminMaterial(props) {
  const {
    setFileUploadStatus,
    fileUploadStatus,
    apiEndPoint,
    uploadTitle,
    mediaSetObj,
    allocationId,
    isCancelAll,
    onClickCancellAll,
    filesLength,
    removeAllFiles,
    status,
    previousAdminFiles,
    removeAdminFileNames,
    adminMaterial,
    addAdminMaterial
  } = props;
  const [filesToUpload, setToUpload] = useState([]);
  const [uploadAdminFiles, setUploadAdminFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [isRemoveConfirm, setIsRemoveConfirm] = useState(false);
  const userLoginstate = useUserLoginState();

  const menuItems = () => {
    return (
        <ul>
          <li>
            {isCancelAll &&
              <a href="javascript:void(0)" onClick={onClickCancellAll}>
                Cancel All
            </a>
            }
          </li>
          {filesLength > 0 && checkUploadDisplay()  && isRemoveEnable() &&  HasWriteAccess(userLoginstate) &&
          (<li> 
          <a href="javascript:void(0)" onClick={removeConfirmModal}>
                Remove All
              </a>
            </li>)}
        </ul>
      );
    };

  const isRemoveEnable = () => {
     if(previousAdminFiles.length > 0){
      return true;
  } else{
    return false;
  }
}

  const removeConfirmModal = () =>{
    localStorage.setItem("scrollPosition", window.scrollY.toString());
    setIsRemoveConfirm(true)
  }

  const cancelRemoveAllModel = () =>{
    const scrollPosition = parseInt(localStorage.getItem("scrollPosition"))
    setTimeout(
      () =>  window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth'
      }),
       100
    );
    setIsRemoveConfirm(false)
  }

  const confirmRemoveAllFile = () =>{ 
    setIsRemoveConfirm(false);
     removeAllFiles()
  }


  const getCredentials = (file) => {
    const axiosConfig = {
      headers: {
        "content-type": "application/json",
      },
    };
    return axios
      .post(
        apiEndPoint,
        JSON.stringify({
          fileName: file.name.replace(/[^\w\d_\-\.]+/gi, ''),
          allocationId: allocationId,
          fileId: `adminMaterial/${allocationId}/${file.name.replace(/[^\w\d_\-\.]+/gi, '')}`,
        }),
        axiosConfig
      )
      .then((postResponse) => {
        return postResponse;
      })
      .catch((error) => {
        console.log("Credentials error", error);
        return error;
      });
  };

  // useEffect(() => {
  //   if(adminMaterial.responseCode === 409){
  //     setErrorMessage("File already exists. Please rename this file so it can be uploaded");
  //     setShowError(true);
  //   }
  // },[adminMaterial]);

  useEffect(() => {
    let prevFileNames = [];
    previousAdminFiles &&
      previousAdminFiles.map(f => {
        prevFileNames.push(f.fileName.toLowerCase());
      });
    setUploadAdminFiles(prevFileNames);
  }, [previousAdminFiles && previousAdminFiles.length]);

  useEffect(() => {
    filesToUpload.forEach((f, index) => {
      let dragUploadFiles = [];
      if(f.size/ 1024 / 1024>config.MEDIA_FILES.MAX_FILE_SIZE){
        
        setErrorMessage("Upload not allowed (File size greater than 8GB)");
        setShowError(true);
      } else if (f.size == 0) {
        removeSingleAdminFileLocalStorage(f.name);
        setErrorMessage("This is 0 bytes and cannot be uploaded");
        setShowError(true);
      } else {
        if (uploadAdminFiles.includes(f.name.toLowerCase())) {
          setErrorMessage("File already exists. Please rename this file so it can be uploaded");
          setShowError(true);
        } else {
          Promise.resolve(getCredentials(f)).then((credentials) => {
            if (credentials.data && credentials.data.responseCode === 409) {
              setErrorMessage("File already exists. Please rename this file so it can be uploaded");
              setShowError(true);
              return;
            }
            if (credentials.data && credentials.data.responseCode === 403) {
              removeSingleAdminFileLocalStorage(f.name);
              setErrorMessage("Upload not allowed (unsupported file format)");
              setShowError(true);
              return;
            }
            if (credentials.data && credentials.data.responseCode === 400 && credentials.data.responseText.includes("deleted")) {
              removeSingleAdminFileLocalStorage(f.name);
              setErrorMessage("File removal is still in progress. Please rename this file so it can be uploaded.");
              setShowError(true);
              return;
            }

            dragUploadFiles.push(f.name.toLowerCase());
            let newPercentageStatus = [...fileUploadStatus];

            f["file"] = f;

            for (let key in mediaSetObj) {
              f[key] = mediaSetObj[key];
            }
            f["AllocationId"] = allocationId;
            const uploader = new S3UploadMultipart(
              credentials && credentials.data,
              f,
              `adminMaterial/${allocationId}/${f.name.replace(/[^\w\d_\-\.]+/gi, '')}`
            );

            uploader.addFileToUpload();
            uploader.startUpload();
            const cancelFile = () => {
              uploader.abortUpload();
            };
            uploader.onProgress = (percentage) => {
              if (newPercentageStatus[index].cancelUpload) {
                uploader.abortUpload();
              } else {
                let uploadPercentage = percentage * 100;
                    uploadPercentage = Number(uploadPercentage.toFixed(0));
                    newPercentageStatus[index].showProgress = true;
                    newPercentageStatus[index].percentage =
                      100 -
                      Math.round(
                        (percentage.remainingSize / percentage.fileSize) * 100
                      );
                    newPercentageStatus[index].cancelFile = cancelFile;
                    newPercentageStatus[index].isFileUploaded = false;
                    newPercentageStatus[index].isFileAddedToDB = false;
                    newPercentageStatus[index].isError = false;
                    newPercentageStatus[index].cancelUpload = false;
                    setFileUploadStatus([...newPercentageStatus]);
              }
            };

            uploader.onFinishS3Put = () => {
              newPercentageStatus[index].isFileUploaded = true;
              setFileUploadStatus([...newPercentageStatus]);
              // console.log("newPercentageStatus[index]",newPercentageStatus[index])
              addAdminMaterial(
                allocationId,
                newPercentageStatus[index].fileKey+removeSpecialCharacters(newPercentageStatus[index].name), 
                newPercentageStatus[index].fileKey+removeSpecialCharacters(newPercentageStatus[index].name), 
                newPercentageStatus[index].name.replace(/[^\w\d_\-\.]+/ig, ''),
                newPercentageStatus[index].size + "",
                FILE_USER_TYPE.ADMIN_LP
              );
            };

            uploader.onError = (err) => {
              console.log("[S3 Uploader Admin Material: Error]");
              newPercentageStatus[index].isError = true;
              newPercentageStatus[index].errorMsg = err;
              setFileUploadStatus([...newPercentageStatus]);
            };

            setUploadAdminFiles([...uploadAdminFiles, ...dragUploadFiles]);
          });
        }
      }
    });
    // eslint-disable-next-line
  }, [filesToUpload]);

  useEffect(() => {
    let upload = uploadAdminFiles.filter(function(el) {
      return removeAdminFileNames.indexOf(el) > 0;
    });
    setUploadAdminFiles(upload);
  }, [removeAdminFileNames]);

  const onDrop = useCallback((acceptedFiles) => {
    const isError = setAdminFileLocalStorage(acceptedFiles);
    if(isError) {
      return;
    }
    setShowError(false);
    setFileUploadStatus(acceptedFiles);
    setToUpload(acceptedFiles);
    // eslint-disable-next-line
  }, []);

  const checkUploadDisplay = () => {
    if (
      status === REQUSEST_STATUS.SUBMITTING ||
      status === REQUSEST_STATUS.SUBMITTED
    ) {
      return false;
    }
    return true;
  };

  const removeSpecialCharacters = (value)=>{
    return value.replace(/[^\w\d_\-\.]+/gi,"")
  };

  const setAdminFileLocalStorage = (acceptedFiles) => {
    var isError = false;
    var fileNames = [];
    let fullFileName = "";
    let localStorageFileUploaded = localStorage.getItem("adminfileUploaded")
    ? JSON.parse(localStorage.getItem("adminfileUploaded"))
    : [];
    
    
    acceptedFiles && acceptedFiles.forEach(file => {
      fullFileName = `adminMaterial/${allocationId}/` + removeSpecialCharacters(file.name);
      var inProgressFileExists = localStorageFileUploaded.some(ls => 
        ls.files.some(f => f.fullFileName === fullFileName));
      var alreadyFileExists = previousAdminFiles && previousAdminFiles.some(f => 
        f.fileKey === fullFileName);
      if (!inProgressFileExists && !alreadyFileExists) {
        fileNames.push({fullFileName: fullFileName, 
          fileName: removeSpecialCharacters(file.name), fileSize: file.size});
      }
      else {
        isError = true;
        setErrorMessage("File already exists. Please rename this file so it can be uploaded");
        setShowError(true);
        return isError;
      }
    });

    if(fileNames && fileNames.length > 0)
    {
       const index = localStorageFileUploaded.findIndex(x => x.section ==="adminMaterial" && x.allocationId === allocationId);
       

      if (index !== -1) { 
        localStorageFileUploaded[index].files.push(...fileNames);
      }
      else { 
        localStorageFileUploaded.push({ section: "adminMaterial",allocationId:allocationId, files: fileNames });
      }
      localStorage.setItem("adminfileUploaded", JSON.stringify(localStorageFileUploaded));
    }
    return isError;
  }

  const removeSingleAdminFileLocalStorage = (fileName) => {
    let localStorageFileUploaded = localStorage.getItem("adminfileUploaded")
      ? JSON.parse(localStorage.getItem("adminfileUploaded"))
      : [];
    const adminMaterialIndex = localStorageFileUploaded.findIndex(
      (x) => x.section ==="adminMaterial" && x.allocationId === allocationId
    );
    if (adminMaterialIndex !== -1) {
      const fullFileName = `adminMaterial/${allocationId}/` + removeSpecialCharacters(fileName);
      if (localStorageFileUploaded[adminMaterialIndex].files.length === 1) {
        localStorageFileUploaded.splice(adminMaterialIndex, 1);
      } else {
        const fileIndex = localStorageFileUploaded[adminMaterialIndex].files.findIndex(
          (file) => file.fullFileName === fullFileName
        );
        if (fileIndex !== -1) {
          localStorageFileUploaded[adminMaterialIndex].files.splice(fileIndex, 1);
        }
      }
    }

    localStorage.setItem("adminfileUploaded", JSON.stringify(localStorageFileUploaded));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <>
      <div className="uploadHeader gold-yellow-bg">
        
        <div className="gr-grid-container ie-flex margin0">
          <div className="uploadtext gr-col-one  gr-col-span-eleven text-weight-700">
            {uploadTitle()}
          </div>
          <div className=" gr-col-one learnersmenu">
          <ThreeDotsMenu render={menuItems()} cssStyle={{minWidth:"130px", maxWidth:"155px"}} />
          </div>
        </div>
      </div>
      <div>
      {HasWriteAccess(userLoginstate) && (
        <div className="uploaddragzone"
        style={{ display: checkUploadDisplay() ? "" : "none" }}
        >
          <section className="container">
            <div {...getRootProps({ className: "dropzone dragzone" })}>
              <input {...getInputProps()} />
              <p className="tooltipInit">
                <span className="tooltipdropInit" id={Date.now()+''}>
                  Drop files here or <a style={{ cursor: "pointer" }}>Browse</a>
                </span>
              </p>
            </div>
          </section>
        </div>
      )}
        <span style={{ float: "right", paddingRight: "3%", color: "red" }}>
          {showError ? (
            <>
              {errorMessage} &nbsp;&nbsp;
              <span
                style={{
                  color: "black",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                onClick={e => setShowError(false)}
              >
                x
              </span>
            </>
          ) : (
            <>&nbsp;</>
          )}
        </span>
        <Modal isConfirmBut={true} show={isRemoveConfirm} title="Remove All Files Confirmation" fromKebabMenu={true} modalData="removeAllFiles" onCancel={() => cancelRemoveAllModel()} onConfirm={() => confirmRemoveAllFile()} fileType={FILE_USER_TYPE.ADMIN_LP} />
      </div>
    </>
  );
}

export default S3UploaderAdminMaterial;
