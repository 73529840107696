import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { StoreState } from "../../store/store-state";
import SearchEvidence, {StateProps, DispatchProps} from "./search-evidence.component";
import {
  SearchEvidenceActions,
  getSeries,
  searchEvidence
} from "./ducks/search-evidence.actions";
import { EvidenceSearchCriteria } from "../../models/evidence";

const mapStateToProps = (state: StoreState): StateProps => {
    return {
        selectedSeries: "0",
        productText: "",
        assessmentAssociateText: "",
        learningProviderText: "",
        series: state.series,
        evidenceSearchResult: state.evidenceSearchResult
      };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<StoreState, undefined, SearchEvidenceActions>
): DispatchProps => {
  return {
    getSeries: () =>
      dispatch(getSeries()),
    searchEvidence: (criteria: EvidenceSearchCriteria) =>
      dispatch(searchEvidence(criteria)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchEvidence);
