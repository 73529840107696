interface RouteEvent {
    event: string;
    listener: (data: string) => void;
  }
  
  export default class RouteEventPublisher {
      private static instance: RouteEventPublisher;
      listeners: RouteEvent[] = [];
  
      private constructor() { }
  
      public static getInstance(): RouteEventPublisher {
        if (!RouteEventPublisher.instance) {
          RouteEventPublisher.instance = new RouteEventPublisher();
        }
  
        return RouteEventPublisher.instance;
      }
  
      subscribe(event: string, listener: (data: string) => void){
  
        let newRouteEvent: RouteEvent = { event, listener };
        this.listeners.push(newRouteEvent);
      }
    
      publish(event: string, data: string){
        
        this.listeners.forEach(l => {
          if (l.event.toLowerCase() === event.toLowerCase()) {
            l.listener(data)
          }
        })
      }
    }