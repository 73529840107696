import { actionTypes } from "../../../actions/action-types";
import { ViewPapersDetailsAction } from "./view-papers.actions";
import initialState from "../../../reducers/initialState";
import  {ViewPapers}  from "../../../models/exam-request";



export function GetViewPapersReducer(
  state: ViewPapers = initialState.viewPapers,
  action: ViewPapersDetailsAction | any
): ViewPapers {
  if (action.type === actionTypes.GET_VIEW_PAPERS_DETAILS_SUCCESS) {
    
    if(action.viewPapersData.reply.totalRecords>10){
      localStorage.setItem("vPPaginationData","true")
    }
    return action.viewPapersData;
  } else {
    return state;
  }
}

