import { StoreState } from "../store/store-state";
import { ViewPapers } from '../models/exam-request';
import Learner, { LearnersList, SaveLearners, SaveLinkLearners } from "../models/learner";
import SubmitAllocation, { SubmitAllowed } from "../models/submit-allocation";
import LearnerMaterial from "../models/learner-material";
import AdminMaterial from "../models/admin-material";
import { AdminLearnerSummary, AdminSharedMaterialSummary } from "../models/admin-material";
import SharedFileLearners from "../models/shared-file";
import { SubmitSetupResponse, ConfigSearchResult, SubmitSetupPrsResponse } from "../models/config-models";

export const InitialState: StoreState = {
  showCreateMenu: false,
  examRequests: [],
  examRequestStatusCode: 0,
  examRequestsSummary: {
    learningProviderCode: "",
    examination: { toDoCount: 0, inProgressCount: 0, completedCount: 0 },
  },
  viewPapers: new ViewPapers(),
  viewLearnersDetails: new LearnersList(),
  learnerMaterial: new LearnerMaterial,
  submitAllocation: new SubmitAllocation,
  submitAllowed: new SubmitAllowed,
  series: [],
  evidenceSearchResult: null,
  moderationRequests: [],
  moderationRequestStatusCode: 0,
  moderationRequestsSummary: {
    learningProviderCode: "",
    examination: { toDoCount: 0, inProgressCount: 0, completedCount: 0 },
  },
  downloadAdminMaterial: "",
  manageLearnersDetails: [],
  //linkLearnersDetails: [],
  currentlySelectedManageLearners: [],
  currentlySelectedLinkLearners: [],
  saveLearners: new SaveLearners,
  saveLinkLearners: new SaveLinkLearners,
  adminMaterial: new AdminMaterial,
  adminSharedMaterial: new AdminSharedMaterialSummary,
  sharedFileLearnersResponse: new SharedFileLearners,
  isShowLLLoader: false,
  qualificationDetails: [],
  assessmentDeadlines: new ConfigSearchResult(),
  submitSetupResponse: new SubmitSetupResponse(),
  submitSetupPrsResponse: new SubmitSetupPrsResponse(),
  manageAssessmentDeadline: null,
  assesssmentDeadlineConfigseries: []
};
export default InitialState;
