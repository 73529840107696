const sizeInBytes = (filesizeStr: number, decimals: number = 2) => {
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    // const sizes = ['MB']
    const i = Math.floor(Math.log(filesizeStr) / Math.log(k));
    const fileSize = parseFloat((filesizeStr / Math.pow(k, i)).toFixed(dm));
    const sizeType = sizes[i] === undefined ? 'Bytes' : sizes[i];
    const size = (fileSize.toString() === "NaN") ? 0 : fileSize;
    return size + ' ' + sizeType;
}

export default sizeInBytes;