import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "../../components/pearson/select";
// import { StoreState } from "../../store/store-state";
import sizeInBytes from "../../helpers/sizeInBytes";
// import { getAdminMaterialSummary } from "../view-learner/ducks/view-learner.actions";
// import { getSavedSearch } from "../../helpers/dashboard-request-helper";
// import SavedSearch from "../../models/saved-search";
import FileDetails from "../../models/file-details";
// import { getSharedFileLearners } from "./ducks/link-learners.actions";
import { getAllocId } from './link-learners.helper';
import { AdminSharedMaterialSummary } from "../../models/admin-material";
import { ASSESSMENT_TYPE, FILE_STATUS } from "../../shared/app-enums";

interface fileOption {
  key: string;
  value: string;
}

interface Props {
    adminSharedMaterial: AdminSharedMaterialSummary;
    getAdminMaterialSummary: (allocationId: number) => void;
    getSharedFileLearners: (allocationId: number, filKey: string, assessmentMethodType: number) => void;
    updateSelectedFile: (filekey: string) => void;
    updateSelectedFileDetails: (file: FileDetails | undefined) => void;
    updateLinkLearner: (learnerIds:number[]) => void;
}

const MSFDropdown = (props:Props) => {  
  
  const [selectedFile, setSelectedFile] = useState('');
  const currentAssessmentType = localStorage.getItem("currentAssessMethod")+""
  const assessmentMethodType = currentAssessmentType !==  'examination' ? ASSESSMENT_TYPE.MODERATION : ASSESSMENT_TYPE.EXAMINATION;
  let fullFileList: FileDetails[] = props.adminSharedMaterial.learnerSharedMaterial;
  const [fileList, setFileList] = React.useState<fileOption[] | []>([]);

  const dispatch = useDispatch();
  useEffect(() => {   
    props.getAdminMaterialSummary(getAllocId());    
  }, []);

  useEffect(() => {
    let accFileList = [{ key: "", value: "Select a file" }];

    fullFileList &&
      fullFileList.forEach((file: any) => {
        if (file.fileStatusCode === FILE_STATUS.REJECTED && !file.isLinkedToLearner) {
          // Don't allow any file link changes
          return;
        }

        let fileNameText = file.fileName + " (" + sizeInBytes(parseInt(file.fileSize)) + ")";
        if (file.fileStatusCode === FILE_STATUS.REJECTED) {
          fileNameText += ` [${file.fileStatusTitle}]`
        }

        accFileList.push({
          key: file.fileKey,
          value: fileNameText
        });
      });
    setFileList(accFileList);
  }, [fullFileList]);

  
  const onFileChange = (event: any) => {
    let fileKey = event.target.value;
    dispatch(props.updateLinkLearner && props.updateLinkLearner([]));
    if(fileKey.length){  
        setSelectedFile(fileKey);
        props.updateSelectedFile(fileKey);

        const currentFile = fullFileList.find(f => f.fileKey === fileKey);
        props.updateSelectedFileDetails(currentFile);

        // props.showLoader        
        dispatch(props.getSharedFileLearners && props.getSharedFileLearners(getAllocId(), fileKey, assessmentMethodType));        
    } else {
        // make learners empty in redux store
        setSelectedFile("");
        props.updateSelectedFile("");
        props.updateSelectedFileDetails(undefined);
    }
  };  

  return (
    <div className="gr-col-one gr-col-span-one file-dropdown">
      <Select
        disabled={false} 
        id="fileOption"
        placeholder=""
        value={selectedFile}
        options={fileList}
        onChange={onFileChange}
        learner={false}
      />
    </div>
  );
};

export default MSFDropdown;
