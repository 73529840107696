import _ from 'lodash';
import React from 'react'
import { forwardRef, ReactNode, useState } from 'react';
import { Form, FormControlProps } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import Icon from '../icon';

export const ACCESSIBLE_LABEL_REQUIRED_TEXT = 'A label or aria-label must be provided to make this component accessible to screen readers.';
export const MAX_DATE_BEFORE_MIN_DATE_TEXT = 'The max date can not be before the min date.';

export interface DatePickerProps extends Omit<FormControlProps, 'maxLength' | 'minLength' | 'onChange' | 'type'> {
	className?: string;
	disabled?: boolean;
	feedback?: ReactNode;
	feedbackTooltip?: boolean;
	feedbackType?: 'invalid' | 'valid';
	helpText?: ReactNode;
	isInvalid?: boolean;
	isValid?: boolean;
	label?: ReactNode;
	max?: string;
	min?: string;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const StyledFormControl = styled(Form.Control)`
	border-radius: '2px';
	box-sizing: border-box;
	line-height: 1.5;
	margin: 4px 0;
	padding: 0 12px;
	height: 36px;
	border-radius: 4px;
	width:50%;
	background: #E9E9E9;
	border:0;
	color: black;
	${(props) =>
		props.plaintext &&
		css`
			background-color: transparent !important;
			padding-left: .80rem;
		`};
	&:-webkit-autofill,
	&:-webkit-autofill:focus {
		transition: background-color 600000s 0s, color 600000s 0s;
	}
	&:focus,
	&:invalid:focus,
	&:valid:focus,
	&.is-invalid:focus,
	&.is-valid:focus {
		outline: 0;
		${(props) =>
			props.plaintext &&
			css`
				border-color: transparent;
				box-shadow: none;
			`};
	}

	:not(:disabled):not(.disabled) {
		:focus,
		:active:focus {
			border: 1px solid #1977d4;
			box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #1977d4;
			outline: 0;
			
		}
		background: #FFFFFF;
		border: 1px solid #C7C7C7;
	}
`;

const StyledFormLabel = styled(Form.Label)`
	color: #6c757d;
	font-size: 0.85rem;
	font-weight: 500;
	letter-spacing: 0.3px;
	line-height: 1rem;
	margin-bottom: 0;
	text-rendering: optimizelegibility;
	margin-bottom: 0.25rem;
`;

const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
	({ feedback, feedbackTooltip, feedbackType, helpText, id, label, max, min, ...props }: DatePickerProps, ref) => {
		if (!props['aria-label'] && !label) {
			throw new Error(ACCESSIBLE_LABEL_REQUIRED_TEXT);
		}

		if (max && min && max < min) {
			throw new Error(MAX_DATE_BEFORE_MIN_DATE_TEXT);
		}

		const [uniqueId] = useState(id ?? _.uniqueId('datePicker'));

		return (
			<Form.Group>
				{label && <StyledFormLabel htmlFor={uniqueId}>{label}</StyledFormLabel>}
				<StyledFormControl
					aria-describedby={helpText ? uniqueId + 'HelpText' : undefined}
					id={uniqueId}
					max={max}
					min={min}
					ref={ref}
					type='date'
					{...props}
				/><br/>
				{helpText && <Form.Text id={uniqueId + 'HelpText'}>{helpText}</Form.Text>}
				{feedback && feedbackType && (
				
					<Form.Control.Feedback
						tooltip={feedbackTooltip}
						type={feedbackType}
					>
							<Icon
					icon="warning"
					size="18"
				fill="gr-error"
					styleCss="warning"/>{feedback}
					</Form.Control.Feedback>
				)}
			</Form.Group>
		);
	}
);

DatePicker.displayName = 'DatePicker';

export default DatePicker;
