import React from "react";
import Icon from "./icon";
// import { updateSavedSearch } from '../../helpers/dashboard-request-helper'
import ThreeDotsMenu from "../pearson/threeDotsMenu";
import Modal from './CommonModal';
// import { useHistory } from "react-router";
import {
  
  dateFormatter
} from "../../helpers/dashboard-request-helper";
import UserDataProvider, { initialStateType } from "../../shared/context-api/provider";
import { useUserLoginState } from "../../shared/context-api/hooks";
import { IsUserInternal } from '../../components/common/user-validation';



interface RequestCardProps {
  name: string;
  // recLabel?: string;
  // recInfo: string;
  completionDate: string;
  status: string;
  learnerCount: string;
  submissionDate?: string;
  totalLearnerCount: string;
  AADeadlineDate: string;
  allocationId: string;
  aaNumber?: string;
  aaName?: string;
  submissionDeadLineDate?: string;
  unitInfo?: string;
  compLabel?: string;
  compInfo?: string;
  statusId?: string;
  onViewLearners: (allocId: string) => void;
  onSubmitRequest: (allocId: number) => void;
  checkSubmitAllowed?: (allocId: number) => void;
  isThreeDotRequired: boolean;
  evidenceScanningCount?: number;
  evidenceAcceptedCount?: number;
  evidenceRejectedCount?: number;
  isSubmitAllowed?: boolean;
  returnedBy?: string;
  returnedOn?: string;
  userClassType?: string;
  returnUserClassType?: string;
}

//function MyRequestCard() {
const ViewRequestCard = (props: RequestCardProps) => {
  //user internal check
  const userLoginstate = useUserLoginState();
  const isUserInternal = IsUserInternal(userLoginstate as initialStateType);

  const [openModal, setOpenModal] = React.useState(false)
  const [disable, setDisable] = React.useState(false);
  const [isRequestSubmitted, setRequestSubmitted] = React.useState(false);
  //const [openIsSubmitModal, setOpenIsSubmitModal] = React.useState(false)
  const learnersCount = (props.statusId === "1") ? props.totalLearnerCount : props.learnerCount + "/" + props.totalLearnerCount;
  // const learnersDeadline = (props.status === "New") ? "Submission Deadline" : (props.status.includes("Received")) ? "Assessment Deadline" : (props.status === "Completed") ? "Completion Date" : "";
  const learnersDeadline = "Submission Deadline";
  // const learnersDeadlineDate = (props.status === "New") ? props.submissionDate : (props.status.includes("Received")) ? props.AADeadlineDate : (props.status === "Completed") ? props.completionDate : "";
  // const learnersDeadlineDate = props.submissionDate;

  const viewEvidenceNav = (e: any, selectedAllocationId: string) => {
    e.stopPropagation();
    // updateSavedSearch({selectedAllocationId})
    // history.push('view-evidence')
    props.onViewLearners(selectedAllocationId);
  }
  //const history = useHistory();
  const submitRequest = (selectedAllocationId: number) => {
    if(!isRequestSubmitted)
    {
      setOpenModal(true)
      if(props.isSubmitAllowed)
      {     
        setDisable(true);
        props.onSubmitRequest(selectedAllocationId);
        setOpenModal(false)
        setRequestSubmitted(true);
      }
    }
    // history.push('/')
  }
  // const profile: any = localStorage.getItem("payload");
  const openModalWindow = () => {
    // setOpenIsSubmitModal(false)
     props.checkSubmitAllowed && props.checkSubmitAllowed(parseInt(props.allocationId))
    setOpenModal(true)
  }

  //const submitAllowed = !props.isSubmitAllowed ? "isDisabled" : "";
  const submitAllowed = (disable || (props.statusId === "4") || (props.statusId === "7")) ? "isDisabled" : "";
  const menuItems = () => {
    return (
      <ul>
        <li>
          <a href="javascript:void(0)" onClick={(e) => viewEvidenceNav(e, props.allocationId)}>
            View Learners
          </a>
        </li>
        {((props.statusId === "2") || (props.statusId === "3") || (props.statusId === "4") || (props.statusId === "5") || (props.statusId === "6")) && (
          <li>
            <a href="#!" className={submitAllowed} onClick={() => openModalWindow()}>Submit Request</a>
            {/* <a href="#!" className={submitAllowed} onClick={() => openModalWindow}>Submit Request</a> */}
          </li>
        )}
        {/* {((props.statusId === "4") || (props.statusId === "5")) && (
          <li>
            <a href="javascript:void(0)" className="isDisabled">Submit Request</a>
          </li>
        )} */}
      </ul>
    );
  };

  
  return (
    <div>
      <div
        className="gr-grid-container ie-flex margin0"
        style={{ marginTop: "5px", marginBottom: "8px" }}
      >
        <div className="gr-col-one gr-col-span-twelve ">
          <pearson-card>
            <div className="gr-grid-container ie-flex requestCard"  style={{ alignContent: "center" }}>{

              <div className="gr-col-one gr-col-span-five " >
                <span
                  className="gr-h3 gr-neutral-med-one gr-font-normal"
                  style={{ lineHeight: "22px" }}
                >
                  {props.name} <span className="gr-neutral-med-none semi-bold-650"> {props.unitInfo}</span>
                </span>
                <div
                  className="gr-neutral-med-one gr-meta-without-color"
                >
                  {/* {(props.statusId !== "7") ? (<div style={{ "lineHeight": "20px" }}><div><span>{props.recLabel}</span><span className="gr-neutral-high-one" style={{ color: "#6a6a6a" }}> {props.recInfo}</span></div><div><span>{props.compLabel}</span><span className="gr-neutral-high-one"> {props.compInfo}</span></div></div>) : ( */}
                  <div style={{ "lineHeight": "16px" }}>
                    {/* <div>
                        <span>{props.recLabel}</span>
                        <span className="gr-neutral-high-one" style={{ color: "#6a6a6a" }}> {props.recInfo}</span>
                      </div> */}
                    {(props.statusId === "7") &&
                    <div>
                      <span>{props.compLabel}</span>
                      <span className="gr-neutral-high-one"> {props.compInfo}</span>
                    </div>}
                  </div>
                  {/* )} */}
                  <span className="refNumber">Ref: {props.allocationId}</span>
                  {(props.statusId !== "1") && (props.statusId !== "7") ? (props.evidenceScanningCount && props.evidenceScanningCount > 0) ? (<span className="scanningTxt" style={{ paddingLeft: "0px", width: "20%", float: "left" }}>Uploaded ({props.evidenceScanningCount})</span>) : ("") : ""}
                  {(props.statusId !== "1") && (props.statusId !== "7") ? (props.evidenceAcceptedCount && props.evidenceAcceptedCount > 0) ? (<span className="acceptedTxt" style={{ paddingLeft: "0px", width: "20%", float: "left" }}>Accepted ({props.evidenceAcceptedCount})</span>) : ("") : ""}
                  {(props.statusId !== "1") && (props.statusId !== "7") ? (props.evidenceRejectedCount && props.evidenceRejectedCount > 0) ? (<span className="rejectedTxt" style={{ paddingLeft: "0px", width: "20%", float: "left" }}>Rejected ({props.evidenceRejectedCount})</span>) : ("") : ""}
                  
                </div>

              </div>
            }

              <div className="gr-col-one  gr-theme-four-light-bg ">
                <Icon size="18" icon="account" styleCss={"userIconSVGPosition"} />
                <h3 className="gr-h3 gr-font-normal">
                  <span className="userCount gr-semi-bold">
                    {learnersCount}
                  </span>
                </h3>
              </div>
              <div className={props.isThreeDotRequired ? "gr-col-one gr-col-span-five " : "gr-col-one gr-col-span-six "} style={{ marginTop: "0.5%" }}>
                <div className="statusContainer" style={{ "lineHeight": "24px" }}>
                  <div className="statusLabel gr-neutral-med-one ">Status</div>
                  <div className="spacing">&nbsp;</div>
                  {(props.statusId !== '5' ) ? (
                    <div className="statusText gr-theme-four-light-bg">
                      {" "}
                      {props.status}&nbsp;{(props.statusId === "6" &&  props.returnedOn ) && (<div
            className="tooltip gr-neutral-med-one gr-meta-without-color"
           
          ><Icon size="18" icon="warning" styleCss={"gr-condition-one returnimg"} />
          <span className="tooltiptext returntooltiptext">
            {isUserInternal
              ? <p>Returned by {props.returnedBy} on {props.returnedOn && dateFormatter(props.returnedOn).date} at {props.returnedOn && dateFormatter(props.returnedOn).reqTime}</p>
              : <>
                  {props.returnUserClassType === 'I'
                    ? <p>Returned by {props.returnedBy} on {props.returnedOn && dateFormatter(props.returnedOn).date} at {props.returnedOn && dateFormatter(props.returnedOn).reqTime}</p>
                    : <p>Returned on {props.returnedOn && dateFormatter(props.returnedOn).date} at {props.returnedOn && dateFormatter(props.returnedOn).reqTime}</p>
                  }
              </>
            }
          </span>
      </div>)}
                    </div>) : (
                      <div className="statusText gr-theme-four-light-bg notSubmitWrapper">
                        {" "}
                        {props.status}
                      </div>
                    )}
                  <div className="statusLabel gr-neutral-med-one">
                    {learnersDeadline}
                  </div>
                  <div className="spacing">&nbsp;</div>
                  <div className="statusText gr-theme-four-light-bg">
                    {" "}
                    {props.submissionDeadLineDate}
                    {" "}
                  </div>
                </div>
              </div>

              {props.isThreeDotRequired && props.status !== "Pending" && <div className="gr-col-one learnersmenu ">
                <ThreeDotsMenu render={menuItems()} checkSubmitAllowed={props.checkSubmitAllowed} allocId={props.allocationId} statusId={props.statusId} />
              </div>}
            </div>
          </pearson-card>
          {/* submitAllowed={props.isSubmitAllowed} */}

          {/* <SubmitAllowedModal isAllowed={props.isSubmitAllowed} isConfirmBut={true} show={openIsSubmitModal} title="Is Submit Request Allowed" modalData="submitRequest" onCancel={() => setOpenIsSubmitModal(false)} onConfirm={() => openModalWindow()} /> */}
          <Modal isConfirmBut={false} show={openModal} title={props.isSubmitAllowed? "Submit Request": "Request cannot be submitted"} fromKebabMenu={true} modalData="submitRequest" onCancel={() => setOpenModal(false)} onConfirm={() => submitRequest(parseInt(props.allocationId))} submitAllowed={props.isSubmitAllowed}/>
          
        </div>
      </div>
    </div>
  );
};

export default ViewRequestCard;
