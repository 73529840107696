import React, { useEffect } from "react";
import {
  updateSavedSearch,
  getSavedSearch,
} from "../../helpers/dashboard-request-helper";
import SavedSearch from "../../models/saved-search";
import { useUserLoginState } from "../../shared/context-api/hooks";
import * as dbrdHelper from '../../helpers/dashboard-helper'


export interface DispatchProps {
  getExamRequests: (
    learningProviderCode: string,
    status: number[],
    searchText: string
  ) => Promise<void>;

   getModerationRequests: (
     learningProviderCode: string,
     status: number[],
     searchText: string
   ) => Promise<void>;
}

export interface StateProps {
  examRequestsSummary?: any;
  moderationRequestsSummary?:any;
}

type Props = DispatchProps & StateProps;

function GetData(props:Props) {
  const userLoginstate = useUserLoginState();
  const { examRequestsSummary,moderationRequestsSummary } = props;
  let tabCountData:any ;
  if(dbrdHelper.getCurrentAssessMethod() === 'examination'){
   tabCountData = examRequestsSummary && examRequestsSummary.reply
  }else {
     tabCountData =  moderationRequestsSummary && moderationRequestsSummary.reply
  }
  let LPIdFirstInArray =
  (userLoginstate &&
    userLoginstate.userData &&
    userLoginstate.userData.associatedLearningProviders &&
    userLoginstate.userData.associatedLearningProviders[0] &&
    userLoginstate.userData.associatedLearningProviders[0].learningProviderCode) || "";
  const learningProviderCenter = userLoginstate && userLoginstate.userData &&
    userLoginstate.userData.associatedLearningProviders || [];
    const getCenterNameStorage =  localStorage.getItem("centerName");
  const getCenterName = getCenterNameStorage && getCenterNameStorage.substring(0, getCenterNameStorage.indexOf(' '));
  LPIdFirstInArray = getCenterName?getCenterName:LPIdFirstInArray;
    // console.log("LPIdFirstInArray",LPIdFirstInArray);
    const userData = userLoginstate && userLoginstate.userData
    localStorage.setItem('userData',JSON.stringify(userData))
const updateTabIndex = (tabIndex: string, learningProviderId: string, status: number[], isTabNull: boolean = true, searchTextP: string = "" ) => {
  if(isTabNull){
    updateSavedSearch({selectedTabIndex:tabIndex})
  } 
  props && props.getExamRequests(learningProviderId, status, searchTextP);
  props && props.getModerationRequests(learningProviderId, status, searchTextP);
}
const isExamTodoCount = () => {
  return  tabCountData && 
          tabCountData.examination &&
          tabCountData.examination.toDoCount
}

const isExamInprogressCount = () => {
  return  tabCountData &&
          tabCountData.examination &&
          tabCountData.examination.inProgressCount
}

const isExamCompletedcount = () => {
  return  tabCountData &&
          tabCountData.examination &&
          tabCountData.examination.completedCount
}

const isModTodoCount = () => {
  return  tabCountData &&
          tabCountData.moderation &&
          tabCountData.moderation.toDoCount
}

const isModInprogressCount = () => {
  return  tabCountData &&
          tabCountData.moderation &&
          tabCountData.moderation.inProgressCount
}

const isModCompletedCount = () => {
  return  tabCountData &&
          tabCountData.moderation &&
          tabCountData.moderation.completedCount
}
React.useMemo(() => {
  const savedSearch = getSavedSearch() as SavedSearch;

  const tabIndex = savedSearch.selectedTabIndex;
  const searchText = savedSearch.searchText ? savedSearch.searchText : "";

  const learningProviderId = savedSearch.learningProviderId
    ? savedSearch.learningProviderId
    : LPIdFirstInArray;
  
  if (tabIndex === "") {
    if (isExamTodoCount() > 0) {
      updateTabIndex("0", learningProviderId, [1]);
    } else if (isExamInprogressCount() > 0) { 
      updateTabIndex("1", learningProviderId, [2, 3, 4, 5, 6]);
    } else if (isExamCompletedcount() > 0) {
      updateTabIndex("2", learningProviderId, [7]);
    } 
     if(isExamTodoCount() === 0 && isExamInprogressCount() === 0  && isExamCompletedcount() === 0){
      if(isModTodoCount() !== 0 || isModInprogressCount() !== 0  || isModCompletedCount() !== 0){
      if (isModTodoCount() > 0) {
        updateTabIndex("0", learningProviderId, [1]);
      } else if (isModInprogressCount() > 0) {
        updateTabIndex("1", learningProviderId, [2, 3, 4, 5, 6]);
      } else if (isModCompletedCount() > 0) {
        updateTabIndex("2", learningProviderId, [7]);
      } 
      dbrdHelper.setCurrentAssessMethod("moderation");
    }
    }
    if(isExamTodoCount() === 0 && isExamInprogressCount() === 0  && isExamCompletedcount() === 0 && isModTodoCount() === 0 && isModInprogressCount() === 0  && isModCompletedCount() === 0){
      updateTabIndex("0", learningProviderId, [1]);
      dbrdHelper.setCurrentAssessMethod("examination");
    }
  } else {
    if (tabIndex && tabIndex === "0") {
      updateTabIndex("", learningProviderId, [1], false, searchText);
    } else if (tabIndex && tabIndex === "2") {
      updateTabIndex("", learningProviderId, [7], false, searchText);
    } else {
      const searchOption = savedSearch.searchFilter;
      if (searchOption === "All" || searchOption === "") {
        updateTabIndex("", learningProviderId, [2, 3, 4, 5, 6], false, searchText);
      }
      if (searchOption === "InProgress") {
        updateTabIndex("", learningProviderId, [2], false, searchText);
      }
      if (searchOption === "InProgress(LE)") {
        updateTabIndex("", learningProviderId, [3], false, searchText);
      }
      if (searchOption === "Submitting") {
        updateTabIndex("", learningProviderId, [4], false, searchText);
      }
      if (searchOption === "NotSubmitted") {
        updateTabIndex("", learningProviderId, [5], false, searchText);
      }
      if (searchOption === "Returned") {
        updateTabIndex("", learningProviderId, [6], false, searchText);
      }
    }
  }

}, [tabCountData]);

  useEffect(() => {
    
    if(learningProviderCenter && learningProviderCenter.length < 2){ 
      updateSavedSearch({learningProviderId:LPIdFirstInArray})
    if (LPIdFirstInArray && LPIdFirstInArray.length) {
    }
  }else{
    
    const savedSearch = getSavedSearch() as SavedSearch;
    
    const savedLearningProviderId = savedSearch && savedSearch.learningProviderId;
    updateSavedSearch({learningProviderId:savedLearningProviderId})
    
  }
  // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [LPIdFirstInArray]);
  
  return (
    <></>
  )
}

export default GetData;
