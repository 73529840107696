import RouteEventPublisher from '../../components/common/route-event-publisher';
import { useHistory } from 'react-router-dom';
import React from 'react';

export const Redirect: React.FC = () => {

    const history = useHistory();
    const publisher = RouteEventPublisher.getInstance();

    publisher.subscribe('Error', (data: string) => {
        if (window.location.pathname.indexOf('/application-error') === -1 && window.location.pathname.indexOf('/access-denied') === -1){
            history.push('/application-error/' + data);
        }
    });

    publisher.subscribe('Unavailable', (data: string) => {
        if (window.location.pathname.indexOf('/system-unavailable') === -1){
            history.push('/system-unavailable');
        }
    });

    publisher.subscribe('Maintenance', (dataMaintence: string) => {        
        if (window.location.pathname.indexOf('/maintenance') === -1){
            history.push('/maintenance');
        }
    });

    return <div></div>;
}

export default Redirect;