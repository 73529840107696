import React from "react";
import SavedSearch,{ViewPapersSavedSearch} from "../models/saved-search";
import ViewRequestHeader from "../components/pearson/viewRequestHeader";
import ViewRequestCard from "../components/pearson/viewRequestCard";
import moment from "moment";
import { ExamRequestProps } from "../components/dashboard/dashboard";

export const viewRequests = (
  selectedTab: string,
  requests: any,
  navigateToViewLearners: (allocId: string) => void,
  navigateToViewAll: (e:any,qualificationTypeCode:string,seriesCode:string,qualificationName:string) => void,
  submitRequest: (allocationId: number) => void,
  checkSubmitAllowed: (allocationId: number) => void,
  isSubmitAllowed: boolean
) => {
  // let fiteredRequests = Object.keys(requests).map((key: any, i) => {

  return (
    <div>
      {requests &&
        requests.map((request: (ExamRequestProps|any), key: any) => {
          return (
            <div>
              <ViewRequestHeader
                key={key}
                label={request.qualificationTypeName}
                name=""
                series={request.seriesName}
                subjectCount={request.subjectCount}
                paperCount={request.totalPaperCount}
              />
              {request &&
                request.papers.map((paper: any, key2: any) => {
                  return (
                    <ViewRequestCard
                      key={key2}
                      name={paper.qualificationName ? paper.qualificationName : ""}
                      unitInfo={
                        paper.unitCode +
                        " " +
                        paper.unitName
                      }
                      status={paper.status}
                      statusId={paper.statusId}
                      totalLearnerCount={paper.expectedLearnerCount}
                      learnerCount={paper.actualLearnerEvidenceCount}
                      // recLabel={`Allocated AA:`}
                      // recInfo={`${paper.aaNumber}${" "}${paper.aaName}`}
                      compLabel={`Submitted on`}
                      compInfo={`${
                        dateFormatter(paper.evidenceSubmittedOn).date
                      } at ${
                        dateFormatter(paper.evidenceSubmittedOn).reqTime
                      } by ${paper.evidenceSubmittedBy}`}
                      completionDate={
                        dateFormatter(paper.submissionDeadlineDate).date
                      }
                      submissionDate={
                        dateFormatter(paper.evidenceSubmittedOn).date
                      }
                      submissionDeadLineDate={
                        dateFormatter(paper.submissionDeadlineDate).date
                      }
                      AADeadlineDate={
                        dateFormatter(paper.submissionDeadlineDate).date
                      }
                      allocationId={paper.allocationId}
                      onViewLearners={navigateToViewLearners}
                      aaName={paper.aaName}
                      aaNumber={paper.aaNumber}
                      isThreeDotRequired = {true}
                      evidenceScanningCount ={paper.evidenceScanningCount}
                      evidenceAcceptedCount ={paper.evidenceAcceptedCount}
                      evidenceRejectedCount ={paper.evidenceRejectedCount}
                      onSubmitRequest={submitRequest}
                      checkSubmitAllowed={checkSubmitAllowed}
                      isSubmitAllowed={isSubmitAllowed}
                      returnedBy ={paper.returnedBy}
                      returnedOn = {paper.returnedOn}
                      userClassType={paper.userClassType}
                      returnUserClassType={paper.returnUserClassType}
                    />
                  );
                })}
              <div className="view-all-less">
                <div className="subject-request-count">
                  Showing {request.displayPaperCount} of{" "}
                  {request.totalPaperCount}
                </div>
                {request.totalPaperCount > request.displayPaperCount && (
                  <div>
                    <button className="gr-btn" type="button" onClick={(e) => navigateToViewAll(e, request.qualificationTypeCode,request.seriesCode,request.qualificationTypeName)}>
                      View All
                    </button>
                  </div>
                ) 
                }
              </div>
            </div>
          );
        })}
    </div>
  );
};

export const removeAllErrorText = () => {
  let htmlText = '';
  htmlText += '<div>Some of the file(s) cannot be removed because they are linked to learner(s).</div>'
  htmlText += '<div>Use the Manage Shared Files screen to unlink any learners from these file(s).</div>'
  return htmlText;
}

export const dateFormatter = (date: string) => {
  let dateFormat = (date === null) ? "?" : new Date(date);
  let reqDate =
    dateFormat === "?" ? "?" : dateFormat.toString() !== "Invalid Date"
      ? moment(dateFormat).format("DD/MM/YYYY")
      : date;
  let reqTime = moment(dateFormat).format("HH:mm");
  return {
    date:
      reqDate && reqDate.length
        ? reqDate.includes("0001")
          ? "?"
          : reqDate
        : "?",
    reqTime
  };
};
export const getSavedSearch = () => {
  const savedSearch = JSON.parse(localStorage.getItem("savedSearch") + "");
  let obj = {};
  if (!savedSearch) {
    obj = {
      selectedTabIndex: "",
      searchFilter: "",
      searchText: "",
      selectedAllocationId: "" 
    };
  } else {
    obj = savedSearch;
  }
  localStorage.setItem("savedSearch", JSON.stringify(obj));
  return obj;
};

export const updateSavedSearch = (props: SavedSearch) => {
  const savedSearch = JSON.parse(localStorage.getItem("savedSearch") + "");
  let updatedSavedSearch = {};
  if (!savedSearch) {
    let savedSearchObj: SavedSearch = {
      selectedTabIndex: "",
      searchFilter: "",
      searchText: "",
      selectedAllocationId: ""
    };
    updatedSavedSearch = { ...savedSearchObj, ...props };
  } else {
    updatedSavedSearch = { ...savedSearch, ...props };
  }
  localStorage.setItem("savedSearch", JSON.stringify(updatedSavedSearch));
  return updatedSavedSearch;
};

export const getViewPapersSavedSearch = () => {
  const savedSearch = JSON.parse(localStorage.getItem("viewPaperssavedSearch") + "");
  let obj = {};
  if (!savedSearch) {
    obj = {
      selectedTabIndex: "",
      searchFilter: "",
      searchText: "",
      selectedAllocationId: "",
      qualificationTypeCode: "",
      seriesCode:"",
      learningProviderId: "",
      qualificationTypeName: ""
    };
  } else {
    obj = savedSearch;
  }
  localStorage.setItem("viewPaperssavedSearch", JSON.stringify(obj));
  return obj;
};

export const updateViewPapersSavedSearch = (props: SavedSearch) => {
  const savedSearch = JSON.parse(localStorage.getItem("viewPaperssavedSearch") + "");
  let updatedSavedSearch = {};
  if (!savedSearch) {
    let savedSearchObj: ViewPapersSavedSearch = {
      selectedAllocationId: "",
      selectedTabIndex: "",
      searchFilter: "",
      searchText: "",
      qualificationTypeCode: "",
      seriesCode:"",
      learningProviderId: "",
      qualificationTypeName: ""
    };
    updatedSavedSearch = { ...savedSearchObj, ...props };
  } else {
    updatedSavedSearch = { ...savedSearch, ...props };
  }
  localStorage.setItem("viewPaperssavedSearch", JSON.stringify(updatedSavedSearch));
  return updatedSavedSearch;
};

export const deleteSavedSearch = () => {
  localStorage.removeItem("savedSearch");
};

export const deleteViewPapersSavedSearch = () => {
  localStorage.removeItem("viewPaperssavedSearch");
  // localStorage.removeItem("currentPage")
};

export const clearIntervals = (intervals: any[]) => {
  intervals.forEach( interval => {
    clearInterval(interval);
  })  
}
