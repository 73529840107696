import axios, { AxiosRequestConfig } from "axios";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import config from "../../../app-config";

import { actionTypes } from "../../../actions/action-types";
import { StoreState } from "../../../store/store-state";
// import LearnersList, { ViewLearnersSummary } from "../../../models/learner";
import  ViewPapers  from "../../../models/exam-request";
// import AdminMaterial from "../../../models/admin-material";
import { getCurrentAssessMethodNumber } from "../../../helpers/dashboard-helper";




interface GetViewPapersDetailsAction extends Action {
  type: actionTypes.GET_VIEW_PAPERS_DETAILS_SUCCESS;
  viewPapersData: ViewPapers;
}

export type ViewPapersDetailsAction =
  | GetViewPapersDetailsAction  

type ThunkResult<R> = ThunkAction<R, StoreState, undefined, ViewPapersDetailsAction>;



export const GetViewPapersDetailsSuccessAction = (
  viewPapersData: ViewPapers
): ViewPapersDetailsAction => ({
  viewPapersData,
  type: actionTypes.GET_VIEW_PAPERS_DETAILS_SUCCESS,
});



export const getViewPapersDetails = (
  learningProviderCode: string,
  qualificationTypeCode: string,
  seriesCode: string,
  searchText: string,
  status: number[],
  pageNumber: number,
  pageSize: number
): ThunkResult<Promise<void>> => {
  return (dispatch) => {
    const axiosConfig: AxiosRequestConfig = {
      headers: { "content-type": "application/json" },
    };
    const assessmentMethodType =  getCurrentAssessMethodNumber()
    return axios
      .post(
        `${config.API_GATEWAY.URL}/exam-requests/get-examination-subject-papers/0`,
        JSON.stringify({
          learningProviderCode,
          qualificationTypeCode,
          seriesCode,
          searchText,
          status,
          pageNumber,
          pageSize,
          assessmentMethodType
        }),
        axiosConfig
      )
      .then((postResponse) => {
        const viewPapersData = postResponse.data as ViewPapers;
        dispatch(GetViewPapersDetailsSuccessAction(viewPapersData));
      })
      .catch((error) => {
        // console.log("View Papers Action Error==>", error);
        // throw error;
      });
  };
};






