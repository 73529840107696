import React, { FormEvent, useEffect, useMemo } from "react";
import "./link-learners.css";
import MSFDropdown from "./msf-dropdown.component";
import MSFBackToVL from "./msf-back-to-VL.component";
import { AdminSharedMaterialSummary } from "../../models/admin-material";

import MSFSearch from "./msf-search.component";
import MSFFilters from "./msf-filters.component";
import * as LLHlpr from "./link-learners.helper";
import SharedFileLearners from "../../models/shared-file";
import {
  LearnerLL,
  SaveLinkLearners,
  learnerFilterType,
} from "../../models/learner";
import _ from "lodash";
import MSFGuidance from "./msf-guidance.component";
import MSFAlerts from "./msf-alerts.component";
import MSFShowLearners from "./msf-show-learners.component";
import {
  getSharedFileLearners,
  updateLinkLearner,
} from "./ducks/link-learners.actions";
import MSFSave from "./msf-save.component";
import MSFSelectAll from "./msf-select-all.component";
import Pagination from "../../components/pearson/pagination";
import { ASSESSMENT_TYPE, FILE_STATUS, FILTER_LEARNER, SEL_ALL_ACTIONS } from "../../shared/app-enums";
import * as dbrdHelper from '../../helpers/dashboard-helper'
import FileDetails from "../../models/file-details";


export interface StateProps {
  adminSharedMaterial: AdminSharedMaterialSummary;
  sharedFileLearnersResponse: SharedFileLearners;
  currentlySelectedLinkLearners?: number[];
  saveLearnersResponse: SaveLinkLearners;
  learners?: LearnerLL[];
  isShowLLLoader: boolean;
}

export interface DispatchProps {
  saveLinkLearners: (
    allocationId: number,
    fileKey: string,
    selectedLearners: number[],
    unSelectedLearners: number[]
  ) => Promise<void>;
  updateLinkLearner: (learnersData: number[]) => void;
  updateLLLoader: (isShowLLLoader:boolean) => void;
  getAdminMaterialSummary: (allocationId: number) => Promise<void>;
  getSharedFileLearners: (allocationId: number, fileKey: string, assessmentMethodType: number) => Promise<void>;
}

interface Props extends DispatchProps, StateProps {}

const LinkLearners = (props: Props) => {

  const [currentPage, setCurrentPage] = React.useState(1);
  const [currentFilter, setCurrentFilter] = React.useState(FILTER_LEARNER.ALL);
  const [selectedFile, setSelectedFile] = React.useState("");
  const [selectedFileDetails, setSelectedFileDetails] = React.useState<FileDetails | undefined>(undefined);
  const [searchText, setSearchText] = React.useState("");
  const currentAssessmentType = localStorage.getItem("currentAssessMethod")+""
  const assessmentMethodType = currentAssessmentType !==  'examination' ? ASSESSMENT_TYPE.MODERATION : ASSESSMENT_TYPE.EXAMINATION;
  const linkLearnerPerPageNumber = 15;

  const learnersData: LearnerLL[] =
    (props && props.sharedFileLearnersResponse.learners) || [];
  const currSelLIds: number[] =
    (props && props.currentlySelectedLinkLearners) || [];

  let searchResult: LearnerLL[] = LLHlpr.getLLSearchResult(
    learnersData,
    searchText    
  );
  let searchedFilteredList = LLHlpr.getFilteredList(
    searchResult,
    currentFilter,
    currSelLIds
  );

  useEffect(() => {        
    addSavedLearnersToReduxStore();
    updateCurrentFilter(FILTER_LEARNER.ALL);

  }, [learnersData])  
  useEffect(() => {  
    window.addEventListener('beforeunload', dbrdHelper.alertUser)
    window.addEventListener('unload', dbrdHelper.handleTabClosing)
        return () => {
            window.removeEventListener('beforeunload', dbrdHelper.alertUser)
            window.removeEventListener('unload', dbrdHelper.handleTabClosing)
        } 
  },[])

  const isLIdSelected = (learnerId: number) =>
    _.includes(currSelLIds, learnerId);

  const isSelAllChecked = 
  currentFilter === FILTER_LEARNER.UNSEL ? false : 
  LLHlpr.extractLIds(searchedFilteredList).every(
    isLIdSelected
  );
  
  /*** Initial counts */
  const { allCount, selCount, unselCount, linkedCount } = useMemo(() => {
    const currSelLearnerids = currSelLIds;
    return searchResult.reduce(
      (acc, l, i) => {
        acc.allCount++;
        l.isSharedFile
          ? acc.linkedCount++
          : currSelLearnerids.includes(l.learnerId)
          ? acc.selCount++
          : acc.unselCount++;
        return acc;
      },
      { allCount: 0, selCount: 0, unselCount: 0, linkedCount: 0 }
    );
  }, [searchResult, searchText]);
  /*** ******* */
  
  const addSavedLearnersToReduxStore = () => {
    const dbSelLearners:number[] = learnersData && learnersData.map((l:LearnerLL)=>
        (l.isSharedFile && !l.isDisabled ?l.learnerId:0)
    ).filter(l=>l>0);

    if(dbSelLearners.length && selectedFile.length){        
        props.updateLinkLearner(dbSelLearners)
    } 
  } 
  
  const getAdminMaterialSummary = () => {
    props.getAdminMaterialSummary(LLHlpr.getAllocId());
  }

  const upDateSelectedFile = (fileKey: string) => {
    setSelectedFile(fileKey);
  };

  const updateSelectedFileDetails = (file: FileDetails | undefined) => {
    setSelectedFileDetails(file);
  }

  const onCheckboxChange = (event: FormEvent<HTMLInputElement>) => {
    const checkboxlearnerId = +event.currentTarget.value; //plus to change str to int
    const checkboxAction = event.currentTarget.checked ? "add" : "remove";

    //...currSelLIds must spread. Then, only values will be copied. 
    // just assignment (=) will copy reference, and .push will trigger 
    // state mutation without dispatch, which will throw error
    let currentSelected: number[] = [...currSelLIds];

    if (checkboxAction === "add") {
      if (!_.includes(currentSelected, checkboxlearnerId)) {
        currentSelected.push(checkboxlearnerId);
      }
    } else if (checkboxAction === "remove") {
      if (_.includes(currentSelected, checkboxlearnerId)) {
        _.remove(currentSelected, (el) => el === checkboxlearnerId);
      }      
    }
    currentSelected && props.updateLinkLearner(currentSelected);
    if(currentFilter === FILTER_LEARNER.SEL || currentFilter === FILTER_LEARNER.UNSEL) {
      if(selCount%15 === 1 && currentPage > 1){
        setCurrentPage(currentPage-1)
      }
      if(unselCount%15 === 1 && currentPage > 1){
        setCurrentPage(currentPage-1)
      }  
    }
  };  

  const onSearch = (e: any, searchText: string) => {
    setSearchText(searchText);
    setCurrentPage(1);
  };

  const updateCurrentFilter = (filter: learnerFilterType) => {
    setCurrentFilter(filter);
    setCurrentPage(1);
  };    

  const handleSelectAll = (
    action: SEL_ALL_ACTIONS.ADDALL | SEL_ALL_ACTIONS.REMOVEALL
  ) => {
    const searchedFilteredLIds = searchedFilteredList
      .map((l) => (!l.isDisabled ? l.learnerId : 0))
      .filter((l) => l > 0);
    let addRemoveLIds: number[] = [];

    if (action === SEL_ALL_ACTIONS.ADDALL) {
      addRemoveLIds = _.uniq([...currSelLIds, ...searchedFilteredLIds]);
    } else if (action === SEL_ALL_ACTIONS.REMOVEALL) {
      addRemoveLIds = _.difference([...currSelLIds], searchedFilteredLIds);
    }

    props.updateLinkLearner(addRemoveLIds);
  };

  const onPagination = (newPage: number) => {
    setCurrentPage(newPage);
  };
  const getTotalPgCount = () => {
    return (
      learnersData && Math.ceil(learnersData.length / linkLearnerPerPageNumber)
    );
  };

  const handlePostSave = () => {
    const allocId = LLHlpr.getAllocId();
    props.getAdminMaterialSummary(allocId)  
    props.getSharedFileLearners(allocId, selectedFile, assessmentMethodType);
    localStorage.setItem("isLLMsgShow","true");
    setSearchText("")
    // setSelectedFile("");
  }

  const handleSaveMSFChanges = (
    allocationId: number,
    fileKey: string,
    selectedLearners: number[],
    unSelectedLearners: number[]
  ) => {
    props.saveLinkLearners(
      allocationId,
      fileKey,
      selectedLearners,
      unSelectedLearners
    );
  };

  const showSaveButton = (isBottom: boolean = false) => {
      return (!props.isShowLLLoader && selectedFile.length) ? (
        <div style={{width:"30%", float:"right", marginTop:isBottom?"0px":"20px"}}>
          <MSFSave
              selectedFile={selectedFile}
              selectedFileDetails={selectedFileDetails!}
              totalLearners={learnersData}
              currentSelectedLIds={currSelLIds}
              handlePostSave={handlePostSave}
              msfSaveResponse={props.saveLearnersResponse}
              msfSaveChanges={handleSaveMSFChanges}
          />
        </div>
      ) : (
        <></>
      )
  }

  return (
    <div>
      <MSFAlerts
        msfSaveResponse={props.saveLearnersResponse}
        getAdminMaterialSummary={getAdminMaterialSummary}
      />
      <MSFBackToVL isTop={true} />
      
      <div style={{ minHeight: "7vh" }}>
        <MSFGuidance />
        {showSaveButton()}
      </div>
      <br />
      <MSFDropdown
        getAdminMaterialSummary={getAdminMaterialSummary}
        getSharedFileLearners={getSharedFileLearners}
        adminSharedMaterial={props.adminSharedMaterial}
        updateSelectedFile={upDateSelectedFile}
        updateLinkLearner={updateLinkLearner}
        updateSelectedFileDetails={updateSelectedFileDetails}        
      />
      <div>
        {!props.isShowLLLoader && selectedFile.length ? (
          <div style={{ marginTop: "35px", float: "left", width: "100%" }}>
            <div style={{ width: "30%", float: "left" }}>
              <MSFSearch
                placeholderText="Search"
                searchText={searchText}
                onSearch={onSearch}
              />
            </div>
            <div style={{ width: "70%", float: "right" }}>
              <MSFFilters
                allCount={allCount}
                selCount={selCount}
                unselCount={unselCount}
                linkedCount={linkedCount}
                updateCurrentFilter={updateCurrentFilter}
                currentFilter={currentFilter}
              />
            </div>
          </div>
        ) : (
          <></>
        )}        
      </div>

      {selectedFile.length ? (
        <div id="show-learners">          
            {!props.isShowLLLoader && searchedFilteredList.length && selectedFileDetails?.fileStatusCode !== FILE_STATUS.REJECTED ? 
                (<MSFSelectAll
                    isCheckedSelectedAll={isSelAllChecked}
                    onSelectAll={handleSelectAll}
                />) :
                (<></>)}
            <MSFShowLearners
                learnersList={searchedFilteredList}
                learnersPerPage={linkLearnerPerPageNumber}
                currentPageNumber={currentPage}
                onCheckboxChange={onCheckboxChange}
                updateLinkLearner={updateLinkLearner}
                currentlySelectedLearners={currSelLIds} 
                isShowLLLoader={props.isShowLLLoader}
                selectedFile={selectedFileDetails!}
                
            />
          <div style={{ marginTop: "8px", float: "left", width: "100%" }}>
            <div
              className="paginationWrapper"
              style={{
                marginRight: LLHlpr.isRequestSubmitted() ? "0px" : "45px",
              }}
            >
              {!props.isShowLLLoader && searchedFilteredList.length > linkLearnerPerPageNumber ? (
                <Pagination
                 id="learner_pagination"
                 key={""}
                 activePage={currentPage + ""}
                 totalPageCount={getTotalPgCount()}
                 onPageChange={onPagination}
                 totalRecords={learnersData && learnersData.length}
                 pageCount={linkLearnerPerPageNumber}
                />
              ) : (
                <></>
              )}
            </div>
          </div>

          <div style={{ minHeight: "15vh" }}>
            <div style={{ width: "70%", float: "left" }}>
              {!props.isShowLLLoader?
              (<MSFBackToVL />):(<></>)}
            </div>
            {showSaveButton(true)}    
          </div>
        </div>
      ) : (
        " "
      )}
      <br />
      {/* <div style={{float:"left", width:"100%"}}>
        Current Selected:
        {JSON.stringify(props.currentlySelectedLinkLearners || [])}
      </div> */}
    </div>
  );
};

export default LinkLearners;
