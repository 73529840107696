import { actionTypes } from "../../../actions/action-types";
import { AssessmentDeadlineConfigAction } from "./assessment-deadline-config.actions";
import initialState from "../../../reducers/initialState";
import { QualificationDetails, ConfigSearchResult } from "../../../models/config-models";
import { Series } from "../../../models/series";

export function GetQualificationDetailsReducer(
  state: QualificationDetails[] = initialState.qualificationDetails,
  action: AssessmentDeadlineConfigAction | any
): QualificationDetails[] {
  if (action.type === actionTypes.CONFIG_GET_QUALIFICATION_SUCCESS) {
    // if(action.viewLearnersDetailsData.totalRecords>10){
    //   localStorage.setItem("vlpaginationData","true")
    // }
    return action.qualificationDetailsData;
  } else {
    return state;
  }
}

export function GetAssessmentDeadlinesReducer(
  state: ConfigSearchResult = initialState.assessmentDeadlines,
  action: AssessmentDeadlineConfigAction | any
): ConfigSearchResult {
  if (action.type === actionTypes.CONFIG_GET_ASSESSMENT_DEADLINES) {
    return action.assessmentDeadlineDetailsData;
  } else {
    return state;
  }
}

export function GetSeriesReducer(
  state: Series[] = initialState.assesssmentDeadlineConfigseries,
  action: AssessmentDeadlineConfigAction | any
): Series[] {
  if (action.type === actionTypes.CONFIG_GET_SERIES_SUCCESS) {
    return action.assesssmentDeadlineConfigseries;
  } else {
    return state;
  }
}