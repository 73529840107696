import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux"; //useSelector
import ShowAlert from '../../components/common/alert';
import { SaveLinkLearners } from "../../models/learner";
import { LL_SAVE_ERRORS } from "../../shared/app-enums";
// import { StoreState } from "../../store/store-state";
// import { getAdminMaterialSummary } from "../view-learner/ducks/view-learner.actions";
import * as LLHlpr from "./link-learners.helper";
interface Props {
  msfSaveResponse:SaveLinkLearners;
  getAdminMaterialSummary: (allocationId:number)=>void;
}
const MSFAlerts = (props:Props) => {
  const dispatch = useDispatch(); 
  // let msfSaveResponse: SaveLinkLearners = useSelector(
  //   (state: StoreState) => state.saveLinkLearners
  // );
  const msfSaveResponse = props.msfSaveResponse
  const noFileErrorMessage = "<div>The selected file has been removed.<div>";
  const fileRejectedErrorMessage = "<div>The selected file has been rejected and learners cannot be linked.<div>";
  const saveSuccessMessage = "<div>The change has been successfully saved for the chosen learner(s).</div>"  

  const [showFileNotExistMessage, setShowFileNotExistMessage] = useState(false);
  const [showFileRejectedMessage, setShowFileRejectedMessage] = useState(false);
  const [showSaveSuccessMessage, setShowSaveSuccessMessage] = useState(false)
  const [showSaveFailMessage, setShowSaveFailMessage] = useState(false);
  const [saveFailMessage, setSaveFailMessage] = useState('')

  useEffect(() => {
    // localStorage.setItem("isLLMsgShow", "true");
    const saveErrorStatus = LLHlpr.checkLinkLearnerSaveError(msfSaveResponse);
    switch(saveErrorStatus) {
      case LL_SAVE_ERRORS.FILE_DELETED:
        handleFileDeletedError();
        return;
      case LL_SAVE_ERRORS.FILE_REJECTED:
        handleFileRejectedError();
        return;
      case LL_SAVE_ERRORS.FULL_SUCCESS:
        showSaveFullSuccess();
        return;
      case LL_SAVE_ERRORS.PART_SUCCESS:
        showSavePartSuccess(msfSaveResponse);
        return; 
      case LL_SAVE_ERRORS.FULL_FAILURE:          
        showSaveFullFailure(); 
        return;      
    }    
  }, [msfSaveResponse])

  const handleFileDeletedError = () => {
    setShowFileNotExistMessage(true);
  }

  const handleFileRejectedError = () => {
    setShowFileRejectedMessage(true);
  }

  const showSavePartSuccess = (saveLLResponse:SaveLinkLearners) => {
    const addAbortedMsg: any = LLHlpr.makeErrorMessage(saveLLResponse,'addfailed');
    const removeAbortedMsg: any = LLHlpr.makeErrorMessage(saveLLResponse,'removefailed');
    const addTopMsg = "<div>The change cannot be saved for the following learner(s):</div>";
    const bottomMsg = "<div>For all other learners the change has been successfully saved</div>";
    const addTopSuccessMsg = "";
    setSaveFailMessage(addTopMsg + addTopSuccessMsg + '<span class="scroller" style="height: auto; max-height: 340px;">' + addAbortedMsg + removeAbortedMsg + bottomMsg + '</span>');
    showSaveFail();    
  }

  const showSaveFail = () => {
    const isLLMsgShow = localStorage.getItem("isLLMsgShow");
    if(isLLMsgShow === "true" && msfSaveResponse.hasError){
      setShowSaveFailMessage(true);
    }
  }

  const showSaveFullFailure = () => {
    setSaveFailMessage(LLHlpr.makeLLSaveFullFailureMsg()); 
    showSaveFail();
  }

  const closeFileNotExist = () => {    
    setShowFileNotExistMessage(false);
  }

  const closeFileRejected = () => {
    setShowFileRejectedMessage(false);
  }

  const showSaveFullSuccess = () => {    
    setShowSaveSuccessMessage(false);
    const isLLMsgShow = localStorage.getItem("isLLMsgShow");
    if(isLLMsgShow === "true" && !msfSaveResponse.hasError){
      setShowSaveSuccessMessage(true);
      localStorage.setItem("isLLMsgShow","false");
    }      
  }  

  const closeSaveSuccess = () => {
    localStorage.setItem("isLLMsgShow", "false");
    setShowSaveSuccessMessage(false); 
  }

  const closeSaveFail = () => {
    localStorage.setItem("isLLMsgShow", "false");
    setShowSaveFailMessage(false)
  }

  return (
    <>
      <ShowAlert
        containerId="save-success"
        parentStateShowAlertVar={showSaveSuccessMessage}
        parentStateHideAlertMethod={closeSaveSuccess}
        alertBody={{ styles: { fontSize: "12px" }, text: saveSuccessMessage }}
      />
      <ShowAlert
        containerId="save-fail"
        type="error"
        parentStateShowAlertVar={showSaveFailMessage}
        parentStateHideAlertMethod={closeSaveFail}
        alertBody={{ styles: { fontSize: "12px" }, text: saveFailMessage }}
        alertClassName="alert-msg-1"
      />
      <ShowAlert
        containerId="file-not-exist"
        type="error"
        parentStateShowAlertVar={showFileNotExistMessage}
        parentStateHideAlertMethod={closeFileNotExist}
        alertBody={{ styles: { fontSize: "12px" }, text: noFileErrorMessage }}
        alertClassName="alert-msg-1"
      />
      <ShowAlert
        containerId="file-rejected"
        type="error"
        parentStateShowAlertVar={showFileRejectedMessage}
        parentStateHideAlertMethod={closeFileRejected}
        alertBody={{ styles: { fontSize: "12px" }, text: fileRejectedErrorMessage }}
        alertClassName="alert-msg-1"
      />
    </>
  );
};

export default MSFAlerts;
