export enum actionTypes {
  AUTH_USER = "AUTH_USER",
  AUTH_IN_PROGRESS = "AUTH_IN_PROGRESS",
  GET_ENV_SUCCESS = "GET_ENV_SUCCESS",
  GET_EXAM_REQUESTS_SUCCESS = "GET_EXAM_REQUESTS_SUCCESS",
  GET_EXAM_REQUESTS_SUMMARY_SUCCESS = "GET_EXAM_REQUESTS_SUMMARY_SUCCESS",
  GET_EXAM_REQUESTS_DETAILS_SUCCESS = "GET_EXAM_REQUESTS_DETAILS_SUCCESS",
  GET_VIEW_PAPERS_DETAILS_SUCCESS = "GET_VIEW_PAPERS_DETAILS_SUCCESS",
  GET_VIEW_LEARNER_DETAILS_SUCCESS = "GET_VIEW_LEARNER_DETAILS_SUCCESS",
  ADD_LEARNER_MATERIAL_SUCCESS = "ADD_LEARNER_MATERIAL_SUCCESS",
  REMOVE_LEARNER_MATERIAL_SUCCESS = "REMOVE_LEARNER_MATERIAL_SUCCESS",
  UPDATE_LEARNER_STATUS_SUCCESS = "UPDATE_LEARNER_STATUS_SUCCESS",
  SUBMIT_ALLOCATION_SUCCESS = "SUBMIT_ALLOCATION_SUCCESS",
  CHECK_SUBMIT_ALLOWED_SUCCESS = "CHECK_SUBMIT_ALLOWED_SUCCESS",
  GET_SERIES_SUCCESS = 'GET_SERIES_SUCCESS',
  GET_EVIDENCE_SEARCH_RESULT_SUCCESS = 'GET_EVIDENCE_SEARCH_RESULT_SUCCESS',
  GET_MODERATION_REQUESTS_SUCCESS = 'GET_MODERATION_REQUESTS_SUCCESS',
  GET_MODERATION_REQUESTS_SUMMARY_SUCCESS = "GET_MODERATION_REQUESTS_SUMMARY_SUCCESS",
  GET_MANAGE_LEARNER_DETAILS_SUCCESS = 'GET_MANAGE_LEARNER_DETAILS_SUCCESS',
  UPDATE_MANAGE_LEARNER_SUCCESS = 'UPDATE_MANAGE_LEARNER_SUCCESS',
  SAVE_MANAGE_LEARNER_SUCCESS = 'SAVE_MANAGE_LEARNER_SUCCESS',
  GET_LINK_LEARNER_DETAILS_SUCCESS = 'GET_MANAGE_LEARNER_DETAILS_SUCCESS',
  UPDATE_LINK_LEARNER_SUCCESS = 'UPDATE_LINK_LEARNER_SUCCESS',
  SAVE_LINK_LEARNER_SUCCESS = 'SAVE_LINK_LEARNER_SUCCESS',
  ADD_ADMIN_MATERIAL_SUCCESS = 'ADD_ADMIN_MATERIAL_SUCCESS',
  REMOVE_ADMIN_MATERIAL_SUCCESS = 'REMOVE_ADMIN_MATERIAL_SUCCESS',
  GET_ADMIN_LEARNER_SUMMARY_SUCCESS = 'GET_ADMIN_LEARNER_SUMMARY_SUCCESS',
  GET_SHARED_FILE_LEARNERS_SUCCESS = 'GET_SHARED_FILE_LEARNERS_SUCCESS',
  UPDATE_LINK_LEARNER_LOADER_SUCCESS = 'UPDATE_LINK_LEARNER_LOADER_SUCCESS',
  CONFIG_GET_QUALIFICATION_SUCCESS = 'CONFIG_GET_QUALIFICATION_SUCCESS',
  CONFIG_GET_ASSESSMENT_DEADLINES = 'CONFIG_GET_ASSESSMENT_DEADLINES',
  CONFIG_SUBMIT_ASSESSMENT_DEADLINE = 'CONFIG_SUBMIT_ASSESSMENT_DEADLINE',
  CONFIG_MANAGE_GET_ASSESSMENT_DEADLINES = 'CONFIG_MANAGE_GET_ASSESSMENT_DEADLINES',
  CONFIG_GET_SERIES_SUCCESS = 'CONFIG_GET_SERIES_SUCCESS',
  DOWNLOAD_ADMIN_MATERIAL_SUCCESS = 'DOWNLOAD_ADMIN_MATERIAL_SUCCESS',
  CONFIG_PRS_SUBMIT_ASSESSMENT_DEADLINE='CONFIG_PRS_SUBMIT_ASSESSMENT_DEADLINE'
}
