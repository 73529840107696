import User from "../../models/user";
import { Types as UserActionTypes } from "./context-api-action-types";

export type UserLoginDataType = {
  userData?: User;
  cognitoPearsonUID?: string;
  userName?: string;
};

export type UserAction = {
  type: UserActionTypes;
  payload?: UserLoginDataType;
};

// Action Creator for UserLoggedIn
export const userLoggedIn = (userLoginData: UserLoginDataType) => {
  return {
    type: UserActionTypes.UserLoggedInAction,
    payload: userLoginData,
  };
};
