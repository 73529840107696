import React, { useEffect,FormEvent } from "react";
import { useHistory } from "react-router";
import { LearnerML, SaveLearners } from "../../models/learner";
import ManageLearnerSearchBar from "../../components/pearson/manageLearnerSearchBar";
import Pagination from "../../components/pearson/pagination";
import {viewLearners,searchLearnerGrouping} from "./manage-learner-helper";
import _ from "lodash";
import Button from "../../components/pearson/button";
import Icon from "../../components/pearson/icon";
import Loader from "../../components/common/loader/loader";
import ShowAlert from '../../components/common/alert';
import {getSavedSearch} from '../../helpers/dashboard-request-helper';
import SavedSearch from "../../models/saved-search";
import { IsUserInternal } from '../../components/common/user-validation';
import { initialStateType } from "../../shared/context-api/provider";
import { useUserLoginState } from "../../shared/context-api/hooks";
import Impersonating from "../Impersonating/impersonating.component";
import "./manage-learners.css";
import * as dbrdHelper from '../../helpers/dashboard-helper'
import { HasWriteAccess  } from "../../components/common/user-validation";


export interface StateProps {
  learners?: LearnerML[];
  currentlySelectedManageLearners?: number[];
  saveLearnersResponse: SaveLearners;
}
export interface DispatchProps {
  getLearners: (allocationId: string) => Promise<void>;
  updateManageLearner: (learnersData: number[]) => void;
  saveManageLearners: (
    allocationId: string, 
    selectedLearners: number[], 
    unSelectedLearners: number[]
  ) => Promise<void>;
}


interface Props extends DispatchProps, StateProps {}
const ManageLearners = (props: Props) => {
  // console.log("--------------ML-RENDER", props.currentlySelectedManageLearners);
  const learnersData:any = props && props.learners;
  const manageLearnerPerPageNumber = 15;
  
  const [currentPage, setCurrentPage] = React.useState(1);
  const [savedSearchText, setSavedSearchText] = React.useState("");
  const [savedLearnerData, setSavedLearnerData] = React.useState(learnersData);
  const [searchOption,setSearchOption] = React.useState("");
  const [noLearnerEligible, setNoLearnerEligible] = React.useState(false);  
  const [loadingContent, setLoadingContent] = React.useState(false);
  const [isCheckSelectAll, setIsCheckSelectAll] = React.useState(false);
  const [showSaveSuccessMessage, setShowSaveSuccessMessage] = React.useState(false);
  const [saveSuccessMessage, setSaveSuccessMessage] = React.useState("");  
  const [showSaveFailMessage, setShowSaveFailMessage] = React.useState(false);
  const [saveFailMessage, setSaveFailMessage] = React.useState("");
  const [learnerCount, setLearnerCount] = React.useState(0);
  
  document.title = "Learner Work Transfer - Manage Learners";
  localStorage.removeItem("viewLearnerGetData")

  
  const savedSearch = getSavedSearch() as SavedSearch;
  const [allocationId] = React.useState(savedSearch.selectedAllocationId || '-999'); // -999 denotes error
  const saveLearnersResponse = props.saveLearnersResponse;
  const [saveLearner,setSaveLearner] = React.useState(false);

  React.useMemo(() => {    
    setSavedLearnerData(learnersData);    
  }, [learnersData]);

  useEffect(() => {
    //setCurrentPage(1);
    setLearnerCount(savedLearnerData.length)
  },[savedLearnerData,currentPage]);

  useEffect(() => {
    const dbSelLearners = learnersData.map((l:LearnerML)=>
      (l.isCentreSelected?l.learnerId:null)
    ).filter(Boolean);
    // everytime API response comes, initiate current selected
    props.updateManageLearner(dbSelLearners)
  }, [learnersData])

  useEffect(() => {    
    setTimeout(() => {
      setNoLearnerEligible(true);
    }, 4000);
    props.getLearners(allocationId);
    window.scrollTo(0, 0);
    window.addEventListener('beforeunload', dbrdHelper.alertUser)
    window.addEventListener('unload', dbrdHelper.handleTabClosing)
        return () => {
            window.removeEventListener('beforeunload', dbrdHelper.alertUser)
            window.removeEventListener('unload', dbrdHelper.handleTabClosing)
        }

  }, []);

  
  useEffect(() => {
    // Check if Select All should be checked.
    // Otherwise uncheck it.
    // Everytime store updates, component re-renders.
    // At that time, the updated current selected can be checked.
    // It cannot be checked in the event handler, when each checkbox clicked 
    const searchResult = getAlllearnerIdsWithSearchCheck(learnersData,savedLearnerData)
    const newSel = getNewlySelectedLearners(learnersData);
    const currSel = props.currentlySelectedManageLearners||[];      

    if(!searchResult.length) {
      // If no search is performed, searchResult 
      // will have all learner Ids, so if thats blank, stop.
      return;
    }
   
    if(isSelectAllSearchResults(newSel,searchResult) || 
       isSelectAllLearners(searchResult, currSel)
    ) {
      setIsCheckSelectAll(true)
    } else {
      setIsCheckSelectAll(false)
    }
  }, [props.currentlySelectedManageLearners]);

  // console.log("props.saveLearnersResponse",props.saveLearnersResponse)
  
  useEffect(() => {
    // JUST FOR TESTING ALERT
    // setSaveFailMessage(testSaveErrorMsg);
    // setShowSaveFailMessage(true);
    const isMLMsgShow = localStorage.getItem("isMLMsgShow");
    if(saveLearner){
      if (saveLearnersResponse && saveLearnersResponse.hasError) {
        const addAbortedMsg: any = makeErrorMessage(saveLearnersResponse,'addfailed');
        const removeAbortedMsg: any = makeErrorMessage(saveLearnersResponse,'removefailed');
        const addTopMsg = "<div>There has been an error.</div>";
        // const addTopMsg = "<div>If your chosen learners are not listed below, they have been sucessfully <br added or removed.</div>";
        const addTopSuccessMsg = "";
        //<div>** The learners not appearing below has been successfully added/removed.</div>";
        setSaveFailMessage(addTopMsg + addTopSuccessMsg + '<span class="scroller">' + addAbortedMsg + removeAbortedMsg + '</span>');
        showSaveFail();
        setSaveLearner(false)
      } else if (saveLearnersResponse && false === saveLearnersResponse.hasError) {
        setSaveSuccessMessage("<div>The change has been successfully saved.</div>");
        showSaveSuccess();
        setSaveLearner(false)        
      }
    }
    // return () => {
    //   cleanup
    // }
  }, [saveLearner]);
  
  useEffect(() => {
    window.addEventListener("beforeunload", setLocalStorageEmpySelected);
    return () => {
    window.removeEventListener("beforeunload", setLocalStorageEmpySelected);
    };
    }, []);
 
  const makeErrorMessage = (saveResponse:SaveLearners, errorType:('addfailed'|'removefailed')) => {
    let htmlText = '';
    if(errorType.length && errorType === 'addfailed') {
      const learners = (saveResponse && saveResponse.addAbortedLearners) || [];
      if(learners.length){
        htmlText = '<p>The following learner(s) cannot be added:</p>';
        htmlText+= makeSaveErrorLearnersList(learners);
        htmlText+= showSaveErrorPossibleReasons(errorType);
      }
    }  
    if (errorType.length && errorType === 'removefailed') {
      const learners = (saveResponse && saveResponse.removeAbortedLearners) || [];
      if(learners.length){
        htmlText = '<p>The following learners cannot be removed:</p>';
        htmlText+= makeSaveErrorLearnersList(learners);
        htmlText+= showSaveErrorPossibleReasons(errorType);
      }
    }
    return htmlText;
  }

  const makeSaveErrorLearnersList = (learners:{learnerCode:string, learnerName:string}[]) => {
    let htmlText = '';
    if(learners.length){      
      learners.forEach( l=>{
        htmlText+=`
          <div>
            <span>${l.learnerCode}</span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span>${l.learnerName}</span>
          </div>`;
      });      
    }
    return htmlText;
  }

  const showSaveErrorPossibleReasons = (errorType:('addfailed'|'removefailed')) => {
    let htmlText = '';
    htmlText+=`
          <div class="sub-heading-3">The possible reasons are:</div>
            <ul>
              <li>their entry has been withdrawn</li>`;
    if(errorType === 'addfailed'){
      htmlText+=`
              <li>they have already been added</li>`;
    }
    if(errorType === 'removefailed') {
      htmlText+=`
              <li>they have been selected by Pearson</li>
              <li>they have already been removed</li>
              <li>they have at least one file uploaded for assessment.</li>`;
    }
    htmlText+=`
            </ul>`;
    return htmlText;              
  }

  const setLocalStorageEmpySelected = (e:any) => {
    localStorage.setItem("makeSelectedLearnersEmpty", 'false');
    e.preventDefault();
    // e.returnValue = "";  // This will show an alert box  
  };

  const getDBSelectedLearners = (learnersDataP: LearnerML[] = []) => {
    let prevSelected: number[] = [];
    learnersDataP.forEach(l=>{
      if(l.isCentreSelected){
        prevSelected.push(l.learnerId)
      }
    });
    return prevSelected;
  }

  const getPreviousSelectedLearners = (learnersDataP: LearnerML[] = []) => {
    let prevSelected: number[] = getDBSelectedLearners(learnersDataP);
    
    if(localStorage.getItem("makeSelectedLearnersEmpty") === 'true') {
      // Ignore all selected came with API response
      prevSelected = []
    }
    return prevSelected;
  }
  
  const getCurrentSelectedLearners = (learnersDataP: LearnerML[] = []) => {
    let currentSelected: number[] = [];
    currentSelected = getPreviousSelectedLearners(learnersDataP);    
    currentSelected = _.uniq([...currentSelected,...props.currentlySelectedManageLearners||[]]);
   
    if(props && props.currentlySelectedManageLearners){ 
      if(props.currentlySelectedManageLearners.length>0){
        currentSelected = [...props.currentlySelectedManageLearners];
      } 
    }    
    return currentSelected;
  }  
  
  const onSearch = (e: any, searchText: string) => {
     setCurrentPage(1)
     setLearnerCount(0)
    
    let searchReqGroup = searchLearnerGrouping(
      learnersData,      
      searchOption,
      searchText
    );
   
    setSavedLearnerData(searchReqGroup)
    // const isChkSelectAll = isAllSelected(learnersData, searchReqGroup, searchText)
    // setIsCheckSelectAll(isChkSelectAll);
    let currentSelected = getCurrentSelectedLearners(learnersData);
    if(getAlllearnerIdsWithSearchCheck(learnersData,savedLearnerData).length === currentSelected.length &&
    currentSelected.length === learnersData.length)
    {
      setIsCheckSelectAll(true)
    } else {
      setIsCheckSelectAll(false)
    }
  };

  const onPaginationFetchLearner = (newPageNumber: number) => {
    setCurrentPage(newPageNumber);
    setLoadingContent(true);
    // viewLearners(
    //   savedLearnerData,              
    //   manageLearnerPerPageNumber,
    //   currentPage,
    // )
  }

  const handleSelectAll = (event:FormEvent<HTMLInputElement>) => {    
    const selectAllAction = event.currentTarget.checked?'addall':'removeall';

     // ignore selected learners came with API response
    // localStorage.setItem("makeSelectedLearnersEmpty", 'true');

    let currentSelected:number[] = [];
    let learnerIdsForSelectAll = getAlllearnerIdsWithSearchCheck(learnersData, savedLearnerData);
    let overallLearnerIds = getAlllearnerIds(learnersData);
    let storeStateSelectedLearnersLen = getCurrentSelectedLearners(learnersData).length || 0;

    if(selectAllAction === 'addall') {
      if(storeStateSelectedLearnersLen > 0) { //manually selected some, before search
        const storeStateSelectedLearners = getCurrentSelectedLearners(learnersData) || [];        
        currentSelected = _.uniq([...learnerIdsForSelectAll,...storeStateSelectedLearners]);        
      } else {
        currentSelected = learnerIdsForSelectAll;
      }      
      setIsCheckSelectAll(true);
    } 
    else if(selectAllAction === 'removeall') {
     
      if(overallLearnerIds.length === storeStateSelectedLearnersLen){
        // select all was checked before search
        if(savedSearchText.length){
          // select all getting unchecked after search
          // so, remove only the search result learners from select all 
          currentSelected = _.difference(overallLearnerIds, learnerIdsForSelectAll);
          setIsCheckSelectAll(false);
        } else {
          // select all getting unchecked without search
          // so just unselect all
          currentSelected = [];
          localStorage.setItem("makeSelectedLearnersEmpty", 'true');
        }
      } else {        
        // select all checked after search, and now it is getting unchecked
        // so, remove only the search result learners from select all
        // CHECK IF MANUALLY SELECTED LEARNERS ARE THERE
        if(storeStateSelectedLearnersLen > 0) { // Manually selected some, before search
          const storeStateSelectedLearners = props && props.currentlySelectedManageLearners;
          currentSelected = _.difference(storeStateSelectedLearners, learnerIdsForSelectAll);
        } else if (storeStateSelectedLearnersLen === 0) {
          currentSelected = [];
          localStorage.setItem("makeSelectedLearnersEmpty", 'true');
        }
        
      } 
      setIsCheckSelectAll(false);     
    } 
    currentSelected && props.updateManageLearner(currentSelected); 
  }

  const isSelectAllSearchResults = (
    newlySelectedLearners: number[], 
    searchResultLearners: number[]
  ):boolean => {

    if(_.difference(searchResultLearners, newlySelectedLearners).length === 0){      
      return true;
    }
    return false;
  }

  const isSelectAllLearners = (
    allLearners: number[], 
    currentlySelectedLearners: number[]
  ):boolean => {
    // console.log(">>>>>>>>>",_.difference(allLearners, currentlySelectedLearners).length,"all=",allLearners, "current=",currentlySelectedLearners)
    if(_.difference(allLearners, currentlySelectedLearners).length === 0){
      return true;
    }
    return false;
  }

  const getAlllearnerIds = (learnersDataP: LearnerML[]) => {
    const currentSelected: number[] = [];
    learnersDataP.forEach( l => {
      currentSelected.push(l.learnerId);
    })
    return currentSelected;
  }

  const getAlllearnerIdsWithSearchCheck = (learnersDataP: LearnerML[], savedLearnerDataP: LearnerML[]) => {
    const currentSelected: number[] = [];
    if (savedLearnerData && savedLearnerData.length){
      savedLearnerDataP.forEach( sld => {
        currentSelected.push(sld.learnerId);
      })
    } else {
      learnersDataP.forEach( l => {
        currentSelected.push(l.learnerId);
      })
    }
    return currentSelected;
  }

  const getUncheckedLearners = (learnersDataP: LearnerML[]=[]) => {
    let dbSelectedLearners: number[] = getDBSelectedLearners(learnersDataP);
    let currentSelected: number[] = getCurrentSelectedLearners(learnersDataP);
    let unCheckedLearners = _.difference(dbSelectedLearners,currentSelected);
    // console.log("UnselectedLearners:::::",unCheckedLearners);
    return unCheckedLearners;
  }

  const getNewlySelectedLearners = (learnersDataP: LearnerML[]=[]) => {
    let dbSelectedLearners: number[] = getDBSelectedLearners(learnersDataP);
    let currentSelected: number[] = getCurrentSelectedLearners(learnersDataP);
    let newlySelected = _.difference(currentSelected, dbSelectedLearners);
    // console.log("Newly Selected::",newlySelected);
    return newlySelected;
  }

  const onCheckboxChange = (event:FormEvent<HTMLInputElement>) => {
    const checkboxlearnerId = +event.currentTarget.value; //plus to change str to int
    const checkboxAction = event.currentTarget.checked?'add':'remove';
    let currentSelected: number[] = getCurrentSelectedLearners(learnersData)
    if(checkboxAction === 'add') {
      if(!_.includes(currentSelected, checkboxlearnerId)){
        currentSelected.push(checkboxlearnerId)
      }
    } else if( checkboxAction === 'remove') {
      let lastLen = currentSelected.length;
      if(_.includes(currentSelected, checkboxlearnerId)){
        _.remove(currentSelected, (el)=>el===checkboxlearnerId)
      }
      if(lastLen===1 && currentSelected.length===0){
        //Ignore selected learners came with API response
        localStorage.setItem("makeSelectedLearnersEmpty", 'true');        
      }
    }    
    currentSelected && props.updateManageLearner(currentSelected)
    // Whether Select All should be checked cannot be decided here, it should be
    // decided in an useEffect, which executes everytime 
    // props.currentSelectedManageLearners changes
  }

  const onTextChange = (event: FormEvent<HTMLInputElement>) => {
    const stxt =
      event && event.target && (event.target as HTMLInputElement).value;
    //  setSavedLearnerData(learnersData)
    setSavedSearchText(stxt);
    

  };  
  
  // const learnerDataLen = savedLearnerData.length;
  let combinedSelectedLearners = getCurrentSelectedLearners(learnersData);
  // const newSelectedLearners = combinedSelectedLearners.length - getPreviousSelectedLearners(learnersData).length;

  const BackToViewLearnersLink = () => {
    const pageURL = window.location.href;
    const lastURLSegment = pageURL.substring(pageURL.lastIndexOf("/") + 1);

    const history = useHistory();
    const backToViewLearners = () => {
      // Manage Learner is not refresh-safe
      props && props.updateManageLearner([]);
      // Count the selected learners came with API response 
      localStorage.setItem("makeSelectedLearnersEmpty", 'false');
      lastURLSegment === '1'?history.push("/view-learner/1"):history.push("/view-learner");
    };
  
    return (
      <span style={{ marginTop: "3px", width: "100%", float: "left" }} onClick={backToViewLearners.bind(null)}>
          <Icon size="18" icon="back" />
          <span className="backToViewLearnersLink gr-semi-bold">
            &nbsp;&nbsp;&nbsp;{"Back to View Learners"}
          </span>
      </span>
    );
  };

  const showManageLearnerSaveBtn = (combinedSelectedLearnersP:number[] = [], isBottomPosition = false) => {
    return (<div style={{width:"20%",float:"left"}}>
      {!isBottomPosition && combinedSelectedLearners.length === 1 && (<div style={{textAlign: 'right'}}>
          There is {combinedSelectedLearners.length} learner selected.
          {/* (Added Now: {newSelectedLearners}) */}
      </div>)}
      {!isBottomPosition && combinedSelectedLearners.length !== 1 && (<div style={{textAlign: 'right'}}>
          There are {combinedSelectedLearners.length} learners selected.
          {/* (Added Now: {newSelectedLearners}) */}
      </div>)}
      <Button
        label="Save"
        type="submit"
        // disabled={combinedSelectedLearners.length > 0 ? false : true}
        className="attention"
        customClass="saveLrBut" 
        onClick={handleSaveClick}
        disabled={!HasWriteAccess(userLoginstate)}
      />
      {/* <button onClick={showSaveFail}>x</button> */}
    </div>)
  }

  const showNoLearnerMessage = () => {          
    return (
      <div style={{width:"80%",float:"left"}}>    
        No learners can be added to the request.<br/>
        Only the following learners will be displayed in this screen:<br/> 
          <ul>
            <li>Learners not added by Pearson to the request</li>
	          <li>Learners not added by a user at your Learning Provider</li>
	          <li>Learners added by a user at your Learning Provider but do not have any uploaded evidence</li>
          </ul>
	      If a user-added learner must be removed, please remove all uploaded evidence from that learner.<br/>
        Their details will appear in this screen.
    </div>
      )    
  }

  const showGeneralMessage = () => (
    <div style={{width:"80%",float:"left"}}>
      This screen shows all learners who can be added to the request. <br />

      The following learners will not be shown:<br />
      <ul>
        <li>Learners who have been pre-selected by Pearson.</li>
        <li>Learners added by any users who now have at least one file uploaded for assessment.</li>
      </ul>
    </div> 
  )  
  const handleSaveClick = async (e:any) => {
    let unSelectedLearners = getUncheckedLearners(learnersData);
    let selectedLearners = getNewlySelectedLearners(learnersData); 
    // let unSelLearnerIds =  convertLCodeToLId(unSelectedLearners, learnersData);  
    // let selLearnerIds =  convertLCodeToLId(selectedLearners, learnersData); 
    
    await props.saveManageLearners(allocationId,selectedLearners,unSelectedLearners); 
    props.getLearners(allocationId);  
    setSaveLearner(true);  
  }
  
  const showSaveSuccess = () => {  
    setShowSaveSuccessMessage(true);
    const isMLMsgShow = localStorage.getItem("isMLMsgShow");
    if(isMLMsgShow === "true" && !saveLearnersResponse.hasError){
      setShowSaveSuccessMessage(true);
    }
  }

  const closeSaveSuccess = () => {
    localStorage.setItem("isMLMsgShow", "false");
    setShowSaveSuccessMessage(false); 
  }

  const showSaveFail = () => {
    const isMLMsgShow = localStorage.getItem("isMLMsgShow");
    if(isMLMsgShow === "true" && saveLearnersResponse.hasError){
      setShowSaveFailMessage(true);
    }
  }

  const closeSaveFail = () => {
    localStorage.setItem("isMLMsgShow", "false");
    setShowSaveFailMessage(false)

  }

  const viewPaperDetails = localStorage.getItem("viewPaperDetails");
  const actOnBehalfLP = localStorage.getItem('actOnBehalfLP')||'NA';
  // console.log("setCurrentPage",currentPage)
  const userLoginstate = useUserLoginState(); 
  
  return (
    <div>       
        <ShowAlert 
          containerId='save-success' 
          parentStateShowAlertVar={showSaveSuccessMessage} 
          parentStateHideAlertMethod={closeSaveSuccess} 
          alertBody={{styles:{fontSize:"12px"}, text:saveSuccessMessage}} 
        />
        <ShowAlert 
          containerId='save-fail' type='error'
          parentStateShowAlertVar={showSaveFailMessage} 
          parentStateHideAlertMethod={closeSaveFail} 
          alertBody={{styles:{fontSize:"12px"}, text:saveFailMessage}} 
          alertClassName="alert-msg-1"  
        />
         {IsUserInternal(userLoginstate as initialStateType) &&
          <Impersonating behalfOf={actOnBehalfLP} backText="" backLink="" page="" />
         }
        <div style={{ marginTop: "40px" }}>
          
              <div className="manage-learner-link">
                  <BackToViewLearnersLink />
              </div>
              <div className="manage-learner-header">
                  <span className="manage-learner-papername-prefix">Manage Learners for&nbsp;</span> 
                  <span>{viewPaperDetails}</span>
              </div>
         

          {learnersData.length ?
          (<div>
          <div style={{width:"100%",float:"left"}}><br />
            {showGeneralMessage()} 
            {learnerCount && 
              learnerCount >0 && 
              savedLearnerData && 
              savedLearnerData.length> 0 ? showManageLearnerSaveBtn(combinedSelectedLearners, false):("")}            
          </div>
          <div style={{ marginTop: "35px", float: "left", width: "100%" }}>
            <ManageLearnerSearchBar
              searchText={savedSearchText}
              onSearch={onSearch}
              filterValue=""
              onTextChange={onTextChange}
              //learnerSummary={props.learnerDetails.learnersCount}
              placeholderText="Search"
            />        
          </div>
          <div>&nbsp;</div>
          </div>):
          (<div style={{ marginTop: "50px" }}>
            {noLearnerEligible && showNoLearnerMessage()}
            <span style={{marginLeft:"50vw"}}>
              <Loader timeoutSecs={4000} message={" "}/>
            </span>
          </div>)}       
        </div> 

        {learnersData.length ? 
        (<div>
            {learnerCount && learnerCount >0 && savedLearnerData && savedLearnerData.length> 0 ? 
            (<div className="" style={{float:"left",width:"100%", marginBottom:"10px", marginTop:"10px"}}>
              &nbsp;&nbsp;&nbsp;
              <input type="checkbox" id="selall" key="selallml" onChange={handleSelectAll} checked={isCheckSelectAll} disabled={!HasWriteAccess(userLoginstate)}/>
              <span style={{marginLeft:"5px", position:"relative", top: "-2px"}}>Select All</span>
            </div>):("")}
            {viewLearners(
              savedLearnerData,              
              manageLearnerPerPageNumber,
              currentPage,
              onCheckboxChange,
              combinedSelectedLearners
            )}

            <div style={{ marginTop: "8px", float: "left", width: "100%" }}>   
              
                {learnerCount && learnerCount >0 && savedLearnerData && savedLearnerData.length> 0  ? (
                  <div className="paginationWrapper" style={{ marginRight: "45px" }}>
                  <Pagination
                    id="learner_pagination"
                    key={""}
                    totalPageCount={Math.ceil(
                      savedLearnerData.length / manageLearnerPerPageNumber
                    )}
                    onPageChange={onPaginationFetchLearner}
                    totalRecords={savedLearnerData.length}
                    
                    pageCount = {manageLearnerPerPageNumber}
                  />
                  </div>
                ):(<div style={{marginBottom:"50px"}}>There are no matching results</div>)}              
            </div>

            <div style={{ minHeight: "15vh" }}>
              <div style={{width:"80%",float:"left"}}>
                <BackToViewLearnersLink />
              </div>
              {learnerCount && 
              learnerCount >0 && 
              savedLearnerData && 
              savedLearnerData.length> 0 ? showManageLearnerSaveBtn(combinedSelectedLearners, true): ("")}
            </div>
        </div>):(" ")
        }
    </div>
  );
};

export default ManageLearners;
