import axios, { AxiosRequestConfig } from "axios";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import config from "../../../app-config";

import { actionTypes } from "../../../actions/action-types";
import { StoreState } from "../../../store/store-state";
import {LearnerML, SaveLearners}  from "../../../models/learner";
import {dateFormatter} from "../../../helpers/dashboard-request-helper";

interface GetManageLearnersDetailsAction extends Action {
  type: actionTypes.GET_MANAGE_LEARNER_DETAILS_SUCCESS;
  manageLearnersDetailsData: LearnerML;
}

interface UpdateManageLearnerAction extends Action {
  type: actionTypes.UPDATE_MANAGE_LEARNER_SUCCESS;
  selectedLearnersData: number[];
}

interface SaveManageLearnerAction extends Action{
  type: actionTypes.SAVE_MANAGE_LEARNER_SUCCESS;
  saveLearnersData: SaveLearners;
}
interface EmptySelectedManageLearnersAction extends Action {
  type: actionTypes.GET_MANAGE_LEARNER_DETAILS_SUCCESS;
  manageLearnersDetailsData: LearnerML[];
}





export type ManageLearnersAction = 
  | GetManageLearnersDetailsAction
  | UpdateManageLearnerAction
  | EmptySelectedManageLearnersAction
  | SaveManageLearnerAction;

type ThunkResult<R> = ThunkAction<R, StoreState, undefined, ManageLearnersAction>;



export const GetManageLearnersDetailsSuccessAction = (
  manageLearnersDetailsData: LearnerML
): GetManageLearnersDetailsAction => ({
  manageLearnersDetailsData,
  type: actionTypes.GET_MANAGE_LEARNER_DETAILS_SUCCESS,
});

export const UpdateManageLearnerSuccessAction = (
  selectedLearnersData: number[]
): UpdateManageLearnerAction => ({
  selectedLearnersData,
  type: actionTypes.UPDATE_MANAGE_LEARNER_SUCCESS
});

export const SaveManageLearnerSuccessAction = (
  saveLearnersData: SaveLearners
): SaveManageLearnerAction => ({
  saveLearnersData,
  type: actionTypes.SAVE_MANAGE_LEARNER_SUCCESS
});

export const EmptySelectedManageLearnersSuccessAction = (
  manageLearnersDetailsData: LearnerML[]
): EmptySelectedManageLearnersAction => ({
  manageLearnersDetailsData,
  type: actionTypes.GET_MANAGE_LEARNER_DETAILS_SUCCESS,
});




export const getManageLearnersDetails = (
  allocationId: string,
  
): ThunkResult<Promise<void>> => {
  return (dispatch) => {
    const axiosConfig: AxiosRequestConfig = {
      headers: { "content-type": "application/json" },
    };
    return axios
      .post(
        `${config.API_GATEWAY.URL}/manage-learners/list/0`,
        JSON.stringify({
          allocationId,          
        }),
        axiosConfig
      )
      .then((postResponse) => {
        const postResponseData = postResponse.data;
        const manageLearnersDetailsData = postResponseData.map( (request: LearnerML)=>{
          request.learnerDob = request.learnerDob !== null ? dateFormatter(request.learnerDob).date : "NA";
          return request;
        })
        dispatch(GetManageLearnersDetailsSuccessAction(manageLearnersDetailsData));
      })
      .catch((error) => {
        console.log("GetManageLearners Action Error==>", error);
        // throw error;
      });
  };
};

export const saveManageLearnersDetails = (
  allocationId: string,
  selectedLearners: number[],
  unSelectedLearners: number[]
): ThunkResult<Promise<void>> => {
  return (dispatch) => {
    const axiosConfig: AxiosRequestConfig = {
      headers: { "content-type": "application/json" },
    };
    /** Convert string array of Learner Ids to Int array */
    // const unSelectedLearnersNum = unSelectedLearners.map(s=>+s);
    // const selectedLearnersNum = selectedLearners.map(s=>+s);
    /** */
    return axios
      .post(
        `${config.API_GATEWAY.URL}/manage-learners/save`,
        JSON.stringify({
          allocationId,
          // selectedLearners:selectedLearnersNum,
          // unSelectedLearners:unSelectedLearnersNum          
          selectedLearners,
          unSelectedLearners
        }),
        axiosConfig
      )
      .then((postResponse) => {
        const saveLearnersResponse = postResponse.data as SaveLearners;
        localStorage.setItem("isMLMsgShow", "true");
        // console.log(localStorage.getItem("isMLMsgShow"),"::::Response SL");
        dispatch(SaveManageLearnerSuccessAction(saveLearnersResponse));
      })
      .catch((error) => {
        // throw error;
      });
  };
};

export const updateManageLearner = (
  selectedLearnersData: number[]  
): UpdateManageLearnerAction => {
   return UpdateManageLearnerSuccessAction(selectedLearnersData);
};













