import React from "react";
import { userDataState, userDataDispatch } from "./context";
import { UserAction, UserLoginDataType } from "./actions";

export const useUserLoginState = (): UserLoginDataType => {
  const context = React.useContext(userDataState);
  if (undefined === context) {
    throw new Error("Please use within UserDataStateProvider");
  }
  return context;
};

export const useUserLoginDispatch = (): React.Dispatch<UserAction> => {
  const context = React.useContext(userDataDispatch);
  if (undefined === context) {
    throw new Error("Please use within UserDataDispatchProvider");
  }
  return context;
};
