import { any } from "prop-types"
import { array } from "yargs"

export enum fileStatusEnums {
    AVATS_STATUS_INFECTED                       = 'INFECTED',
    AVATS_STATUS_REJECTED                       = 'REJECTED',
    AVATS_STATUS_INFECTED_TEXT                  = 'This file is infected with a virus and must be removed.',
    AVATS_STATUS_INFECTED_ZIP_TEXT              = 'Within this compression file, [X] file(s) are infected with a virus.  The compression file must be removed and the problem files replaced with versions that will pass security and virus checks.',
    
    AVATS_STATUS_PASSWORD_PROTECTED             = 'PASSWORD PROTECTED',
    AVATS_STATUS_PASSWORD_PROTECTED_TEXT        = 'This file is password protected and must be removed.',
    AVATS_STATUS_PASSWORD_PROTECTED_ZIP_TEXT    = 'One or more files within this compression file, are password protected and must be removed.',

    AVATS_STATUS_UNSUPPORTED                    = 'UNSUPPORTED',
    AVATS_STATUS_UNSUPPORTED_TEXT               = 'This is an unsupported file format and must be removed.',
    AVATS_STATUS_DEFAULT_TEXT                   = 'There was an unexpected error, the file cannot be uploaded and must be removed',
    AVATS_STATUS_FILE_EXISTS                     = 'FILE NAME ALREADY EXIST IN PROCESSED STORE!',
    AVATS_STATUS_FILE_EXISTS_TEXT                 = 'This file cannot be uploaded and must be renamed.  A file with this name is already uploaded.'
}

export enum REQUSEST_STATUS {
  NEW             = 1,
  IN_PROGRESS     = 2,
  IN_PROGRESS_C   = 3,
  SUBMITTING      = 4,
  NOT_SUBMITTED   = 5,
  RETURNED        = 6,
  SUBMITTED       = 7
}

export enum FILE_STATUS {
  UPLOADED = '1', 
  ACCEPTED = '2', 
  REJECTED = '3',
  NOFILES = '4'
}

export enum REMOVE_RESPONSE_CODE {
  FILE_NOT_DELETED = 405, 
  FILE_ALREADY_DELETED = 410, 
}

export enum LL_SAVE_ERRORS {
  FULL_SUCCESS = 'save-success',
  FULL_FAILURE = 'full-failure',
  PART_SUCCESS = 'part-success',
  FILE_DELETED = 'file-deleted', 
  FILE_REJECTED = 'file-rejected', 
  PART_SUCCESS_ADD_ONLY = 'add-only-error',
  PART_SUCCESS_REMOVE_ONLY = 'remove-only-error',
  PART_SUCCESS_ADD_REMOVE = 'add-remove-error'
}
export enum LL_RESP_CODE {
  SUCCESS = 200,
  FILE_DELETED = 410,
  FULL_FAILURE = 409,
}

export enum FILTER_LEARNER {
  ALL   = 'all', 
  SEL   ='selected', 
  UNSEL ='unselected', 
  LNKD  ='linked', 
  NONE  =''
}

export enum SEL_ALL_ACTIONS {
  ADDALL = 'addall',
  REMOVEALL = 'removeall'
}

export enum FILE_USER_TYPE {
  LEARNER_EVIDENCE = 1,
  ADMIN_LP = 2,
  SHARED_LP = 3,
}

export enum ASSESSMENT_TYPE {
  EXAMINATION = 1,
  MODERATION = 2
}

export enum LINKED_LEARNER_FILTER {
  ALL = 0,
  LINKED = 1,
  UNLINKED = 2
}

export const PQS = [4];
export const PQS_EMS = [4,5]

export const DEFAULT_ERROR_CODE = '99999999-9999-9999-9999-999999999999';