export class QualificationDetails {
    qualificationTypeCode: string;
    qualificationTypeName: string;
    qualifications: Qualifications[];
}

export class Qualifications {
    qualificationCode: string;
    qualificationName: string;
}

export class AssessmentDeadlines {
    assessmentDeadlineId: number;
    seriesCode: string;
    seriesName: string;
    qualificationTypeCode: string;
    qualificationTypeName: string;
    qualificationCode: string;
    qualificationName: string;
    unitCode: string;
    unitName: string;
    isDlwtEnabled: boolean;
    assessmentMethodType: number;
    sampleSelectedBy: string;
    markingMethod: string;
    isSetupSubmitted: boolean;
    submittedOn?: Date;
    submittedBy: string;
    accessBeginDate?: Date;
    submissionDeadline?: Date;
    aADeadline?: Date;
    createRequestEndDate?: Date;
    updateRequestEndDate?: Date;
    accessEndDate?: Date;
    overrideAccessBeginDate?: Date;
    overridesubmissionDeadline?: Date;
    overrideaaDeadline?: Date;
    overridecreateRequestEndDate?: Date;
    overrideupdateRequestEndDate?: Date;
    overrideaccessEndDate?: Date;
    prsSubmittedOn?: Date;
    prsSubmittedBy?: string;
    prsExternalUserAccessBeginDate?: Date;
    prsInternalUserAccessBeginDate?: Date;
    prsExternalUserAccessEndDate?: Date;
    prsInternalUserAccessEndDate?: Date;

}

export class SubmitSetupResponse {
        assessmentDeadlineId: number;
        hasError: boolean;
        responseCode: number;
        responseText: String;
}

export class SubmitSetupPrsResponse {
    assessmentDeadlineId: number;
    hasError: boolean;
    responseCode: number;
    responseText: String;
}

export class ConfigSearchResult {
    assessments: AssessmentDeadlines[];
    pageNumber: number;
    totalPages: number;
    totalRecords: number;
}