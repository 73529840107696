import { connect } from "react-redux";
import { StoreState } from "../../store/store-state";
import ManageAssessmentDeadlineConfig, { StateProps, DispatchProps } from "./manage-assessment-deadline-config.component";
import {
  ManageAssessmentDeadlineConfigAction,
  getAssessmentDeadline,
  submitAssessmentDeadline,
  submitPrsAssessmentDeadline
} from "./ducks/manage-assessment-deadline-config.actions";
import { ThunkDispatch } from "redux-thunk";


const mapStateToProps = (state: StoreState): StateProps => {
  return {
    submitSetupResponse: state.submitSetupResponse,
    submitSetupPrsResponse: state.submitSetupPrsResponse,
    selectedAssessmentDeadline: state.manageAssessmentDeadline,
    isDlwtManaged: ""
  };
};


const mapDispatchToProps = (
  dispatch: ThunkDispatch<StoreState, undefined, ManageAssessmentDeadlineConfigAction>
): DispatchProps => {
  return {
    getAssessmentDeadline: (assessmentDeadlineId: number) =>
      dispatch(getAssessmentDeadline(assessmentDeadlineId)),
    submitAssessmentDeadline: (assessmentDeadlineId: number, isDlwtEnabled: boolean, accessBeginDate?: Date, accessEndDate?: Date, submissionDeadline?: Date, aaDeadline?: Date, createRequestEndDate?: Date, updateRequestEndDate?: Date, isSetupSubmitted?: boolean) =>
      dispatch(submitAssessmentDeadline(assessmentDeadlineId, isDlwtEnabled, accessBeginDate, accessEndDate, submissionDeadline, aaDeadline, createRequestEndDate, updateRequestEndDate, isSetupSubmitted)),
    submitPrsAssessmentDeadline: (assessmentDeadlineId: number, prsInternalUserAccessBeginDate?: Date, prsInternalUserAccessEndDate?: Date, prsExternalUserAccessBeginDate?: Date, prsExternalUserAccessEndDate?: Date) =>
      dispatch(submitPrsAssessmentDeadline(assessmentDeadlineId, prsInternalUserAccessBeginDate, prsInternalUserAccessEndDate, prsExternalUserAccessBeginDate,prsExternalUserAccessEndDate))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageAssessmentDeadlineConfig);
