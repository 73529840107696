import * as React from "react";
import * as _ from "lodash";
import { FormEvent } from "react";
import { Option } from "../../models/option";
import Select from "./select";
import Icon from "./icon";
import Input from "./input";
// import { getSavedSearch, updateSavedSearch } from "../../helpers/dashboard-request-helper";
// import SavedSearch from '../../models/saved-search';

interface SearchBarProps {
  startDisabled: boolean;
  options: Option[];
  placeholderOptions?: string;
  placeholderText?: string;
  searchOption: string;
  searchText: string;
  onSearch: (e: any, searchOption: string, searchText: string) => void;
  onTextChange: (e: any) => void;
  onOptionChange: (e: any) => void;
  onSearchUpdate: (T: string, O: string) => void;
  onViewAll?: boolean;
}

interface LocalState {
  // searchOption: string;
  // searchText: string;
  inputDisabled: boolean;
}

export class SearchBar extends React.Component<SearchBarProps, LocalState> {
  static defaultProps = {
    startDisabled: false,
    placeholderOptions: "",
    placeholderText: "",
    selectedOption: "",
    selectedText: ""
  };

  raiseDoSearchWhenUserStoppedTyping = _.debounce(() => {
    // this.props.onSearch(this.props.searchOption, this.state.searchText);
  }, 300);

  constructor(props: SearchBarProps) {
    super(props);
    this.state = {
      // searchOption: this.props.searchOption,
      // searchText: this.props.searchText,
      inputDisabled: this.props.startDisabled
    };
  }

  onOptionChange = (event: FormEvent<HTMLSelectElement>) => {
    event.preventDefault();
    const option =
      event && event.target && (event.target as HTMLSelectElement).value;
    // this.setState({searchOption:option})
    this.props.onOptionChange(option);
    if (option !== "" && this.state.inputDisabled && this.props.startDisabled) {
      this.setState({ inputDisabled: false });
    }
    this.props.onSearch(event, option, this.props.searchText);
  };

  // handleCriteriaChange = (e: React.FormEvent<HTMLInputElement>) => {
  //   this.setState({ searchText: e.currentTarget.value }, () => {
  //     this.raiseDoSearchWhenUserStoppedTyping();
  //   });
  // };

  _handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      this.props.onSearch(e, this.props.searchOption, this.props.searchText);
    }
  };

  resetSearchText = (e: any) => {
    this.props.onTextChange("");
    this.props.onSearch(e, this.props.searchOption, "");
  };

  render() {
    return (
      <div className=" gr-grid-container margin0 topPad10">
        <div className="gr-col-one gr-col-span-three">
          Status
          <Select
            id="searchOption"
            placeholder={this.props.placeholderOptions}
            value={this.props.searchOption}
            options={this.props.options}
            onChange={this.onOptionChange}
          />
        </div>
        <div className="gr-form-element input gr-col-one gr-col-span-four ">
          <br />
          <Input
            id={"searchText" + Date.now()}
            placeholder={this.props.placeholderText}
            value={this.props.searchText}
            disabled={this.props.startDisabled}
            onChange={this.props.onTextChange}
            onKeyDown={this._handleKeyDown}
          />
          <button
            className="search-bar-btn"
            style={{ position: "relative" }}
            onClick={this.resetSearchText}
          >
            <Icon size="18" icon="close" styleCss="gr-condition-one" />
          </button>
        </div>
        <div className="gr-form-element button gr-col-one gr-col-span-one ">
          <button
            style={{ marginTop: "30px" }}
            onClick={(e: any) =>
              this.props.onSearch(
                e,
                this.props.searchOption,
                this.props.searchText
              )
            }
          >
            <Icon icon="search" size="18" />
          </button>
        </div>
        <div
          className="gr-col-one gr-col-span-one"
          style={{ marginTop: "33px" }}
        >{
          !(this.props && this.props.onViewAll) && 
          <div
            className="tooltip gr-neutral-med-one gr-meta-without-color"
            style={{ float: "left" }}
          >
            <Icon icon="information" size="18" />
            <span className="tooltiptext infotooltiptext">
              You can only see a qualification listed within Learner Work
              Transfer when there is at least one evidence request for an
              associated subject. If a qualification should be listed but is
              missing, please use the help icon at the top of this page to seek
              assistance.
            </span>
          </div>
        }
          
        </div>
      </div>
    );
  }
}

export default SearchBar;
