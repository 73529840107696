import axios from "axios";
import config from "../app-config";
import User from "../models/user";

export const getUser = (forgeRockId:string=""):Promise<any> => {
    // const mockUsers = config.IS_MOCK.MOCK_USERS ? 1 : 0;

    const axiosConfig = {
      headers: {
        "content-type": "application/json",
      }
    };
    return axios
      .get(
        `${config.API_GATEWAY.URL}/user/getuserdetails`,
        axiosConfig
      )
      .then( (response: any) => {
        var userDetails = response.data as User;
        window.localStorage.setItem("internalUserHasValidPermissions", (!userDetails.internalUser ? false : (userDetails.internalUser && userDetails.isUserPermissionValid)).toString());
        return userDetails
      })
      .catch((error: any) => {
        console.log("Get User error", error)
        return error
      });
  }

  export const getLearningProviderName = (users: any, learningProviderId: string) => {
    return users.filter((user: any)=>{
      return user.learningProviderCode === learningProviderId
    })
  }