import { actionTypes } from "../../../actions/action-types";
import { LinkLearnersAction } from "./link-learners.actions";
import initialState from "../../../reducers/initialState";
import  {LearnerLL, SaveLearners, SaveLinkLearners}  from "../../../models/learner";
import SharedFileLearners from "../../../models/shared-file";


// export function GetLinkLearnersDetailsReducer(
//   state: LearnerLL[] = initialState.linkLearnersDetails,
//   action: LinkLearnersAction | any
// ): LearnerLL[] {
//   if (action.type === actionTypes.GET_LINK_LEARNER_DETAILS_SUCCESS) {
//     // console.log("Get Link Learners---------------------------")
//     return action.linkLearnersDetailsData;
//   } else {
//     return state;
//   }
// }

export function UpdateLLLoaderReducer(
  state: boolean = initialState.isShowLLLoader,
  action: LinkLearnersAction | any
): boolean {
  if(action.type === actionTypes.UPDATE_LINK_LEARNER_LOADER_SUCCESS) {
    // console.log("---REDUCER-BEFORE STATE UPDATE---",state)        
    // console.log("---ML REDUCER DATA---",action.isShowLLLoader)      
    return action.isShowLLLoader;
  } else {
    return state;
  }
}

export function UpdateLinkLearnerReducer(
  state: number[] = initialState.currentlySelectedLinkLearners,
  action: LinkLearnersAction | any
): number[] {  
  if (action.type === actionTypes.UPDATE_LINK_LEARNER_SUCCESS) {    
    return action.selectedLearnersData;
  } else {
    return state;
  }
}

export function SaveLinkLearnersDetailsReducer(
  state: SaveLinkLearners = initialState.saveLinkLearners,
  action: LinkLearnersAction | any
): SaveLinkLearners {
  // console.log("Save Link Learners---------------------------",action);
  if (action.type === actionTypes.SAVE_LINK_LEARNER_SUCCESS) {
    return action.saveLinkLearnersData;
  } else {
    return state;
  }
}

export function GetSharedFileLearnersReducer(
  state: SharedFileLearners = initialState.sharedFileLearnersResponse,
  action: LinkLearnersAction | any
): SharedFileLearners {
  if (action.type === actionTypes.GET_SHARED_FILE_LEARNERS_SUCCESS) {
    return action.sharedFileLearnersData;
  } else {
    return state;
  }
}


