import { connect } from "react-redux";
import { StoreState } from "../../store/store-state";
import LinkLearners from "./link-learners.component";
import { LearnerLL, LearnerML, LearnersList, SaveLearners, SaveLinkLearners } from "../../models/learner";
import { ThunkDispatch } from "redux-thunk";

import {
    LinkLearnersAction ,
    // getLinkLearnersDetails,
    updateLinkLearner,
    updateLLLoader,
    saveLinkLearnersDetails,
    getSharedFileLearners
  } from "./ducks/link-learners.actions";

  import {getAdminMaterialSummary} from "../view-learner/ducks/view-learner.actions";
  import { AdminLearnerSummary, AdminSharedMaterialSummary } from "../../models/admin-material";
  import SharedFileLearners from "../../models/shared-file";

export interface StateProps {
  learners?: LearnerLL[];
  currentlySelectedLinkLearners?: number[];
  saveLearnersResponse: SaveLinkLearners;
  adminSharedMaterial: AdminSharedMaterialSummary;
  sharedFileLearnersResponse: SharedFileLearners;
  isShowLLLoader: boolean;
}

export interface DispatchProps {
  // getLearners: (allocationId: string) => Promise<void>;
  updateLinkLearner: (learnersData: number[]) => LinkLearnersAction;
  updateLLLoader: (isShowLLLoader:boolean) => LinkLearnersAction;
  saveLinkLearners: (
    allocationId: number,
    fileKey: string, 
    selectedLearners: number[], 
    unSelectedLearners: number[]
  ) => Promise<void>;
  getAdminMaterialSummary: (allocationId: number) => Promise<void>;
  getSharedFileLearners: (allocationId: number, fileKey: string, assessmentMethodType: number) => Promise<void>;
}

const mapStateToProps = (state: StoreState): StateProps => {
  
  return {
    // learners: state.linkLearnersDetails,
    currentlySelectedLinkLearners: state.currentlySelectedLinkLearners,
    saveLearnersResponse: state.saveLinkLearners,    
    adminSharedMaterial: state.adminSharedMaterial,
    sharedFileLearnersResponse: state.sharedFileLearnersResponse,
    isShowLLLoader: state.isShowLLLoader
  };
};

const mapDispatchToProps = (
    dispatch: ThunkDispatch<StoreState, undefined, LinkLearnersAction>
  ): DispatchProps => {
    return {
        // getLearners: (allocationId: string) =>
        //   dispatch(
        //     getLinkLearnersDetails(allocationId)
        // ),
        updateLinkLearner: (learnersData: number[]) =>
          dispatch(updateLinkLearner(learnersData)), 
        updateLLLoader: (isShowLLLoader:boolean) => 
          dispatch(updateLLLoader(isShowLLLoader)), 
        saveLinkLearners: (allocationId: number, fileKey: string, selectedLearners: number[], unSelectedLearners: number[]) =>
          dispatch(
            saveLinkLearnersDetails(
              allocationId, 
              fileKey,
              selectedLearners, 
              unSelectedLearners
            )
          ),
          getAdminMaterialSummary: (allocationId: number) => 
            dispatch(getAdminMaterialSummary(allocationId)),
          getSharedFileLearners: (allocationId: number, fileKey: string, assessmentMethodType: number) =>
            dispatch(getSharedFileLearners(allocationId, fileKey, assessmentMethodType))
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(LinkLearners);

