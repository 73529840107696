import React, { useEffect } from "react";
import "../../App.css";
import CheckUser from "../common/check-user";
import { useUserLoginState } from "../../shared/context-api/hooks";
import moment from "moment";
import ExamRequest from "../../models/exam-request";
import { ExamRequestsSummary } from "../../models/exam-request";
import Loader from "../common/loader/loader";
import Dashboard from "./dashboard";
import { SubmitAllowed } from "../../models/submit-allocation";
import * as dbrdHelper from '../../helpers/dashboard-helper'


export interface StateProps {
  examRequests?: ExamRequest[];
  examRequestStatusCode?: number;
  examRequestsSummary?: ExamRequestsSummary;
  submitAllowed: SubmitAllowed;
}

export interface DispatchProps {
  getExamRequests: (
    learningProviderCode: string,
    status: number[],
    searchText: string
  ) => Promise<void>;
  getExamRequestsSummary: (learningProviderCode: string) => Promise<void>;
  submitAllocation: (
    allocationId: number,
    assessmentMethodType: number
  ) => Promise<void>;
  checkSubmitAllowed: (
    allocationId: number,
    assessmentMethodType: number
  ) => Promise<void>;
}

type Props = DispatchProps & StateProps;

const DashboardComponent = (props: Props) => {
  const userLoginstate = useUserLoginState();
  const isValidUser =
    userLoginstate &&
    userLoginstate.userData &&
    userLoginstate.userData.isUserPermissionValid;
  const UserLockData =
    userLoginstate &&
    userLoginstate.userData &&
    userLoginstate.userData.lockedUntil;
  const isLockedUser =
    (UserLockData && moment(UserLockData).isAfter()) || false;

  const [loading, setLoading] = React.useState(true);
  const examRequestStatusCode = props && props.examRequestStatusCode;
  const learningProviderId =
    userLoginstate &&
    userLoginstate.userData &&
    userLoginstate.userData.associatedLearningProviders &&
    userLoginstate.userData.associatedLearningProviders[0] &&
    userLoginstate.userData.associatedLearningProviders[0].learningProviderCode;

  const learningProviderLength =
    userLoginstate &&
    userLoginstate.userData &&
    userLoginstate.userData.associatedLearningProviders &&
    userLoginstate.userData.associatedLearningProviders.length;

  useEffect(() => {
    if (learningProviderLength === 1) {
      if (learningProviderId) {
        props && props.getExamRequestsSummary(learningProviderId);
      }
    } else {
      const getCenterNameStorage = localStorage.getItem("centerName");
      const getCenterName =
        getCenterNameStorage &&
        getCenterNameStorage.substring(0, getCenterNameStorage.indexOf(" "));
      if (getCenterName) {
        props && props.getExamRequestsSummary(getCenterName);
      }
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learningProviderId]);

  useEffect(() => {
    if (examRequestStatusCode && examRequestStatusCode === 200) {
      setLoading(false);
    }
    window.addEventListener('beforeunload', dbrdHelper.alertUser)
    window.addEventListener('unload', dbrdHelper.handleTabClosing)
        return () => {
            window.removeEventListener('beforeunload', dbrdHelper.alertUser)
            window.removeEventListener('unload', dbrdHelper.handleTabClosing)
        }
  }, [examRequestStatusCode]);

  document.title = "Learner Work Transfer - Dashboard";
  const { examRequests, examRequestsSummary } = props;

  return (
    <div>
      {isValidUser && !isLockedUser ? (
        <div className="contentWrapper">
          {loading ? (
            <Loader />
          ) : (
            <div>
              <Dashboard
                examRequests={examRequests}
                examRequestsSummary={examRequestsSummary}
                {...props}
              />
            </div>
          )}
        </div>
      ) : (
        <CheckUser islockeduser={isLockedUser} />
      )}
    </div>
  );
};

export default DashboardComponent;
