import _ from "lodash";
import React from "react";
import { dateFormatter } from "../../helpers/dashboard-request-helper";
import { LearnerML } from "../../models/learner";
import  { useUserLoginState } from '../../shared/context-api/hooks';
import { HasWriteAccess  } from "../../components/common/user-validation";
const disabled_checked_checkebox = require('../../assets/images/disabled_checked-checkebox.JPG')
const disabled_unchecked_checkebox = require('../../assets/images/disabled_unchecked-checkebox.JPG')


interface learnerCardProps {
  learnerCode?: string;
  learnerName?: string | number;
  dob?:string;
  learner?: LearnerML,
  isChecked: boolean,
  onCheckboxChange: (e: any)=>void,
  onSelectAll?: (e:any)=>void,
  isDisabled?: boolean,
}

const LearnerCard = (props: learnerCardProps) => {
  const { learner } = props;
  const userLoginstate = useUserLoginState();
  const isLearnerDisabled = props.isDisabled || false;
   /***MOCK MOCK MOCK  */
  // const isLearnerDisabled = _.includes(['1014','1029'], learner?.learnerCode);
  return (
    <div className="uploadHeader" style={{marginBottom:"9px"}}>
    <div className="uploadcheckbox">
      {isLearnerDisabled? 
        <img src={props.isChecked?disabled_checked_checkebox:disabled_unchecked_checkebox} />:
        <input 
          type="checkbox" 
          id="" 
          key={learner?.learnerId} 
          value={learner?.learnerId}  
          checked={props.isChecked} 
          onChange={props.onCheckboxChange}
          disabled={!HasWriteAccess(userLoginstate)}         
        />
     }
    </div>
    <div className="gr-grid-container ie-flex margin0">
      <div className="uploadtext gr-col-one  gr-col-span-nine gr-h3 gr-font-normal semi-bold-650">
        {learner?.learnerCode} {learner?.learnerFirstName} {learner?.learnerLastName}
      </div>
      <div className="uploadtext gr-col-one  gr-col-span-two">
        <div className="dobLabel gr-neutral-med-one ">DOB</div>
        <div className="spacing">&nbsp;</div>
        <div className="statusText ">{learner?.learnerDob !== null && learner?.learnerDob !== "NA"  ? dateFormatter(learner?.learnerDob+"").date: "NA" }</div>
        <div className="spacing">&nbsp;</div>        
      </div>
      {/* <div className="uploadtext gr-col-span-one">
        {learner.isIseries && 
          <span className="semi-bold-400" 
            style={{padding: "5px", backgroundColor:"#007FA3",  color: "#fff"}}
          >System</span>
        }
      </div>       */}
    </div>
  </div>
  
  
  );

  function handleDisabledOnChange (e:any) {
    e.preventDefault();
  }
};



export default LearnerCard;
