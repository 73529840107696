import { combineReducers } from "redux";
import { StoreState } from "../store/store-state";
// import { ExamRequestsReducer as examRequests } from "./exam-requests.reducer";
//import { ExamRequestsStatusReducer as examRequestStatusCode } from "./exam-requests.reducer";
import { ExamRequestsStatusReducer as examRequestStatusCode } from "../components/dashboard/ducks/dashboard.reducers";
// import { ExamRequestsSummaryStatusReducer as examRequestSummaryStatusCode } from"../components/dashboard/ducks/dashboard.reducers";
import { GetEnvReducer as showCreateMenu } from "./user.reducers";
import { GetExamRequestsSummaryReducer as examRequestsSummary } from "../components/dashboard/ducks/dashboard.reducers";
import { ExamRequestsReducer as examRequests } from "../components/dashboard/ducks/dashboard.reducers";
import { GetViewPapersReducer as viewPapers } from "../features/view-papers/ducks/view-papers.reducers";
import { GetViewLearnersDetailsReducer as viewLearnersDetails } from '../features/view-learner/ducks/view-learner.reducers';
// import { UpdateLearnerStatusReducer as updateLearnerStatus } from '../features/view-learner/ducks/view-learner.reducers';
import { AddRemoveLearnerMaterialReducer as learnerMaterial } from '../features/view-learner/ducks/view-learner.reducers'
import { SubmitAllocationReducer as submitAllocation } from '../components/dashboard/ducks/dashboard.reducers'
import { CheckSubmitAllowedReducer as submitAllowed } from '../components/dashboard/ducks/dashboard.reducers'
import { GetSeriesReducer as series, SearchEvidenceReducer as evidenceSearchResult } from '../features/search-evidence/ducks/search-evidence.reducers';
import { GetModerationRequestsSummaryReducer as moderationRequestsSummary } from "../components/moderation/ducks/moderation-dashboard.reducers";
import { ModerationRequestsReducer as moderationRequests } from "../components/moderation/ducks/moderation-dashboard.reducers";
import { ModerationRequestsStatusReducer as moderationRequestStatusCode } from "../components/moderation/ducks/moderation-dashboard.reducers";
import { GetManageLearnersDetailsReducer as manageLearnersDetails } from '../features/manage-learners/ducks/manage-learners.reducers';
//import { GetLinkLearnersDetailsReducer as linkLearnersDetails } from '../features/link-learners/ducks/link-learners.reducers';
import { UpdateManageLearnerReducer as currentlySelectedManageLearners } from '../features/manage-learners/ducks/manage-learners.reducers';
import { UpdateLinkLearnerReducer as currentlySelectedLinkLearners } from '../features/link-learners/ducks/link-learners.reducers';
import { SaveManageLearnersDetailsReducer as saveLearners } from '../features/manage-learners/ducks/manage-learners.reducers';
import { SaveLinkLearnersDetailsReducer as saveLinkLearners } from '../features/link-learners/ducks/link-learners.reducers';
import { AddRemoveAdminMaterialReducer as adminMaterial } from '../features/view-learner/ducks/view-learner.reducers'
import { GetAdminMaterialSummaryReducer as adminSharedMaterial } from '../features/view-learner/ducks/view-learner.reducers'
import { GetSharedFileLearnersReducer as sharedFileLearnersResponse } from '../features/link-learners/ducks/link-learners.reducers';
import { UpdateLLLoaderReducer as isShowLLLoader } from '../features/link-learners/ducks/link-learners.reducers';
import { DownloadAdminMaterialReducer as downloadAdminMaterial } from '../features/view-learner/ducks/view-learner.reducers'

import { GetQualificationDetailsReducer as qualificationDetails,GetAssessmentDeadlinesReducer as assessmentDeadlines,GetSeriesReducer as assesssmentDeadlineConfigseries } from '../features/assessment-deadline-config/ducks/assessment-deadline-config.reducers';
import { GetManageAssessmentDeadlinesReducer as manageAssessmentDeadline,SubmitAssessmentDeadlineReducer as submitSetupResponse,SubmitPrsAssessmentDeadlineReducer as submitSetupPrsResponse} from '../features/manage-assessment-deadline-config/ducks/manage-assessment-deadline-config.reducers';

const rootReducer = combineReducers<StoreState>({
  showCreateMenu,
  examRequests,
  examRequestStatusCode,
  examRequestsSummary,
  downloadAdminMaterial,
  viewPapers,
  viewLearnersDetails,
  learnerMaterial,
  submitAllocation,
  submitAllowed,
  series,
  evidenceSearchResult,
  moderationRequests,
  moderationRequestStatusCode,
  moderationRequestsSummary,
  manageLearnersDetails,
  //linkLearnersDetails,
  currentlySelectedManageLearners,
  currentlySelectedLinkLearners,
  saveLearners,
  saveLinkLearners,
  adminMaterial,
  adminSharedMaterial,
  sharedFileLearnersResponse,
  isShowLLLoader,
  qualificationDetails,
  assessmentDeadlines,
  submitSetupResponse,
  submitSetupPrsResponse,
  manageAssessmentDeadline,
  assesssmentDeadlineConfigseries
});

export default rootReducer;
