import * as React from "react";
//import * as _ from "lodash";
import Modal from "react-bootstrap/Modal";
import Button from "./button";
import "./modal.css";
import { FILE_USER_TYPE } from "../../shared/app-enums";

interface ModalProps {
  title?: string;
  modalData: string;
  show: boolean;
  onCancel: () => void;
  onConfirm?: () => void;
  isConfirmBut?: boolean;
  styleClass?: string;
  submitAllowed?: boolean;
  fromKebabMenu?: boolean;
  fileType?:number;
}

const CommonModal = ({
  title,
  modalData,
  show,
  onCancel,
  onConfirm,
  isConfirmBut,
  styleClass,
  submitAllowed,
  fromKebabMenu,
  fileType=0,
}: ModalProps) => {
  //   const onFormSubmit = (
  // //     event:
  // //       FormEvent<HTMLFormElement> |
  // //       React.MouseEvent<HTMLElement, MouseEvent>
  // //   ) => {
  // //     event.preventDefault();
  // //     onReOpen();
  //   };
  return (
    <div className="re-open-appeal-modal">
      <Modal
        show={show}
        size="lg"
        onHide={onCancel}
        dialogClassName={styleClass}
        aria-labelledby="Re-open appeal"
      >
         <Modal.Header closeButton>
         <Modal.Title> <h3>{title}</h3>  </Modal.Title>
          </Modal.Header>
        <Modal.Body>
          <div className="outcome">
            {modalData === "dashboardTooltip" && (
              <p>
                You can only see a qualification listed within Learner Work
                Transfer when there is at least one evidence request for an
                associated subject. If a qualification should be listed but is
                missing, please use the help icon at the top of this page to
                seek assistance.
              </p>
            )}
            
            {modalData === "removeAllFiles" && (
              <div>
                <p>
                  You are about to remove all files. Please note that, only the
                  files in one of the following status will be removed:
                </p>
                <ul>
                  <li>Uploaded</li>
                  <li>Accepted</li>
                  <li>Rejected</li>
                  { (fileType !== FILE_USER_TYPE.ADMIN_LP) ? 
                  (<li>Not shared with other learners via the shared files functionality</li>) : ""
                  }
                  </ul>
                <p>
                Files will be removed permanently.
                </p>
                
                Do you want to proceed?
              </div>
            )}            
            {(((modalData === 'submitRequest' && fromKebabMenu) || (modalData === 'submitRequest' )) && !submitAllowed) && (
            <div>
              <p style={{fontSize:"13px"}}>This request cannot be submitted because of one or more of the following issues:</p>
              <ul>
                <li style={{fontSize:"13px"}}>
                Files have been uploaded to the Shared Files section but not linked to at least one learner
                </li>
                <li style={{fontSize:"13px"}}>
                Files have been uploaded to the Administration Section but all learners are flagged as 'Absent' or 'No Evidence' being provided
                </li>
                <li style={{fontSize:"13px"}}>
                At least one learner is flagged as 'Present' and no evidence has been uploaded for them
                </li>
                <li style={{fontSize:"13px"}}>
                At least one file is in a 'Rejected' state and has not been removed from the request
                </li>
              </ul>
              <p style={{fontSize:"13px"}}>Please make the required corrections so that your request can be submitted.</p>
              </div>
            ) }
            {(((modalData === 'submitRequest' && fromKebabMenu) || (modalData === 'submitRequest' )) && submitAllowed) && (
              <div>
              <p>You are confirming :</p>
              <ul>
                <li>
                  Where evidence cannot be provided the affected learners are
                  marked as absent or as not providing evidence.
                </li>
                <li>
                  Where evidence is provided it is for the correct subject/paper
                  and per the requirements of the assessment.
                </li>
              </ul>
              <p>
                If virus and security checks are successful, the evidence
                request will be submitted.
              </p>
              <p>
                If virus and security checks are not successful, the evidence
                request will not be submitted.
              </p>
              Do you want to proceed? 
              </div>
            ) }
            {modalData === "Amend Setup" && (
              <div>
                <p>The current dates might already be in use to support activity in Learner Work Transfer and Learner Work Assessment. 
                  <br/>
                  Are you sure you want to amend the setup?
                </p>
              </div>
            )}

            {modalData === "Amend Confirmation" && (
              <div>
                <p>Any change(s) might impact Learner Work Transfer and Learner Work Assessment. 
                <br/>
                Are you sure you want to submit the change(s)?
                </p>
              </div>
            )}
            {/* : (
            <div>
              <p>
                You cannot submit the request now, due to one of following
                reasons
              </p>
              <ul>
                <li>
                  All learners should be either marked as Present / Absent / No
                  Evidence
                </li>
                <li>
                  All the learners marked Present should have atleast 1 evidence
                  uploaded
                </li>
                <li>
                  All files uploaded should be in Accepted / Scanning state
                </li>
              </ul>
            </div>
            )} */}
          </div>
        </Modal.Body>
        <Modal.Footer>
        {(modalData==="Amend Setup" || modalData==="Amend Confirmation") && (<Button
            label="Confirm"
            type="submit"
            className="primary"
            onClick={onConfirm}
          /> 
        )}  
          <Button label="Cancel" type="reset" onClick={onCancel} />
          {((submitAllowed  && fromKebabMenu) ||
            (submitAllowed ) || modalData === "removeAllFiles" ) && (
            <Button
              label="Ok"
              type="submit"
              className="primary"
              onClick={onConfirm}
            />
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

CommonModal.defaultProps = {
  size: "sm"
};

export default CommonModal;
