import {actionTypes} from '../actions/action-types';
import  { UserDetailsAction }  from '../actions/user.actions';
import initialState from './initialState';

export function GetEnvReducer(
  state: boolean = initialState.showCreateMenu,
  action: UserDetailsAction
): boolean {
  if (action.type === actionTypes.GET_ENV_SUCCESS) {    
   
        return action.e;
  }
        else{
          return state;
      }
  
}