import { actionTypes } from "../../../actions/action-types";
import { ManageAssessmentDeadlineConfigAction } from "./manage-assessment-deadline-config.actions";
import initialState from "../../../reducers/initialState";
import { AssessmentDeadlines,SubmitSetupResponse,SubmitSetupPrsResponse } from "../../../models/config-models";
export function GetManageAssessmentDeadlinesReducer(
  state: AssessmentDeadlines | null = initialState.manageAssessmentDeadline,
  action: ManageAssessmentDeadlineConfigAction
): AssessmentDeadlines | null {
  if (action.type === actionTypes.CONFIG_MANAGE_GET_ASSESSMENT_DEADLINES) {
    return action.assessmentDeadlineData;
  } else {
    return state;
  }
}

export function SubmitAssessmentDeadlineReducer(
  state: SubmitSetupResponse = initialState.submitSetupResponse,
  action: ManageAssessmentDeadlineConfigAction | any
): SubmitSetupResponse {
  if (
    action.type === actionTypes.CONFIG_SUBMIT_ASSESSMENT_DEADLINE
  ) {
    return action.submitAssessmentDeadlineStatusData;
  } else {
    return state;
  }
}

export function SubmitPrsAssessmentDeadlineReducer(
  state: SubmitSetupPrsResponse = initialState.submitSetupPrsResponse,
  action: ManageAssessmentDeadlineConfigAction | any
): SubmitSetupPrsResponse {
  if (
    action.type === actionTypes.CONFIG_PRS_SUBMIT_ASSESSMENT_DEADLINE
  ) {
    return action.submitPrsAssessmentDeadlineStatusData;
  } else {
    return state;
  }
}