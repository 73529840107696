import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Icon from "../../components/pearson/icon";
import { updateLinkLearner } from "./ducks/link-learners.actions";

interface Props {
    isTop? :boolean;
}

const MSFBackToVL = (props: Props) => {
    const pageURL = window.location.href;
    const lastURLSegment = pageURL.substring(pageURL.lastIndexOf("/") + 1);
    const viewPaperDetails = localStorage.getItem("viewPaperDetails");

    const history = useHistory();
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(updateLinkLearner([]));
    }, []);
  
  
    const backToViewLearners = () => {
      // Link Learner is not refresh-safe
      dispatch(updateLinkLearner([]));

      // Count the selected learners came with API response 
      localStorage.setItem("makeSelectedLearnersEmpty", 'false');
      localStorage.removeItem("viewLearnerGetData")
      lastURLSegment === '1'?history.push("/view-learner/1"):history.push("/view-learner");
    };
  
    return (
      <div role="back-to-vl" style={{ width: "100%" }}>
        <span className="link-learners-link2" style={{ marginTop: "3px", float: "left" }} onClick={backToViewLearners.bind(null)}>
            <Icon size="18" icon="back" />
            <span className="backToViewLearnersLink gr-semi-bold">
              &nbsp;&nbsp;&nbsp;{"Back to View Learners"}
            </span>
        </span>
        {props.isTop?
          <div className="link-learners-header2">
                <span className="link-learners-papername-prefix">Manage Shared Files for&nbsp;</span> 
                <span>{viewPaperDetails}</span>
          </div> : <></>
        }   
      </div>
    );
}

export default MSFBackToVL;