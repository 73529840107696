import React, { useEffect, FormEvent } from "react";
import { useHistory } from "react-router";
import ExamRequest from "../../models/exam-request";
import config from "../../app-config";
import {
  getSavedSearch,
  updateSavedSearch,
  updateViewPapersSavedSearch
} from "../../helpers/dashboard-request-helper";
import {
  ToDoSearchOptions,
  InProgressSearchOptions,
  CompletedSearchOptions
} from "../../models/option";
import { useUserLoginState } from "../../shared/context-api/hooks";
import Loader from "../common/loader/loader";
import SavedSearch from "../../models/saved-search";
import { SubmitAllowed } from "../../models/submit-allocation";
import * as dbrdHelper from "../../helpers/dashboard-helper";
import "./home.css";
import * as feature from "../../helpers/get-features-helper";
import { MODERATION } from "../pearson/shared/feature-constants";
import Modal from '../pearson/InitialModal';

export interface ExamRequestProps {
  getExamRequests: (
    learningProviderCode: string,
    status: number[],
    searchText: string
  ) => Promise<void>;
  examRequests?: ExamRequest[];
  examRequestsSummary: any;
  submitAllocation: (
    allocationId: number,
    assessmentMethodType: number
  ) => Promise<void>;
  checkSubmitAllowed: (
    allocationId: number,
    assessmentMethodType: number
  ) => Promise<void>;
  submitAllowed: SubmitAllowed;
  getExamRequestsSummary: (learningProviderCode: string) => Promise<void>;
}

const Dashboard = (props: ExamRequestProps) => {
  const { examRequestsSummary } = props;
  const tabCountData = examRequestsSummary && examRequestsSummary.reply;
  const history = useHistory();
  const userLoginstate = useUserLoginState();

  const zerothLPId =
    (userLoginstate &&
      userLoginstate.userData &&
      userLoginstate.userData.associatedLearningProviders &&
      userLoginstate.userData.associatedLearningProviders[0] &&
      userLoginstate.userData.associatedLearningProviders[0]
        .learningProviderCode) ||
    "";

  const learningCenter =
    (userLoginstate &&
      userLoginstate.userData &&
      userLoginstate.userData.associatedLearningProviders) ||
    [];

  let centerOptions: any = [{ value: "Select a Learning Provider", key: "" }];

  learningCenter.forEach(function(center) {
    let learningProvider: any = center;
    const centerObj = Object.assign({
      key: center && learningProvider.learningProviderCode,
      value: learningProvider && learningProvider.learningProviderName
    });
    centerOptions.push(centerObj);
  });
  const savedSearch = getSavedSearch() as SavedSearch;
  const getCenterNameStorage = localStorage.getItem("centerName");
  const lsCenterName =
    getCenterNameStorage &&
    getCenterNameStorage.substring(0, getCenterNameStorage.indexOf(" "));
  const [centerCode, setCenterCode] = React.useState(
    lsCenterName ? lsCenterName : ""
  );

  const [savedSearchText, setSaveSearchText] = React.useState(
    savedSearch.searchText ? savedSearch.searchText : ""
  );
  const [savedSearchOption, setSaveSearchOption] = React.useState(
    savedSearch.searchFilter ? savedSearch.searchFilter : ""
  );
  const [refreshInterval] = React.useState(config.refreshTime || 0);
  const [
    savedSearchSelectedAllocId,
    setsavedSearchSelectedAllocId
  ] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [rerender, setReRender] = React.useState(1);
  const [openModal,setOpenModal] = React.useState<boolean>(false);  
  const [initialDisplayText,setInitialDisplayText] = React.useState<string>("");  

  const isSubmitAllowed =
    props && props.submitAllowed && props.submitAllowed.isSubmitAllowed
      ? props.submitAllowed.isSubmitAllowed
      : false;

  let tabStatusCount = React.useMemo(() => {
    return dbrdHelper.getTabStatusCount(tabCountData);
  }, [tabCountData]);

  useEffect(() => {
    let t = document.getElementById("tab");
    let w = t && t.shadowRoot && t.shadowRoot.querySelector("#tabs-wrapper");
    if (!w) {
      // keep rerendering until tabs-wrapper is rendered fully
      setReRender(Math.round(Date.now() / 1000));
      return;
    }
    setReRender(1);
    w?.addEventListener("click", (e: any) => {
      e.preventDefault();
      if (e.target.querySelector('button[id^="tab-"]')) {
        e.stopPropagation();
      }
    });
  }, [rerender]);

  useEffect(() => {
    const savedSearchData = getSavedSearch() as SavedSearch;
    if (
      savedSearchData &&
      savedSearchData.selectedTabIndex &&
      savedSearchData.selectedTabIndex.length
    ) {
      tabStatusCount = parseInt(savedSearchData.selectedTabIndex + "");
    }
    let tab: any = document.querySelector("pearson-tabs");
    if (tab && tab.setAttribute) {
      tab.setAttribute("activeidx", tabStatusCount);
    }
  }, [tabStatusCount]);

  useEffect(() => {
    setLoading(false);
    switchToModerationFilter();
    dbrdHelper.setCurrentAssessMethod("examination");
    let tab: any = document.querySelector("pearson-tabs");
    if (tab && tab.setAttribute) {
      tab.setAttribute(
        "activeidx",
        parseInt(savedSearch.selectedTabIndex + "")
      );
      window.scrollTo(
        0,
        parseInt(localStorage.getItem("scrollPosition") || "0")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const localInitialLogin =  localStorage.getItem("localInitialLogin");
    const initialLoginStatus = feature.getInitialDisplayFromStorage("UserLoginText");
    
    if(!localInitialLogin && initialLoginStatus  ){
      setOpenModal(true);
      localStorage.setItem("localInitialLogin","true")
      setInitialDisplayText(initialLoginStatus)
    }
  }, [props.examRequests]);

  useEffect(() => {
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(getRefreshData, refreshInterval);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshInterval]);

  const switchToModerationFilter = () => {
    if (dbrdHelper.getCurrentAssessMethod() === "moderation") {
      history.push("/moderation");
    }
  };
  const updateSaveSearchText = (searchText: string) => {
    setSaveSearchText(searchText + "");
  };

  const updateSaveSearchOption = (searchFilter: string) => {
    setSaveSearchOption(searchFilter + "");
  };

  const updateCenterCode = (learningProviderId: string) => {
    setCenterCode(learningProviderId);
  };
  const updateLoading = (isLoading: boolean) => {
    setLoading(isLoading);
  };

  const updatesavedSearchSelectedAllocId = (allocId: string) => {
    setsavedSearchSelectedAllocId(allocId);
  };

  const getSearchBarProps = () => {
    return {
      searchOption: savedSearchOption.length ? savedSearchOption : "All",
      searchText: savedSearchText,
      onTextChange: onTextChange,
      onOptionChange: onOptionChange,
      onSearchUpdate: onSearchUpdate
    };
  };

  const getViewRequestsProps = () => {
    return {
      requests,
      navigateToViewLearners,
      navigateToViewAll,
      submitRequest,
      checkSubmitAllowed,
      isSubmitAllowed
    };
  };

  const getRefreshData = () => {
    dbrdHelper.doDataRefresh(
      updateSaveSearchText,
      updateSaveSearchOption,
      zerothLPId,
      props,
      lsCenterName + ""
    );
  };

  const onCenterChange = (event: any) => {
    dbrdHelper.setCurrentAssessMethod("examination");
    dbrdHelper.handleCenterChange(
      event,
      props,
      updateSaveSearchText,
      updateSaveSearchOption,
      updateCenterCode,
      updateLoading,
      learningCenter
    );
  };

  const onSearchToDo = (e: any, searchOption: string, searchText: string) => {
    dbrdHelper.handleSearch(
      updateLoading,
      onSearchUpdate,
      savedSearch,
      props,
      "ToDo",
      searchText
    );
  };

  const onSearchProgress = (
    e: any,
    searchOption: string,
    searchText: string
  ) => {
    dbrdHelper.handleSearch(
      updateLoading,
      onSearchUpdate,
      savedSearch,
      props,
      searchOption,
      searchText
    );
  };

  const onSearchCompleted = (
    e: any,
    searchOption: string,
    searchText: string
  ) => {
    dbrdHelper.handleSearch(
      updateLoading,
      onSearchUpdate,
      savedSearch,
      props,
      "Completed",
      searchText
    );
  };

  const onTextChange = (event: FormEvent<HTMLInputElement>) => {
    const stxt =
      event && event.target && (event.target as HTMLInputElement).value;
    setSaveSearchText(stxt);
  };

  const onOptionChange = (opt: string = "") => {
    setSaveSearchOption(opt);
  };

  const onSearchUpdate = (searchOption: string, searchText: string) => {
    setSaveSearchOption(searchOption);
    setSaveSearchText(searchText);
    updateSavedSearch({
      selectedAllocationId: "",
      searchFilter: searchOption,
      searchText
    });
  };

  const navigateToViewLearners = (selectedAllocationId: string) => {
    let obj = {
      searchText: savedSearchText,
      searchFilter: savedSearchOption,
      selectedAllocationId
    };
    updateSavedSearch(obj);
    history.push("/view-learner");
  };

  const navigateToViewAll = (
    e: any,
    qualificationTypeCode: string,
    seriesCode: string,
    qualificationTypeName: string
  ) => {
    const learningProviderId = savedSearch && savedSearch.learningProviderId;

    if (learningProviderId) {
      let obj = {
        searchText: savedSearchText,
        searchFilter: savedSearchOption,
        selectedTabIndex: savedSearch && savedSearch.selectedTabIndex,
        qualificationTypeCode: qualificationTypeCode,
        seriesCode: seriesCode,
        learningProviderId: learningProviderId,
        qualificationTypeName: qualificationTypeName
      };

      updateViewPapersSavedSearch(obj);
      history.push("/view-papers");
    }
  };

  const submitRequest = (allocationId: number) => {
    const assessmentMethodType = 1;
    props.submitAllocation(allocationId, assessmentMethodType);
  };

  const checkSubmitAllowed = (allocationId: number) => {
    const assessmentMethodType = 1;
    props.checkSubmitAllowed(allocationId, assessmentMethodType);
  };

  const preventTabBodyClick = (e: any) => {
    e.stopPropagation();
  };

  const showModerationDashboard = (e: any) => {
    tabClickHandler();
    dbrdHelper.setCurrentAssessMethod("moderation");
    history.push("/moderation");
  };

  const tabClickHandler = () => {
    const tab = document.querySelector("pearson-tabs");
    let activeTabIndex = tab && tab.getAttribute("activeidx");
    const savedSearchData = getSavedSearch() as SavedSearch;
    const learningProviderId =
      savedSearchData && savedSearch.learningProviderId;

    // DO NOT LET DOUBLE CLICK HAPPEN
    if (activeTabIndex === savedSearch.selectedTabIndex) {
      return;
    }

    dbrdHelper.handleTabClick(
      updateLoading,
      updateSaveSearchText,
      updateSaveSearchOption,
      updatesavedSearchSelectedAllocId,
      props,
      activeTabIndex + "",
      savedSearchData,
      learningProviderId + "",
      savedSearchSelectedAllocId
    );
  };

  const lpID = savedSearch.learningProviderId;
  const requests = props.examRequests;
  return (
    <div className="tabContainer">
      {dbrdHelper.showCenterDropdown(
        learningCenter,
        centerOptions,
        centerCode,
        onCenterChange
      )}
      {lpID && lpID !== "" && (
        <pearson-tabs
          id="tab"
          style={{ backgroundColor: "#f5f5f5" }}
          onClick={tabClickHandler}
        >
          <dbrdHelper.ShowTopTabs />

          <div className="panels" slot="panels" onClick={preventTabBodyClick}>
            <div data-panel key="0" id="preventTab1">
              {" "}
              <div className="gr-grid-container ie-flex card-container-row">
                {dbrdHelper.showTabReqFilterByMethod(
                  "todo",
                  tabCountData && tabCountData.examination,
                  "Examination",
                  true
                )}
                {feature.getFeatureFromStorage(MODERATION) &&
                  dbrdHelper.showTabReqFilterByMethod(
                    "todo",
                    tabCountData && tabCountData.moderation,
                    "Moderation",
                    false,
                    showModerationDashboard
                  )}
              </div>
              {dbrdHelper.showTabSearchBar(
                ToDoSearchOptions,
                onSearchToDo,
                getSearchBarProps
              )}
              {!loading ? (
                <div>
                  {tabCountData && tabCountData.examination.toDoCount !== 0
                    ? dbrdHelper.showTabRequests("toDo", getViewRequestsProps)
                    : "There are no requests available"}
                </div>
              ) : (
                <Loader />
              )}
            </div>
            <div
              data-panel
              key="1"
              id="preventTab2"
              onClick={preventTabBodyClick}
            >
              <div className="gr-grid-container ie-flex card-container-row">
                {dbrdHelper.showTabReqFilterByMethod(
                  "inprogress",
                  tabCountData && tabCountData.examination,
                  "Examination",
                  true
                )}
                {feature.getFeatureFromStorage(MODERATION) &&
                  dbrdHelper.showTabReqFilterByMethod(
                    "inprogress",
                    tabCountData && tabCountData.moderation,
                    "Moderation",
                    false,
                    showModerationDashboard
                  )}
              </div>
              {dbrdHelper.showTabSearchBar(
                InProgressSearchOptions,
                onSearchProgress,
                getSearchBarProps
              )}
              {!loading ? (
                <div>
                  {tabCountData &&
                  tabCountData.examination.inProgressCount !== 0
                    ? dbrdHelper.showTabRequests(
                        "inProgress",
                        getViewRequestsProps
                      )
                    : "There are no requests available"}
                </div>
              ) : (
                <Loader />
              )}
            </div>
            <div
              data-panel
              key="2"
              id="preventTab3"
              onClick={preventTabBodyClick}
            >
              <div className="gr-grid-container ie-flex card-container-row">
                {dbrdHelper.showTabReqFilterByMethod(
                  "completed",
                  tabCountData && tabCountData.examination,
                  "Examination",
                  true
                )}
                {feature.getFeatureFromStorage(MODERATION) &&
                  dbrdHelper.showTabReqFilterByMethod(
                    "completed",
                    tabCountData && tabCountData.moderation,
                    "Moderation",
                    false,
                    showModerationDashboard
                  )}
              </div>
              {dbrdHelper.showTabSearchBar(
                CompletedSearchOptions,
                onSearchCompleted,
                getSearchBarProps
              )}
              {!loading ? (
                <div>
                  {tabCountData && tabCountData.examination.completedCount !== 0
                    ? dbrdHelper.showTabRequests(
                        "completed",
                        getViewRequestsProps
                      )
                    : "There are no requests available"}
                </div>
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </pearson-tabs>
      )}
      <Modal isConfirmBut={false} show={openModal} title="Confirmation" modalData={initialDisplayText} onCancel={()=>setOpenModal(false)} onConfirm={() => ""} />
    </div>
  );
};

export default Dashboard;
