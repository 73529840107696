import axios, { AxiosRequestConfig } from "axios";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import config from "../../../app-config";
import { ThunkDispatch } from "redux-thunk";

import { actionTypes } from "../../../actions/action-types";
import { StoreState } from "../../../store/store-state";
import { ModerationRequestsSummary } from "../../../models/moderation-request";
import ModerationRequest from "../../../models/moderation-request";

interface GetModerationRequestsSummaryAction extends Action {
  type: actionTypes.GET_MODERATION_REQUESTS_SUMMARY_SUCCESS;
  moderationRequestsSummaryData: ModerationRequestsSummary;
  moderationRequestStatusCode: number;
}

interface GetModerationRequestsAction extends Action {
  type: actionTypes.GET_MODERATION_REQUESTS_SUCCESS
  moderationRequest: ModerationRequest[];
  moderationRequestStatusCode: number
}


export type ModerationRequestsAction =
  | GetModerationRequestsSummaryAction
  | GetModerationRequestsAction

type ThunkResult<R> = ThunkAction<R, StoreState, undefined, ModerationRequestsAction>;

export const GetModerationRequestsSummarySuccessAction = (
  moderationRequestsSummaryData: ModerationRequestsSummary,
  moderationRequestStatusCode: number
): GetModerationRequestsSummaryAction => ({
  moderationRequestsSummaryData,
  moderationRequestStatusCode,
  type: actionTypes.GET_MODERATION_REQUESTS_SUMMARY_SUCCESS,
});

export const GetModerationRequstsSuccessAction = (
  moderationRequest: ModerationRequest[],
  moderationRequestStatusCode: number
): GetModerationRequestsAction => ({
  moderationRequest,
  moderationRequestStatusCode,
  type: actionTypes.GET_MODERATION_REQUESTS_SUCCESS
});

export const getModerationRequestsSummary = (learningProviderCode: string) => {
  return async (
    dispatch: ThunkDispatch<StoreState, void, ModerationRequestsAction>
  ) => {
    try {
      const axiosConfig: AxiosRequestConfig = {
        headers: { "content-type": "application/json" },
      };
      const response = await axios.post(
        `${config.API_GATEWAY.URL}/moderation-requests/summary/0`,
        JSON.stringify({ learningProviderCode }),
        axiosConfig
      );
      const moderationRequestsSummaryData = response.data as ModerationRequestsSummary;
      const moderationRequestStatusCode = response.status;
      dispatch(GetModerationRequestsSummarySuccessAction(moderationRequestsSummaryData, moderationRequestStatusCode));
    } catch (error) {
      
    }
  };
};

export const getModerationRequests = (
  learningProviderCode: string,
  status: number[],
  searchText: string
) => {
  const mockUsers = false ? 1 : 0;

  return async (dispatch: ThunkDispatch<StoreState, void, ModerationRequestsAction>) => {
    try {
      const axiosConfig: AxiosRequestConfig = {
        headers: { "content-type": "application/json" }
      };
      const response = await axios
        .post(`${config.API_GATEWAY.URL}/moderation-requests/get/${mockUsers}`,
          JSON.stringify({
            learningProviderCode,
            status,
            searchText
          }), axiosConfig);
      const moderationDetails = response.data && response.data.allocations;
      const moderationRequestStatusCode = response.status;
      dispatch(GetModerationRequstsSuccessAction(moderationDetails, moderationRequestStatusCode));
    }
    catch (error) {
      
    }
  };
};