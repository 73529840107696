import { actionTypes } from "../../../actions/action-types";
import { ManageLearnersAction } from "./manage-learners.actions";
import initialState from "../../../reducers/initialState";
import  {LearnerML, SaveLearners}  from "../../../models/learner";


export function GetManageLearnersDetailsReducer(
  state: LearnerML[] = initialState.manageLearnersDetails,
  action: ManageLearnersAction | any
): LearnerML[] {
  if (action.type === actionTypes.GET_MANAGE_LEARNER_DETAILS_SUCCESS) {
    // console.log("Get Manage Learners---------------------------")
    return action.manageLearnersDetailsData;
  } else {
    return state;
  }
}

export function UpdateManageLearnerReducer(
  state: number[] = initialState.currentlySelectedManageLearners,
  action: ManageLearnersAction | any
): number[] {  
  if (action.type === actionTypes.UPDATE_MANAGE_LEARNER_SUCCESS) {
    // console.log("---REDUCER-BEFORE STATE UPDATE---",state)        
    // console.log("---ML REDUCER DATA---",action.selectedLearnersData)      
    return action.selectedLearnersData;
  } else {
    return state;
  }
}

export function SaveManageLearnersDetailsReducer(
  state: SaveLearners = initialState.saveLearners,
  action: ManageLearnersAction | any
): SaveLearners {
  // console.log("Save Manage Learners---------------------------",action);
  if (action.type === actionTypes.SAVE_MANAGE_LEARNER_SUCCESS) {
    return action.saveLearnersData;
  } else {
    return state;
  }
}




